import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '.';
import { getWorkoutDetailsThunk } from '../redux/workouts/thunks';
import { Statuses } from '../utils';

const useVideoDetails = (id: string | null | undefined) => {
  const {
    workoutsStore: {
      workoutDetails: { data: workoutDetails },
    },
  } = useAppSelector((state) => ({ workoutsStore: state.workoutsStore }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) dispatch(getWorkoutDetailsThunk(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const videoDetails = id ? workoutDetails[id] : null;

  const detailsStatuse = useAppSelector((state) => state.workoutsStore.workoutDetails.statuse);
  const isVideoDetailsLoading = detailsStatuse === Statuses.idle || detailsStatuse === Statuses.loading;
  const isAuthLoading = useAppSelector((state) => state.authStore.isLoading);
  const isLoading = (isVideoDetailsLoading || isAuthLoading) && !videoDetails;

  return { videoDetails, isLoading, videoDetailsStatuse: detailsStatuse };
};

export default useVideoDetails;
