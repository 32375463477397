import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';

export const getIsFirstVisit = (state: RootState) => state.stepmeterStore.data.isFirstVisit;
export const getIsAccessGranted = (state: RootState) => state.stepmeterStore.data.isAccessGranted;
export const getGoal = (state: RootState) => state.stepmeterStore.data.goal;
export const getProgress = (state: RootState) => state.stepmeterStore.data.progress;
export const getError = (state: RootState) => state.stepmeterStore.error;
export const getCurrentOption = (state: RootState) => state.stepmeterStore.currentOption;
export const getStatuse = (state: RootState) => state.stepmeterStore.statuse;

export const getProgressById = (id: number) =>
  createSelector([getProgress], (data) => data.find((day) => day.id === id) || null);
