import { Stack } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import s from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { AnalyticsName, Paths } from '../../../../utils';
import { Typography } from 'front-package-ui-kit';
import { useEffectOnce } from 'usehooks-ts';
import { useAnalytics, useAppDispatch } from '../../../../hooks';
import { getUserInfoThunk } from '../../../../redux/user/thunks';

const { main } = Paths;

interface IProps {
  handleStep: () => void;
}

const CreatingProgram: FC<IProps> = ({ handleStep }) => {
  const [progress, setProgress] = useState<number>(0);

  // const dispatch = useAppDispatch();

  // const { setAnalytics } = useAnalytics();

  // useEffectOnce(() => {
  //   setAnalytics(AnalyticsName.onboardingDefineCreatingProggrams);

  //   dispatch(getUserInfoThunk());
  // });

  const refBar = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (progress < 100) {
      setTimeout(() => setProgress((prev) => prev + 1), 22);
    }

    if (progress === 100) {
      handleStep();

      setTimeout(() => {
        navigate(main);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  if (refBar.current) refBar.current.style.setProperty('--progress', '100%');

  return (
    <Stack className={s.containerCreatingProgram}>
      <Stack className={s.contentCreatingProgram} marginBottom="20px">
        <Typography variant="h2" fontWeight={700} color="white" marginBottom="88px">
          Приготовьтесь <br /> зарядиться <br /> энергией
        </Typography>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="text6" fontWeight={500} color="white" opacity={0.5}>
            Добавляем <br /> тренировки...
          </Typography>

          <Typography variant="p" color="white" fontWeight={600} fontSize="48px" lineHeight="48px">
            {`${progress}%`}
          </Typography>
        </Stack>
      </Stack>

      <div ref={refBar} className={s.progress}>
        <div className={s.grid}></div>
        <div className={s.bar}></div>
      </div>
    </Stack>
  );
};

export default CreatingProgram;
