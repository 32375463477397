import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import { createServiceNotice } from '../notifications/slice';
import { Environment, noticeCreator } from '../../utils';
import { ICreatetUserActivity, IEditUserActivity, IUserActivity } from '../../api/types';
import { addUserActivity, deleteUserActivity, editUserActivity } from './slice';

export const getUserActivitiesThunk = createAsyncThunk(
  'userActivities/getUserActivities',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getUserActivities()).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'userActivities/getUserActivities', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const createtUserActivityThunk = createAsyncThunk(
  'userActivities/createtUserActivity',
  async (config: ICreatetUserActivity, { rejectWithValue, dispatch }) => {
    try {
      return (await api.createtUserActivity(config)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'userActivities/createtUserActivity', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const deleteUserActivityThunk = createAsyncThunk(
  'userActivities/deleteUserActivity',
  async (userActivity: IUserActivity, { rejectWithValue, dispatch }) => {
    try {
      dispatch(deleteUserActivity(userActivity.id));

      return (await api.deleteUserActivity(userActivity.id)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(addUserActivity(userActivity));

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'userActivities/deleteUserActivity', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const editUserActivityThunk = createAsyncThunk(
  'userActivities/editUserActivity',
  async (config: IEditUserActivity, { rejectWithValue, dispatch }) => {
    try {
      const res = (await api.editUserActivity(config)).data.data;
      dispatch(editUserActivity(res));
      return res;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'userActivities/editUserActivity', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
