import { ProductCard } from 'front-package-ui-kit';
import { FC, MouseEvent, memo } from 'react';

import { IUserActivity } from '../../../../api/types';
import personalActivity from '../../../../assets/image/personalActivity.png';
import { ImageCover } from '../../../../components';

export const TEST_ID = 'UserActivityCard';

export interface UserActivityCardProps {
  activity: IUserActivity;
  withIcon?: boolean;
  setCurrentActivity?: (activity: IUserActivity) => void;
  setAnchorEl?: (target: EventTarget & HTMLButtonElement) => void;
}

const UserActivityCard: FC<UserActivityCardProps> = ({
  activity,
  withIcon = false,
  setCurrentActivity,
  setAnchorEl,
}) => {
  const { id, minutes, text } = activity;

  const handleClick = (acttivity: IUserActivity) => (event: MouseEvent<HTMLButtonElement>) => {
    setCurrentActivity?.(acttivity);

    setAnchorEl?.(event.currentTarget);
  };

  return (
    <ProductCard
      key={id}
      cover={<ImageCover img={personalActivity} />}
      size="S"
      title="Личная активность"
      subTitle={text}
      bottomText={`${minutes} мин`}
      topRightIconOptions={
        withIcon
          ? {
              type: 'Menu',
              onClick: handleClick(activity),
            }
          : undefined
      }
    />
  );
};

export const UserActivityCardMemo = memo(UserActivityCard);
