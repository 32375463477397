import React from 'react';

const MasterCardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18" fill="none">
      <rect x="1" y="1" width="28" height="16" rx="3" fill="white" />
      <path d="M12.5665 4.2833H17.4335V13.7166H12.5665V4.2833Z" fill="#FF5F00" />
      <path
        d="M12.8756 9C12.8756 7.08336 13.7099 5.38333 14.9923 4.2833C14.0498 3.48332 12.8602 3 11.5623 3C8.48752 3 6 5.68329 6 9C6 12.3166 8.48752 15 11.5622 15C12.8601 15 14.0498 14.5167 14.9923 13.7166C13.7099 12.6333 12.8756 10.9166 12.8756 9Z"
        fill="#EB001B"
      />
      <path
        d="M24 9C24 12.3166 21.5125 15 18.4378 15C17.1399 15 15.9502 14.5167 15.0077 13.7166C16.3056 12.6167 17.1245 10.9166 17.1245 9C17.1245 7.08336 16.2901 5.38333 15.0077 4.2833C15.9502 3.48332 17.1399 3 18.4378 3C21.5125 3 24 5.7 24 9Z"
        fill="#F79E1B"
      />
      <rect
        x="0.75"
        y="0.75"
        width="28.5"
        height="16.5"
        rx="3.25"
        stroke="#1D1D1D"
        strokeOpacity="0.1"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default MasterCardIcon;
