import { FC, memo } from 'react';
import s from './styles.module.scss';
import { Stack, StackProps } from '@mui/material';
import { Typography } from 'front-package-ui-kit';

export const TEST_ID = 'SingleParameterBadge';

export interface SingleParameterBadgeProps extends StackProps {
  value: string | number;
}

const SingleParameterBadge: FC<SingleParameterBadgeProps> = ({ value, ...props }) => {
  return (
    <Stack
      columnGap="4px"
      direction="row"
      alignItems="center"
      className={s.singleParameterBadge}
      data-testid={TEST_ID}
      {...props}
    >
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="3.5" fill="#DC01FF" stroke="#2C2C2C" />
      </svg>

      <Stack className={s.badge} justifyContent="center" alignItems="center">
        <Typography variant="text8" color="white">
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const SingleParameterBadgeMemo = memo(SingleParameterBadge);
