import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useBankCards = () => {
  const {
    bankCardsStore: {
      bankCards: { data: bankCards, statuse },
      deleteBankCard: { statuse: deleteStatuse },
      setMainBankCard: { statuse: setMainStatuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ bankCardsStore: state.bankCardsStore, authStore: state.authStore }));

  const mainBankCard = bankCards?.find((card) => card.active);

  const isBankCards = Boolean(bankCards?.length);

  const isBankCardsLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isBankCards) || isLoading;

  const isLinkedBankCards = !!bankCards?.length;

  const isDeleteBankCardLoading = deleteStatuse === Statuses.loading;

  const isSetMainBankCardLoading = setMainStatuse === Statuses.loading;

  return {
    bankCards,
    mainBankCard,
    isBankCards,
    isBankCardsLoading,
    isLinkedBankCards,
    isDeleteBankCardLoading,
    isSetMainBankCardLoading,
  };
};
