import { FilterNames, TypesSort } from '../../utils';

export enum CoursesTabButtonsValues {
  all = 'all',
  inProgress = 'inProgress',
  favorite = 'favorite',
  completed = 'completed',
}

export const SORT_VALUES = [TypesSort.popular, TypesSort.novelty];

export const BUTTON_FILTERS = [
  { courseKey: FilterNames.groups, filterTitle: 'Цель', groupType: 'target' },
  { courseKey: FilterNames.levels, filterTitle: 'Уровень' },
];

//TODO: возможно пригодится
// export const QUICK_FILTERS = [
//   { courseKey: FilterNames.groups, filterTitle: 'Цель', groupType: 'target' },
//   { courseKey: FilterNames.groups, filterTitle: 'Новинки', groupType: 'new' },
//   { courseKey: FilterNames.levels, filterTitle: 'Уровень' },
// ];

// export const NAME_FILTER = [{ courseKey: FilterNames.name, filterTitle: FilterNames.name }];

// export const FILTERS_USED = [...BUTTON_FILTERS, ...QUICK_FILTERS, ...NAME_FILTER];

export const FILTERS_USED = [...BUTTON_FILTERS];

export const PAGE_NAME = 'Courses';
