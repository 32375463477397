import React from 'react';

const ActivityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M3.13485 14.73C3.84983 15.3206 4.6792 15.7424 5.56576 15.9393C5.65156 15.9674 5.70876 15.8549 5.65156 15.7987C3.73543 13.9707 4.78869 11.9925 5.56576 11.1006C6.12038 10.4641 6.90991 9.41462 6.85271 8.00843C6.85271 7.86782 6.99571 7.75532 7.1101 7.83969C8.19686 8.40216 8.94043 9.63961 9.14063 10.6521C9.45522 10.3427 9.56961 9.8646 9.56961 9.44274C9.56961 9.30212 9.7412 9.18963 9.8842 9.30212C10.9138 10.2302 12.6869 13.3801 9.827 15.8549C9.7698 15.9112 9.827 16.0237 9.8842 15.9956C10.7422 15.7706 11.5715 15.3768 12.2865 14.8425C16.9481 11.2427 13.9166 4.85858 11.9433 2.7493C11.6859 2.49619 11.2283 2.66493 11.2283 3.03054C11.1997 3.78988 10.971 4.63359 10.3704 5.19606C9.9128 3.28365 8.38832 1.11153 6.21481 0.0428249C5.92882 -0.0977935 5.58563 0.127196 5.61423 0.436557C5.67143 3.05206 3.96422 4.71796 2.47708 6.88349C1.16153 8.82402 0.303561 12.3957 3.13485 14.73Z"
        fill="#7FFF00"
      />
    </svg>
  );
};

export default ActivityIcon;
