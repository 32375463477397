import React from 'react';
import { FC, SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
  white?: boolean;
}

const ArrowBackIcon: FC<IProps> = ({ white, ...props }) => {
  const fillColor = white ? '#ffffff' : '#1D1D1D';

  return (
    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11 1L1 11L11 21" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowBackIcon;
