import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TTheme } from '../../components/layouts/ThemeProvider';
import { LocalStorage } from '../../utils';

interface ITheme {
  theme: 'dark' | 'light';
}

const initialState: ITheme = {
  theme: 'light',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme(state) {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      state.theme = newTheme;
      localStorage.setItem(LocalStorage.theme, newTheme);
    },
    setTheme(state, { payload }: PayloadAction<TTheme>) {
      state.theme = payload;
    },
  },
});

export const { changeTheme, setTheme } = themeSlice.actions;

export default themeSlice.reducer;
