import React, { FC, memo, useCallback, useState, MouseEvent } from 'react';
import s from './styles.module.scss';
import {
  AddIcon,
  BlockCover,
  DeleteOutlineIcon,
  NamesOfColors,
  Typography,
  VerticalMenuIcon,
} from 'front-package-ui-kit';
import { useAppDispatch, useBankCards, useBottomSheet, usePayment } from '../../../hooks';
import { BankCardsSkeleton } from './BankCardsSkeleton';
import { BankCardIcon } from '../../../assets/icons';
import { Divider, Popover, Stack } from '@mui/material';
import {
  BindingBankCardBottomSheet,
  DeletBankCardBottomSheet,
  Fallback,
  openBindingBankCardBottomSheet,
  openDeletBankCardBottomSheet,
} from '../..';
import { IBankCardWithDataInform } from '../../../api/types';
import { deleteBankCardThunk, setMainBankCardThunk } from '../../../redux/bankCards/thunks';

export const TEST_ID = 'BankCards';

export interface BankCardsProps {}

const BankCards: FC<BankCardsProps> = () => {
  const dispatch = useAppDispatch();

  const { onOpenBottomSheet } = useBottomSheet();

  const { handleBindingBankCard, loading: bindingBankCardLoading } = usePayment();

  const { bankCards, isBankCardsLoading, isDeleteBankCardLoading, isSetMainBankCardLoading } = useBankCards();

  const handleAddBankCard = useCallback(() => {
    onOpenBottomSheet(openBindingBankCardBottomSheet);
  }, [onOpenBottomSheet]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [currentBankCard, setCurrentBankCard] = useState<IBankCardWithDataInform | null>(null);

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const handleClosePopover = () => {
    setCurrentBankCard(null);

    setAnchorEl(null);
  };

  const handleOpenPopover = useCallback(
    (bankCard: IBankCardWithDataInform) => (event: MouseEvent<HTMLButtonElement>) => {
      setCurrentBankCard(bankCard);

      setAnchorEl?.(event.currentTarget);
    },
    []
  );

  const handleSetMainBankCard = useCallback(() => {
    setAnchorEl(null);

    if (!currentBankCard) return;

    dispatch(setMainBankCardThunk(currentBankCard.id));

    setCurrentBankCard(null);
  }, [currentBankCard, dispatch]);

  const handleDeleteBankCard = useCallback(() => {
    setAnchorEl(null);

    onOpenBottomSheet(openDeletBankCardBottomSheet);
  }, [onOpenBottomSheet]);

  const handleDeletMainBankCard = useCallback(
    (bankCard: IBankCardWithDataInform) => () => {
      setCurrentBankCard(bankCard);

      handleDeleteBankCard();
    },
    [handleDeleteBankCard]
  );

  const handleBackDeletBankCard = useCallback(() => {
    setCurrentBankCard(null);
  }, []);

  const handleApplyDeleteBankCard = () => {
    if (!currentBankCard) return;

    dispatch(deleteBankCardThunk(currentBankCard.id));

    setCurrentBankCard(null);
  };

  return (
    <div className={s.bankCards} data-testid={TEST_ID}>
      <BlockCover padding="12px" width="120px" height="92px">
        <button onClick={handleAddBankCard} className={s.btn}>
          <AddIcon color={NamesOfColors.grey50} size={24} />

          <Typography fontWeight={500} variant="text7" textAlign="left">
            Добавить <br /> карту
          </Typography>
        </button>
      </BlockCover>

      {bankCards?.map((card) => (
        <BlockCover key={card.id} padding="12px" width="124px" height="92px">
          <div className={s.btn}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <BankCardIcon bankCardType={card.card_type} />

              {card.active && (
                <button onClick={handleDeletMainBankCard(card)} className={s.btnInCard}>
                  <DeleteOutlineIcon />
                </button>
              )}

              {!card.active && (
                <button onClick={handleOpenPopover(card)} className={s.btnInCard}>
                  <VerticalMenuIcon size={16} color={NamesOfColors.grey50} />
                </button>
              )}
            </Stack>

            <Typography fontWeight={500} variant="text7">
              {`${card.last4 ? card.last4 : 'Привязанная карта'}${card.active ? '· основной' : ''} `}
            </Typography>
          </div>
        </BlockCover>
      ))}

      <Popover
        id={id}
        open={open}
        style={{ zIndex: 99999 }}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack px="14px">
          <button className={s.popoverBtn} onClick={handleSetMainBankCard}>
            <Typography variant="text5" textAlign="left">
              Сделать основным
            </Typography>
          </button>

          <Divider />

          <button className={s.popoverBtn} onClick={handleDeleteBankCard}>
            <Typography variant="text5" textAlign="left">
              Удалить
            </Typography>
          </button>
        </Stack>
      </Popover>

      {/* {isBankCardsLoading && <BankCardsSkeleton />} */}

      <DeletBankCardBottomSheet
        bankCard={currentBankCard}
        onBackClick={handleBackDeletBankCard}
        onApplyClick={handleApplyDeleteBankCard}
      />

      <BindingBankCardBottomSheet
        infoText="Для добавления карты укажите ее данные."
        onApplyClick={() => handleBindingBankCard({ autoRenewal: false })}
        loading={bindingBankCardLoading}
      />

      <Fallback active={isDeleteBankCardLoading || isSetMainBankCardLoading || bindingBankCardLoading} />
    </div>
  );
};

export const BankCardsMemo = memo(BankCards);
