import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { BottomButtonBlock, ButtonWithAnalytic, CssPlatformContainer, VideoCover } from '../../../../components';
import darkScreen from '../../../../assets/image/darkScreen.jpg';
import Start_mp4 from '../../../../assets/video/Start_mp4.mp4';
import Start_ogg from '../../../../assets/video/Start_ogg.ogg';
import Start_webm from '../../../../assets/video/START_webm.webm';
import s from './styles.module.scss';
import { Typography } from 'front-package-ui-kit';
import { AnalyticsName } from '../../../../utils';

interface IProps {
  onClick: () => void;
}

const Init: FC<IProps> = ({ onClick }) => {
  return (
    <div className={s.Init}>
      <VideoCover poster={darkScreen} webm={Start_webm} mp4={Start_mp4} ogg={Start_ogg} />

      <div className={s.shadow}></div>

      <CssPlatformContainer>
        <Stack justifyContent="flex-end" className={s.content}>
          <BottomButtonBlock>
            <ButtonWithAnalytic
              analyticInfo={{ name: AnalyticsName.login }}
              fullWidth
              onClick={onClick}
              variant="contained"
              color="lime"
              size="L"
            >
              <Typography whiteSpace="nowrap" variant="text6" fontWeight={700}>
                Войти / зарегистрироваться
              </Typography>
            </ButtonWithAnalytic>
          </BottomButtonBlock>
        </Stack>
      </CssPlatformContainer>
    </div>
  );
};

export default Init;
