import * as yup from 'yup';

export const emailValidationSchema = yup.object().shape({
  login: yup.string().email('Некорректный email').required('Обязательное поле'),
});

export enum InputNames {
  login = 'login',
}

export const initialValues = {
  login: '',
};
