import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const getDateMonthNameAndDay = (date: Date) => {
  const currentDate = new Date();

  const isCurrentYear = currentDate.getFullYear() === date.getFullYear();

  const transformDate = isCurrentYear
    ? format(date, 'd MMMM', { locale: ru })
    : format(date, 'd MMMM yyyy', { locale: ru });

  return transformDate;
};

export const getDateMonthNameAndDayAndTime = (date: string) => {
  const currentDate = new Date();

  const isCurrentYear = currentDate.getFullYear() === new Date(date).getFullYear();

  const transformDate = format(new Date(date), `d MMMM ${isCurrentYear ? '' : 'yyyy'} в kk:mm`, { locale: ru }).replace(
    /\b24(?=:)/,
    '00'
  );

  return transformDate;
};
