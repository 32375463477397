import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyComments = lazy(() => import('./Comments'));

export const Comments = () => (
  <Suspense fallback={<Loading />}>
    <LazyComments />
  </Suspense>
);
