export enum SwiperPickerValues {
  cm = 'см',
  mm = 'мм',
  kg = 'кг',
  gramm = 'гр',
  h = 'ч',
  min = 'мин',
  km = 'км',
  m = 'м',
  steps = 'шагов',
}
