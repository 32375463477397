import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazySettings = lazy(() => import('./Settings'));

export const Settings = () => (
  <Suspense fallback={<Loading />}>
    <LazySettings />
  </Suspense>
);
