import { useBankCards, useUser } from '.';
import { TypesStatusesOrderPaid, ValuesOfConstants } from '../utils';
import useParseWebView from './useParseWebView';

export const useNeedTrial = () => {
  const { bankCards, isBankCardsLoading } = useBankCards();

  const { user, isUserLoading } = useUser();

  const loading = isBankCardsLoading || isUserLoading;

  const isHasOrderSuccess = user ? user.orders.some(({ status }) => status === TypesStatusesOrderPaid.paid) : false;

  const isHasCard = bankCards ? bankCards.length > 0 : false;

  const [recurrentEnabled] = useParseWebView([ValuesOfConstants.isRecurrentPayment]);

  const isRecurrentEnabled = Boolean(Number(recurrentEnabled));

  const isNeedUseTrial = isRecurrentEnabled && !isHasOrderSuccess && !isHasCard;

  return { isNeedUseTrial, loading };
};
