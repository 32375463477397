import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISavedFilter, TFilters } from '../../hooks/useFilter';
import { FilterNames } from '../../utils';

interface ICachedFiltersInfo {
  filtersHistory: ISavedFilter[];
  filters: TFilters;
}

type TFiltersCache = Record<string, ICachedFiltersInfo>;

interface IFiltersStore {
  filtersCache: TFiltersCache | null;
  singleFilterBottomSheet: string | null;
  prefilledFilterName: FilterNames | null;
  prefilledFilterValue: string;
}

export const initialState: IFiltersStore = {
  filtersCache: null,
  singleFilterBottomSheet: null,
  prefilledFilterName: null,
  prefilledFilterValue: '',
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setCachedFiltersInfo(state, { payload }: PayloadAction<TFiltersCache>) {
      if (state.filtersCache) {
        state.filtersCache = { ...state.filtersCache, ...payload };
      } else {
        state.filtersCache = payload;
      }
    },
    setSingleFilterBottomSheet(state, { payload }: PayloadAction<string>) {
      state.singleFilterBottomSheet = payload;
    },
    clearCashe(state, { payload }: PayloadAction<string>) {
      delete state.filtersCache?.[payload];
    },
    setPrefilledFilterName(state, { payload }: PayloadAction<FilterNames | null>) {
      state.prefilledFilterName = payload;
    },
    setPrefilledFilterValue(state, { payload }: PayloadAction<string>) {
      state.prefilledFilterValue = payload;
    },
  },
});

export const {
  setCachedFiltersInfo,
  setSingleFilterBottomSheet,
  clearCashe,
  setPrefilledFilterName,
  setPrefilledFilterValue,
} = filtersSlice.actions;

export default filtersSlice.reducer;
