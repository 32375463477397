import React, { FC, ReactNode, useRef } from 'react';
import s from './styles.module.scss';
import cn from 'classnames';

export const TEST_ID = 'CssPlatformContainer';

interface IProps {
  children: ReactNode;
  backgroundInherit?: boolean;
}

const CssPlatformContainer: FC<IProps> = ({ children, backgroundInherit }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const classes = cn(s.cssPlatformContainer, {
    [s.backgroundInherit]: backgroundInherit,
  });

  return (
    <div ref={ref} className={classes} data-testid={TEST_ID}>
      {children}
    </div>
  );
};

export default CssPlatformContainer;
