import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyCreateActivity = lazy(() => import('./CreateActivity'));

export const CreateActivityPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyCreateActivity />
  </Suspense>
);
