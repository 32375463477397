import { endOfWeek, format, startOfWeek } from 'date-fns';
import { ru } from 'date-fns/locale';

import { monthShortNames } from '../constant/const';

/**
 * Форматирует диапазон дат в локальное строковое представление.
 * @param {Date} date - Дата из диапазона.
 * @return {string} Строковое представление диапазона дат в формате 'день месяц - день месяц 'год''.
 */
export function formatDateRangeToLocalString(date: string): string {
  if (typeof date !== 'string' || date === '') return '';
  const currentDate = new Date(date);

  // Определяем дату понедельника и воскресенья для текущей недели
  const startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endDate = endOfWeek(currentDate, { weekStartsOn: 1 });

  // Форматируем дни и месяцы
  const startDay = format(startDate, 'd', { locale: ru });
  const endDay = format(endDate, 'd', { locale: ru });

  const startMonth = monthShortNames[startDate.getMonth()];
  const endMonth = monthShortNames[endDate.getMonth()];

  const year = format(startDate, 'yy');

  return `${startDay} ${startMonth} - ${endDay} ${endMonth} '${year}`;
}
