import { Box, Stack } from '@mui/material';
import { Checkbox, CheckBoxIcon, CheckBoxOutlineBlankIcon, Typography } from 'front-package-ui-kit';
import React, { FC } from 'react';
import {
  BottomButtonBlock,
  ButtonWithAnalytic,
  CssPlatformContainer,
  NavBar,
  ReactRouterLinkWithAnalytic,
} from '../../../../components';
import { AnalyticsName, Paths, ValuesOfConstants } from '../../../../utils/constants/config';
import s from './styles.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setUserAgreements } from '../../../../redux/userAgreements/slice';

const VALUES = [
  ValuesOfConstants.advertisingMailings,
  ValuesOfConstants.userAgreement,
  ValuesOfConstants.privacyPolicy,
] as const;

const { profile, settings, aboutPage, privacyPolicy, userAgreement, processingPersonalData } = Paths;

interface IProps {
  onClick: () => void;
  onBackClick: () => void;
}

const SetUserAgreements: FC<IProps> = ({ onClick, onBackClick }) => {
  const {
    userAgreementsStore: { userAgreements },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const setCheckedValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (userAgreements.includes(event.target.value)) {
      dispatch(setUserAgreements(userAgreements.filter((elem) => elem !== event.target.value)));
    } else {
      dispatch(setUserAgreements([...userAgreements, event.target.value]));
    }
  };

  const acceptAllCheckedValueHandler = () => {
    dispatch(setUserAgreements([...VALUES]));
  };

  const onClickHandler = () => onClick();

  const disabled =
    !userAgreements.includes(ValuesOfConstants.privacyPolicy) ||
    !userAgreements.includes(ValuesOfConstants.userAgreement);

  return (
    <div className={s.SetUserAgreements}>
      <CssPlatformContainer>
        <Stack className={s.content}>
          <NavBar goBackButtonProps={{ white: true }} goBackButton onBackClick={onBackClick} />

          <Box flexGrow={1} paddingX="30px" paddingTop="24px">
            <Typography marginBottom="40px" variant="h2" color="white" fontWeight={500}>
              Ваши данные <br /> в безопасности
            </Typography>

            <Stack direction="column" rowGap="14px">
              <Stack direction="row" columnGap="8px">
                <Checkbox
                  checked={userAgreements.includes(ValuesOfConstants.userAgreement)}
                  onChange={setCheckedValueHandler}
                  checkedIcon={<CheckBoxIcon />}
                  icon={<CheckBoxOutlineBlankIcon />}
                  value={ValuesOfConstants.userAgreement}
                />

                <Box alignSelf="center">
                  <Typography variant="text7" color="white">
                    Я соглашаюсь с{' '}
                    <ReactRouterLinkWithAnalytic
                      className={s.link}
                      to={`${profile}${settings}${aboutPage}${userAgreement}`}
                      analyticInfo={{ name: AnalyticsName.userAgreement }}
                    >
                      пользовательским соглашением
                    </ReactRouterLinkWithAnalytic>
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="row" columnGap="8px">
                <Checkbox
                  checked={userAgreements.includes(ValuesOfConstants.privacyPolicy)}
                  onChange={setCheckedValueHandler}
                  checkedIcon={<CheckBoxIcon />}
                  icon={<CheckBoxOutlineBlankIcon />}
                  value={ValuesOfConstants.privacyPolicy}
                />

                <Typography variant="text7" color="white">
                  Я соглашаюсь с{' '}
                  <ReactRouterLinkWithAnalytic
                    className={s.link}
                    to={`${profile}${settings}${aboutPage}${privacyPolicy}`}
                    analyticInfo={{ name: AnalyticsName.privacyPolicy }}
                  >
                    политикой конфиденциальности
                  </ReactRouterLinkWithAnalytic>
                  ,{' '}
                  <ReactRouterLinkWithAnalytic
                    className={s.link}
                    to={processingPersonalData}
                    analyticInfo={{ name: AnalyticsName.processingPersonalData }}
                  >
                    условиями обработки персональных данных и условиями пользования
                  </ReactRouterLinkWithAnalytic>
                </Typography>
              </Stack>

              <Stack direction="row" columnGap="8px">
                <Checkbox
                  checked={userAgreements.includes(ValuesOfConstants.advertisingMailings)}
                  onChange={setCheckedValueHandler}
                  checkedIcon={<CheckBoxIcon />}
                  icon={<CheckBoxOutlineBlankIcon />}
                  value={ValuesOfConstants.advertisingMailings}
                />

                <Typography variant="text7" color="white">
                  Я соглашаюсь получать рекламные рассылки и информацию об обновлениях
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <BottomButtonBlock direction="column" rowGap="8px">
            <ButtonWithAnalytic
              disabled={disabled}
              fullWidth
              onClick={onClickHandler}
              variant="contained"
              color="lime"
              size="L"
              theme="dark"
              analyticInfo={{ name: AnalyticsName.setUserAgreementsNext }}
            >
              <Typography disabled={disabled} theme="dark" variant="text6" fontWeight={700}>
                Далее
              </Typography>
            </ButtonWithAnalytic>

            <ButtonWithAnalytic
              analyticInfo={{ name: AnalyticsName.acceptEverything }}
              fullWidth
              onClick={acceptAllCheckedValueHandler}
              variant="text"
              size="L"
            >
              <Typography variant="text6" color="lime" fontWeight={700}>
                Принять всё
              </Typography>
            </ButtonWithAnalytic>
          </BottomButtonBlock>
        </Stack>
      </CssPlatformContainer>
    </div>
  );
};

export default SetUserAgreements;
