import { IWorkout } from '../api/types';
import { WorkoutsProgressStatuses } from './constants/config';

export const getLastActiveWeekByVideos = (videos: IWorkout[] | undefined) => {
  if (!videos) return null;

  const lastActiveWeek = [...videos]
    .reverse()
    .find(({ progress }) => WorkoutsProgressStatuses.completed === progress?.status)?.week;

  return lastActiveWeek;
};
