import { Stack, StackProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import s from './styles.module.scss';
import cn from 'classnames';

export const TEST_ID = 'BottomButtonBlock';

interface IProps extends Omit<StackProps, 'className'> {
  children: ReactNode;
  transparent?: boolean;
}

const BottomButtonBlock: FC<IProps> = ({ children, transparent, ...props }) => {
  return (
    <Stack
      data-testid={TEST_ID}
      direction="row"
      columnGap="8px"
      justifyContent="space-between"
      alignItems="center"
      className={cn(s.bottomButtonBlock, { [s.transparent]: transparent })}
      {...props}
    >
      {children}
    </Stack>
  );
};

export default BottomButtonBlock;
