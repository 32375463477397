import React, { FC } from 'react';
import { getIsValue } from '../../utils';

interface IProps {
  type: 1 | 2;
}

const LockIcon: FC<IProps> = ({ type }) => {
  return (
    <>
      {getIsValue(type, 1) && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3C14.7614 3 17 5.23858 17 8V10.0014C17.9611 10.0152 18.3396 10.1299 18.7211 10.3339C19.1285 10.5518 19.4482 10.8715 19.6661 11.2789C19.884 11.6863 20 12.0905 20 13.2049V17.7951C20 18.9095 19.884 19.3136 19.6661 19.7211C19.4482 20.1285 19.1285 20.4482 18.7211 20.6661C18.3136 20.884 17.9095 21 16.7951 21H7.20486C6.09046 21 5.68635 20.884 5.27894 20.6661C4.87154 20.4482 4.5518 20.1285 4.33392 19.7211C4.11603 19.3136 4 18.9095 4 17.7951V13.2049C4 12.0905 4.11603 11.6863 4.33392 11.2789C4.5518 10.8715 4.87154 10.5518 5.27894 10.3339C5.66035 10.1299 6.03887 10.0152 7 10.0014V8C7 5.23858 9.23858 3 12 3ZM6.35599 12.049C6.51125 12.019 6.77844 12 7.20486 12H16.7951C17.2216 12 17.4887 12.019 17.644 12.049C17.7048 12.0607 17.7183 12.0657 17.7779 12.0975C17.8367 12.129 17.871 12.1633 17.9025 12.2221C17.9343 12.2817 17.9393 12.2952 17.951 12.356C17.981 12.5113 18 12.7784 18 13.2049V17.7951C18 18.2216 17.981 18.4887 17.951 18.644C17.9393 18.7048 17.9343 18.7183 17.9025 18.7779C17.871 18.8367 17.8367 18.871 17.7779 18.9025C17.7183 18.9343 17.7048 18.9393 17.644 18.951C17.4887 18.981 17.2216 19 16.7951 19H7.20486C6.77844 19 6.51125 18.981 6.35599 18.951C6.29517 18.9393 6.28173 18.9343 6.22214 18.9025C6.16327 18.871 6.12903 18.8367 6.09754 18.7779C6.06567 18.7183 6.06072 18.7048 6.04897 18.644C6.01899 18.4887 6 18.2216 6 17.7951V13.2049C6 12.7784 6.01899 12.5113 6.04897 12.356C6.06072 12.2952 6.06567 12.2817 6.09754 12.2221C6.12903 12.1633 6.16327 12.129 6.22214 12.0975C6.28173 12.0657 6.29517 12.0607 6.35599 12.049ZM15 8V10H9V8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8Z"
            fill="white"
          />
        </svg>
      )}
      {getIsValue(type, 2) && (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" width="80" height="80" rx="40" fill="#E8E8E8" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.9999 25.75C45.3722 25.75 48.9166 29.2944 48.9166 33.6667V36.8356C50.4384 36.8574 51.0377 37.0391 51.6416 37.362C52.2867 37.707 52.7929 38.2133 53.1379 38.8583C53.4829 39.5034 53.6666 40.1432 53.6666 41.9077V49.1756C53.6666 50.9401 53.4829 51.5799 53.1379 52.225C52.7929 52.8701 52.2867 53.3763 51.6416 53.7213C50.9965 54.0663 50.3567 54.25 48.5922 54.25H33.4076C31.6431 54.25 31.0033 54.0663 30.3582 53.7213C29.7132 53.3763 29.2069 52.8701 28.862 52.225C28.517 51.5799 28.3333 50.9401 28.3333 49.1756V41.9077C28.3333 40.1432 28.517 39.5034 28.862 38.8583C29.2069 38.2133 29.7132 37.707 30.3582 37.362C30.9621 37.0391 31.5615 36.8574 33.0833 36.8356V33.6667C33.0833 29.2944 36.6277 25.75 40.9999 25.75ZM32.0636 40.0775C32.3094 40.0301 32.7325 40 33.4076 40H48.5922C49.2674 40 49.6904 40.0301 49.9363 40.0775C50.0326 40.0961 50.0538 40.104 50.1482 40.1544C50.2414 40.2043 50.2956 40.2585 50.3455 40.3517C50.3959 40.4461 50.4038 40.4674 50.4224 40.5637C50.4699 40.8095 50.4999 41.2325 50.4999 41.9077V49.1756C50.4999 49.8508 50.4699 50.2738 50.4224 50.5197C50.4038 50.616 50.3959 50.6373 50.3455 50.7316C50.2956 50.8248 50.2414 50.879 50.1482 50.9289C50.0538 50.9794 50.0326 50.9872 49.9363 51.0058C49.6904 51.0533 49.2674 51.0833 48.5922 51.0833H33.4076C32.7325 51.0833 32.3094 51.0533 32.0636 51.0058C31.9673 50.9872 31.946 50.9794 31.8516 50.9289C31.7584 50.879 31.7042 50.8248 31.6544 50.7316C31.6039 50.6373 31.5961 50.616 31.5775 50.5197C31.53 50.2738 31.4999 49.8508 31.4999 49.1756V41.9077C31.4999 41.2325 31.53 40.8095 31.5775 40.5637C31.5961 40.4674 31.6039 40.4461 31.6544 40.3517C31.7042 40.2585 31.7584 40.2043 31.8516 40.1544C31.946 40.104 31.9673 40.0961 32.0636 40.0775ZM45.7499 33.6667V36.8333H36.2499V33.6667C36.2499 31.0433 38.3766 28.9167 40.9999 28.9167C43.6233 28.9167 45.7499 31.0433 45.7499 33.6667Z"
            fill="#1D1D1D"
          />
        </svg>
      )}
    </>
  );
};

export default LockIcon;
