import { PayloadAction, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { getStatusOrderThunk } from './thunks';
import { IOrderPayment } from '../../api/types';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';

interface IOrder {
  order: IStoreBase<IOrderPayment | null>;
}

const initialState: IOrder = {
  order: { data: null, error: '', statuse: Statuses.idle },
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatusOrderThunk.fulfilled, (state, { payload }: PayloadAction<IOrderPayment>) => {
        state.order.error = '';
        state.order.statuse = Statuses.succeeded;
        state.order.data = payload;
      })
      //getStatusOrderThunk
      .addMatcher(isPending(getStatusOrderThunk), (state) => {
        state.order.error = '';
        state.order.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getStatusOrderThunk), (state, { payload }) => {
        state.order.statuse = Statuses.failed;
        state.order.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const {} = orderSlice.actions;

export default orderSlice.reducer;
