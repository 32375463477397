import { Stack, StackProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { IconButtonUI } from '../..';
import s from './styles.module.scss';
import cn from 'classnames';
import { CloseIcon, NamesOfColors } from 'front-package-ui-kit';

export const TEST_ID = 'bottom-sheet-header';

interface IProps extends Omit<StackProps, 'className' | 'title'> {
  title?: ReactNode;
  onClose?: () => void;
  transparent?: boolean;
  closeIconColor?: NamesOfColors;
  theme?: 'dark' | 'light';
}

const BottomSheetHeader: FC<IProps> = ({
  title,
  transparent,
  onClose,
  theme = 'light',
  closeIconColor = NamesOfColors.grey30,
  ...props
}) => {
  const onCloseClickHandler = () => {
    if (!onClose) return;

    onClose();
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      className={cn(s.container, { [s.transparent]: transparent, [s.withCloseBtn]: !!onClose })}
      data-transparent={Boolean(transparent)}
      data-testid={TEST_ID}
      data-theme={theme}
      {...props}
    >
      {title}

      {!!onClose && (
        <div className={s.closeBtn}>
          <IconButtonUI size={24} onClick={onCloseClickHandler}>
            <CloseIcon size={24} color={closeIconColor} />
          </IconButtonUI>
        </div>
      )}
    </Stack>
  );
};

export default BottomSheetHeader;
