import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useProducts = () => {
  const {
    productsStore: {
      productsWithPromocode: {
        data: productsWithPromocode,
        statuse: statuseProductWithPromocode,
        error: errorProductWithPromocode,
      },
      products: { data: products, statuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({
    authStore: state.authStore,
    productsStore: state.productsStore,
  }));

  const isProducts = Boolean(products?.length);

  const isProductsLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isProducts) || isLoading;

  return {
    products,
    isProducts,
    isProductsLoading,
    productsWithPromocode,
    statuseProductWithPromocode,
    errorProductWithPromocode,
  };
};
