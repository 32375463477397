import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useCourses = () => {
  const {
    coursesStore: {
      courses: { data: courses, statuse },
      myCourses,
      completedCourses,
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ coursesStore: state.coursesStore, authStore: state.authStore }));

  const isCourses = Boolean(courses?.length);

  const isCoursesLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isCourses) || isLoading;

  const isCoursesLoadingCompleted = isCourses || statuse === Statuses.succeeded || statuse === Statuses.failed;

  return {
    courses,
    isCourses,
    isCoursesLoading,
    isCoursesLoadingCompleted,
    myCourses,
    completedCourses,
  };
};
