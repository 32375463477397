/**
 * Formats the given minutes into hours and remaining minutes.
 *
 * @param {number} minutes - The total number of minutes to format.
 * @return {[number|null, number]} An array containing the hours (or null if 0) and the remaining minutes.
 */
export function formatTime(minutes: number): [number | null, number] {
  const hours = minutes >= 60 ? Math.floor(minutes / 60) : null;
  const remainingMinutes = minutes % 60 > 0 ? minutes % 60 : 0;

  return [hours, remainingMinutes];
}
