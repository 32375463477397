import React, { FC, memo, useCallback } from 'react';
import s from './styles.module.scss';
import { MethodPaymentType } from '../../../api/types';
import { Typography } from 'front-package-ui-kit';
import { CheckIcon } from './CheckIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setNewCardMethodPayment } from '../../../redux/payment/slice';

export const TEST_ID = 'SetMethodPaymentType';

export interface SetMethodPaymentTypeProps {}

const options = [
  { label: 'Российская карта', value: MethodPaymentType.yookassa },
  { label: 'Зарубежная карта', value: MethodPaymentType.robokassa },
];

const SetMethodPaymentType: FC<SetMethodPaymentTypeProps> = () => {
  const { newCardMethodPayment } = useAppSelector((state) => state.paymentStore);

  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (methodPaymentType: MethodPaymentType) => () => {
      dispatch(setNewCardMethodPayment(methodPaymentType));
    },
    [dispatch]
  );

  return (
    <div className={s.setMethodPaymentType} data-testid={TEST_ID}>
      <Typography opacity={0.5} variant="text6" fontWeight={500} marginBottom="8px">
        Выберите тип карты для оплаты:
      </Typography>

      {options.map(({ label, value }) => (
        <div key={`${label}-${value}`} className={s.setMethodPaymentTypeBtn} onClick={handleChange(value)}>
          <Typography variant="text4" fontWeight={500}>
            {label}
          </Typography>

          {newCardMethodPayment === value && <CheckIcon />}
        </div>
      ))}
    </div>
  );
};

export const SetMethodPaymentTypeMemo = memo(SetMethodPaymentType);
