import { ArrowRightIcon, Button } from 'front-package-ui-kit';
import React, { FC } from 'react';

interface IProps {
  loading: boolean;
  disabled: boolean;
}

const ConfiguredCircleButtonLime: FC<IProps> = ({ loading, disabled }) => {
  return (
    <Button
      size="L"
      color="lime"
      variant="contained"
      borderType="circle"
      buttonType="iconButton"
      theme="dark"
      type="submit"
      disabled={disabled}
      loading={loading}
    >
      <ArrowRightIcon theme="dark" color={disabled ? 'grey' : 'black'} size={20} />
    </Button>
  );
};

export default ConfiguredCircleButtonLime;
