import { IStepmeterProgress } from '../model/types';

/**
 * Вычисляет средние показатели (время, расстояние, калории) из заданного массива данных прогресса Stepmeter.
 *
 * @param {Array<IStepmeterProgress | null>} data - Массив данных прогресса Stepmeter.
 * @return {Array<number>} Массив, содержащий среднее время, расстояние калории и количество шагов в том порядке.
 */
export const getMedianMetrics = (data: (IStepmeterProgress | null)[] | null): Array<number> => {
  if (!data) {
    return [0, 0, 0, 0];
  }

  const filteredData = data.filter((item) => item !== null) as IStepmeterProgress[];
  // Инициализируем переменные для суммы значений и счетчика
  let totalCalories = 0;
  let totalDistance = 0;
  let totalTime = 0;
  let totalSteps = 0;
  let count = 0;

  // Проходим по каждому объекту в массиве и суммируем значения
  for (const item of filteredData) {
    totalCalories += item.calories || 0;
    totalDistance += item.distance || 0;
    totalTime += item.time || 0;
    totalSteps += item.steps || 0;
    count++;
  }

  // Если count равен 0, возвращаем нули, чтобы избежать деления на ноль
  if (count === 0) {
    return [0, 0, 0, 0];
  }

  // Вычисляем средние значения
  const averageCalories = Math.floor(totalCalories / count);
  const averageDistance = Number((totalDistance / count).toFixed(1));
  const averageTime = Math.floor(totalTime / count);
  const averageSteps = Math.floor(totalSteps / count);

  // Возвращаем объект с тремя средними значениями
  return [averageTime, averageDistance, averageCalories, averageSteps];
};
