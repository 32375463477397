import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from './redux';
import { useNavigate } from 'react-router-dom';
import { Environment, ErrorMessages, Paths, ValuesOfConstants, noticeCreator } from '../utils';
import { createServiceNotice } from '../redux/notifications/slice';
import api from '../api';
import { useUser } from './useUser';
import { useBankCards, useParseWebView } from '.';
import { MethodPaymentType } from '../api/types';

const { orders } = Paths;

export const usePayment = () => {
  const { currentProductForPayment, promocode, trial, newCardMethodPayment } = useAppSelector(
    (state) => state.paymentStore
  );

  const {
    productsWithPromocode: { data: productsWithPromocode },
  } = useAppSelector((state) => state.productsStore);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { user } = useUser();

  const { isLinkedBankCards, mainBankCard } = useBankCards();

  const mainCardType =
    mainBankCard?.card_type === 'robokassa' ? MethodPaymentType.robokassa : MethodPaymentType.yookassa;

  const [loading, setLoading] = useState<boolean>(false);

  const [recurrentEnabled] = useParseWebView([ValuesOfConstants.isRecurrentPayment]);

  const isRecurrentEnabled = Boolean(Number(recurrentEnabled));

  const promocodeForPayment =
    promocode && productsWithPromocode && currentProductForPayment && productsWithPromocode[currentProductForPayment.id]
      ? promocode
      : '';

  const methodPayment: MethodPaymentType = isLinkedBankCards ? mainCardType : newCardMethodPayment;

  const paymentType = window?.getPaymentType();

  const handlePay = useCallback(
    async (autoRenewal: boolean) => {
      try {
        const currentPlatform = await window?.getPlatform();

        // isRecurrentEnabled если рекурентные платежи отключены, ордер всегда разовый платеж
        // single: true платеж ордер разовый без привязки карты.
        // single: false, или без  этого параметра, значит рекуретный  платеж.
        // с привязкой карты если первый раз.
        // если уже привязана карта, то списание с карты.
        //триальный период возможно взять только при включенном автопродлении

        const multiple = isRecurrentEnabled && (mainBankCard || autoRenewal);
        const single = !multiple;

        if (currentProductForPayment?.id) {
          const product = {
            product_id: currentProductForPayment.id,
            method: methodPayment ?? MethodPaymentType.yookassa,
            single,
            platform: currentPlatform,
            promocode: promocodeForPayment,
            native: paymentType === 'sdk',
            discount_days: trial && !!autoRenewal,
            autopay: autoRenewal,
          } as const;

          setLoading(true);

          const order = (await api.getOrder(product)).data.data;

          localStorage.setItem('currentProductForPayment', JSON.stringify(currentProductForPayment));

          if (order) {
            if (isRecurrentEnabled && mainBankCard) {
              // рукурентый платеж
              navigate(`${orders}/${order.id}`);
            } else if (
              (paymentType === 'internal' ||
                (paymentType === 'sdk' && methodPayment === MethodPaymentType.robokassa)) &&
              order.link
            ) {
              window.location.href = order.link;
            } else if (paymentType === 'external' && order.link) {
              window?.openUrl(order.link);

              navigate(`${orders}/${order.id}`);
            } else if (paymentType === 'invalid') {
              dispatch(
                createServiceNotice({
                  notice: noticeCreator(ErrorMessages.paymentTypeInvalid, 'error'),
                  otherInfo: { error: 'error', pathname: 'handlePay', forEnvironment: Environment.production },
                })
              );
            } else if (paymentType === 'sdk' && methodPayment === MethodPaymentType.yookassa) {
              window?.openPayment(
                order,
                paymentType,
                {
                  customerId: user?.phone ?? user?.email ?? '',
                  phone: user?.phone ?? '',
                },
                single
              );
            }
          }
        }
      } catch (error) {
        dispatch(
          createServiceNotice({
            notice: noticeCreator(ErrorMessages.payment, 'error'),
            otherInfo: { error, pathname: 'handlePay', forEnvironment: Environment.production },
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [
      currentProductForPayment,
      dispatch,
      isRecurrentEnabled,
      mainBankCard,
      methodPayment,
      navigate,
      paymentType,
      promocodeForPayment,
      trial,
      user?.email,
      user?.phone,
    ]
  );

  const handleBindingBankCard = useCallback(
    async ({ autoRenewal }: { autoRenewal: boolean }) => {
      try {
        setLoading(true);

        const order = (await api.bindingBankCard({ method: newCardMethodPayment, autopay: autoRenewal })).data.data;

        const product = { title: 'Привязка банковской карты' };

        if (order) {
          if (
            (paymentType === 'internal' || (paymentType === 'sdk' && methodPayment === MethodPaymentType.robokassa)) &&
            order.link
          ) {
            window.location.href = order.link;
          } else if (paymentType === 'external' && order.link) {
            window?.openUrl(order.link);

            navigate(`${orders}/${order.id}`);
          } else if (paymentType === 'invalid') {
            dispatch(
              createServiceNotice({
                notice: noticeCreator(ErrorMessages.paymentTypeInvalid, 'error'),
                otherInfo: {
                  error: 'error',
                  pathname: 'handleBindingBankCard',
                  forEnvironment: Environment.production,
                },
              })
            );
          } else if (paymentType === 'sdk' && methodPayment === MethodPaymentType.yookassa) {
            window?.openPayment(
              { ...order, product },
              paymentType,
              {
                customerId: user?.phone ?? user?.email ?? '',
                phone: user?.phone ?? '',
              },
              false
            );
          }
        }
      } catch (error) {
        dispatch(
          createServiceNotice({
            notice: noticeCreator(ErrorMessages.bindingBankCard, 'error'),
            otherInfo: { error, pathname: 'handleBindingBankCard', forEnvironment: Environment.production },
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, methodPayment, navigate, newCardMethodPayment, paymentType, user?.email, user?.phone]
  );

  return { loading, handlePay, handleBindingBankCard };
};
