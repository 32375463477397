import { createSlice, isPending, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { getSocialsThunk } from './thunks';
import { ISocial } from '../../api/types';
import { Statuses } from '../../utils';
import { IStoreBase } from '../../models';

interface ISocials {
  socials: IStoreBase<ISocial[] | null>;
}

const initialState: ISocials = {
  socials: { data: null, error: '', statuse: Statuses.idle },
};

export const socialsSlice = createSlice({
  name: 'socials',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSocialsThunk.fulfilled, (state, { payload }: PayloadAction<ISocial[]>) => {
        state.socials.error = '';
        state.socials.statuse = Statuses.succeeded;
        state.socials.data = payload;
      })
      .addMatcher(isPending(getSocialsThunk), (state) => {
        state.socials.error = '';
        state.socials.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getSocialsThunk), (state, { payload }) => {
        state.socials.statuse = Statuses.failed;
        state.socials.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export default socialsSlice.reducer;
