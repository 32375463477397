import React, { createContext, FC, ReactNode } from 'react';
import { useAppSelector } from '../../hooks';
import NoServerConection from '../../pages/NoServerConection';

interface INoServerConectionContext {}

const NoServerConectionContext = createContext<INoServerConectionContext | null>(null);

interface IProps {
  children: ReactNode;
}

const NoServerConectionContextProvider: FC<IProps> = ({ children }) => {
  const { serverConection } = useAppSelector((state) => state.serverConectionStore);

  return (
    <NoServerConectionContext.Provider value={{}}>
      {serverConection && children}

      {!serverConection && <NoServerConection />}
    </NoServerConectionContext.Provider>
  );
};

export { NoServerConectionContext, NoServerConectionContextProvider };
