import { Typography } from 'front-package-ui-kit';
import { FC, memo } from 'react';

import NotFoundStepmeterDataIcon from '../../../../assets/icons/NotFoundIcons/NotFoundStepmeterDataIcon.png';
import { ImageCover } from '../../../../components';

import s from './styles.module.scss';

const NoStepmeterDataComponent: FC = () => (
  <div className={s.noStepmeterData}>
    <div className={s.iconWrapper}>
      <ImageCover img={NotFoundStepmeterDataIcon} />
    </div>

    <Typography variant="text6" color="black" fontWeight={500} textAlign="center">
      Здесь появится
      <br /> история вашего прогресса
    </Typography>
  </div>
);

export const NoStepmeterData = memo(NoStepmeterDataComponent);
