import React from 'react';
import { FC } from 'react';

interface IProps {}

const SuccessIcon: FC<IProps> = () => {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="72" height="72" rx="36" fill="#7FFF00" />
      <path
        d="M48.5736 24.5731C49.0856 23.9218 50.0286 23.8088 50.6799 24.3208C51.3312 24.8327 51.4441 25.7758 50.9322 26.427L34.9975 46.6986C34.9037 46.818 34.798 46.9276 34.682 47.0257C33.7334 47.8284 32.3137 47.7101 31.511 46.7615L23.855 37.7134C23.3198 37.081 23.3987 36.1345 24.0311 35.5994C24.6635 35.0643 25.61 35.1432 26.1451 35.7756L33.2072 44.1217L48.5736 24.5731Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default SuccessIcon;
