import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyTest = lazy(() => import('./Test'));

export const Test = () => (
  <Suspense fallback={<Loading />}>
    <LazyTest />
  </Suspense>
);
