import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazySingleWorkoutsGroups = lazy(() => import('./SingleWorkoutsGroups'));

export const SingleWorkoutsGroups = () => (
  <Suspense fallback={<Loading />}>
    <LazySingleWorkoutsGroups />
  </Suspense>
);
