import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyOrderStatus = lazy(() => import('./OrderStatus'));

export const OrderStatus = () => (
  <Suspense fallback={<Loading />}>
    <LazyOrderStatus />
  </Suspense>
);
