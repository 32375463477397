import { createSlice, isPending, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { IWorkoutDetailse } from '../../api/types';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';
import {
  changeWorkoutProgressThunk,
  deleteWorkoutFromHistoryThunk,
  getFavoriteWorkoutsThunk,
  getFullStoryWorkoutsThunk,
  getStoryWorkoutsThunk,
  getWorkoutDetailsThunk,
} from './thunks';

type TVideosDetails = Record<string, IWorkoutDetailse>;

interface IFavoritesWorkouts extends IStoreBase<IWorkoutDetailse[]> {
  cache: Record<number, boolean>;
}

interface IWorkouts {
  workoutDetails: IStoreBase<TVideosDetails>;
  favoritesWorkouts: IFavoritesWorkouts;
  storyWorkouts: IStoreBase<IWorkoutDetailse[]>;
  fullStoryWorkouts: IStoreBase<IWorkoutDetailse[]>;
}

const initialState: IWorkouts = {
  workoutDetails: { data: {}, error: '', statuse: Statuses.idle },
  favoritesWorkouts: { data: [], error: '', statuse: Statuses.idle, cache: {} },
  storyWorkouts: { data: [], error: '', statuse: Statuses.idle },
  fullStoryWorkouts: { data: [], error: '', statuse: Statuses.idle },
};

export const workoutsSlice = createSlice({
  name: 'workouts',
  initialState,
  reducers: {
    removeFavoriteWorkout(state, { payload: { id } }: PayloadAction<{ id: number }>) {
      state.favoritesWorkouts.cache[id] = false;
      state.favoritesWorkouts.data = state.favoritesWorkouts.data.filter((workout) => workout.id !== id);
    },
    addFavoritesWorkouts(state, { payload: { id } }: PayloadAction<{ id: number }>) {
      state.favoritesWorkouts.cache[id] = true;
    },
    restoreFavoritesWorkouts(state, { payload }: PayloadAction<IFavoritesWorkouts>) {
      state.favoritesWorkouts = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkoutDetailsThunk.fulfilled, (state, { payload }: PayloadAction<IWorkoutDetailse>) => {
        state.workoutDetails.error = '';
        state.workoutDetails.statuse = Statuses.succeeded;
        state.workoutDetails.data[payload.id] = payload;
      })
      .addCase(getFavoriteWorkoutsThunk.fulfilled, (state, { payload }: PayloadAction<IWorkoutDetailse[]>) => {
        state.favoritesWorkouts.error = '';
        state.favoritesWorkouts.statuse = Statuses.succeeded;
        state.favoritesWorkouts.data = payload;
      })
      .addCase(changeWorkoutProgressThunk.fulfilled, (state, { payload }: PayloadAction<IWorkoutDetailse>) => {
        state.workoutDetails.error = '';
        state.workoutDetails.statuse = Statuses.succeeded;
        state.workoutDetails.data[payload.id] = payload;
      })
      .addCase(getStoryWorkoutsThunk.fulfilled, (state, { payload }: PayloadAction<IWorkoutDetailse[]>) => {
        state.storyWorkouts.error = '';
        state.storyWorkouts.statuse = Statuses.succeeded;
        state.storyWorkouts.data = payload;
      })
      .addCase(getFullStoryWorkoutsThunk.fulfilled, (state, { payload }: PayloadAction<IWorkoutDetailse[]>) => {
        state.fullStoryWorkouts.error = '';
        state.fullStoryWorkouts.statuse = Statuses.succeeded;
        state.fullStoryWorkouts.data = payload;
      })
      .addCase(deleteWorkoutFromHistoryThunk.fulfilled, (state) => {
        state.fullStoryWorkouts.error = '';
        state.fullStoryWorkouts.statuse = Statuses.succeeded;
      })
      //getFavoriteWorkoutsThunk
      .addMatcher(isPending(getFavoriteWorkoutsThunk), (state) => {
        state.favoritesWorkouts.error = '';
        state.favoritesWorkouts.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getFavoriteWorkoutsThunk), (state, { payload }) => {
        state.favoritesWorkouts.statuse = Statuses.failed;
        state.favoritesWorkouts.error = typeof payload === 'string' ? payload : '';
      })
      //getWorkoutDetailsThunk
      .addMatcher(isPending(getWorkoutDetailsThunk), (state) => {
        state.workoutDetails.error = '';
        state.workoutDetails.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getWorkoutDetailsThunk), (state, { payload }) => {
        state.workoutDetails.statuse = Statuses.failed;
        state.workoutDetails.error = typeof payload === 'string' ? payload : '';
      })
      //changeWorkoutProgressThunk
      .addMatcher(isPending(changeWorkoutProgressThunk), (state) => {
        state.workoutDetails.error = '';
        state.workoutDetails.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(changeWorkoutProgressThunk), (state, { payload }) => {
        state.workoutDetails.statuse = Statuses.failed;
        state.workoutDetails.error = typeof payload === 'string' ? payload : '';
      })
      //getStoryWorkoutsThunk
      .addMatcher(isPending(getStoryWorkoutsThunk), (state) => {
        state.storyWorkouts.error = '';
        state.storyWorkouts.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getStoryWorkoutsThunk), (state, { payload }) => {
        state.storyWorkouts.statuse = Statuses.failed;
        state.storyWorkouts.error = typeof payload === 'string' ? payload : '';
      })
      //getFullStoryWorkoutsThunk
      .addMatcher(isPending(getFullStoryWorkoutsThunk), (state) => {
        state.fullStoryWorkouts.error = '';
        state.fullStoryWorkouts.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getFullStoryWorkoutsThunk), (state, { payload }) => {
        state.fullStoryWorkouts.statuse = Statuses.failed;
        state.fullStoryWorkouts.error = typeof payload === 'string' ? payload : '';
      })
      //deleteWorkoutFromHistoryThunk
      .addMatcher(isPending(deleteWorkoutFromHistoryThunk), (state) => {
        state.fullStoryWorkouts.error = '';
        state.fullStoryWorkouts.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(deleteWorkoutFromHistoryThunk), (state, { payload }) => {
        state.fullStoryWorkouts.statuse = Statuses.failed;
        state.fullStoryWorkouts.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const { addFavoritesWorkouts, removeFavoriteWorkout, restoreFavoritesWorkouts } = workoutsSlice.actions;

export default workoutsSlice.reducer;
