import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import { createServiceNotice } from '../notifications/slice';
import { Environment, noticeCreator } from '../../utils';

export const getCountriesThunk = createAsyncThunk(
  'countries/getCountries',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getCountries()).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'countries/getCountries', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
