import React, { FC, useState } from 'react';
import { CssPlatformContainer, NavBar, PinInputUI } from '../../../../components';
import { usePlatform, useTimer } from '../../../../hooks';
import { getIsValue } from '../../../../utils';
import { LoginTypes } from '../../LoginRegister';
import s from './styles.module.scss';
import cn from 'classnames';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { useEffectOnce } from 'usehooks-ts';
import { v4 as uuid } from 'uuid';
import { Typography } from 'front-package-ui-kit';
import ConfiguredCircleButtonLime from '../ConfiguredCircleButtonLime';

const PIN_LENGTH = 4;

interface IProps {
  step: string;
  loginType: LoginTypes;
  loginName: string;
  value: string;
  onChange: (value: string) => void;
  onSubmit: (foundSmsCode?: string) => void;
  onRefresh: () => void;
  onBackClick: () => void;
  loading: boolean;
}

const Confirmation: FC<IProps> = ({
  step,
  onBackClick,
  loginName,
  loginType,
  onRefresh,
  value,
  onSubmit,
  loading,
  onChange,
}) => {
  const [pinInputKey, setPinInputKey] = useState(uuid());

  const [smsCode, setSmsCode] = useState('');

  const { timer, restartTimer } = useTimer({ initialMinutes: 0, initialSeconds: 59 });

  const isKeyboardOpen = useDetectKeyboardOpen();

  const { platform } = usePlatform();

  // const isNeedRestructuringContent = platform === 'android' && isKeyboardOpen;

  const isNeedRestructuringContent = false;

  const [error, setError] = useState(false);

  // const onBlurHandler = () => {
  //   const isValidPin = value.length === PIN_LENGTH;

  //   if (!isValidPin) {
  //     setError(!isValidPin);
  //   }
  // };

  const onFocusHandler = () => {
    setError(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValidPin = value.length === PIN_LENGTH;

    if (!isValidPin) {
      setError(!isValidPin);
    }

    if (error || !isValidPin) return;

    onSubmit();
  };

  const onRefreshClickHandler = () => {
    onRefresh();

    restartTimer();
  };

  const disabled = error || value.length < PIN_LENGTH;

  useEffectOnce(() => {
    try {
      window?.findSMSCode().then((foundSmsCode) => {
        if (typeof foundSmsCode === 'string') {
          setSmsCode(foundSmsCode);

          setPinInputKey(uuid());

          onSubmit(foundSmsCode);
        }
      });
    } catch (e) {}
  });

  return (
    <div className={s.confirmation}>
      <CssPlatformContainer>
        <NavBar
          marginBottom="23px"
          justifyContent={isNeedRestructuringContent ? 'space-between' : 'flex-end'}
          onBackClick={onBackClick}
          goBackButton
          goBackButtonProps={{ white: true }}
        >
          {isNeedRestructuringContent && (
            <Typography color="white" variant="text4">
              Введите код из {loginType === LoginTypes.email ? 'письма' : 'СМС'}
            </Typography>
          )}

          <Typography variant="text4" color="white">
            {step}
          </Typography>
        </NavBar>

        <div className={s.content}>
          {!isNeedRestructuringContent && (
            <>
              {loginType === LoginTypes.email && (
                <Typography marginBottom="40px" fontWeight={700} variant="h2" color="white">
                  Введите код <br /> из письма
                </Typography>
              )}

              {loginType === LoginTypes.phone && (
                <Typography marginBottom="40px" fontWeight={700} variant="h2" color="white">
                  Введите код из СМС
                </Typography>
              )}
            </>
          )}

          <form onSubmit={handleSubmit}>
            <PinInputUI
              key={pinInputKey}
              onFocus={onFocusHandler}
              initialValue={smsCode}
              error={error ? 'Неверный код. Попробуйте еще раз' : ''}
              onChange={onChange}
              onComplete={onChange}
            />

            {getIsValue(loginType, LoginTypes.email) && (
              <Typography marginBottom="14px" variant="text6" color="white">
                Код подтверждения отправлен на почту <br /> {loginName}
              </Typography>
            )}

            {getIsValue(loginType, LoginTypes.phone) && (
              <Typography marginBottom="14px" variant="text6" color="white">
                Код подтверждения отправлен <br /> на {loginName}
              </Typography>
            )}

            {Boolean(timer) && (
              <Typography marginBottom="40px" color="white" variant="text6">
                Новый код можно получить через {timer}
              </Typography>
            )}

            {!Boolean(timer) && (
              <Typography
                onClick={onRefreshClickHandler}
                marginBottom="40px"
                textDecoration="underline"
                color="lime"
                variant="text6"
              >
                Получить новый код
              </Typography>
            )}

            <div className={cn(s.btn, { [s.btnRestructuring]: isNeedRestructuringContent })}>
              <ConfiguredCircleButtonLime loading={loading} disabled={disabled} />
            </div>
          </form>
        </div>
      </CssPlatformContainer>
    </div>
  );
};

export default Confirmation;
