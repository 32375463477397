import { API_APP_URL, API_BASE_URL, API_COMMENTS_URL, API_NOTIFICATION_URL, API_VIDEO_URL } from '../api/config';
import { IImagePathProps } from '../models';
import { ApiVersion } from './constants/config';

export const getImagePath = ({ baseApiService, imagePath, name, width, height, resize }: IImagePathProps) => {
  if (!name) return '';

  switch (baseApiService) {
    case 'VIDEO':
      return `${API_VIDEO_URL}/${ApiVersion.videoService}/${imagePath}/${name}/${width}/${height}/${resize}`;

      break;

    case 'BASE':
      return `${API_BASE_URL}/${ApiVersion.baseService}/${imagePath}/${name}/${width}/${height}/${resize}`;

      break;

    case 'APP':
      return `${API_APP_URL}/${ApiVersion.appService}/${imagePath}/${name}/${width}/${height}/${resize}`;

      break;

    case 'COMMENTS':
      return `${API_COMMENTS_URL}/${ApiVersion.commentsService}/${imagePath}/${name}/${width}/${height}/${resize}`;

      break;

    case 'NOTIFICATION':
      return `${API_NOTIFICATION_URL}/${ApiVersion.notificationsService}/${imagePath}/${name}/${width}/${height}/${resize}`;

      break;

    default:
      return '';

      break;
  }
};
