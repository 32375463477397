import { IStoreBase } from '../../../models';

export interface IStepmeter {
  isFirstVisit: boolean; // зашел впервые?
  isAccessGranted: boolean; // пользователь дал разрешение?
  goal: number; // цель, по умолчанию 5000 шагов
  progress: IStepmeterProgress[];
}

export interface IStepmeterProgress {
  id: number;
  steps: number;
  distance: number;
  time: number;
  calories: number;
  date: string;
}

export enum CurrentOption {
  DISTANCE = 'distance',
  TIME = 'time',
  CALORIES = 'calories',
  STEPS = 'steps',
}

export interface IStepmeterState extends IStoreBase<IStepmeter> {
  currentOption: CurrentOption;
}
