import classNames from 'classnames';
import { FC, memo, useMemo, useState } from 'react';

import { IStepmeterProgress } from '../../model/types';
import { StepmeterChartTooltip } from '../StepmeterChartTooltip';
import { StepmeterSwiperChart } from '../StepmeterSwiperChart';

import s from './styles.module.scss';

export const TEST_ID = 'StepmeterChartContainer';

export interface StepmeterChartContainerProps {
  fullInfo: boolean;
  goal: number;
  splittedProgress: (IStepmeterProgress | null)[][];
  todayProgress: IStepmeterProgress | null;
  handleSetCurrentWeek: (value: number) => void;
}

const StepmeterChartContainer: FC<StepmeterChartContainerProps> = ({
  fullInfo,
  goal,
  splittedProgress,
  handleSetCurrentWeek,
  todayProgress,
}) => {
  const [selectedDay, setSelectedDay] = useState<IStepmeterProgress | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [transitionValue, setTransitionValue] = useState<number>(0); // Начальное значение коэффициента

  const defaultTooltip = useMemo(() => {
    return Boolean(selectedDay === null && selectedIndex === null) || splittedProgress.length === 0;
  }, [selectedDay, selectedIndex, splittedProgress.length]);

  return (
    <div className={s.StepmeterChartContainer} data-testid={TEST_ID}>
      <StepmeterChartTooltip
        key="StepmeterChartTooltip"
        defaultTooltip={defaultTooltip}
        goal={goal}
        selectedDay={selectedDay}
        todayProgress={todayProgress}
        transitionValue={transitionValue}
      />

      {fullInfo && (
        <StepmeterSwiperChart
          key="StepmeterSwiperChart"
          goal={goal}
          splittedProgress={splittedProgress}
          handleSetCurrentWeek={handleSetCurrentWeek}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          setTransitionValue={setTransitionValue}
        />
      )}

      <div className={s.dividerContainer} key="tooltip-divider">
        <div
          className={classNames(s.divider, { [s.transparent]: defaultTooltip, [s.hidding]: defaultTooltip })}
          style={{
            left: `calc(16px + ((calc((100% - 60px) / 7) + 10px) * ${selectedIndex}) + calc(calc((100% - 60px) / 7) / 2) - 1px)`,
          }}
        />
      </div>
    </div>
  );
};

export const StepmeterChartContainerMemo = memo(StepmeterChartContainer);
