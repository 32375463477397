import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypesSort } from '../../utils';

export type SortSchema = Record<string, TypesSort>;

interface IScrollSaveStore {
  sort: SortSchema;
}

export const initialState: IScrollSaveStore = {
  sort: {},
};

export const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    setSort: (state, { payload }: PayloadAction<{ path: string; sort: TypesSort }>) => {
      state.sort[payload.path] = payload.sort;
    },
  },
});

export const { setSort } = sortSlice.actions;

export default sortSlice.reducer;
