import React from 'react';

const QuestionSign = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10ZM8.75 14.2428C8.75 13.5202 9.29913 13 9.99277 13C10.7009 13 11.25 13.5202 11.25 14.2428C11.25 14.9653 10.7009 15.5 9.99277 15.5C9.29913 15.5 8.75 14.9653 8.75 14.2428ZM10.2248 4.5C8.06777 4.5 6.44995 5.74332 6.44995 7.75061V7.7749C6.44995 8.05104 6.67381 8.2749 6.94995 8.2749H7.75319C7.99311 8.2749 8.1876 8.08041 8.1876 7.84049C8.1876 6.717 9.05643 6.11781 10.2248 6.11781C11.3633 6.11781 12.1123 6.717 12.1123 7.57085C12.1123 8.37976 11.6929 8.75425 10.6443 9.2336L10.3447 9.36842C9.46088 9.75789 9.13133 10.3421 9.13133 11.3607V11.5C9.13133 11.7761 9.35519 12 9.63133 12H10.369C10.6451 12 10.869 11.7761 10.869 11.5V11.4656C10.869 11.0162 10.9888 10.8364 11.3483 10.6717L11.6479 10.5368C12.9062 9.96761 13.85 9.21862 13.85 7.58583V7.49595C13.85 5.77328 12.352 4.5 10.2248 4.5Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default QuestionSign;
