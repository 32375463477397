import { IWorkout } from '../api/types';

interface IWorkoutByWeek {
  [key: string]: IWorkout[];
}

export const getVideosByWeek = (workouts: IWorkout[] | undefined) =>
  workouts?.reduce<IWorkoutByWeek>((acc, workout) => {
    if (workout.week === null) return acc;
    if (acc[workout.week]) {
      acc[workout.week] = [...acc[workout.week], workout];
    } else {
      acc[workout.week] = [workout];
    }
    return acc;
  }, {});
