import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TRenderSchemes } from '../../api/types';
import { matcherHelper } from '../utils';
import { getRenderSchemesThunk } from './thunks';
import { RenderSchemesNames } from '../../utils';

interface IRenderSchemes {
  renderSchemes: TRenderSchemes;
  error: string;
  isLoading: boolean;
}

const initialState: IRenderSchemes = {
  renderSchemes: { [RenderSchemesNames.duration]: [], [RenderSchemesNames.schemeWorkout]: [] },
  error: '',
  isLoading: false,
};

export const renderSchemesSlice = createSlice({
  name: 'renderSchemes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRenderSchemesThunk.fulfilled, (state, { payload }: PayloadAction<TRenderSchemes>) => {
        state.error = '';
        state.isLoading = false;
        state.renderSchemes = { ...state.renderSchemes, ...payload };
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, renderSchemesSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, renderSchemesSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const {} = renderSchemesSlice.actions;

export default renderSchemesSlice.reducer;
