import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { BottomButtonBlock, CssPlatformContainer, ImageCover, NavBar } from '../../../../components';
import joinToCommunity from '../../../../assets/image/joinToCommunity.png';
import s from './styles.module.scss';
import { Button, Typography } from 'front-package-ui-kit';
import { AppleIcon, GoogleIcon, VkIcon } from '../../../../assets/icons';

interface IProps {
  onClick: () => void;
  onBackClick: () => void;
}

const JoinToCommunity: FC<IProps> = ({ onClick, onBackClick }) => {
  const handleSetSocial = (social: string) => {
    window?.loginSocial(social);
  };

  const loginSocialList = window?.loginSocialList();

  const collectionSocialList = loginSocialList.reduce<{ [key: string]: string }>((acc, prev) => {
    acc[prev] = prev;

    return acc;
  }, {});

  return (
    <div className={s.JoinToCommunity}>
      <ImageCover img={joinToCommunity} />

      <div className={s.shadow}></div>

      <CssPlatformContainer>
        <Stack justifyContent="space-between" className={s.content}>
          <NavBar goBackButton goBackButtonProps={{ white: true }} onBackClick={onBackClick} />

          <div>
            <Typography variant="h2" fontWeight={700} color="white" textAlign="center" marginBottom="40px">
              Присоединяйся <br /> к сообществу KOLSA!
            </Typography>

            <Stack direction="row" justifyContent="center" columnGap="40px" marginBottom="32px">
              {collectionSocialList.google && (
                <button onClick={() => handleSetSocial('google')} className={s.socialBtn}>
                  <GoogleIcon />
                </button>
              )}

              {collectionSocialList.vk && (
                <Stack onClick={() => handleSetSocial('vk')} className={s.socialBtn}>
                  <VkIcon />
                </Stack>
              )}

              {collectionSocialList.apple && (
                <button onClick={() => handleSetSocial('apple')} className={s.socialBtn}>
                  <AppleIcon />
                </button>
              )}
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="center" paddingX="34px" marginBottom="16px">
              <div className={s.divider} />

              <Typography marginLeft="8px" marginRight="8px" opacity={0.5} variant="text5" color="white">
                или
              </Typography>

              <div className={s.divider} />
            </Stack>

            <BottomButtonBlock>
              <Button fullWidth onClick={onClick} variant="contained" color="lime" size="L">
                <Typography whiteSpace="nowrap" variant="text6" fontWeight={700}>
                  Через телефон или e-mail
                </Typography>
              </Button>
            </BottomButtonBlock>
          </div>
        </Stack>
      </CssPlatformContainer>
    </div>
  );
};

export default JoinToCommunity;
