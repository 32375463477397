import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';

export const getReferralLinkThunk = createAsyncThunk('referral/getReferralLink', async (_, { rejectWithValue }) => {
  try {
    return (await api.getReferralLink()).data.data;
  } catch (error) {
    const textError = getTextError(error);

    return rejectWithValue(textError);
  }
});

export const createReferralThunk = createAsyncThunk('referral/createReferralLink', async (_, { rejectWithValue }) => {
  try {
    return (await api.createReferralLink()).data.data;
  } catch (error) {
    const textError = getTextError(error);

    return rejectWithValue(textError);
  }
});

export const getReferralProductThunk = createAsyncThunk(
  'referral/getReferralProductThunk',
  async (_, { rejectWithValue }) => {
    try {
      return (await api.getReferralProducts()).data.data;
    } catch (error) {
      const textError = getTextError(error);

      return rejectWithValue(textError);
    }
  }
);

export const getReferralGiftThunk = createAsyncThunk(
  'referral/getReferralGiftThunk',
  async (id: number, { rejectWithValue }) => {
    try {
      return (await api.getReferralGift(id)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      return rejectWithValue(textError);
    }
  }
);

export const getIdsGotGiftsThunk = createAsyncThunk('referral/getIdsGotGiftsThunk', async (_, { rejectWithValue }) => {
  try {
    return (await api.getIdsGotGifts()).data.data;
  } catch (error) {
    const textError = getTextError(error);

    return rejectWithValue(textError);
  }
});
