import React, { createContext, FC, ReactNode } from 'react';
import { RemoveTokens, setSession } from '../../api/jwt';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { loginThunk } from '../../redux/auth/thunks';

interface IAuthContext {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
}

const AuthContext = createContext<IAuthContext | null>(null);

interface IProps {
  children: ReactNode;
}

const AuthProvider: FC<IProps> = ({ children }) => {
  const { isLoading } = useAppSelector((state) => state.authStore);

  const dispatch = useAppDispatch();

  const login = async (email: string, password: string) => {
    await dispatch(loginThunk({ email, password }));
  };

  const logout = () => {
    setSession(RemoveTokens.message);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
