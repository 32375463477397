import React from 'react';

export const NotFoundIcon404 = () => {
  return (
    <svg width="199" height="177" viewBox="0 0 199 177" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_bi_262_19834)">
        <path
          d="M75.4286 131.683L131.938 98.5144C133.527 97.5818 134.028 95.4823 133.057 93.8272L126.989 83.4904C126.385 82.4598 125.618 81.5661 124.72 80.7915L114.137 75.121C112.311 73.5895 111.064 71.4659 110.617 69.125L110.018 57.8039C109.408 54.6352 108.275 51.6055 106.625 48.7949L85.44 12.702C80.2159 3.80173 69.0383 0.621285 60.4644 5.65377L29.1666 24.0241C20.6227 29.039 17.9019 40.3352 23.1443 49.2667L44.3291 85.3596C45.9605 88.139 48.0715 90.6369 50.5406 92.7146L60.1331 98.7569C61.9591 100.288 63.2056 102.412 63.6525 104.753L63.4437 116.758C63.6822 117.92 64.0889 119.025 64.6938 120.055L70.761 130.392C71.7325 132.047 73.8098 132.633 75.3987 131.701L75.4286 131.683Z"
          fill="url(#paint0_linear_262_19834)"
          fillOpacity="0.2"
        />
        <path
          d="M113.797 75.5253L113.839 75.5604L113.887 75.5862L124.419 81.2289C125.258 81.9599 125.971 82.7975 126.534 83.7575L132.602 94.0943C133.436 95.5158 132.991 97.2844 131.671 98.0593L75.1615 131.228L75.1315 131.246C73.8114 132.02 72.0505 131.546 71.2161 130.125L65.1489 119.788C64.5854 118.828 64.2017 117.797 63.9724 116.708L64.1801 104.762L64.1811 104.707L64.1709 104.654C63.7011 102.194 62.3914 99.9622 60.4723 98.3526L60.4448 98.3295L60.4144 98.3104L50.8527 92.2875C48.4413 90.2529 46.379 87.8095 44.7842 85.0925L23.5994 48.9996C18.4945 40.3023 21.1579 29.3367 29.4337 24.4792L60.7315 6.10888C69.0361 1.23443 79.8976 4.30192 84.9849 12.9691L106.17 49.062C107.783 51.8099 108.893 54.7708 109.493 57.8683L110.09 69.1529L110.092 69.1888L110.099 69.224C110.569 71.6843 111.878 73.9157 113.797 75.5253Z"
          stroke="url(#paint1_linear_262_19834)"
          strokeOpacity="0.5"
          strokeWidth="1.05543"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.555 134.789L117.051 128.629L117.082 128.61C118.964 127.505 119.751 125.245 119.069 123.256L132.396 115.434C137.881 112.214 139.703 105.157 136.51 99.7162L133.176 94.0367L70.8894 130.596L74.223 136.276C77.4339 141.746 84.4968 143.549 89.9521 140.346L100.912 133.914C102.316 135.478 104.673 135.894 106.555 134.789Z"
        fill="url(#paint2_linear_262_19834)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.349 151.031L113.74 137.036L105.157 150.991C104.03 152.65 103.372 154.652 103.372 156.808C103.372 162.534 108.013 167.175 113.739 167.175C119.464 167.175 124.106 162.534 124.106 156.808C124.106 154.669 123.458 152.682 122.348 151.031H122.349Z"
        fill="url(#paint3_linear_262_19834)"
      />
      <path
        d="M70.6772 56.194V50.6945L84.4664 28.9705H89.208V36.5827H86.4018L77.7089 50.3397V50.5977H97.3041V56.194H70.6772ZM86.5308 62V54.5167L86.6598 52.0815V28.9705H93.2076V62H86.5308ZM114.907 62.7257C112.134 62.715 109.747 62.0323 107.747 60.6775C105.758 59.3228 104.226 57.3606 103.15 54.7909C102.086 52.2212 101.559 49.1301 101.57 45.5175C101.57 41.9156 102.102 38.846 103.166 36.3086C104.242 33.7711 105.774 31.8412 107.763 30.5187C109.763 29.1855 112.144 28.5189 114.907 28.5189C117.671 28.5189 120.047 29.1855 122.036 30.5187C124.036 31.8519 125.573 33.7873 126.648 36.3247C127.724 38.8514 128.256 41.9156 128.245 45.5175C128.245 49.1408 127.707 52.2374 126.632 54.807C125.568 57.3767 124.041 59.3389 122.052 60.6937C120.063 62.0484 117.681 62.7257 114.907 62.7257ZM114.907 56.9359C116.8 56.9359 118.31 55.9844 119.439 54.0813C120.568 52.1782 121.127 49.3236 121.117 45.5175C121.117 43.0123 120.859 40.9265 120.343 39.2599C119.837 37.5934 119.117 36.3408 118.181 35.5022C117.257 34.6635 116.165 34.2442 114.907 34.2442C113.026 34.2442 111.521 35.185 110.392 37.0666C109.263 38.9481 108.693 41.7651 108.682 45.5175C108.682 48.0549 108.935 50.173 109.44 51.8718C109.956 53.5598 110.682 54.8285 111.617 55.6779C112.553 56.5166 113.65 56.9359 114.907 56.9359ZM132.725 56.194V50.6945L146.514 28.9705H151.255V36.5827H148.449L139.756 50.3397V50.5977H159.351V56.194H132.725ZM148.578 62V54.5167L148.707 52.0815V28.9705H155.255V62H148.578Z"
        fill="url(#paint4_linear_262_19834)"
      />
      <defs>
        <filter
          id="filter0_bi_262_19834"
          x="-22.8663"
          y="-40.0765"
          width="199.759"
          height="215.561"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="21.6702" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_262_19834" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_262_19834" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.22339" />
          <feGaussianBlur stdDeviation="14.4468" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_262_19834" />
        </filter>
        <linearGradient
          id="paint0_linear_262_19834"
          x1="61.9202"
          y1="116.048"
          x2="-4.35923"
          y2="97.4843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="1" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_262_19834"
          x1="80.1848"
          y1="122.658"
          x2="78.188"
          y2="14.2244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.423397" stopColor="white" stopOpacity="0.576603" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_262_19834"
          x1="144.003"
          y1="94.7528"
          x2="76.3072"
          y2="133.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_262_19834"
          x1="113.739"
          y1="137.036"
          x2="113.739"
          y2="167.175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_262_19834"
          x1="115.13"
          y1="34.8589"
          x2="113.596"
          y2="66.7003"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.16" stopColor="#BDBDBD" />
          <stop offset="1" stopColor="#5CFF33" />
        </linearGradient>
      </defs>
    </svg>
  );
};
