import { FC, memo } from 'react';
import { ImageCover, VideoCover } from '../..';
import { API_VIDEO_URL } from '../../../api/config';
import { IFilesForDetailseCourseCover } from '../../../api/types';
import defaultCover from '../../../assets/image/defaultCover.png';
import { ApiVersion, BaseVideoCoverPath, FilesCoverPath, ResizeType } from '../../../utils/constants/config';
import s from './styles.module.scss';
import { getCoverType } from '../../../utils/getCoverType';

export const TEST_ID = 'DetailsPageCover';

interface IProps {
  files: IFilesForDetailseCourseCover[] | undefined;
  fileCoverPath: FilesCoverPath;
}

interface IProps {}

const DetailsPageCover: FC<IProps> = memo(({ files = [], fileCoverPath }) => {
  const { img, webm, mp4, ogg, isVideo } = getCoverType(files);

  return (
    <div className={s.detailsPageCover}>
      {!isVideo && (
        <ImageCover
          img={{
            baseApiService: 'VIDEO',
            imagePath: fileCoverPath,
            name: img,
            width: 1000,
            height: 1480,
            resize: ResizeType.resizeWidthCenter,
          }}
          fit="cover"
          defaultImg={defaultCover}
        />
      )}

      {isVideo && (
        <VideoCover
          webm={`${API_VIDEO_URL}/${ApiVersion.videoService}/${BaseVideoCoverPath.path}/${webm}`}
          mp4={`${API_VIDEO_URL}/${ApiVersion.videoService}/${BaseVideoCoverPath.path}/${mp4}`}
          ogg={`${API_VIDEO_URL}/${ApiVersion.videoService}/${BaseVideoCoverPath.path}/${ogg}`}
          poster={defaultCover}
        />
      )}
    </div>
  );
});

DetailsPageCover.displayName = 'DetailsPageCover';
export default DetailsPageCover;
