import React from 'react';
import { FC } from 'react';
import { IBottomNavigationIconProps } from '../types';

interface IProps extends IBottomNavigationIconProps {}

const ProfileIconNav: FC<IProps> = ({ isAcvive, theme = 'light' }) => {
  return (
    <>
      {!isAcvive && (
        <>
          {theme === 'light' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6566 15.3814C8.5268 15.3814 5 16.0058 5 18.5065C5 21.0072 8.50442 21.654 12.6566 21.654C16.7865 21.654 20.3123 21.0286 20.3123 18.5289C20.3123 16.0292 16.8089 15.3814 12.6566 15.3814Z"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6548 11.8146C15.3649 11.8146 17.5616 9.61699 17.5616 6.9068C17.5616 4.19662 15.3649 2 12.6548 2C9.94457 2 7.74694 4.19662 7.74694 6.9068C7.73778 9.60783 9.92017 11.8055 12.6202 11.8146H12.6548Z"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6566 15.3814C8.5268 15.3814 5 16.0058 5 18.5065C5 21.0072 8.50442 21.654 12.6566 21.654C16.7865 21.654 20.3123 21.0286 20.3123 18.5289C20.3123 16.0292 16.8089 15.3814 12.6566 15.3814Z"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6548 11.8146C15.3649 11.8146 17.5616 9.61699 17.5616 6.9068C17.5616 4.19662 15.3649 2 12.6548 2C9.94457 2 7.74694 4.19662 7.74694 6.9068C7.73778 9.60783 9.92017 11.8055 12.6202 11.8146H12.6548Z"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </>
      )}

      {isAcvive && (
        <>
          {theme === 'light' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5006 14.8027C7.64584 14.8027 3.5 15.5367 3.5 18.4763C3.5 21.416 7.61955 22.1763 12.5006 22.1763C17.3553 22.1763 21.5 21.4411 21.5 18.5026C21.5 15.5642 17.3816 14.8027 12.5006 14.8027Z"
                fill="#1D1D1D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4984 12.9609C15.6843 12.9609 18.2665 10.3775 18.2665 7.1916C18.2665 4.00571 15.6843 1.42352 12.4984 1.42352C9.31248 1.42352 6.7291 4.00571 6.7291 7.1916C6.71834 10.3667 9.28379 12.9501 12.4577 12.9609H12.4984Z"
                fill="#1D1D1D"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5006 14.8027C7.64584 14.8027 3.5 15.5367 3.5 18.4763C3.5 21.416 7.61955 22.1763 12.5006 22.1763C17.3553 22.1763 21.5 21.4411 21.5 18.5026C21.5 15.5642 17.3816 14.8027 12.5006 14.8027Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4984 12.9609C15.6843 12.9609 18.2665 10.3775 18.2665 7.1916C18.2665 4.00571 15.6843 1.42352 12.4984 1.42352C9.31248 1.42352 6.7291 4.00571 6.7291 7.1916C6.71834 10.3667 9.28379 12.9501 12.4577 12.9609H12.4984Z"
                fill="white"
              />
            </svg>
          )}
        </>
      )}
    </>
  );
};

export default ProfileIconNav;
