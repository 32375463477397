import React, { FC, memo } from 'react';
import s from './styles.module.scss';
import { Overlay, Portal } from '../..';
import { CircularProgress } from '@mui/material';

export const TEST_ID = 'Fallback';

export interface FallbackProps {
  active: boolean;
}

const Fallback: FC<FallbackProps> = ({ active }) => {
  return (
    <>
      {active && (
        <Portal element={document.getElementById('app') ?? document.body}>
          <div className={s.fallback} data-testid={TEST_ID}>
            <Overlay />

            <div className={s.circularProgress}>
              <CircularProgress color="inherit" />
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export const FallbackMemo = memo(Fallback);
