import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getData = (state: RootState) => state.commentStore.comments;

export const getAllComments = createSelector(
  [(state: RootState) => state.commentStore.comments],
  (comments) => comments.data
);
export const getCommentStatus = createSelector(
  [(state: RootState) => state.commentStore.comments],
  (comments) => comments.statuse
);
export const getCommentError = createSelector(
  [(state: RootState) => state.commentStore.comments],
  (comments) => comments.error
);

export const getSelectedComment = (state: RootState) => state.commentStore.selectedComment;
export const isEditing = (state: RootState) => state.commentStore.isEdit;

export const getCommentTotal = (state: RootState) => state.commentStore.total;
export const getCommentGroupId = (state: RootState) => state.commentStore.groupId;
export const getCommentPage = (state: RootState) => state.commentStore.currentPage;
export const getCommentLastPage = (state: RootState) => state.commentStore.lastPage;
export const getCommentSort = (state: RootState) => state.commentStore.sortType;

export const getCommentForm = (state: RootState) => state.commentStore.commentForm;

export const getCommentFormText = createSelector(
  [(state: RootState) => state.commentStore.commentForm],
  (commentForm) => commentForm.text
);

export const getCommentFormTitle = createSelector(
  [(state: RootState) => state.commentStore.commentForm],
  (commentForm) => commentForm.title
);

export const getCommentFormParentId = createSelector(
  [(state: RootState) => state.commentStore.commentForm],
  (commentForm) => commentForm.parent_id
);

export const getCommentFormImages = createSelector(
  [(state: RootState) => state.commentStore.commentForm],
  (commentForm) => commentForm.images
);
