import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyCollectionsDetails = lazy(() => import('./CollectionsDetails'));

export const CollectionsDetails = () => (
  <Suspense fallback={<Loading />}>
    <LazyCollectionsDetails />
  </Suspense>
);
