import { Box } from '@mui/material';
import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import { FC, memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { dayShortNames } from '../../constant/const';
import { IStepmeterProgress } from '../../model/types';

import s from './styles.module.scss';

export const TEST_ID = 'StepmeterSwiperChart';

export interface StepmeterSwiperChartProps {
  goal: number;
  splittedProgress: (IStepmeterProgress | null)[][];
  selectedDay: IStepmeterProgress | null;
  setSelectedDay: (value: IStepmeterProgress | null) => void;
  selectedIndex: number | null;
  setSelectedIndex: (value: number | null) => void;
  setTransitionValue: (value: number) => void;
  handleSetCurrentWeek: (value: number) => void;
}

const emptyArray = new Array(7).fill(null);

const StepmeterSwiperChart: FC<StepmeterSwiperChartProps> = ({
  splittedProgress,
  goal,
  handleSetCurrentWeek,
  selectedDay,
  setSelectedDay,
  selectedIndex,
  setSelectedIndex,
  setTransitionValue,
}) => {
  const handleBarClick = (day: IStepmeterProgress | null, index: number) => (e: any) => {
    e.stopPropagation();

    if (day === null) return;

    // Если тултип открыт, то закрываем его
    if (index === selectedIndex && selectedDay === day) {
      setSelectedDay(null);
      setSelectedIndex(null);
      setTransitionValue(0);
      return;
    }

    setSelectedDay(day);
    setSelectedIndex(index);
    setTransitionValue(index * (goal < 10000 ? 9.85 : 8.6)); // Устанавливаем коэффициент на основе индекса и ширины строки
  };

  return (
    <Swiper
      className={s.stepmeterSwiperChart}
      resistanceRatio={0}
      initialSlide={splittedProgress.length > 0 ? splittedProgress.length - 1 : 0}
      onInit={(swiper) => handleSetCurrentWeek(swiper.realIndex)}
      onSlideChange={(swiper) => {
        handleSetCurrentWeek(swiper.realIndex);
        setSelectedDay(null);
        setSelectedIndex(null);
        setTransitionValue(0);
      }}
      data-testid={TEST_ID}
    >
      {splittedProgress.length > 0 &&
        splittedProgress.map((week) => (
          <SwiperSlide key={week.filter((day) => day !== null)[0]?.id}>
            <div className={s.slideWrapper}>
              {week.map((day, i) => (
                <div key={day !== null ? day.date : i} className={s.goalBar} onClick={handleBarClick(day, i)}>
                  <div
                    className={s.stepsBar}
                    style={{
                      height:
                        day !== null
                          ? day.steps <= goal
                            ? `${Number((day.steps / goal).toFixed(2)) * 100}%`
                            : '36px'
                          : 0,
                    }}
                  />
                </div>
              ))}
            </div>
          </SwiperSlide>
        ))}

      {splittedProgress.length === 0 && (
        <SwiperSlide>
          <div className={s.slideWrapper}>
            {emptyArray.map((_, i) => (
              <Box className={s.goalBar} key={i} />
            ))}
          </div>
        </SwiperSlide>
      )}

      <div className={s.arrowLeft} slot="container-start">
        <KeyboardArrowLeftIcon color={NamesOfColors.white30} size={8} />
      </div>
      <div className={s.arrowRight} slot="container-end">
        <KeyboardArrowRightIcon color={NamesOfColors.white30} size={8} />
      </div>

      <div className={s.daysOfWeekContainer} slot="container-end">
        {dayShortNames.map((day) => (
          <div key={day} className={s.dayOfWeek}>
            <Typography variant="text8" color="white" opacity={0.5} fontWeight={500}>
              {day}
            </Typography>
          </div>
        ))}
      </div>
    </Swiper>
  );
};

export const StepmeterSwiperChartMemo = memo(StepmeterSwiperChart);
