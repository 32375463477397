import { IGroups, TypeGroups } from '../api/types';
import { ICourseWithExtraOptions } from '../redux/courses/slice';
import { LevelsID, VirtualGroups } from './constants/config';

const isLevelsID = (id: number): id is LevelsID => {
  return id === LevelsID.beginner || id === LevelsID.middle || id === LevelsID.high;
};

const getCourseByLevel = (courses: ICourseWithExtraOptions[], levelID: LevelsID) => {
  if (!isLevelsID(levelID)) return [];

  return courses.filter((course) => course.levels.findIndex(({ id }) => id === levelID) >= 0);
};

export type IGroupOptions = Record<TypeGroups, IGroups[]>;

const getCoursesByDuration = (courses: ICourseWithExtraOptions[], from: number, to: number) => {
  return courses.filter((course) => {
    if (course.duration === null) return false;

    const duration = course.duration;

    return duration >= from && duration <= to;
  });
};

export const getCoursesByGroup = (courses: ICourseWithExtraOptions[], id: string | number) => {
  if (!isNaN(Number(id))) return courses.filter((course) => course.groups.includes(Number(id)));

  if (typeof id === 'string' && id.includes(VirtualGroups.duration)) {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const [_, from, to] = id.split('-');

    return getCoursesByDuration(courses, parseInt(from) ? parseInt(from) : 0, parseInt(to) ? parseInt(to) : 9999999);
  }

  if (typeof id === 'string' && id.includes(VirtualGroups.level)) {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const [_, levelId] = id.split('-');

    return getCourseByLevel(courses, +levelId);
  }

  return [];
};

export const getCoursesWithoutGroup = (courses: ICourseWithExtraOptions[], id: string | number) => {
  if (!isNaN(Number(id))) return courses.filter((course) => !course.groups.includes(Number(id)));

  //при необходимости можно добавить виртуальные группы, по аналогии с функцией getCoursesByGroup

  return [];
};
