import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import { createServiceNotice } from '../notifications/slice';
import { Environment, ErrorMessages, SuccessMessages, noticeCreator } from '../../utils';
import { IBankCard, IBankCardDataInform } from '../../api/types';
import { getUserInfoThunk } from '../user/thunks';

const getBankCardsWithDataIndorm = (bankCards: IBankCard[]) => {
  return bankCards.map((card) => {
    const dataInform: IBankCardDataInform = JSON.parse(card.data);

    return { ...card, ...dataInform };
  });
};

export const getCardListThunk = createAsyncThunk('cards/getCardListThunk', async (_, { rejectWithValue, dispatch }) => {
  try {
    const bankCards = (await api.getCardList()).data.data;

    const bankCardsWithDataIndorm = getBankCardsWithDataIndorm(bankCards);

    return bankCardsWithDataIndorm;
  } catch (error) {
    const textError = getTextError(error);

    dispatch(
      createServiceNotice({
        notice: noticeCreator(textError, 'error'),
        otherInfo: { error, pathname: 'cards/getCardListThunk', forEnvironment: Environment.development },
      })
    );

    return rejectWithValue(textError);
  }
});

export const deleteBankCardThunk = createAsyncThunk(
  'cards/deleteBankCardThunk',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const bankCards = (await api.deleteBankCard(id)).data.data;

      dispatch(getUserInfoThunk());

      const bankCardsWithDataIndorm = getBankCardsWithDataIndorm(bankCards);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(SuccessMessages.deleteCard, 'success', 1000),
          otherInfo: { pathname: 'cards/deleteBankCardThunk', forEnvironment: Environment.production },
        })
      );

      return bankCardsWithDataIndorm;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(ErrorMessages.deleteCard, 'error'),
          otherInfo: { error, pathname: 'cards/deleteBankCardThunk', forEnvironment: Environment.production },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const setMainBankCardThunk = createAsyncThunk(
  'cards/setMainBankCardThunk',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const bankCards = (await api.setMaindBankCard(id)).data.data;

      const bankCardsWithDataIndorm = getBankCardsWithDataIndorm(bankCards);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(SuccessMessages.setMainBankCard, 'success', 1000),
          otherInfo: { pathname: 'cards/setMainBankCardThunk', forEnvironment: Environment.production },
        })
      );

      return bankCardsWithDataIndorm;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(ErrorMessages.setMainBankCard, 'error'),
          otherInfo: { error, pathname: 'cards/setMainBankCardThunk', forEnvironment: Environment.production },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
