import { openSingleFilterBottomSheet } from '../components';
import { setSingleFilterBottomSheet } from '../redux/filters/slice';
import { useAppDispatch } from './redux';
import useBottomSheet from './useBottomSheet';

export const useOpenSingleFilterBottomSheet = () => {
  const { onOpenBottomSheet } = useBottomSheet();

  const dispatch = useAppDispatch();

  const handleOpenSingleFilterBottomSheet = (filterName: string) => () => {
    onOpenBottomSheet(openSingleFilterBottomSheet);

    dispatch(setSingleFilterBottomSheet(filterName));
  };

  return { handleOpenSingleFilterBottomSheet };
};
