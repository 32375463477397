import { FilterNames } from '.';
import { TFilters } from '../hooks/useFilter';

export enum FilterType {
  buttonFilters = 'buttonFilters',
  nameFilter = 'nameFilter',
  allFilters = 'allFilters',
}

export const isFilterNames = (filterName: string): filterName is FilterNames => {
  return filterName in FilterNames;
};

export const getNumberOfFilters = (filters: TFilters, filterType: FilterType = FilterType.allFilters) => {
  const arrayOfFiltersName = Object.keys(filters);

  let counter = 0;

  arrayOfFiltersName.forEach((filterName) => {
    if (isFilterNames(filterName) && filters[filterName].length) {
      if (filterType === FilterType.allFilters) counter = counter + filters[filterName].length;

      if (filterName === FilterNames.name && filterType === FilterType.nameFilter)
        counter = counter + filters[filterName].length;

      if (filterName !== FilterNames.name && filterType === FilterType.buttonFilters)
        counter = counter + filters[filterName].length;
    }
  });

  return counter;
};
