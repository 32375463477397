import React, { FC } from 'react';
import s from './styles.module.scss';
import splashScreen from '../../../assets/image/splashScreen.png';
import { CardMedia } from '@mui/material';

export const TEST_ID = 'SplashScreen';

interface IProps {}

const SplashScreen: FC<IProps> = () => {
  return (
    <div className={s.splashScreenContainer} data-testid={TEST_ID}>
      <div className={s.splashScreen}>
        <CardMedia className={s.img} component="img" image={splashScreen} alt="" />
      </div>
    </div>
  );
};

export default SplashScreen;
