import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import { createServiceNotice } from '../notifications/slice';
import { Environment, noticeCreator } from '../../utils';

export const getIntensityDictionaryThunk = createAsyncThunk(
  'dictionaries/getIntensityDictionary',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { data },
      } = await api.getIntensityDictionary();

      return data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: {
            error,
            pathname: 'dictionaries/getIntensityDictionary',
            forEnvironment: Environment.development,
          },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getLevelsDictionaryThunk = createAsyncThunk(
  'dictionaries/getLevelsDictionary',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { data },
      } = await api.getLevelsDictionary();

      const levels = data.sort(({ id: a }, { id: b }) => a - b);

      return levels;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'dictionaries/getLevelsDictionary', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
