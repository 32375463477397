import { AxiosError } from 'axios';
import { IResponseError } from '../api/types';
import { ErrorMessages } from '../utils';

const environment = window.getEnvironment();

const checkActionStatus = (actionType: string, status: string, name: string) =>
  actionType.startsWith(name) && actionType.endsWith(status);

export const matcherHelper = {
  isPendingAction: (actionType: string, name: string) => {
    return checkActionStatus(actionType, 'pending', name);
  },

  isRejectedAction: (actionType: string, name: string) => {
    return checkActionStatus(actionType, 'rejected', name);
  },
};

const isIResponseError = (object: any): object is IResponseError => {
  return 'error' in object && 'error_description' in object && 'message' in object;
};

export const getTextError = (error: unknown): string => {
  if (typeof error === 'string') {
    return error;
  } else if (error instanceof AxiosError) {
    if (environment === 'development') {
      const url = (error.response?.config.url ?? '').split('api')[1];

      return `Url: ${url} | Status: ${error.response?.status} | ${error.response?.data.message}`;
    }

    return error.response?.data.message ?? ErrorMessages.defaultTextError;
  } else if (error instanceof Error) {
    return error.message;
  } else if (error && typeof error === 'object' && isIResponseError(error)) {
    return error.message;
  } else {
    console.error(error);
    throw new Error('Unknown error type!!!');
  }
};
