import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { matcherHelper } from '../utils';
import { postUserAgreementsThunk } from './thunks';
import { ValuesOfConstants } from '../../utils/constants/config';

interface IUserAgreements {
  userAgreements: string[];
  error: string;
  isLoading: boolean;
}

const initialState: IUserAgreements = {
  userAgreements: [ValuesOfConstants.advertisingMailings],
  error: '',
  isLoading: false,
};

export const userAgreementsSlice = createSlice({
  name: 'userAgreements',
  initialState,
  reducers: {
    setUserAgreements(state, { payload }: PayloadAction<string[]>) {
      state.userAgreements = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUserAgreementsThunk.fulfilled, (state, { payload }: PayloadAction<string[]>) => {
        state.error = '';
        state.isLoading = false;
        state.userAgreements = payload;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, userAgreementsSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, userAgreementsSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const { setUserAgreements } = userAgreementsSlice.actions;

export default userAgreementsSlice.reducer;
