import { IStepmeterProgress } from './model/types';

export const mockProgress: IStepmeterProgress[] = [
  { date: '2024-08-17', id: 16, steps: 2890, calories: 1191, distance: 6.2, time: 82 },
  { date: '2024-08-16', id: 17, steps: 6210, calories: 1795, distance: 10.7, time: 259 },
  { date: '2024-08-15', id: 15, steps: 4500, calories: 795, distance: 5.7, time: 70 },
  { date: '2024-08-14', id: 14, steps: 2890, calories: 1191, distance: 6.2, time: 82 },
  { date: '2024-08-13', id: 13, steps: 4210, calories: 739, distance: 8.7, time: 249 },
  { date: '2024-08-12', id: 12, steps: 4500, calories: 795, distance: 5.7, time: 70 },
  { date: '2024-08-11', id: 11, steps: 2890, calories: 1191, distance: 6.2, time: 82 },
  { date: '2024-08-10', id: 10, steps: 6210, calories: 1795, distance: 10.7, time: 259 },
  { date: '2024-08-08', id: 8, steps: 1821, calories: 1093, distance: 13.0, time: 318 },
  { date: '2024-08-06', id: 6, steps: 3667, calories: 1191, distance: 8.5, time: 137 },
  { date: '2024-08-09', id: 9, steps: 13781, calories: 1795, distance: 15.7, time: 570 },
  { date: '2024-08-07', id: 7, steps: 5270, calories: 817, distance: 10.2, time: 370 },
  { date: '2024-08-05', id: 5, steps: 4380, calories: 1191, distance: 10.1, time: 371 },
  { date: '2023-08-11', id: 18, steps: 2890, calories: 1191, distance: 6.2, time: 82 },
  { date: '2023-08-10', id: 19, steps: 6210, calories: 1795, distance: 10.7, time: 259 },
  { date: '2024-08-03', id: 3, steps: 4690, calories: 1795, distance: 6.3, time: 232 },
  { date: '2024-08-04', id: 4, steps: 5011, calories: 693, distance: 4.9, time: 117 },
  { date: '2024-08-02', id: 2, steps: 2245, calories: 1191, distance: 11.5, time: 247 },
  { date: '2024-08-01', id: 1, steps: 3870, calories: 784, distance: 5.7, time: 170 },
  { date: '2024-07-11', id: 0, steps: 4690, calories: 1795, distance: 6.3, time: 232 },
];
