import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyCourseDetails = lazy(() => import('./CourseDetails'));

export const CourseDetails = () => (
  <Suspense fallback={<Loading />}>
    <LazyCourseDetails />
  </Suspense>
);
