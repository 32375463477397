import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const SEARCH_PARAMS_NAME = 'step';

export const useStep = (defaultStep?: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has(SEARCH_PARAMS_NAME) || defaultStep === undefined) return;

    setSearchParams({ [SEARCH_PARAMS_NAME]: defaultStep });
  });

  const nextStepHandler = (step: string) => {
    setSearchParams({ [SEARCH_PARAMS_NAME]: step });
  };

  const goBackHandler = () => {
    window.history.back();
  };

  const currentStep = searchParams.has(SEARCH_PARAMS_NAME) ? searchParams.get(SEARCH_PARAMS_NAME) : defaultStep;

  return { currentStep, nextStepHandler, goBackHandler };
};
