import { Box, Stack } from '@mui/material';
import { Button, Typography } from 'front-package-ui-kit';
import React, { FC, memo } from 'react';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, FilterMenuItem } from '../..';
import { useBottomSheet } from '../../../hooks';
import { IButtonsFilterOptions } from '../../../hooks/useFilter';
import { upperCaseFirst } from '../../../utils';

export const TEST_ID = 'SingleFilterBottomSheet';

export const openSingleFilterBottomSheet = 'openSingleFilterBottomSheet';

export interface SingleFilterBottomSheetProps {
  filter: IButtonsFilterOptions | undefined;
  numberOfCourses: number;
  numberOfFilters: number;
}

const SingleFilterBottomSheet: FC<SingleFilterBottomSheetProps> = ({ numberOfCourses, numberOfFilters, filter }) => {
  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openSingleFilterBottomSheet);

  const isFiltersActive = Boolean(numberOfFilters);

  return (
    <BottomSheetUI
      data-testid={TEST_ID}
      height="auto"
      touchLine
      onDismiss={() => onCloseBottomSheet()}
      expandOnContentDrag
      open={isOpen}
      header={
        <BottomSheetHeader
          alignItems="center"
          title={
            <Typography variant="h4" fontWeight={800}>
              {upperCaseFirst(filter?.title ?? '')}
            </Typography>
          }
          onClose={() => onCloseBottomSheet()}
        />
      }
      footer={
        <BottomButtonBlock direction="column" gap="8px">
          <Button variant="contained" fullWidth onClick={() => onCloseBottomSheet()} size="L" color="black">
            <Stack flexDirection="row" columnGap="6px">
              <Typography variant="text6" fontWeight={700} color="white">
                Показать
              </Typography>

              {isFiltersActive && (
                <Typography variant="text6" fontWeight={700} color="lime">
                  {numberOfCourses}
                </Typography>
              )}
            </Stack>
          </Button>

          <Button size="L" fullWidth variant="text" onClick={filter?.clearFilterHandler}>
            <Typography variant="text6" fontWeight={700}>
              Сбросить
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <Box padding="0 20px">
        <FilterMenuItem
          filterValues={filter?.filterValues ?? []}
          onFilterButtonClickHandler={filter?.onFilterButtonClickHandler ?? (() => () => {})}
          options={filter?.filterButtons ?? []}
        />
      </Box>
    </BottomSheetUI>
  );
};

export const SingleFilterBottomSheetMemo = memo(SingleFilterBottomSheet);
