import React, { FC } from 'react';
import { useAppDispatch } from '../../../hooks';
import { IServiceNotice } from '../../../redux/notifications/slice';
import { clearServiceNotice } from '../../../redux/notifications/thunks';
import s from './styles.module.scss';
import { NoticeTypes } from './config';
import { Box, Stack } from '@mui/material';
import { ErrorIcon, WarningIcon } from '../../../assets/icons';
import { useEffectOnce } from 'usehooks-ts';
import cn from 'classnames';
import { CheckCircleOutlineIcon, InfoIcon, Typography } from 'front-package-ui-kit';

interface IProps extends IServiceNotice {}

const Notice: FC<IProps> = ({ id, text, type, sleepTime }) => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(clearServiceNotice({ id, sleepTime }));
  });

  return (
    <>
      {type === NoticeTypes.error && (
        <div className={s.notice}>
          <Stack direction="row" columnGap="10px" className={cn(s.noticeContent, s.error)}>
            <Box width="24px" height="24px">
              <ErrorIcon />
            </Box>

            <Typography color="white" variant="text4">
              <p style={{ overflowWrap: 'anywhere' }}>{text}</p>
            </Typography>
          </Stack>
        </div>
      )}

      {type === NoticeTypes.success && (
        <div className={s.notice}>
          <Stack direction="row" columnGap="10px" className={cn(s.noticeContent, s.success)}>
            <Box width="24px" height="24px">
              <CheckCircleOutlineIcon size={24} />
            </Box>

            <Typography variant="text4">{text}</Typography>
          </Stack>
        </div>
      )}

      {type === NoticeTypes.info && (
        <div className={s.notice}>
          <Stack direction="row" columnGap="10px" className={cn(s.noticeContent, s.info)}>
            <Box width="16px" height="16px">
              <InfoIcon />
            </Box>

            <Typography variant="text4">{text}</Typography>
          </Stack>
        </div>
      )}
    </>
  );
};

export default Notice;
