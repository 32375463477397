import React from 'react';
import { FC } from 'react';
import { useAnalytics } from '../hooks';
import { AnalyticsName } from './constants/config';
import { EventParameters } from '../contexts/AnalyticsContext';

export interface IAnalyticInfo {
  name: AnalyticsName;
  parameters?: EventParameters;
}

interface IBase {
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement>;
}

interface IComponentWithAnalytic {
  analyticInfo?: IAnalyticInfo;
}

export const withAnalytic = <TProps extends IBase>(Component: FC<TProps>) => {
  const ComponentWithAnalytic = ({ analyticInfo, ...props }: TProps & IComponentWithAnalytic) => {
    const { setAnalytics } = useAnalytics();

    const onClick = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
      if (props.onClick) props.onClick(e);

      if (analyticInfo) setAnalytics(analyticInfo.name, analyticInfo.parameters);
    };

    return <Component {...(props as unknown as TProps)} onClick={onClick} />;
  };

  ComponentWithAnalytic.displayName = Component.name;

  return ComponentWithAnalytic;
};
