import React, { FC } from 'react';
import s from './styles.module.scss';
import { Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import SliderCards, { TRenderCard, TSkeleton } from '../../molecules/SliderCards';
import { ICourse } from '../../../api/types';
import { KeyboardArrowRightIcon, NamesOfColors, Skeleton, Typography } from 'front-package-ui-kit';

export const TEST_ID = 'CourseSwiper';

interface IProps {
  courses: ICourse[];
  title: string;
  path: string;
  renderCard: TRenderCard<ICourse>;
  skeleton?: TSkeleton;
  loading?: boolean;
  renderLimit?: number;
  saveScrollKey: string;
}

const CourseSwiper: FC<IProps> = ({
  title,
  path,
  courses,
  renderCard,
  loading,
  skeleton,
  renderLimit,
  saveScrollKey,
}) => {
  return (
    <Box flexShrink={0}>
      <Stack className={s.title} direction="row" justifyContent="space-between">
        {!loading && (
          <Typography variant="h4" fontWeight={700}>
            {title}
          </Typography>
        )}

        {loading && <Skeleton width="45%" height="20px" borderRadius="4px" />}

        {!loading && courses.length > 1 && (
          <Link className={s.link} to={path}>
            <Typography variant="text6" color="black" opacity={0.5} fontWeight={500}>
              Все
            </Typography>

            <KeyboardArrowRightIcon size={16} color={NamesOfColors.grey50} />
          </Link>
        )}
      </Stack>

      <SliderCards
        renderLimit={renderLimit}
        options={courses}
        renderCard={renderCard}
        loading={loading}
        skeleton={skeleton}
        saveScrollKey={saveScrollKey}
      />
    </Box>
  );
};

export default CourseSwiper;
