import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import s from './styles.module.scss';
import { useAppDispatch, useAppSelector, useProducts } from '../../../hooks';
import { Statuses } from '../../../utils';
import { clearProductsWithPromocode, setProductsWithPromocodeStatusIdle } from '../../../redux/products/slice';
import { setPromocode } from '../../../redux/payment/slice';
import { setProductPromocodeThunk } from '../../../redux/products/thunks';
import { Box, BoxProps, Stack } from '@mui/material';
import { Button, CloseIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import cn from 'classnames';

export const TEST_ID = 'Promocode';

export interface PromocodeProps extends BoxProps {
  placeholder?: string;
  theme?: 'light' | 'dark';
}

const Promocode: FC<PromocodeProps> = ({ theme = 'light', placeholder, ...props }) => {
  const isThemeLight = theme === 'light';

  const { promocode } = useAppSelector((state) => state.paymentStore);

  const [value, setValue] = useState<string>(promocode);

  const dispatch = useAppDispatch();

  const [focus, setFocus] = useState<boolean>(false);

  const { statuseProductWithPromocode, errorProductWithPromocode } = useProducts();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (statuseProductWithPromocode === Statuses.failed || statuseProductWithPromocode === Statuses.succeeded) {
        dispatch(setProductsWithPromocodeStatusIdle());
      }

      setValue(event.target.value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statuseProductWithPromocode]
  );

  const handlePostPromocode = useCallback(() => {
    dispatch(setPromocode(value));
    dispatch(setProductPromocodeThunk({ promocode: value }));
  }, [dispatch, value]);

  const handleClearPromocode = useCallback(() => {
    dispatch(setProductsWithPromocodeStatusIdle());
    dispatch(clearProductsWithPromocode());
    dispatch(setPromocode(''));
    setValue('');
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearProductsWithPromocode());
      dispatch(setPromocode(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={cn(s.promocode, { [s.active]: focus })} data-testid={TEST_ID} data-theme={theme} {...props}>
      <Stack className={s.promocodeContent}>
        {value && (
          <label htmlFor="">
            {statuseProductWithPromocode === Statuses.idle && (
              <Typography variant="text8" opacity={0.5} color={isThemeLight ? 'black' : 'white'} fontWeight={500}>
                Введите промокод
              </Typography>
            )}

            {statuseProductWithPromocode === Statuses.succeeded && (
              <Typography variant="text8" color="lime" fontWeight={500}>
                Промокод успешно применен
              </Typography>
            )}

            {statuseProductWithPromocode === Statuses.failed && (
              <Typography variant="text8" color="error" fontWeight={500}>
                {errorProductWithPromocode}
              </Typography>
            )}
          </label>
        )}

        <input
          className={s.inputPromocode}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          type="text"
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      </Stack>

      {promocode &&
        promocode === value &&
        (statuseProductWithPromocode === Statuses.failed || statuseProductWithPromocode === Statuses.succeeded) && (
          <button onClick={handleClearPromocode} className={s.promocodeButton}>
            <CloseIcon color={isThemeLight ? NamesOfColors.grey50 : NamesOfColors.white50} size={24} />
          </button>
        )}

      {value && statuseProductWithPromocode === Statuses.idle && (
        <Button variant="contained" color={isThemeLight ? 'black' : 'white'} size="XS">
          <Typography
            onClick={handlePostPromocode}
            variant="text6"
            fontWeight={700}
            color={isThemeLight ? 'white' : 'black'}
          >
            Применить
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export const PromocodeMemo = memo(Promocode);
