import { addDays, addWeeks, addMonths, addYears } from 'date-fns';
import { TypeDurationProduct } from '../api/types';

export const getDurationByProduct = (
  productCalculate: string | null | undefined,
  duration: number,
  typeDuration: TypeDurationProduct
) => {
  switch (typeDuration) {
    case 'day':
      return addDays(productCalculate ? new Date(productCalculate) : new Date(), duration);
    case 'week':
      return addWeeks(productCalculate ? new Date(productCalculate) : new Date(), duration);
    case 'month':
      return addMonths(productCalculate ? new Date(productCalculate) : new Date(), duration);
    case 'year':
      return addYears(productCalculate ? new Date(productCalculate) : new Date(), duration);
    default:
      return new Date();
  }
};
