import { useEffectOnce } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '.';
import { IIntensity } from '../api/types';
import { getIntensityDictionaryThunk } from '../redux/dictionaries/thunks';

const useDictionaries = () => {
  const {
    dictionariesStore: { intensity },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    if (!intensity.length) dispatch(getIntensityDictionaryThunk());
  });

  const intensityDictionary = intensity.reduce<{ [key: number]: IIntensity['title'] }>((acc, { id, title }) => {
    acc[id] = title;

    return acc;
  }, {});

  return { intensityDictionary };
};

export default useDictionaries;
