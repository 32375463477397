const CloseIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00004C14.6667 4.31814 11.6819 1.33337 8.00004 1.33337C4.31814 1.33337 1.33337 4.31814 1.33337 8.00004C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667ZM4.93537 4.9353C5.19572 4.67495 5.61783 4.67495 5.87818 4.9353L8.00011 7.05723L10.122 4.9353C10.3824 4.67495 10.8045 4.67495 11.0649 4.9353C11.3252 5.19565 11.3252 5.61776 11.0649 5.87811L8.94292 8.00004L11.0649 10.122C11.3252 10.3823 11.3252 10.8044 11.0649 11.0648C10.8045 11.3251 10.3824 11.3251 10.122 11.0648L8.00011 8.94285L5.87818 11.0648C5.61783 11.3251 5.19572 11.3251 4.93537 11.0648C4.67502 10.8044 4.67502 10.3823 4.93537 10.122L7.0573 8.00004L4.93537 5.87811C4.67502 5.61776 4.67502 5.19565 4.93537 4.9353Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default CloseIcon;
