import { Box } from '@mui/material';
import React, { FC, MouseEvent } from 'react';
import { AddIcon, Button, NamesOfColors, Typography } from 'front-package-ui-kit';
import s from './styles.module.scss';

interface IProps {
  onButtonClick: (e: MouseEvent<HTMLButtonElement>) => void;
  textBtn: 'вес' | 'объём';
}

const FooterDashBoard: FC<IProps> = ({ onButtonClick, textBtn }) => {
  return (
    <Box className={s.btnAddContainer}>
      <Button onClick={onButtonClick} variant="outlined" theme="light" size="S" color="white20" fullWidth>
        <Typography variant="text6" fontWeight={500} color="white" marginRight="8px">
          {`Добавить ${textBtn}`}
        </Typography>

        <AddIcon color={NamesOfColors.white100} size={16} />
      </Button>
    </Box>
  );
};

export default FooterDashBoard;
