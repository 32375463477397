interface IBackUrls {
  base: string;
  video: string;
  analytics: string;
  app: string;
  notification: string;
  comments: string;
}

export let API_BASE_URL = '';
export let API_VIDEO_URL = '';
export let API_ANALYTICS_URL = '';
export let API_APP_URL = '';
export let API_NOTIFICATION_URL = '';
export let API_COMMENTS_URL = '';

export const setBackUrls = ({ base, video, analytics, app, notification, comments }: IBackUrls) => {
  API_BASE_URL = base;
  API_VIDEO_URL = video;
  API_ANALYTICS_URL = analytics;
  API_APP_URL = app;
  API_NOTIFICATION_URL = notification;
  API_COMMENTS_URL = comments;
};

export const USER_FOR_TESTS = { email: 'ron_zh@bk.ru', password: '123456' };
