import TabUnstyled from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import cn from 'classnames';
import { Skeleton, Typography } from 'front-package-ui-kit';
import { useEffect, useState } from 'react';

import s from './styles.module.scss';

export const TEST_ID = 'tab-buttons';

const skeletons = [1, 2];

interface IProps<T> {
  options: T[];
  onChange?: (tabValue: T) => void;
  defaultValue: T;
  variant?: 'contained' | 'transparent';
  loading?: boolean;
}

export const TabButtons = <T extends string | number>({
  options,
  onChange,
  defaultValue,
  loading,
  variant = 'transparent',
}: IProps<T>) => {
  const tabBtnContainerClasses = cn(s.tabButtons, { [s.tabButtonsContained]: variant === 'contained' });

  const [currentTab, setCurrentTab] = useState(defaultValue);

  const onTabChange = (tabValue: T) => () => {
    setCurrentTab(tabValue);
  };

  useEffect(() => {
    if (!onChange) return;

    onChange(currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <TabsListUnstyled data-testid={TEST_ID} className={tabBtnContainerClasses}>
      {!loading &&
        options.map((val) => (
          <TabUnstyled
            key={val}
            className={cn(s.tabBtn, { [s.tabBtnActive]: currentTab === val })}
            value={val}
            onChange={onTabChange(val)}
          >
            <Typography variant="text6" fontWeight={500} color="black" opacity={currentTab === val ? 1 : 0.6}>
              {val}
            </Typography>
          </TabUnstyled>
        ))}

      {loading &&
        skeletons.map((skeleton) => (
          <div key={skeleton} className={cn(s.tabBtn, s.skeletonContainer)}>
            <Skeleton width="56%" height="14px" borderRadius="4px" />
          </div>
        ))}
    </TabsListUnstyled>
  );
};
