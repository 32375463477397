import { useAnalytics, useAppDispatch, useAppSelector } from '.';
import { AnalyticsName, CourseOrWorkoutTypes, Environment, ErrorMessages, noticeCreator } from '../utils';
import { useLocation } from 'react-router-dom';
import { addFavoriteCourse, removeFavoriteCourse, restoreFavoritesCourses } from '../redux/courses/slice';
import { createServiceNotice } from '../redux/notifications/slice';
import { getTextError } from '../redux/utils';
import { addFavoritesWorkouts, removeFavoriteWorkout, restoreFavoritesWorkouts } from '../redux/workouts/slice';
import api from '../api';

const useOnFavoritesClickHandler = (
  defaultFvorite: boolean | undefined | null,
  id: number | undefined | null,
  type: CourseOrWorkoutTypes
) => {
  const {
    coursesStore: { favoritesCourses },
    workoutsStore: { favoritesWorkouts },
  } = useAppSelector((state) => ({
    coursesStore: state.coursesStore,
    workoutsStore: state.workoutsStore,
  }));

  const { cache: favoritesCoursesCache } = favoritesCourses;
  const { cache: favoritesWorkoutsCache } = favoritesWorkouts;

  const { pathname } = useLocation();

  const cache = {
    [CourseOrWorkoutTypes.course]: favoritesCoursesCache,
    [CourseOrWorkoutTypes.workout]: favoritesWorkoutsCache,
  };

  const isFavorite: boolean = cache[type][id ?? 0] ?? !!defaultFvorite;

  const dispatch = useAppDispatch();

  const { setAnalytics } = useAnalytics();

  const onFavoritesClickHandler = async () => {
    if (typeof id !== 'number' || typeof isFavorite !== 'boolean') return;

    try {
      if (isFavorite === true) {
        if (type === CourseOrWorkoutTypes.course) {
          dispatch(removeFavoriteCourse({ id }));
        }

        if (type === CourseOrWorkoutTypes.workout) {
          dispatch(removeFavoriteWorkout({ id }));
        }

        await api.removeFavorites({ id, type });
      }

      if (isFavorite === false) {
        if (type === CourseOrWorkoutTypes.course) {
          dispatch(addFavoriteCourse({ id }));
        }

        if (type === CourseOrWorkoutTypes.workout) {
          dispatch(addFavoritesWorkouts({ id }));
        }

        await api.addFavorites({ id, type });

        dispatch(
          createServiceNotice({
            notice: noticeCreator('Сохранено', 'success', 1000),
            otherInfo: { pathname: 'addFavorites', forEnvironment: Environment.production },
          })
        );
      }

      if (type === CourseOrWorkoutTypes.course) {
        setAnalytics(AnalyticsName.courseFavorites, { id, pathname, isFavorite: !isFavorite });
      }

      if (type === CourseOrWorkoutTypes.workout) {
        setAnalytics(AnalyticsName.workoutFavorites, { id, pathname, isFavorite: !isFavorite });
      }
    } catch (error) {
      if (type === CourseOrWorkoutTypes.course) {
        dispatch(restoreFavoritesCourses(favoritesCourses));
      }

      if (type === CourseOrWorkoutTypes.workout) {
        dispatch(restoreFavoritesWorkouts(favoritesWorkouts));
      }

      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError ?? ErrorMessages.defaultTextError, 'error'),
          otherInfo: { error, pathname: 'onFavoritesClickHandler', forEnvironment: Environment.production },
        })
      );
    }
  };

  return { onFavoritesClickHandler, isFavorite: isFavorite ?? false };
};

export default useOnFavoritesClickHandler;
