import { PayloadAction, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { deleteBankCardThunk, getCardListThunk, setMainBankCardThunk } from './thunks';
import { IBankCardWithDataInform } from '../../api/types';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';

interface IBankCards {
  bankCards: IStoreBase<IBankCardWithDataInform[] | null>;
  deleteBankCard: { error: string; statuse: Statuses };
  setMainBankCard: { error: string; statuse: Statuses };
}

const initialState: IBankCards = {
  bankCards: { data: null, error: '', statuse: Statuses.idle },
  deleteBankCard: { error: '', statuse: Statuses.idle },
  setMainBankCard: { error: '', statuse: Statuses.idle },
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCardListThunk.fulfilled, (state, { payload }: PayloadAction<IBankCardWithDataInform[]>) => {
        state.bankCards.error = '';
        state.bankCards.statuse = Statuses.succeeded;
        state.bankCards.data = payload;
      })
      .addCase(deleteBankCardThunk.fulfilled, (state, { payload }: PayloadAction<IBankCardWithDataInform[]>) => {
        state.deleteBankCard.error = '';
        state.deleteBankCard.statuse = Statuses.succeeded;
        state.bankCards.data = payload;
      })
      .addCase(setMainBankCardThunk.fulfilled, (state, { payload }: PayloadAction<IBankCardWithDataInform[]>) => {
        state.setMainBankCard.error = '';
        state.setMainBankCard.statuse = Statuses.succeeded;
        state.bankCards.data = payload;
      })
      //getCardListThunk
      .addMatcher(isPending(getCardListThunk), (state) => {
        state.bankCards.error = '';
        state.bankCards.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getCardListThunk), (state, { payload }) => {
        state.bankCards.statuse = Statuses.failed;
        state.bankCards.error = typeof payload === 'string' ? payload : '';
      })
      //deleteBankCardThunk
      .addMatcher(isPending(deleteBankCardThunk), (state) => {
        state.deleteBankCard.error = '';
        state.deleteBankCard.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(deleteBankCardThunk), (state, { payload }) => {
        state.deleteBankCard.statuse = Statuses.failed;
        state.deleteBankCard.error = typeof payload === 'string' ? payload : '';
      })
      //setMainBankCardThunk
      .addMatcher(isPending(setMainBankCardThunk), (state) => {
        state.setMainBankCard.error = '';
        state.setMainBankCard.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(setMainBankCardThunk), (state, { payload }) => {
        state.setMainBankCard.statuse = Statuses.failed;
        state.setMainBankCard.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const {} = orderSlice.actions;

export default orderSlice.reducer;
