import { parseISO } from 'date-fns';
import { getDateMonthNameAndDay } from '.';
import { IUserActivity, IWorkoutDetailse } from '../api/types';

type GroupedVideos = Record<string, IWorkoutDetailse[]>;
type GroupedActivities = Record<string, IUserActivity[]>;
type MergedGrouped = Record<string, { activities: IUserActivity[]; workouts: IWorkoutDetailse[] }>;

const months: Record<string, string> = {
  января: '01',
  февраля: '02',
  марта: '03',
  апреля: '04',
  мая: '05',
  июня: '06',
  июля: '07',
  августа: '08',
  сентября: '09',
  октября: '10',
  ноября: '11',
  декабря: '12',
};

const convertingDate = (dateString: string): Date => {
  const [day, month, year] = dateString.split(' ');
  const formattedDate = `${year ?? new Date().getFullYear()}-${months[month]}-${day.padStart(2, '0')}`;
  return new Date(formattedDate);
};

const sortDates = (dates: string[]) => {
  return dates.sort((a, b) => convertingDate(b).getTime() - convertingDate(a).getTime());
};

export const getGroupedVideoByDate = (videos: IWorkoutDetailse[] = []) => {
  return videos.reduce<GroupedVideos>((acc, video) => {
    if (!video.progress) return acc;

    const videoDate = parseISO(video.progress?.date);

    const convertDate = getDateMonthNameAndDay(videoDate);

    if (acc[convertDate]) {
      acc[convertDate] = [...acc[convertDate], video];
    } else {
      acc[convertDate] = [video];
    }
    return acc;
  }, {});
};

export const getGroupedActivityByDate = (activities: IUserActivity[] = []) => {
  return activities.reduce<GroupedActivities>((acc, activity) => {
    if (!activity.date) return acc;

    const videoDate = parseISO(activity.date);

    const convertDate = getDateMonthNameAndDay(videoDate);

    if (acc[convertDate]) {
      acc[convertDate] = [...acc[convertDate], activity];
    } else {
      acc[convertDate] = [activity];
    }
    return acc;
  }, {});
};

export const getGroupedVideoAndActivityByDate = (
  videos: IWorkoutDetailse[],
  activities: IUserActivity[]
): MergedGrouped => {
  const result: MergedGrouped = {};
  const groupedVideos = getGroupedVideoByDate(videos);
  const groupedActivities = getGroupedActivityByDate(activities);

  const uniqueDates = new Set([...Object.keys(groupedVideos), ...Object.keys(groupedActivities)]);

  const allDates = Array.from(uniqueDates);

  const sortedDates = sortDates(allDates);

  for (const date of sortedDates) {
    if (groupedVideos[date]) {
      result[date] = { activities: [], workouts: groupedVideos[date] || [] };
    } else {
      result[date] = { activities: [], workouts: [] };
    }
    if (groupedActivities[date]) {
      result[date] = { ...result[date], activities: groupedActivities[date] };
    }
  }
  return result;
};
