import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { createServiceNotice } from '../../../redux/notifications/slice';
import { getTextError } from '../../../redux/utils';
import { Environment, noticeCreator } from '../../../utils';
import { getStoriesToSetViewed } from './selectors';
import { RootState } from '../../../redux/store';

export const getAllStoriesThunk = createAsyncThunk(
  'stories/getAllStories',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const storiesData = (await api.getAllStories()).data;
      return storiesData;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'stories/getAllStories', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getStoryByIdThunk = createAsyncThunk(
  'stories/getStoryById',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const storyData = (await api.getStoryById(id)).data;
      return storyData.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'stories/getStoryById', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getStoryViewedThunk = createAsyncThunk(
  'stories/getStoryViewed',
  async (ids: number[], { rejectWithValue, dispatch, getState }) => {
    const idsArr: number[] = getStoriesToSetViewed(getState() as RootState);
    try {
      const storyData = (await api.getStoryViewed(idsArr)).data;
      return storyData.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'stories/getStoryViewed', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
