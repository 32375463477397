import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useSocials = () => {
  const {
    socialsStore: {
      socials: { data: socials, statuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ socialsStore: state.socialsStore, authStore: state.authStore }));

  const isSocials = Boolean(socials?.length);

  const isSocialsLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isSocials) || isLoading;

  return {
    socials,
    isSocials,
    isSocialsLoading,
  };
};
