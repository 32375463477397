import React, { FC, MouseEvent, memo } from 'react';
import DashBoardContainer, { IDashBoardBase } from '../DashBoardContainer';
import FooterDashBoard from '../FooterDashBoard';
import LineGraphic from '../../../LineGraphic';
import { Stack } from '@mui/material';
import ContentDashBoard from '../ContentDashBoard';
import { Typography } from 'front-package-ui-kit';
import HeaderDashboard from '../HeaderDashboard';
import s from './styles.module.scss';
import useDashBoardHelper from '../../hooks/useDashBoardHelper';
import { SingleParameterBadge } from '../SingleParameterBadge';
import { PARAMETERS_TYPES } from '../../../../../utils';

interface IProps extends IDashBoardBase {
  isHeader?: boolean;
}

const DashBoardWeight: FC<IProps> = ({
  parameters,
  onButtonClick,
  onButtonPlusClick,
  onAreaClick,
  isBtnAdd,
  isHeader = true,
  loading,
  size,
}) => {
  const handleButton = (e: MouseEvent<HTMLButtonElement>) => {
    if (onButtonClick) {
      e.stopPropagation();
      onButtonClick();
    }
  };

  const { isEmpty, isOneParameter, currentParamValue, valueProgress, uniqDates } = useDashBoardHelper(
    parameters,
    PARAMETERS_TYPES.weight.id
  );

  return (
    <DashBoardContainer onAreaClick={onAreaClick} loading={loading} size={size}>
      {isHeader && (
        <HeaderDashboard
          isEmpty={isEmpty}
          onButtonPlusClick={onButtonPlusClick}
          title={
            <Typography variant="text4" fontWeight={500} color="white">
              Вес
            </Typography>
          }
        />
      )}

      <ContentDashBoard
        isEmpty={isEmpty}
        unit="кг"
        rightTitle="вес"
        isOneParameter={isOneParameter}
        currentParamValue={currentParamValue}
        valueProgress={valueProgress}
      />

      {isOneParameter && <SingleParameterBadge value={currentParamValue} />}

      {!isEmpty && !isOneParameter && (
        <Stack className={s.graphicContainer}>
          <LineGraphic options={uniqDates} />
        </Stack>
      )}

      {(isEmpty || isBtnAdd) && handleButton && <FooterDashBoard onButtonClick={handleButton} textBtn="вес" />}
    </DashBoardContainer>
  );
};

export default memo(DashBoardWeight);
