import classNames from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Typography } from 'front-package-ui-kit';
import { FC, memo } from 'react';

import { IStepmeterProgress } from '../../model/types';
import { formatToLocaleString } from '../../utils/formatToLocaleString';

import s from './styles.module.scss';

export const TEST_ID = 'StepmeterChartTooltip';

export interface StepmeterChartTooltipProps {
  defaultTooltip: boolean;
  goal: number;
  todayProgress: IStepmeterProgress | null;
  selectedDay: IStepmeterProgress | null;
  transitionValue: number;
}

const StepmeterChartTooltip: FC<StepmeterChartTooltipProps> = ({
  defaultTooltip,
  goal,
  todayProgress,
  selectedDay,
  transitionValue,
}) => (
  <div className={s.stepmeterChartTooltip} data-testid={TEST_ID}>
    <div
      className={classNames(s.tooltip, { [s.translatedTooltip]: !defaultTooltip })}
      style={{
        transform: `translateX(${defaultTooltip ? 0 : transitionValue}%)`,
      }}
    >
      <Typography variant="text7" color="white" opacity={0.5} fontWeight={500}>
        {defaultTooltip
          ? 'Цель, шаги'
          : format(new Date(selectedDay !== null ? selectedDay.date : Date.now()), 'd MMMM yyyy', {
              locale: ru,
            })}
      </Typography>

      <div className={s.stepsGoal}>
        <Typography variant="h3" color="white" fontWeight={600}>
          {defaultTooltip
            ? formatToLocaleString(todayProgress?.steps ?? 0)
            : formatToLocaleString(selectedDay?.steps ?? 0)}
          &nbsp;
        </Typography>

        <Typography variant="h5" color="white" opacity={0.5} fontWeight={500}>
          {'/ ' + formatToLocaleString(goal)}
        </Typography>
      </div>
    </div>
  </div>
);

export const StepmeterChartTooltipMemo = memo(StepmeterChartTooltip);
