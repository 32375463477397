import { useMemo } from 'react';
import { useProducts } from '.';
import { declinationTextByProductUnit, getDeclinationText } from '../utils';

export const useProductWithDeclination = (id: number | undefined) => {
  const { products } = useProducts();

  const currentProduct = products?.find((product) => product.id === id);

  const currentProductDuration = useMemo(() => {
    return currentProduct?.duration ?? 0;
  }, [currentProduct]);

  const currentProductDurationType = useMemo(() => {
    return currentProduct?.duration_type ?? '';
  }, [currentProduct]);

  const declinationText = declinationTextByProductUnit[currentProductDurationType];

  const currentProductDurationText = useMemo(() => {
    return currentProduct
      ? `${currentProductDuration} ${getDeclinationText(currentProductDuration, declinationText)}`
      : '';
  }, [currentProduct, currentProductDuration, declinationText]);

  const currentProductPrice = currentProduct?.price;

  return { currentProductDurationText, currentProductPrice };
};
