import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector, useBottomSheet } from '../../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, DividerUI } from '../../../../components';
import { Button, Typography } from 'front-package-ui-kit';
import s from './styles.module.scss';
import { Box, Stack } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ARRAY_OF_MONTHS } from '../../../../utils';
import { IUserActivity, IWorkoutDetailse } from '../../../../api/types';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { setCurrentHistoryIntervalMonth, setCurrentHistoryIntervalYear } from '../../slice';
import { useUpdateEffect } from 'usehooks-ts';

export const TEST_ID = 'SetHistoryIntervalBottomSheet';

export const openSetHistoryIntervalBottomSheet = 'openSetHistoryIntervalBottomSheet';

export const ALL_TIME_VALUE = 'Все время';

type TMonth<T> = Record<string, T extends IWorkoutDetailse ? IWorkoutDetailse[] : IUserActivity[]>;

type TYear<T> = Record<string, TMonth<T>>;

export interface IntervalValue {
  workouts: IWorkoutDetailse[];
  activities: IUserActivity[];
}

export interface IHistoryIntervalValue {
  title: string;
  value: IntervalValue;
}

export interface IWorkoutsByYears<T extends IWorkoutDetailse | IUserActivity | typeof ALL_TIME_VALUE> {
  options: TYear<T>;
}

export interface IProps {
  workouts: IWorkoutDetailse[];
  activities: IUserActivity[];
  onChange: (value: IHistoryIntervalValue) => void;
}

const getWorkoutOptions = (workouts: IWorkoutDetailse[]) => {
  return workouts.reduce<TYear<IWorkoutDetailse>>((acc, workout) => {
    if (!workout.progress?.date) return acc;

    const videoDate = parseISO(workout.progress.date);

    const year = videoDate.getFullYear();

    const month = videoDate.getMonth();

    if (!acc[year]) {
      acc[year] = { [month]: [workout] };
    } else {
      if (!acc[year][month]) {
        acc[year] = { ...acc[year], [month]: [workout] };
      } else {
        acc[year] = { ...acc[year], [month]: [...acc[year][month], workout] };
      }
    }

    return acc;
  }, {});
};

const getActivityOptions = (activities: IUserActivity[]) => {
  return activities.reduce<TYear<IUserActivity>>((acc, activity) => {
    if (!activity.date) return acc;

    const videoDate = parseISO(activity.date);

    const year = videoDate.getFullYear();

    const month = videoDate.getMonth();

    if (!acc[year]) {
      acc[year] = { [month]: [activity] };
    } else {
      if (!acc[year][month]) {
        acc[year] = { ...acc[year], [month]: [activity] };
      } else {
        acc[year] = { ...acc[year], [month]: [...acc[year][month], activity] };
      }
    }

    return acc;
  }, {});
};

const getOptions = (activities: (IWorkoutDetailse | IUserActivity)[]) => {
  return activities.reduce<TYear<IWorkoutDetailse | IUserActivity>>((acc, activity) => {
    if ('progress' in activity) {
      if (!activity.progress?.date) return acc;

      const videoDate = parseISO(activity.progress.date);

      const year = videoDate.getFullYear();

      const month = videoDate.getMonth();

      if (!acc[year]) {
        acc[year] = { [month]: [activity] };
      } else {
        if (!acc[year][month]) {
          acc[year] = { ...acc[year], [month]: [activity] };
        } else {
          acc[year] = { ...acc[year], [month]: [...acc[year][month], activity] };
        }
      }
    } else if ('date' in activity) {
      if (!activity.date) return acc;

      const videoDate = parseISO(activity.date);

      const year = videoDate.getFullYear();

      const month = videoDate.getMonth();

      if (!acc[year]) {
        acc[year] = { [month]: [activity] };
      } else {
        if (!acc[year][month]) {
          acc[year] = { ...acc[year], [month]: [activity] };
        } else {
          acc[year] = { ...acc[year], [month]: [...acc[year][month], activity] };
        }
      }
    }

    return acc;
  }, {});
};

export const SetHistoryIntervalBottomSheet: FC<IProps> = memo(({ workouts, activities, onChange }) => {
  const { year, month } = useAppSelector((state) => ({
    month: state.profileStore.currentHistoryIntervalMonth,
    year: state.profileStore.currentHistoryIntervalYear,
  }));

  const dispatch = useAppDispatch();

  const workoutOptions = useMemo(() => {
    return getWorkoutOptions(workouts);
  }, [workouts]);

  const activityOptions = useMemo(() => {
    return getActivityOptions(activities);
  }, [activities]);

  const options = useMemo(() => {
    return getOptions([...workouts, ...activities]);
  }, [workouts, activities]);

  const yearsOptions = useMemo(() => {
    return [
      ALL_TIME_VALUE,
      ...Object.keys(options).sort((a, b) => {
        return +b - +a;
      }),
    ];
  }, [options]);

  const { getIsOpenBottomSheet, onCloseBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openSetHistoryIntervalBottomSheet);

  const handleSetYear = useCallback(
    (value: keyof IWorkoutsByYears<IWorkoutDetailse | IUserActivity | typeof ALL_TIME_VALUE>) => () => {
      if (year === value) {
        dispatch(setCurrentHistoryIntervalYear(ALL_TIME_VALUE));

        return;
      }
      dispatch(setCurrentHistoryIntervalYear(value));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [year]
  );

  const handleSetMonth = (value: number | null) => () => {
    if (month !== null && month === value) {
      dispatch(setCurrentHistoryIntervalMonth(null));
      return;
    }

    if (month !== value) {
      dispatch(setCurrentHistoryIntervalMonth(value));
    }
  };

  useUpdateEffect(() => {
    dispatch(setCurrentHistoryIntervalMonth(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  useEffect(() => {
    if (year === ALL_TIME_VALUE) {
      onChange({ value: { workouts, activities }, title: ALL_TIME_VALUE });

      return;
    }

    if (month === null || !options[year]?.[month]) return;

    const currentDate = new Date();

    const workoutseDate = new Date(+year, month);

    const isCurrentYear = currentDate.getFullYear() === workoutseDate.getFullYear();

    const workoutsMonth = format(workoutseDate, 'LLLL', { locale: ru });

    const workoutsMonthWithYear = format(workoutseDate, 'LLLL yyyy', { locale: ru });

    const title = isCurrentYear ? workoutsMonth : workoutsMonthWithYear;

    onChange({
      value: { activities: activityOptions?.[year]?.[month], workouts: workoutOptions?.[year]?.[month] },
      title,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, workouts, activities, options]);

  const countOfWorkouts = useMemo(() => {
    return year === ALL_TIME_VALUE
      ? workouts.length + activities.length
      : year !== ALL_TIME_VALUE && month !== null && options[year]?.[month]
      ? options[year]?.[month].length
      : null;
  }, [month, options, workouts.length, activities.length, year]);

  const isCountOfWorkouts = Boolean(countOfWorkouts);

  const handleCloseBottomSheet = useCallback(() => {
    onCloseBottomSheet();
  }, [onCloseBottomSheet]);

  return (
    <BottomSheetUI
      data-testid={TEST_ID}
      open={isOpen}
      touchLine
      expandOnContentDrag
      height="auto"
      onDismiss={handleCloseBottomSheet}
      header={
        <BottomSheetHeader
          alignItems="center"
          title={
            <Typography variant="text3" textAlign="center" fontWeight={700} marginBottom="4px">
              Выбрать месяц
            </Typography>
          }
          onClose={handleCloseBottomSheet}
        />
      }
      footer={
        <BottomButtonBlock direction="column" rowGap="8px">
          <Button
            size="L"
            onClick={handleCloseBottomSheet}
            color="black"
            variant="contained"
            type="submit"
            fullWidth
            disabled={!isCountOfWorkouts}
          >
            <Typography variant="text6" color="white" fontWeight={500}>
              Показать
            </Typography>

            {isCountOfWorkouts && (
              <Typography variant="text6" color="lime" fontWeight={500}>
                {countOfWorkouts}
              </Typography>
            )}
          </Button>

          <Button
            size="L"
            onClick={handleSetYear(ALL_TIME_VALUE as keyof IWorkoutsByYears<IWorkoutDetailse | IUserActivity>)}
            color="white"
            variant="contained"
            type="submit"
            fullWidth
          >
            <Typography variant="text6" color="black" fontWeight={500}>
              Сбросить
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <div className={s.setHistoryIntervalBottomSheet}>
        <Typography fontWeight={500} color="black" variant="text7" opacity={0.5} marginLeft="20px" marginBottom="8px">
          ГОД
        </Typography>

        <Box marginBottom="32px">
          <Swiper className={s.sliderContainer} slidesPerView="auto" spaceBetween={8} watchOverflow={true} freeMode>
            {yearsOptions.map((yearOption, index) => (
              <SwiperSlide key={`${yearOption}-${index}`}>
                <Button
                  type="button"
                  variant={year === yearOption ? 'contained' : 'outlined'}
                  onClick={handleSetYear(yearOption as keyof IWorkoutsByYears<IWorkoutDetailse | IUserActivity>)}
                  size="S"
                  color={year === yearOption ? 'lime' : 'grey20'}
                >
                  <Typography whiteSpace="nowrap" fontWeight={500} color="black" variant="text6">
                    {yearOption}
                  </Typography>
                </Button>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <DividerUI marginBottom="20px" color="grey" />

        <Typography fontWeight={500} color="black" variant="text7" opacity={0.5} marginLeft="20px" marginBottom="8px">
          МЕСЯЦ
        </Typography>

        <Stack className={s.monthsContainer} direction="row" flexWrap="wrap" paddingX="20px" rowGap="8px">
          {ARRAY_OF_MONTHS.map((m, i) => (
            <div className={s.monthsButtonContainer} key={`${m}-${i}`}>
              <Button
                disabled={!!!options?.[year]?.[i]}
                fullWidth
                type="button"
                variant={i === month ? 'contained' : 'outlined'}
                onClick={handleSetMonth(i)}
                size="S"
                color={i === month ? 'lime' : 'grey20'}
              >
                <Typography whiteSpace="nowrap" fontWeight={500} color="black" variant="text6">
                  {m}
                </Typography>
              </Button>
            </div>
          ))}
        </Stack>
      </div>
    </BottomSheetUI>
  );
});

SetHistoryIntervalBottomSheet.displayName = 'SetHistoryIntervalBottomSheet';
