import { Box, Stack } from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';
import s from './styles.module.scss';
import cn from 'classnames';
import { Typography } from 'front-package-ui-kit';

export const TEST_ID = 'FormTextarea';

interface IProps
  extends Omit<
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    'className'
  > {
  label?: string;
  marginBottom?: string;
  endAdornment?: ReactNode;
  error?: string;
}

const FormTextarea: FC<IProps> = ({
  error,
  endAdornment,
  label,
  marginBottom,
  maxLength,
  value,
  onFocus,
  onBlur,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const isError = Boolean(error);

  const classes = cn(s.textarea, { [s.errorText]: isError });

  const onFocusHandler = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    if (onFocus) onFocus(e);

    if (isFocused === false) setIsFocused(true);
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    if (onBlur) onBlur(e);

    if (isFocused === true) setIsFocused(false);
  };

  return (
    <Box marginBottom={marginBottom} className={s.formTextarea} data-testid={TEST_ID}>
      <label className={s.label}>
        <Typography variant="text7" opacity={0.3}>
          {label}
        </Typography>

        {Boolean(maxLength) && (
          <Typography variant="text7" opacity={0.3}>
            {`${value ? value.toString().length : 0}/${maxLength}`}
          </Typography>
        )}
      </label>

      <Stack
        direction="row"
        className={cn(s.inputContainer, { [s.inputContainerError]: isError, [s.focused]: isFocused })}
      >
        <textarea
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          className={classes}
          maxLength={maxLength}
          value={value}
          {...props}
        />

        {endAdornment && <div className={s.endAdornment}>{endAdornment}</div>}
      </Stack>

      {isError && (
        <Typography color="error" variant="text7">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default FormTextarea;
