import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentOption, IStepmeterState } from './types';
import { Statuses } from '../../../utils';
import { mockProgress } from '../mock';

export const initialState: IStepmeterState = {
  data: {
    isFirstVisit: false,
    isAccessGranted: true, // TODO для реальных данных будет false
    goal: 5000,
    progress: [...mockProgress.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())],
  },
  statuse: Statuses.succeeded,
  error: '',
  currentOption: CurrentOption.TIME,
};

const stepMeterSlice = createSlice({
  name: 'stepmeter',
  initialState,
  reducers: {
    toggleAccess(state) {
      state.data.isAccessGranted = !state.data.isAccessGranted;
    },
    setStepmeterCurrentOption(state, action: PayloadAction<CurrentOption>) {
      state.currentOption = action.payload;
    },
    setStepmeterGoal(state, action: PayloadAction<number>) {
      state.data.goal = action.payload;
    },
    editProgressDay(state, { payload }: PayloadAction<{ date: string; time: number; distance: number }>) {
      state.data.progress = state.data.progress.map((day) => {
        if (day.date === payload.date) {
          return {
            ...day,
            time: payload.time,
            distance: payload.distance,
          };
        }
        return day;
      });
    },
    removeProgressDay(state, action: PayloadAction<number>) {
      state.data.progress = state.data.progress.filter((day) => day.id !== action.payload);
    },
    resetAllProgress(state) {
      state.data.progress = [];
    },
  },
});

export const {
  setStepmeterGoal,
  setStepmeterCurrentOption,
  removeProgressDay,
  resetAllProgress,
  editProgressDay,
  toggleAccess,
} = stepMeterSlice.actions;

export default stepMeterSlice.reducer;
