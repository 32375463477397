import { Box } from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { CssPlatformContainer, NavBar } from '../../../../components';
import { emailValidationSchema, InputNames, MASK, phoneValidationSchema } from './config';
import InputMask from 'react-input-mask';
import s from './styles.module.scss';
import { getIsPhoneNumber } from '../../utils';
import cn from 'classnames';
import { usePlatform } from '../../../../hooks';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { FormInput, Typography } from 'front-package-ui-kit';
import ConfiguredCircleButtonLime from '../ConfiguredCircleButtonLime';
import { useEffectOnce } from 'usehooks-ts';

interface IProps {
  step: string;
  login: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onBackClick: () => void;
  loading: boolean;
}

const LoginInput: FC<IProps> = ({ step, onChange, onBackClick, onSubmit, login, loading }) => {
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);

  const isKeyboardOpen = useDetectKeyboardOpen();

  const { platform } = usePlatform();

  // const isNeedRestructuringContent = platform === 'android' && isKeyboardOpen;

  const isNeedRestructuringContent = false;

  const validationSchema = isPhoneNumber ? phoneValidationSchema : emailValidationSchema;

  const initialValues = {
    login: login ?? '',
  };

  const { errors, setErrors, values, setFieldValue, handleSubmit, getFieldProps } = useFormik({
    initialValues,
    validateOnChange: false,
    validationSchema,
    onSubmit: () => {
      onSubmit();
    },
  });

  const isError = Boolean(Object.entries(errors).length);

  const onChangeHandler = ({ target: { value: val } }: React.ChangeEvent<HTMLInputElement>) => {
    const isNumber = getIsPhoneNumber(val);

    setIsPhoneNumber(isNumber);

    let newValue: string;

    if (isNumber && val.includes('+7')) {
      newValue = val;

      setFieldValue(InputNames.login, newValue);

      return;
    } else if (isNumber && !val.includes('+7') && val.length === 10) {
      const [a, b, c, d, e, f, g, h, j, k] = val.split('');

      newValue = `+7 ${a}${b}${c} ${d}${e}${f} ${g}${h} ${j}${k}`;

      setFieldValue(InputNames.login, newValue);

      return;
    } else {
      newValue = val.replace('+7', '').replace(/ /g, '');

      setFieldValue(InputNames.login, newValue);
    }
  };

  const onFocusHandler = () => {
    setErrors({});
  };

  useEffect(() => {
    onChange(values.login);
  }, [onChange, values.login]);

  useEffectOnce(() => {
    const isInitialValuesNumber = getIsPhoneNumber(initialValues.login);

    setIsPhoneNumber(isInitialValuesNumber);
  });

  const disabled = isError || !Boolean(login.length);

  return (
    <div className={s.loginInput}>
      <CssPlatformContainer>
        <NavBar
          marginBottom="23px"
          justifyContent={isNeedRestructuringContent ? 'space-between' : 'flex-end'}
          onBackClick={onBackClick}
          goBackButton
          goBackButtonProps={{ white: true }}
        >
          {isNeedRestructuringContent && (
            <Typography color="white" variant="text4">
              Укажите номер телефона или e-mail
            </Typography>
          )}

          <Typography variant="text4" color="white">
            {step}
          </Typography>
        </NavBar>

        <div className={s.content}>
          {!isNeedRestructuringContent && (
            <Typography marginBottom="40px" fontWeight={700} variant="h2" color="white">
              Укажите номер телефона или e-mail
            </Typography>
          )}

          <form onSubmit={handleSubmit}>
            <InputMask
              mask={isPhoneNumber ? MASK : ''}
              maskPlaceholder={null}
              {...getFieldProps(InputNames.login)}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
            >
              <FormInput
                theme="dark"
                marginBottom="40px"
                type="text"
                inputMode="email"
                label="Номер телефона или e-mail"
                error={errors.login}
                autoFocus
                autoCapitalize="off"
              />
            </InputMask>

            <Box className={cn(s.btn, { [s.btnRestructuring]: isNeedRestructuringContent })}>
              <ConfiguredCircleButtonLime loading={loading} disabled={disabled} />
            </Box>
          </form>
        </div>
      </CssPlatformContainer>
    </div>
  );
};

export default LoginInput;
