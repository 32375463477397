import { Box, Stack } from '@mui/material';
import { Button, NamesOfColors, Typography } from 'front-package-ui-kit';
import React, { FC, memo } from 'react';
import { IButtonsFilterOptions } from '../../../hooks/useFilter';
import { useBottomSheet } from '../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, DividerUI, FilterMenuItem } from '../..';

export const TEST_ID = 'FiltersBottomSheet';

export const openFiltersBottomSheet = 'openFiltersBottomSheet';

export interface IFiltersBottomSheetProps {
  configuredButtonFilters: IButtonsFilterOptions[];
  onResetClick: () => void;
  numberOfCourses: number;
  numberOfFilters: number;
  theme?: 'dark' | 'light';
}

const FiltersBottomSheet: FC<IFiltersBottomSheetProps> = ({
  configuredButtonFilters,
  onResetClick,
  numberOfCourses,
  numberOfFilters,
  theme = 'light',
}) => {
  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isFiltersActive = Boolean(numberOfFilters);

  const isOpen = getIsOpenBottomSheet(openFiltersBottomSheet);

  return (
    <BottomSheetUI
      data-testid={TEST_ID}
      height="auto"
      touchLine
      onDismiss={() => onCloseBottomSheet()}
      open={isOpen}
      expandOnContentDrag
      theme={theme}
      header={
        <BottomSheetHeader
          alignItems="center"
          theme={theme}
          title={
            <Box width="100%">
              <Typography variant="h4" fontWeight={700} textAlign="center" color={theme === 'dark' ? 'white' : 'black'}>
                Фильтры
              </Typography>
            </Box>
          }
          onClose={onCloseBottomSheet}
          closeIconColor={theme === 'dark' ? NamesOfColors.white100 : NamesOfColors.grey50}
        />
      }
      footer={
        <BottomButtonBlock direction="column" gap="8px">
          <Button
            variant="contained"
            fullWidth
            onClick={() => onCloseBottomSheet()}
            size="L"
            color={theme === 'dark' ? 'white' : 'black'}
          >
            <Stack flexDirection="row" columnGap="6px">
              <Typography variant="text6" fontWeight={700} color={theme === 'dark' ? 'black' : 'white'}>
                Показать
              </Typography>

              {isFiltersActive && (
                <Typography variant="text6" fontWeight={700} color={theme === 'dark' ? 'black' : 'lime'}>
                  {numberOfCourses}
                </Typography>
              )}
            </Stack>
          </Button>

          <Button size="L" fullWidth variant="text" onClick={onResetClick}>
            <Typography variant="text6" fontWeight={700} color={theme === 'dark' ? 'white' : 'black'}>
              Сбросить
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <Box padding="0 20px">
        {configuredButtonFilters.map(({ onFilterButtonClickHandler, filterButtons, title, filterValues }, i) => (
          <Box key={title}>
            <FilterMenuItem
              theme={theme}
              title={title}
              filterValues={filterValues}
              onFilterButtonClickHandler={onFilterButtonClickHandler}
              options={filterButtons}
              padding=" 0 0 30px 0"
            />

            {configuredButtonFilters.length > i + 1 && (
              <DividerUI color={theme === 'dark' ? 'white' : 'grey'} marginBottom="16px" />
            )}
          </Box>
        ))}
      </Box>
    </BottomSheetUI>
  );
};

export const FiltersBottomSheetMemo = memo(FiltersBottomSheet);
