import React, { FC } from 'react';
import s from './styles.module.scss';
import { CssPlatformContainer, NavBar } from '../../..';
import { Box } from '@mui/material';
import darkScreen from '../../../../assets/image/darkScreen.jpg';

export const TEST_ID = 'Player';

export const openMeasureSizePlayer = 'openMeasureSizePlayer';

interface IProps {}

const Player: FC<IProps> = () => {
  return (
    <div className={s.player} data-testid={TEST_ID}>
      <CssPlatformContainer>
        <video className={s.video} playsInline muted autoPlay loop preload="auto" poster={darkScreen} controls>
          <source src={`https://app.kolsanovafit.ru/video/size.mp4`} type="video/mp4" />
          <source src={`https://app.kolsanovafit.ru/video/size.ogg`} type="video/ogg" />
          <source src={`https://app.kolsanovafit.ru/video/size.webm`} type="video/webm" />
        </video>

        <Box className={s.navBarContainer} position="absolute" top={0}>
          <NavBar goBackButton goBackButtonProps={{ white: true }} />
        </Box>
      </CssPlatformContainer>
    </div>
  );
};

export default Player;
