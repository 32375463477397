import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTextError } from '../utils';
import api from '../../api';
import { createServiceNotice } from '../notifications/slice';
import { Environment, RenderSchemesNames, noticeCreator } from '../../utils';
import { TRenderSchemes } from '../../api/types';

export const getRenderSchemesThunk = createAsyncThunk(
  'renderSchemes/getRenderSchemes',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = (await api.getRenderSchemes()).data.data;

      const renderSchemes = response.reduce<TRenderSchemes>(
        (acc, renderSchema) => {
          acc = { ...acc, ...renderSchema };

          return acc;
        },
        { [RenderSchemesNames.duration]: [], [RenderSchemesNames.schemeWorkout]: [] }
      );

      return renderSchemes;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'renderSchemes/getRenderSchemes', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
