import { PushNotificationsTypes } from '.';
import { INotificationStorage } from '../redux/notifications/slice';

export const readAllNotificationTypePushNative = (notifications: INotificationStorage[]) => {
  notifications.forEach((notification) => {
    if (notification.readed_at) return;

    if (notification.type === PushNotificationsTypes.push) window?.readPushNotice?.(notification.id);
  });
};
