import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  weight: yup.number().typeError('Введите число'),
});

export enum InputNames {
  weight = 'weight',
}

export default validationSchema;
