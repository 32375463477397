import React from 'react';

const DefaultCardIcon = () => {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7951 0H3.20486C2.09046 0 1.68635 0.116032 1.27894 0.333916C0.871535 0.5518 0.5518 0.871535 0.333916 1.27894C0.116032 1.68635 0 2.09046 0 3.20486V10.7951C0 11.9095 0.116032 12.3136 0.333916 12.7211C0.5518 13.1285 0.871535 13.4482 1.27894 13.6661C1.68635 13.884 2.09046 14 3.20486 14H16.7951C17.9095 14 18.3137 13.884 18.7211 13.6661C19.1285 13.4482 19.4482 13.1285 19.6661 12.7211C19.884 12.3136 20 11.9095 20 10.7951V3.20486C20 2.09046 19.884 1.68635 19.6661 1.27894C19.4482 0.871535 19.1285 0.5518 18.7211 0.333916C18.3137 0.116032 17.9095 0 16.7951 0ZM2.35599 2.04897C2.51125 2.01899 2.77844 2 3.20486 2H16.7951C17.2216 2 17.4887 2.01899 17.644 2.04897C17.7048 2.06072 17.7183 2.06567 17.7779 2.09754C17.8367 2.12903 17.871 2.16327 17.9025 2.22214C17.9343 2.28173 17.9393 2.29517 17.951 2.35599C17.981 2.51125 18 2.77844 18 3.20486V4H2V3.20486C2 2.77844 2.01899 2.51125 2.04897 2.35599C2.06072 2.29517 2.06567 2.28173 2.09754 2.22214C2.12903 2.16327 2.16327 2.12903 2.22214 2.09754C2.28173 2.06567 2.29517 2.06072 2.35599 2.04897ZM2 6H18V10.7951C18 11.2216 17.981 11.4887 17.951 11.644C17.9393 11.7048 17.9343 11.7183 17.9025 11.7779C17.871 11.8367 17.8367 11.871 17.7779 11.9025C17.7183 11.9343 17.7048 11.9393 17.644 11.951C17.4887 11.981 17.2216 12 16.7951 12H3.20486C2.77844 12 2.51125 11.981 2.35599 11.951C2.29517 11.9393 2.28173 11.9343 2.22214 11.9025C2.16327 11.871 2.12903 11.8367 2.09754 11.7779C2.06567 11.7183 2.06072 11.7048 2.04897 11.644C2.01899 11.4887 2 11.2216 2 10.7951V6ZM5 10C4.44772 10 4 9.55229 4 9C4 8.44771 4.44772 8 5 8H8C8.55229 8 9 8.44771 9 9C9 9.55229 8.55229 10 8 10H5Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default DefaultCardIcon;
