import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { CommentGroupType, ICreateCommentRequest, IEditCommentRequest } from '../../api/types';
import { Environment, noticeCreator } from '../../utils';
import { createServiceNotice } from '../notifications/slice';
import { getTextError } from '../utils';
import { getCommentSort } from './selectors';
import { RootState } from '../store';
import { resetComments } from './slice';

export const getCommentsByGroupId = createAsyncThunk(
  'comments/getCommentsByGroupId',
  async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { id, replace = true, page = 1 }: { id: number; replace?: boolean; page?: number },
    { rejectWithValue, dispatch, getState }
  ) => {
    const sort = getCommentSort(getState() as RootState);

    try {
      const res = (await api.getCommetsByGroupId(id, page, sort)).data;

      return res;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'comments/getCommentsByGroupId', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getCommentsGroupId = createAsyncThunk(
  'comments/getCommentsGroupId',
  async ({ type, id }: { type: CommentGroupType; id: number }, { rejectWithValue, dispatch }) => {
    dispatch(resetComments());

    try {
      const groupId = (await api.getCommentGroupId(type, id)).data.data.id;

      dispatch(getCommentsByGroupId({ id: groupId, replace: true }));

      return groupId;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'comments/getCommentsGroupId', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const createComment = createAsyncThunk(
  'comments/createComment',
  async ({ isRoot, ...config }: ICreateCommentRequest & { isRoot: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const comment = (await api.createComment(config)).data.data;

      return comment;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'comments/createComment', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const editComment = createAsyncThunk(
  'comments/editComment',
  async (config: IEditCommentRequest, { rejectWithValue, dispatch }) => {
    try {
      const res = await api.editComment(config);

      return res.data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'comments/editComment', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const fetchNextCommentsPage = createAsyncThunk(
  'comments/fetchNextCommentsPage',
  async ({ id, page }: { id: number; page: number }, { dispatch }) => {
    dispatch(getCommentsByGroupId({ id, replace: false, page }));
  }
);

export const likeComment = createAsyncThunk(
  'comments/likeComment',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await api.likeComment(id);

      return id;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'comments/likeComment', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const unlikeComment = createAsyncThunk(
  'comments/unlikeComment',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await api.unlikeComment(id);

      return id;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'comments/unlikeComment', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const deleteComment = createAsyncThunk(
  'comments/deleteComment',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ isRoot, id }: { isRoot: boolean; id: number }, { rejectWithValue, dispatch }) => {
    try {
      await api.deleteComment(id);
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'comments/deleteComment', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
