import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TImages } from '../../api/types';
import { Statuses } from '../../utils';

interface IImages {
  images: TImages;
}

const initialState: IImages = {
  images: {},
};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setImage(state, { payload: { name, imageUrl } }: PayloadAction<{ name: string; imageUrl: string }>) {
      state.images[name].data = imageUrl;
      state.images[name].error = '';
      state.images[name].statuse = Statuses.succeeded;
    },
    setImageStatus(
      state,
      {
        payload: { name, imageUrl, status, error },
      }: PayloadAction<{ name: string; imageUrl: string; status: Statuses; error: string }>
    ) {
      state.images[name] = { ...state.images[name], ...{ data: imageUrl, statuse: status, error } };
    },
  },
  extraReducers: {},
});

export const { setImage, setImageStatus } = imagesSlice.actions;

export default imagesSlice.reducer;
