import React from 'react';

export const NotFoundCourses = () => {
  return (
    <svg width="111" height="113" viewBox="0 0 111 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_bi_261_17204)">
        <path
          d="M50.7772 87.3374L88.2568 65.3387C89.3106 64.7202 89.6428 63.3277 88.9984 62.2299L84.9744 55.3742C84.5732 54.6907 84.0649 54.0979 83.4693 53.5842L76.4499 49.8233C75.2388 48.8075 74.4121 47.3991 74.1157 45.8465L73.7184 38.3379C73.3136 36.2363 72.5619 34.2269 71.4678 32.3628L57.4171 8.42452C53.9523 2.5215 46.5389 0.412104 40.8523 3.74985L20.0944 15.9338C14.4278 19.2599 12.6232 26.7519 16.1002 32.6757L30.1508 56.6139C31.2328 58.4573 32.6329 60.114 34.2705 61.492L40.6326 65.4995C41.8437 66.5152 42.6704 67.9237 42.9668 69.4763L42.8284 77.4385C42.9865 78.209 43.2563 78.9418 43.6574 79.6253L47.6815 86.4811C48.3258 87.5789 49.7035 87.9677 50.7573 87.3491L50.7772 87.3374Z"
          fill="url(#paint0_linear_261_17204)"
          fillOpacity="0.2"
        />
        <path
          d="M76.225 50.0914L76.2527 50.1147L76.2846 50.1318L83.2696 53.8742C83.826 54.3591 84.2988 54.9146 84.6726 55.5513L88.6966 62.4071C89.25 63.3499 88.9552 64.5229 88.0796 65.0368L50.6001 87.0356L50.5802 87.0473C49.7046 87.5612 48.5367 87.2467 47.9833 86.3039L43.9593 79.4482C43.5855 78.8114 43.331 78.1278 43.179 77.4056L43.3168 69.4823L43.3174 69.4462L43.3106 69.4106C42.9991 67.7789 42.1304 66.2989 40.8576 65.2313L40.8393 65.216L40.8192 65.2034L34.4775 61.2088C32.8782 59.8593 31.5104 58.2388 30.4526 56.4367L16.402 32.4985C13.0162 26.7301 14.7828 19.4573 20.2716 16.2356L41.0295 4.05169C46.5375 0.818769 53.7412 2.85324 57.1153 8.60169L71.1659 32.5399C72.2356 34.3624 72.9718 36.3262 73.3702 38.3806L73.7662 45.865L73.7674 45.8887L73.7719 45.9121C74.0835 47.5439 74.9521 49.0239 76.225 50.0914Z"
          stroke="url(#paint1_linear_261_17204)"
          strokeOpacity="0.5"
          strokeWidth="0.7"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.4216 89.3975L78.3828 85.3117L78.4034 85.2995C79.6519 84.5667 80.1733 83.0673 79.7212 81.7485L88.56 76.5605C92.1981 74.4252 93.4064 69.7441 91.2885 66.1358L89.0775 62.3689L47.7666 86.6165L49.9776 90.3834C52.1072 94.0116 56.7916 95.2071 60.4097 93.0834L67.6788 88.8169C68.6098 89.8545 70.1732 90.1303 71.4216 89.3975Z"
        fill="url(#paint2_linear_261_17204)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.8965 100.17L76.1873 90.8877L70.4948 100.143C69.7473 101.243 69.3105 102.571 69.3105 104.002C69.3105 107.799 72.3889 110.877 76.1862 110.877C79.9835 110.877 83.0618 107.799 83.0618 104.002C83.0618 102.583 82.6322 101.265 81.896 100.17H81.8965Z"
        fill="url(#paint3_linear_261_17204)"
      />
      <defs>
        <filter
          id="filter0_bi_261_17204"
          x="-14.4159"
          y="-26.5803"
          width="132.488"
          height="142.969"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="14.3725" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_261_17204" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_261_17204" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.79084" />
          <feGaussianBlur stdDeviation="9.58167" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_261_17204" />
        </filter>
        <linearGradient
          id="paint0_linear_261_17204"
          x1="141.914"
          y1="103.37"
          x2="47.6081"
          y2="134.924"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.129474" stopColor="#424242" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_261_17204"
          x1="53.9317"
          y1="81.3519"
          x2="52.6073"
          y2="9.43422"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.423397" stopColor="white" stopOpacity="0.576603" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_261_17204"
          x1="99.8623"
          y1="61.3265"
          x2="52.8554"
          y2="91.0229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_261_17204"
          x1="76.1861"
          y1="90.8876"
          x2="76.1861"
          y2="110.877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
      </defs>
    </svg>
  );
};
