import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { IChangeCourseProgress, ICourse } from '../../api/types';
import { changeCourseProgress } from './slice';
import { getTextError } from '../utils';
import { CourseTypes, Environment, getCourseStatuses, noticeCreator } from '../../utils';
import { createServiceNotice } from '../notifications/slice';

const upgradeCourse = (course: ICourse) => {
  const haveInventory = course.inventory && course.inventory !== 'без инвентаря' ? 'С инвентарем' : 'без инвентаря';
  return {
    ...course,
    haveInventory,
  };
};

//fix name multipleWorkouts
export const getCoursesThunk = createAsyncThunk(
  'courses/getCourses',
  async ({ trusted }: { trusted: boolean }, { rejectWithValue, dispatch }) => {
    try {
      // const courses = (await api.getCourses(CourseTypes.multiple)).data.data;

      const courses = (await api.getCoursesFast()).data.data;

      const coursesWithExtraOptions = courses.map(upgradeCourse);

      if (trusted === true) {
        return coursesWithExtraOptions;
      }

      console.warn('количество курсов ограничено переменной trusted');

      return coursesWithExtraOptions.filter((course) => {
        const { isAvailable } = getCourseStatuses(course);

        return isAvailable;
      });
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'courses/getCourses', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getSingleWorkoutsThunk = createAsyncThunk(
  'courses/getSingleWorkouts',
  async ({ trusted }: { trusted: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const courses = (await api.getCourses(CourseTypes.single)).data.data;

      const coursesWithExtraOptions = courses.map(upgradeCourse);

      if (trusted === true) {
        return coursesWithExtraOptions;
      }

      console.warn('количество курсов ограничено переменной trusted');

      return coursesWithExtraOptions.filter((course) => {
        const { isAvailable } = getCourseStatuses(course);

        return isAvailable;
      });
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'courses/getSingleWorkouts', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getComplexesThunk = createAsyncThunk(
  'courses/getComplexes',
  async ({ trusted }: { trusted: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const courses = (await api.getCourses(CourseTypes.complex)).data.data;

      const coursesWithExtraOptions = courses.map(upgradeCourse);

      if (trusted === true) {
        return coursesWithExtraOptions;
      }

      console.warn('количество курсов ограничено переменной trusted');

      return coursesWithExtraOptions.filter((course) => {
        const { isAvailable } = getCourseStatuses(course);

        return isAvailable;
      });
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'courses/getComplexes', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getRecommendedThunk = createAsyncThunk(
  'courses/getRecommended',
  async ({ trusted }: { trusted: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const courses = (await api.getRecommended()).data.data;

      if (trusted === true) {
        return courses;
      }

      console.warn('количество рекомендованных курсов ограничено переменной trusted');

      return courses.filter((course) => {
        const { isAvailable } = getCourseStatuses(course);

        return isAvailable;
      });
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'courses/getRecommended', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const changeCourseProgressThunk = createAsyncThunk(
  'courses/changeCourseProgress',
  async (config: IChangeCourseProgress, { rejectWithValue, dispatch }) => {
    try {
      const { id, progress } = (await api.changeCourseProgress(config)).data.data;

      dispatch(changeCourseProgress({ id, progress }));
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'courses/changeCourseProgress', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getFavoritesCoursesThunk = createAsyncThunk(
  'courses/getFavoritesCourses',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getFavoritesCourses()).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'courses/getFavoritesCourses', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getCourseDetailsThunk = createAsyncThunk(
  'courses/getCourseDetails',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getCourseDetails(id)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'courses/getCourseDetails', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
