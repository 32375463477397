import { useAppSelector } from '.';
import { RenderSchemesNames } from '../utils';

export const useRenderSchemes = <T extends RenderSchemesNames>(name: T) => {
  const { renderSchemes } = useAppSelector((state) => state.renderSchemesStore);

  return {
    renderScheme: renderSchemes[name],
  };
};
