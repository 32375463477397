import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';

export const getStories = (state: RootState) => state.storyStore.stories.data;
export const getStoriesStatus = (state: RootState) => state.storyStore.stories.statuse;
export const getStoriesError = (state: RootState) => state.storyStore.stories.error;
export const getUnwatchedStoriesIds = (state: RootState) => state.storyStore.unwatchedStoriesIds;
export const getStoriesToSetViewed = (state: RootState) => state.storyStore.storiesToSetViewed;
export const getStartedViewedStoryId = (state: RootState) => state.storyStore.startedViewedStoryId;
export const getCurrentStoryId = (state: RootState) => state.storyStore.currentStoryId;
export const getCurrentStory = createSelector([getCurrentStoryId, getStories], (storyId, stories) =>
  storyId ? stories.find((story) => story.id === storyId) || null : null
);
export const getStoryById = (id: number) =>
  createSelector([getStories], (stories) => stories.find((story) => story.id === id) || null);

export const getImages = (state: RootState) => state.imageStore.images;
export const getImageByName = (name: string) =>
  createSelector([getImages], (images) => (images[name] ? images[name] : null));
