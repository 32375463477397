import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import api from '../api';
import { ValuesOfConstants } from '../utils/constants/config';
import { TypesConstants } from '../models';

const useParseWebView = (key: ValuesOfConstants[]) => {
  const [elementsConstant, setElementConstant] = useState<TypesConstants>([]);

  useEffect(() => {
    const getConstantsApi = async () => {
      const constants = (await api.getConstants(key)).data.data;

      const constantByKey = constants.map(({ value }) => parse(value, { trim: true }));

      if (Array.isArray(constantByKey)) setElementConstant(constantByKey);
      else if (!Array.isArray(constantByKey)) setElementConstant([constantByKey]);
    };

    getConstantsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return elementsConstant;
};

export default useParseWebView;
