import { useNavigate } from 'react-router-dom';
import { IWorkout } from '../api/types';
import { Environment, ErrorMessages, Paths, noticeCreator } from '../utils';
import { useAppDispatch } from './redux';
import { createServiceNotice } from '../redux/notifications/slice';
import { useState } from 'react';
import api from '../api';
import { getTextError } from '../redux/utils';

const { video } = Paths;

const useOnPlayClickHandler = (workout: IWorkout | undefined | null) => {
  const [onPlayLoading, setOnPlayLoading] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onPlayClickHandler = async () => {
    if (!workout) return;

    setOnPlayLoading(true);

    const { source_id: sourceId, source_type: sourceType, type, id: videoId } = workout;

    let ratingMe;

    try {
      const updatedWorkout = (await api.getWorkoutDetails(String(videoId))).data.data;

      ratingMe = Boolean(updatedWorkout.rating_me);
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'useOnPlayClickHandler', forEnvironment: Environment.development },
        })
      );
    }

    let resultOfOpenPlayer;

    try {
      resultOfOpenPlayer = window?.openPlayer?.({
        videoId,
        sourceId,
        videoType: type || 'workout',
        sourceType,
        ratingMe: Boolean(ratingMe),
      });
    } catch (error) {
      dispatch(
        createServiceNotice({
          notice: noticeCreator(ErrorMessages.openPlayer, 'error'),
          otherInfo: { error, pathname: 'openPlayer', forEnvironment: Environment.development },
        })
      );
    }

    setOnPlayLoading(false);

    if (resultOfOpenPlayer) return;

    navigate(`${video}/${videoId}/${type}`);
  };

  return { onPlayClickHandler, onPlayLoading };
};

export default useOnPlayClickHandler;
