import { PayloadAction, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { CommentSortType, IComment, ICommentImage } from '../../api/types';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';
import {
  createComment,
  deleteComment,
  editComment,
  getCommentsByGroupId,
  getCommentsGroupId,
  likeComment,
  unlikeComment,
} from './thunks';

interface ICommentForm {
  id?: number;
  title: string;
  text: string;
  images: ICommentImage[];
  parent_id?: number | null;
  group_id?: number | null;
}
interface ICommentsState {
  comments: IStoreBase<IComment[]>;
  sortType: CommentSortType;
  groupId: number | null;
  currentPage: number;
  lastPage: number;
  total: number | null;
  selectedComment: IComment | null;
  isEdit: boolean;
  commentForm: ICommentForm;
}

const initialState: ICommentsState = {
  comments: {
    data: [],
    error: '',
    statuse: Statuses.idle,
  },
  sortType: CommentSortType.DATE_DESC,
  groupId: null,
  currentPage: 1,
  lastPage: 1,
  total: null,
  selectedComment: null,
  isEdit: false,
  commentForm: {
    id: undefined,
    title: '',
    text: '',
    images: [],
    parent_id: null,
    group_id: undefined,
  },
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setSort: (state, { payload }: PayloadAction<CommentSortType>) => {
      state.sortType = payload;
    },
    setSelectedComment: (state, { payload }: PayloadAction<IComment>) => {
      state.selectedComment = payload;
    },
    resetSelectedComment: (state) => {
      state.selectedComment = null;
    },
    setCommentForm: (state, { payload }: PayloadAction<ICommentForm>) => {
      state.commentForm = { ...state.commentForm, ...payload, images: [...payload.images] };
    },
    setIsEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isEdit = payload;
    },
    resetCommentForm: (state) => {
      state.commentForm = {
        id: undefined,
        title: '',
        text: '',
        images: [],
        parent_id: null,
        group_id: undefined,
      };
    },
    resetComments: (state) => {
      state.total = null;
      state.comments.data = [];
    },
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommentsGroupId.fulfilled, (state, { payload }) => {
        state.comments.error = '';
        state.comments.statuse = Statuses.succeeded;
        state.groupId = payload;
      })

      .addCase(getCommentsByGroupId.fulfilled, (state, { payload, meta }) => {
        state.comments.error = '';

        const res = payload;

        if (meta.arg.replace) {
          state.comments.data = [...res.data];
        } else {
          state.comments.data = [...state.comments.data, ...res.data];
        }

        state.currentPage = res.meta.current_page;
        state.lastPage = res.meta.last_page;
        state.total = res.meta.total;
        state.comments.statuse = Statuses.succeeded;
      })

      .addCase(createComment.fulfilled, (state, { payload, meta }) => {
        state.comments.error = '';
        const { isRoot } = meta.arg;
        if (isRoot) {
          state.comments.data = [payload, ...state.comments.data];
        } else {
          state.comments.data = state.comments.data.map((elem) => {
            if (elem.id === payload.parent_id) {
              return {
                ...elem,
                replies: [...elem.replies, payload],
              };
            } else if (elem.replies.some((reply) => reply.id === payload.parent_id)) {
              return {
                ...elem,
                replies: [...elem.replies, payload],
              };
            } else {
              return elem;
            }
          });
        }
        state.total = state.total !== null ? state.total + 1 : 1;
        state.comments.statuse = Statuses.succeeded;
      })

      .addCase(editComment.fulfilled, (state, { payload }) => {
        state.comments.error = '';
        state.comments.statuse = Statuses.succeeded;
        if (payload.parent_id === null) {
          state.comments.data = state.comments.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                ...elem,
                text: payload.text,
                images: payload.images,
                status: payload.status,
                baned: payload.baned,
                replies: payload.replies,
              };
            }
            return elem;
          });
        } else {
          state.comments.data = state.comments.data.map((elem) => {
            if (elem.replies.length > 0) {
              elem.replies = elem.replies.map((reply) => {
                if (reply.id === payload.id) {
                  return {
                    ...reply,
                    text: payload.text,
                    images: payload.images,
                    status: payload.status,
                    baned: payload.baned,
                    replies: payload.replies,
                  };
                }
                return reply;
              });
            }
            return elem;
          });
        }
      })

      .addCase(deleteComment.fulfilled, (state, action) => {
        state.comments.error = '';
        const { id, isRoot } = action.meta.arg;

        let revomedCommentsCount = 1;

        if (isRoot) {
          const removedComment = state.comments.data.find((elem) => elem.id === id);

          state.comments.data = state.comments.data.filter((elem) => elem.id !== id);

          revomedCommentsCount = removedComment
            ? removedComment.replies.length + revomedCommentsCount
            : revomedCommentsCount;
        } else {
          state.comments.data.forEach((elem) => {
            if (elem.replies.length > 0) {
              if (elem.replies.some((reply) => reply.id === id)) {
                elem.replies = elem.replies.filter((item) => item.id !== id);

                return;
              }
            }
          });
        }
        state.total = state.total !== null ? state.total - revomedCommentsCount : null;
        state.comments.statuse = Statuses.succeeded;
      })

      .addCase(likeComment.fulfilled, (state, { payload }) => {
        state.comments.error = '';
        state.comments.statuse = Statuses.succeeded;
        state.comments.data = state.comments.data.map((elem, i) => {
          if (elem.id === payload) {
            return {
              ...elem,
              like_count: elem.like_count + 1,
              liked_me: true,
            };
          } else if (elem.id !== payload && elem.replies.length > 0) {
            state.comments.data[i].replies = elem.replies.map((reply) => {
              if (reply.id === payload) {
                return {
                  ...reply,
                  like_count: reply.like_count + 1,
                  liked_me: true,
                };
              }
              return reply;
            });
          }
          return elem;
        });
      })

      .addCase(unlikeComment.fulfilled, (state, { payload }) => {
        state.comments.error = '';
        state.comments.statuse = Statuses.succeeded;
        state.comments.data = state.comments.data.map((elem) => {
          if (elem.id === payload) {
            return {
              ...elem,
              like_count: elem.like_count - 1,
              liked_me: false,
            };
          } else if (elem.id !== payload && elem.replies.length > 0) {
            elem.replies = elem.replies.map((reply) => {
              if (reply.id === payload) {
                return {
                  ...reply,
                  like_count: reply.like_count - 1,
                  liked_me: false,
                };
              }
              return reply;
            });
          }
          return elem;
        });
      })

      // getCommentsGroupId
      .addMatcher(isPending(getCommentsGroupId), (state) => {
        state.comments.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getCommentsGroupId), (state, { payload }) => {
        state.comments.statuse = Statuses.failed;
        state.comments.error = typeof payload === 'string' ? payload : '';
      })

      // getCommentsByGroupId
      .addMatcher(isPending(getCommentsByGroupId), (state) => {
        state.comments.statuse = Statuses.loading;
        state.total = null;
      })
      .addMatcher(isRejectedWithValue(getCommentsByGroupId), (state, { payload }) => {
        state.comments.statuse = Statuses.failed;
        state.comments.error = typeof payload === 'string' ? payload : '';
      })

      // deleteComment
      .addMatcher(isPending(deleteComment), (state) => {
        state.comments.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(deleteComment), (state, { payload }) => {
        state.comments.statuse = Statuses.failed;
        state.comments.error = typeof payload === 'string' ? payload : '';
      })

      // likeComment
      // .addMatcher(isPending(likeComment), (state) => {
      //   state.comments.statuse = Statuses.loading;
      // })
      .addMatcher(isRejectedWithValue(likeComment), (state, { payload }) => {
        state.comments.statuse = Statuses.failed;
        state.comments.error = typeof payload === 'string' ? payload : '';
      })

      // unlikeComment
      // .addMatcher(isPending(unlikeComment), (state) => {
      //   state.comments.statuse = Statuses.loading;
      // })
      .addMatcher(isRejectedWithValue(unlikeComment), (state, { payload }) => {
        state.comments.statuse = Statuses.failed;
        state.comments.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const {
  setSort,
  resetStore,
  resetSelectedComment,
  setSelectedComment,
  setCommentForm,
  resetCommentForm,
  setIsEdit,
  resetComments,
} = commentsSlice.actions;
export default commentsSlice.reducer;
