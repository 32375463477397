import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import React, {
  ComponentPropsWithoutRef,
  JSXElementConstructor,
  ReactElement,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import s from './styles.module.scss';

export const TEST_ID = 'DropDownMenu';

interface IProps extends ComponentPropsWithoutRef<'div'> {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const DropDownMenu = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // модальное окно , для предотвращения клика в область вне дропдауна

  const { open, setOpen, children } = props;

  const refDropDown = useRef<HTMLDivElement | null>(null);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (typeof ref !== 'function' && ref?.current && ref.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (typeof ref !== 'function' && ref?.current && prevOpen.current === true && open === false) {
      ref.current.focus();
    }

    if (open) setIsModalOpen(true);

    prevOpen.current = open;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {isModalOpen && (
        <div
          className={s.modalContent}
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(false);
            setOpen(false);
          }}
        ></div>
      )}

      <Popper
        className={s.poper}
        onClick={(e) => {
          e.stopPropagation();
          setIsModalOpen(false);
        }}
        open={open}
        anchorEl={ref && 'current' in ref ? ref.current : refDropDown.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        data-testid={TEST_ID}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper className={s.paper}>
              <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
});

DropDownMenu.displayName = 'DropDownMenu';

export default DropDownMenu;
