import { Box } from '@mui/material';
import { Button, Typography } from 'front-package-ui-kit';
import { FC, FormEvent, memo } from 'react';
import { useLocation } from 'react-router-dom';

import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, SwiperPicker } from '../../../../components';
import { useAnalytics, useBottomSheet, useLoadingBottomSheet, useSwiperPickerHelper } from '../../../../hooks';
import { Action, AnalyticsName, stepsValues } from '../../../../utils';

export const TEST_ID = 'StepmeterSetGoalBottomsheet';

export const openStepmeterSetGoalBottomsheet = 'openStepmeterSetGoalBottomsheet';

export interface StepmeterSetGoalBottomsheetProps {
  title: string;
  goal: number;
  handleChangeGoal: (value: number) => void;
}

const StepmeterSetGoalBottomsheet: FC<StepmeterSetGoalBottomsheetProps> = ({ title, goal, handleChangeGoal }) => {
  const { pathname } = useLocation();

  const { stepsPicker, handleSetSteps } = useSwiperPickerHelper();

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const { setAnalytics } = useAnalytics();

  const { isLoading, setLoadingHandler } = useLoadingBottomSheet();

  const isOpen = getIsOpenBottomSheet(openStepmeterSetGoalBottomsheet);

  const handleCloseBottomSheet = () => onCloseBottomSheet();

  const onChangeGoalHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setAnalytics(AnalyticsName.current_target_distance, {
      action: Action.edit,
      pathname,
      target: Number(stepsPicker.step),
    });

    handleChangeGoal(Number(stepsPicker.step));
    handleCloseBottomSheet();
  };

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onSpring={setLoadingHandler}
      onDismiss={handleCloseBottomSheet}
      header={
        <BottomSheetHeader
          alignItems="center"
          title={
            title && (
              <Box width="100%">
                <Typography variant="text3" textAlign="center" fontWeight={700}>
                  {title}
                </Typography>
              </Box>
            )
          }
          onClose={handleCloseBottomSheet}
        />
      }
      data-testid={TEST_ID}
    >
      <form onSubmit={onChangeGoalHandler}>
        <SwiperPicker
          loading={isLoading}
          onChange={handleSetSteps}
          middlePicker={{
            options: stepsValues,
            defaultValue: goal,
            unit: 'шагов',
          }}
          leftPicker={{ options: [], defaultValue: '' }}
          rightPicker={{ options: [], defaultValue: '' }}
        />

        <BottomButtonBlock direction="column" rowGap="8px">
          <Button disabled={isLoading} size="L" color="black" variant="contained" type="submit" fullWidth>
            <Typography variant="text6" color="white" fontWeight={700}>
              Сохранить
            </Typography>
          </Button>

          <Button size="L" onClick={handleCloseBottomSheet} type="button" variant="text" fullWidth>
            <Typography variant="text6" fontWeight={700} color="black">
              Отменить
            </Typography>
          </Button>
        </BottomButtonBlock>
      </form>
    </BottomSheetUI>
  );
};

export const StepmeterSetGoalBottomsheetMemo = memo(StepmeterSetGoalBottomsheet);
