import { useEffectOnce } from 'usehooks-ts';
import api from '../api';
import { setAgreement } from '../redux/user/slice';
import { postUserAgreementsThunk } from '../redux/userAgreements/thunks';
import { getTextError } from '../redux/utils';
import { ValuesOfConstants } from '../utils/constants/config';
import { useAppDispatch, useAppSelector } from './redux';

const useAgreements = () => {
  const {
    userStore: { isAgreement },
    userAgreementsStore: { userAgreements },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    (async () => {
      if (isAgreement === null) {
        try {
          const listClientAgreements = (await api.getClientAgreement()).data.data;

          const foundedClientAgreement = listClientAgreements.find(
            (elem) =>
              elem.document.includes(ValuesOfConstants.privacyPolicy) &&
              elem.document.includes(ValuesOfConstants.userAgreement)
          );

          dispatch(setAgreement(Boolean(foundedClientAgreement)));

          if (Boolean(foundedClientAgreement) === false) {
            if (userAgreements.length > 0) dispatch(postUserAgreementsThunk(userAgreements));
          }
        } catch (error) {
          const textError = getTextError(error);

          console.error(textError);
        }
      }
    })();
  });
};

export default useAgreements;
