import { useAppSelector } from '.';
import { NotificationParamsNames, Statuses } from '../utils';

export const useNotifications = () => {
  const {
    notificationsStore: {
      notifications: { data: notifications, statuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ notificationsStore: state.notificationsStore, authStore: state.authStore }));

  const isNotifications = Boolean(notifications?.length);

  const isNotificationsLoading =
    ((statuse === Statuses.idle || statuse === Statuses.loading) && !isNotifications) || isLoading;

  const unreadNotifications = notifications?.filter((notice) => !Boolean(notice.readed_at)) ?? [];

  const countOfUnreadNotifications = unreadNotifications.length;

  const isHaveUnreadNotifications = !!countOfUnreadNotifications;

  const titleCountOfUnreadNotifications = countOfUnreadNotifications > 100 ? '99+' : `${countOfUnreadNotifications}`;

  const unreadSupportNotifications = unreadNotifications.filter(
    (notice) => notice.params[NotificationParamsNames.type] === 'support'
  );

  const countOfUnreadSupportNotifications = unreadSupportNotifications.length;

  const isHaveUnreadSupportNotifications = !!countOfUnreadSupportNotifications;

  const titleCountOfUnreadSupportNotifications =
    countOfUnreadSupportNotifications > 100 ? '99+' : `${countOfUnreadSupportNotifications}`;

  return {
    notifications,
    isNotifications,
    isNotificationsLoading,
    isHaveUnreadNotifications,
    titleCountOfUnreadNotifications,

    unreadSupportNotifications,
    countOfUnreadSupportNotifications,
    isHaveUnreadSupportNotifications,
    titleCountOfUnreadSupportNotifications,
  };
};
