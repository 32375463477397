import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { CssPlatformContainer, NavBar } from '../../../../components';
import validationSchema, { initialValues, InputNames } from './config';
import s from './styles.module.scss';
import cn from 'classnames';
import { usePlatform } from '../../../../hooks';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { FormInput, Typography } from 'front-package-ui-kit';
import ConfiguredCircleButtonLime from '../ConfiguredCircleButtonLime';

interface IProps {
  step: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onBackClick: () => void;
  loading: boolean;
}

const SetRecoveryPassword: FC<IProps> = ({ step, onChange, onBackClick, onSubmit, loading }) => {
  const isKeyboardOpen = useDetectKeyboardOpen();

  const { platform } = usePlatform();

  // const isNeedRestructuringContent = platform === 'android' && isKeyboardOpen;

  const isNeedRestructuringContent = false;

  const { getFieldProps, errors, values, handleSubmit, setErrors } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      onSubmit();
    },
  });

  const isError = Boolean(Object.entries(errors).length);

  const onFocusHandler = () => {
    setErrors({});
  };

  useEffect(() => {
    onChange(values.password);
  }, [onChange, values.password]);

  const disabled = isError || !Boolean(values.password.length) || !Boolean(values.confirmPassword.length);

  return (
    <div className={s.setRecoveryPassword}>
      <CssPlatformContainer>
        <NavBar
          marginBottom="24px"
          justifyContent={isNeedRestructuringContent ? 'space-between' : 'flex-end'}
          onBackClick={onBackClick}
          goBackButton
          goBackButtonProps={{ white: true }}
        >
          {isNeedRestructuringContent && (
            <Typography color="white" variant="text4">
              восстановление пароля
            </Typography>
          )}

          <Typography color="white" variant="text4">
            {step}
          </Typography>
        </NavBar>

        <div className={s.content}>
          {!isNeedRestructuringContent && (
            <Typography marginBottom="40px" fontWeight={700} variant="h2" color="white">
              Восстановление <br /> пароля
            </Typography>
          )}

          <form onSubmit={handleSubmit}>
            <FormInput
              theme="dark"
              {...getFieldProps(InputNames.password)}
              onFocus={onFocusHandler}
              error={errors.password}
              label="Придумайте новый пароль"
              type="password"
              tabIndex={1}
              autoFocus
              marginBottom="32px"
              autoComplete="new-password"
            />

            <FormInput
              theme="dark"
              {...getFieldProps(InputNames.confirmPassword)}
              onFocus={onFocusHandler}
              error={errors.confirmPassword}
              label="Повторите пароль"
              type="password"
              tabIndex={2}
              marginBottom="40px"
              autoComplete="new-password"
            />

            <Stack
              className={cn(s.btn, { [s.btnRestructuring]: isNeedRestructuringContent })}
              direction="row"
              justifyContent="flex-end"
            >
              <ConfiguredCircleButtonLime loading={loading} disabled={disabled} />
            </Stack>
          </form>
        </div>
      </CssPlatformContainer>
    </div>
  );
};

export default SetRecoveryPassword;
