import { getParametersByID } from '../utils';
import { TParametersTypesIDs } from '../utils/constants/config';
import { IParameters } from '../api/types';
import { useMemo } from 'react';
import { useParameters } from '.';

const useLastParameters = (parameterNames: TParametersTypesIDs) => {
  const { parameters } = useParameters();

  const lastParameters: (IParameters | undefined)[] = useMemo(() => {
    return parameterNames.map((parameterID) => {
      const [lastParameter] = parameters ? getParametersByID(parameters, parameterID) : [];

      return lastParameter;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters?.length]);

  return lastParameters;
};

export default useLastParameters;
