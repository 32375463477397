import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyStepmeter = lazy(() => import('./Stepmeter'));

export const Stepmeter = () => (
  <Suspense fallback={<Loading />}>
    <LazyStepmeter />
  </Suspense>
);
