import { parseISO, startOfWeek, differenceInDays, format } from 'date-fns';
import { IStepmeterProgress } from '../model/types';
/**
 * Разбивает массив данных на массивы недельных интервалов.
 * Каждая неделя представлена массивом из 7 элементов, начиная с понедельника и заканчивая воскресеньем.
 * Если в данные попадает неделя, которая начинается не с понедельника, пропущенные дни заполняются `null`.
 * Аналогично, если неделя неполная, оставшиеся дни также заполняются `null`.
 *
 * @param {IStepmeterProgress[]} data - Массив объектов с данными шагомера, содержащими дату и связанные с ней метрики.
 * @returns {(IStepmeterProgress | null)[][]} - Массив недель, где каждая неделя представлена массивом из 7 элементов (`null` для пропущенных дней).
 *
 * @example
 * const data = [
 *   { date: '2024-08-12', id: 12, steps: 4500, calories: 795, distance: 5.7, time: 70 },
 *   { date: '2024-08-11', id: 11, steps: 2890, calories: 1191, distance: 6.2, time: 82 },
 *   // ...
 * ];
 *
 * const weeks = splitByWeeks(data);
 * // weeks = [
 * //   [null, null, null, null, null, { date: '2024-08-11', ... }, { date: '2024-08-12', ... }],
 * //   ...
 * // ]
 */
export function splitByWeeks(data: IStepmeterProgress[]): (IStepmeterProgress | null)[][] {
  const weeksMap = new Map<string, (IStepmeterProgress | null)[]>();

  data.forEach((item) => {
    const date = parseISO(item.date);

    // Определяем начало и конец недели
    const weekStart = startOfWeek(date, { weekStartsOn: 1 });

    // Форматируем начало недели как ключ
    const weekKey = format(weekStart, 'yyyy-MM-dd');

    // Определяем день недели (0 - понедельник, 6 - воскресенье)
    const dayIndex = differenceInDays(date, weekStart);

    // Если эта неделя уже есть в Map, получаем её, иначе создаем новую с 7 null
    let currentWeek = weeksMap.get(weekKey);
    if (!currentWeek) {
      currentWeek = Array(7).fill(null);
      weeksMap.set(weekKey, currentWeek);
    }

    // Заменяем null на текущее значение в соответствующем дне недели
    currentWeek[dayIndex] = item;
  });

  // Преобразуем Map в массив
  const weeksArray = Array.from(weeksMap.values());

  return weeksArray;
}
