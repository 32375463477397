import React, { FC, memo, useCallback } from 'react';
import s from './styles.module.scss';
import { CheckCircleIcon, CircleOutlineBlankIcon, NamesOfColors, Skeleton, Typography } from 'front-package-ui-kit';
import { Box, Stack } from '@mui/material';
import { setCurrentProductForPayment } from '../../../redux/payment/slice';
import BadgeIcon from './BadgeIcon';
import { IProduct } from '../../../api/types';
import { useAppDispatch, useAppSelector, useProducts } from '../../../hooks';
import cn from 'classnames';

export const TEST_ID = 'ToggleProduct';

export interface ToggleProductProps {
  theme?: 'light' | 'dark';
}

const ToggleProduct: FC<ToggleProductProps> = ({ theme = 'light' }) => {
  const isThemeLight = theme === 'light';

  const dispatch = useAppDispatch();

  const { productsWithPromocode, products, isProductsLoading } = useProducts();

  const { currentProductForPayment } = useAppSelector((state) => state.paymentStore);

  const handleSetCurrentProduct = useCallback(
    (product: IProduct) => () => dispatch(setCurrentProductForPayment(product)),
    [dispatch]
  );

  const pricePerMonth = (product: IProduct) => {
    const price = Math.round(
      Number(
        productsWithPromocode && productsWithPromocode[product.id]
          ? productsWithPromocode[product.id]?.price_promotion
          : product.price_promotion !== 0
          ? product.price_promotion
          : product.price
      )
    );

    switch (product.duration_type) {
      case 'month':
        return Math.round(price / product.duration);
        break;
      case 'day':
        return Math.round((price / product.duration) * 30);
        break;
      case 'week':
        return Math.round(price * 4);
        break;
      case 'year':
        return Math.round(price / product.duration / 12);
        break;
      default:
        return 0;
    }
  };

  return (
    <Stack className={s.toggleProduct} rowGap={isThemeLight ? '12px' : '8px'} data-testid={TEST_ID} data-theme={theme}>
      {!isProductsLoading &&
        products?.map((product) => (
          <div
            key={product.id}
            className={cn(s.toggleProductBtn, {
              [s.toggleProductBtnActive]: currentProductForPayment?.id === product.id,
            })}
            onClick={handleSetCurrentProduct(product)}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box position="relative">
                <Typography variant="text6" marginRight="14px" color={isThemeLight ? 'black' : 'white'}>
                  {product.title}
                </Typography>

                {((productsWithPromocode && productsWithPromocode[product.id]) ||
                  product.promotion ||
                  product.badge) && (
                  <div className={s.badge}>
                    <Stack className={s.badgeContent} direction="row" columnGap="6px" alignItems="center">
                      <BadgeIcon theme={theme} />

                      <Typography
                        variant="text7"
                        color={isThemeLight ? 'black' : 'white'}
                        fontWeight={600}
                        whiteSpace="nowrap"
                      >
                        {productsWithPromocode && productsWithPromocode[product.id]
                          ? `Скидка -${
                              productsWithPromocode[product.id]?.promotion?.percent
                                ? `${Math.round(Number(productsWithPromocode[product.id]?.promotion?.percent))}%`
                                : `${Math.round(Number(productsWithPromocode[product.id]?.promotion?.discount))}₽`
                            }`
                          : product.price_promotion !== 0 && product.promotion
                          ? `Скидка -${
                              product.promotion.percent
                                ? `${Math.round(Number(product.promotion.percent))}%`
                                : `${Math.round(Number(product.promotion.discount))}₽`
                            }`
                          : product.badge}
                      </Typography>
                    </Stack>
                  </div>
                )}
              </Box>

              {currentProductForPayment?.id === product.id && (
                <CheckCircleIcon
                  color={isThemeLight ? NamesOfColors.grey100 : NamesOfColors.lime}
                  bgColor={isThemeLight ? NamesOfColors.white100 : NamesOfColors.grey100}
                  size={20}
                />
              )}

              {currentProductForPayment?.id !== product.id && (
                <CircleOutlineBlankIcon size={20} color={isThemeLight ? NamesOfColors.grey30 : NamesOfColors.white50} />
              )}
            </Stack>

            <Stack direction="row" alignItems="end" justifyContent="space-between">
              <Stack direction="row" alignItems="flex-end">
                <Stack direction="row" columnGap="8px" alignItems="baseline">
                  <Typography
                    variant="h3"
                    fontWeight={isThemeLight ? 500 : 600}
                    color={isThemeLight ? 'black' : 'white'}
                  >
                    {Number(
                      productsWithPromocode && productsWithPromocode[product.id]
                        ? productsWithPromocode[product.id]?.price_promotion
                        : product.price_promotion !== 0
                        ? product.price_promotion
                        : product.price
                    ).toLocaleString('ru')}
                    ₽
                  </Typography>

                  {((productsWithPromocode && productsWithPromocode[product.id]) || product.price_promotion !== 0) && (
                    <Typography
                      variant="text6"
                      color={isThemeLight ? 'black' : 'white'}
                      opacity={0.5}
                      fontWeight={500}
                      textDecoration="line-through"
                    >
                      {productsWithPromocode && productsWithPromocode[product.id]
                        ? `${Number(productsWithPromocode[product.id]?.price).toLocaleString('ru')}₽`
                        : product.price_promotion !== 0
                        ? `${Number(product.price).toLocaleString('ru')}₽`
                        : ''}
                    </Typography>
                  )}
                </Stack>
              </Stack>

              {(product.duration_type !== 'month' || product.duration !== 1) && (
                <Stack direction="row" columnGap="5px" alignItems="baseline">
                  <Typography variant="text6" color={isThemeLight ? 'black' : 'white'} opacity={0.5} fontWeight={500}>
                    {pricePerMonth(product).toLocaleString('ru')}₽
                  </Typography>

                  <Typography variant="text6" opacity={0.6} color={isThemeLight ? 'black' : 'white'}>
                    / мес
                  </Typography>
                </Stack>
              )}
            </Stack>
          </div>
        ))}

      {isProductsLoading && (
        <>
          <Skeleton
            height="92px"
            width="100%"
            color={isThemeLight ? NamesOfColors.grey10 : NamesOfColors.white10}
            borderRadius="20px"
          />

          <Skeleton
            height="92px"
            width="100%"
            color={isThemeLight ? NamesOfColors.grey10 : NamesOfColors.white10}
            borderRadius="20px"
          />

          <Skeleton
            height="92px"
            width="100%"
            color={isThemeLight ? NamesOfColors.grey10 : NamesOfColors.white10}
            borderRadius="20px"
          />
        </>
      )}
    </Stack>
  );
};

export const ToggleProductMemo = memo(ToggleProduct);
