import React, { FC } from 'react';

const BellIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6151 2C11.2576 2 10.9498 2.25234 10.8797 2.60291L10.7373 3.31473C10.6504 3.74916 10.3759 4.12296 9.98744 4.33591L9.68719 4.50049C7.94585 5.29358 6.67166 6.95729 6.44809 8.96942L5.90764 13.8335C5.80701 14.7391 5.3445 15.5656 4.62525 16.125L4.27154 16.4001C3.80269 16.7648 3.59318 17.3717 3.73723 17.9479L3.75025 18C3.89717 18.5877 4.42523 19 5.03102 19H9.00024C9.00024 20.6569 10.3434 22 12.0002 22C13.6571 22 15.0002 20.6569 15.0002 19H18.9695C19.5753 19 20.1033 18.5877 20.2502 18L20.2633 17.9479C20.4073 17.3717 20.1978 16.7648 19.729 16.4001L19.3752 16.125C18.656 15.5656 18.1935 14.7391 18.0929 13.8335L17.5524 8.96942C17.3288 6.95729 16.0547 5.29357 14.3133 4.50049L14.0131 4.33591C13.6246 4.12296 13.3501 3.74916 13.2632 3.31473L13.1208 2.60291C13.0507 2.25234 12.7429 2 12.3854 2H11.6151ZM17.9001 17H14.8173C12.3127 17 12.1435 17 9.18323 17H6.1001C7.49927 16.15 7.77311 15.1536 7.89525 14.0544L8.43571 9.19028C8.63751 7.37405 10.1727 6 12.0001 6C13.8275 6 15.3627 7.37405 15.5645 9.19028L16.1049 14.0544C16.2271 15.1536 16.5049 16.15 17.9001 17Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default BellIcon;
