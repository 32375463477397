import React, { FC, ReactNode } from 'react';
import s from './styles.module.scss';
import { IParameters } from '../../../../../api/types';
import { Skeleton } from 'front-package-ui-kit';
import { TypeSizeDashboard } from '../../../../../utils';
import cn from 'classnames';

interface IProps {
  onAreaClick?: () => void;
  children: ReactNode;
  loading: boolean;
  size: TypeSizeDashboard;
}

export interface IDashBoardBase {
  parameters: IParameters[] | null;
  onButtonPlusClick?: () => void;
  onButtonClick?: () => void;
  onAreaClick?: () => void;
  isBtnAdd?: boolean;
  loading: boolean;
  size: TypeSizeDashboard;
}

const DashBoardContainer: FC<IProps> = ({ onAreaClick, loading, children, size }) => {
  const classes = cn(s.container, {
    [s.smallHeight]: size === TypeSizeDashboard.small,
    [s.mediumHeight]: size === TypeSizeDashboard.medium,
    [s.largeHeight]: size === TypeSizeDashboard.large,
  });

  return (
    <>
      {!loading && (
        <div className={classes} onClick={onAreaClick ? () => onAreaClick() : undefined}>
          <div className={s.dashboardContainer}>{children}</div>
        </div>
      )}

      {loading && <Skeleton width="100%" height="154px" borderRadius="20px" />}
    </>
  );
};

export default DashBoardContainer;
