import { useState } from 'react';
import { eachDayOfInterval, format, getDaysInMonth } from 'date-fns';
import { TypesBirthDayForDrum, TypesBirthDayPicker } from '../../models';
import { months, years } from '../../utils';

export const useSwiperPickerDate = (defaultValue: TypesBirthDayForDrum = { day: 1, month: months[0], year: 1960 }) => {
  const [datePickerValue, setDatePickerValue] = useState<TypesBirthDayForDrum>(defaultValue);

  const setDateSwiperPickerHandler = ({ left, middle, right }: TypesBirthDayPicker) => {
    if (typeof left === 'number' && typeof middle === 'string' && typeof right === 'number')
      setDatePickerValue({ day: left, month: middle, year: right });
  };

  const getCountDays = () => {
    const month = months.findIndex((elem) => elem === datePickerValue.month);

    const count = getDaysInMonth(new Date(datePickerValue.year, month, 1));

    return Array(count)
      .fill(null)
      .map((_, i) => i + 1);
  };

  const month = months.findIndex((elem) => elem === datePickerValue.month);

  const currentPickerDate = new Date(datePickerValue.year, month, datePickerValue.day);

  const getAllDaysForInterval = () =>
    eachDayOfInterval({
      start: new Date(new Date().setDate(new Date().getDate() - 300)),
      end: new Date(new Date().setDate(new Date().getDate())),
    }).map((date) => format(date, 'dd.MM.yy'));

  return {
    datePickerValue,
    setDatePickerValue,
    setDateSwiperPickerHandler,
    currentPickerDate,
    months,
    years,
    days: getCountDays(),
    getAllDaysForInterval,
  };
};
