import React, { memo, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { easings } from '@react-spring/web';
import cn from 'classnames';

// Import components and modules
import { Overlay, Portal } from '..';
import { AnimationProvider, useAnimationLibs } from '../../contexts/AnimationProvider';

// Import styles
import s from './Drawer.module.scss';

// Types and interfaces
export type SpringEvent = {
  type: Statuses;
};

interface DrawerProps {
  children: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  onSpring?: (event: SpringEvent) => void;
  touchLine?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  transparentHeader?: boolean;
  theme?: 'dark' | 'light';
  dataTestid: string;
}

const height = window.innerHeight;

export enum Statuses {
  OPEN = 'open',
  CLOSED = 'closed',
  CLOSING = 'closing',
}

export const DrawerContent = memo((props: DrawerProps) => {
  const {
    children,
    isOpen,
    onClose,
    header,
    footer,
    onSpring,
    onScroll,
    touchLine = true,
    transparentHeader = false,
    theme,
    dataTestid,
  } = props;

  const [immediateOpen, setImmediateOpen] = useState(true);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const timer = useRef<NodeJS.Timer | null>(null);
  const divRef = useRef() as MutableRefObject<HTMLDivElement | null>;

  const [status, setStatus] = useState<Statuses>(isOpen ? Statuses.OPEN : Statuses.CLOSED);

  const { Spring, Gesture } = useAnimationLibs();
  const [{ y }, api] = Spring.useSpring(() => ({
    y: height,
    config: {
      mass: 1,
      tension: 250,
      friction: 30,
      easing: easings.easeOutQuart,
    },
  }));

  const openDrawer = useCallback(() => {
    api.start({ y: 0, immediate: immediateOpen, config: { mass: 1, tension: 250, friction: 30 } });
  }, [immediateOpen, api]);

  const close = useCallback(() => {
    setStatus(Statuses.CLOSING);

    timer.current = setTimeout(() => {
      setStatus(Statuses.CLOSED);
    }, 700);

    api.start({
      y: height,
      immediate: false,
      config: { mass: 1, tension: 250, friction: 30, duration: 700, easing: easings.easeInOutQuart },
    });
  }, [api]);

  const handleSwipeDown = Gesture.useDrag(
    ({ last, velocity: [, vy], direction: [, dy], movement: [, my] }) => {
      if (last) {
        if (my > height * 0.1 || (vy > 0.1 && dy > 0)) onClose?.();
        else openDrawer();
      } else
        api.start({
          y: my,
          immediate: false,
          config: { mass: 1, tension: 250, friction: 30, duration: 700, easing: easings.easeInOutQuart },
        });
    },
    { from: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: false }
  );

  useEffect(() => {
    if (isOpen) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      openDrawer();
      setStatus(Statuses.OPEN);
      return;
    }

    if (isOpen === false) {
      close();
    }
  }, [isOpen]);

  useEffect(() => {
    onSpring?.({ type: status });
  }, [status]);

  useEffect(() => {
    setImmediateOpen(false);
  }, []);

  if (status === Statuses.CLOSED) return null;

  return (
    <Portal element={document.getElementById('app') ?? document.body}>
      <div className={cn(s.Drawer)} data-theme={theme} data-testid={dataTestid}>
        {status === Statuses.OPEN && <Overlay onClick={() => onClose?.()} />}
        <Spring.a.div
          ref={divRef}
          className={s.sheet}
          style={{
            y,
            willChange: 'transform',
            transform: 'translateZ(0)',
          }}
        >
          {touchLine && <div className={cn(s.touchLine)} {...handleSwipeDown()} />}
          <div className={s.contentContainer}>
            {header && (
              <div className={cn({ [s.transparentHeader]: transparentHeader })} {...handleSwipeDown()}>
                {header}
              </div>
            )}
            <div
              className={cn(s.scrollContainer, {
                [s.transparentHeaderScrollContainer]: transparentHeader,
              })}
            >
              <div className={s.scroll} ref={scrollRef} onScroll={onScroll}>
                {children}
              </div>
            </div>
            {footer && <div>{footer}</div>}
          </div>
        </Spring.a.div>
      </div>
    </Portal>
  );
});

DrawerContent.displayName = 'DrawerContent';

const DrawerAsync = (props: DrawerProps) => {
  const { isLoaded } = useAnimationLibs();
  if (!isLoaded) return null;
  return <DrawerContent {...props} />;
};

export const Drawer = (props: DrawerProps) => {
  return (
    <AnimationProvider>
      <DrawerAsync {...props} />
    </AnimationProvider>
  );
};
