import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyPaySubscribe = lazy(() => import('./PaySubscribe'));

export const PaySubscribe = () => (
  <Suspense fallback={<Loading />}>
    <LazyPaySubscribe />
  </Suspense>
);
