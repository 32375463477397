import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { IRating } from '../../api/types';
import { getTextError } from '../utils';
import { createServiceNotice } from '../notifications/slice';
import { Environment, noticeCreator } from '../../utils';

const ratingThunk = createAsyncThunk('rating/setRating', async (rating: IRating, { rejectWithValue, dispatch }) => {
  try {
    api.setRating(rating);
  } catch (error) {
    const textError = getTextError(error);

    dispatch(
      createServiceNotice({
        notice: noticeCreator(textError, 'error'),
        otherInfo: { error, pathname: 'rating/setRating', forEnvironment: Environment.development },
      })
    );

    return rejectWithValue(textError);
  }
});

export default ratingThunk;
