import React from 'react';

const AppleIcon = () => {
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="56" height="56" rx="16" fill="white" />
      <path
        d="M36.8298 39.8766C35.2144 41.3542 33.4508 41.1209 31.753 40.421C29.9563 39.7055 28.308 39.6744 26.4125 40.421C24.0389 41.3853 22.7862 41.1053 21.3687 39.8766C13.3249 32.0532 14.5117 20.1391 23.6433 19.7036C25.8685 19.8125 27.418 20.8546 28.7201 20.9479C30.6651 20.5746 32.5277 19.5014 34.6046 19.6414C37.0935 19.828 38.9726 20.7612 40.2088 22.441C35.0661 25.3496 36.2858 31.7421 41 33.5307C40.0605 35.8638 38.8407 38.1813 36.8133 39.8922L36.8298 39.8766ZM28.5553 19.6103C28.308 16.1418 31.2915 13.28 34.72 13C35.198 17.0128 30.8629 19.9991 28.5553 19.6103Z"
        fill="black"
      />
    </svg>
  );
};

export default AppleIcon;
