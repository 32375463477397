import { Grid, GridProps } from '@mui/material';
import { FC } from 'react';

import { CourseCardSkeleton } from '../..';
import { ICourse } from '../../../api/types';
import { AnalyticsName, CourseTypes, getAnalyticInfo, getIsValue } from '../../../utils';
import CourseCard, { TCourseCardSize } from '../../molecules/CourseCard';
import SingleWorkoutCard from '../../molecules/SingleWorkoutCard';

const skeletons = [1, 2];

interface IProps extends GridProps {
  options: ICourse[];
  cardSize: TCourseCardSize;
  loading?: boolean;
  theme?: 'light' | 'dark';
}

const CoursesBar: FC<IProps> = ({ options, cardSize, loading, theme, ...props }) => {
  return (
    <Grid container padding="0 12px" rowGap="12px" {...props}>
      {!loading && (
        <>
          {options.map((course) => (
            <Grid key={course.id} item container justifyContent="center">
              {getIsValue(course.course_type, CourseTypes.multiple) && (
                <CourseCard
                  analyticInfo={getAnalyticInfo(AnalyticsName.course, { id: course.id })}
                  course={course}
                  size={cardSize}
                  fullWidth
                />
              )}

              {getIsValue(course.course_type, CourseTypes.single) && (
                <SingleWorkoutCard
                  analyticInfo={getAnalyticInfo(AnalyticsName.course, { id: course.id })}
                  course={course}
                  size="M"
                />
              )}
            </Grid>
          ))}
        </>
      )}

      {loading && (
        <>
          {skeletons.map((skeleton) => (
            <Grid key={skeleton} item container justifyContent="center">
              <CourseCardSkeleton theme={theme} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default CoursesBar;
