import React, { FC, memo, useCallback } from 'react';
import { Button, Typography } from 'front-package-ui-kit';
import { useAppSelector, useBottomSheet } from '../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, SetMethodPaymentType } from '../..';
import s from './styles.module.scss';
import { MethodPaymentType } from '../../../api/types';

export const TEST_ID = 'BindingBankCardBottomSheet';

export const openBindingBankCardBottomSheet = 'openBindingBankCardBottomSheet';

export interface BindingBankCardBottomSheetProps {
  onApplyClick: () => void;
  loading: boolean;
  infoText: string;
  onCancel?: () => void;
}

const BindingBankCardBottomSheet: FC<BindingBankCardBottomSheetProps> = ({
  onApplyClick,
  loading,
  infoText,
  onCancel,
}) => {
  const { newCardMethodPayment } = useAppSelector((state) => state.paymentStore);

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openBindingBankCardBottomSheet);

  const handleClose = useCallback(() => {
    onCloseBottomSheet();

    onCancel?.();
  }, [onCancel, onCloseBottomSheet]);

  const handleApply = useCallback(() => {
    onCloseBottomSheet();

    onApplyClick();
  }, [onApplyClick, onCloseBottomSheet]);

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={handleClose}
      expandOnContentDrag
      header={
        <BottomSheetHeader
          justifyContent="flex-start"
          title={
            <Typography variant="h4" fontWeight={700} marginLeft="-24px">
              Добавление карты
            </Typography>
          }
          onClose={handleClose}
        />
      }
      footer={
        <BottomButtonBlock direction="column" rowGap="8px">
          <Button loading={loading} size="L" color="black" onClick={handleApply} variant="contained" fullWidth>
            <Typography variant="text6" fontWeight={700} color="white">
              Добавить
            </Typography>
          </Button>

          <Button size="L" onClick={handleClose} variant="text" fullWidth>
            <Typography variant="text6" fontWeight={700}>
              Отменить
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <div className={s.bindingBankCardBottomSheet}>
        <Typography variant="text5" marginBottom="20px" opacity={0.5}>
          {`${infoText} В целях проверки карты будет списан 1 рубль${
            newCardMethodPayment === MethodPaymentType.yookassa ? ' и возвращен обратно' : ''
          }.`}
        </Typography>

        <SetMethodPaymentType />
      </div>
    </BottomSheetUI>
  );
};

export const BindingBankCardBottomSheetMemo = memo(BindingBankCardBottomSheet);
