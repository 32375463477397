import React, { FC, memo } from 'react';
import s from './styles.module.scss';
import { useBankCards } from '../../../hooks';
import { Typography } from 'front-package-ui-kit';
import { BankCardIcon } from '../../../assets/icons';

export const TEST_ID = 'MainBankCard';

export interface MainBankCardProps {}

const MainBankCard: FC<MainBankCardProps> = () => {
  const { mainBankCard } = useBankCards();

  return (
    <div className={s.mainBankCard} data-testid={TEST_ID}>
      <Typography opacity={0.5} variant="text6" fontWeight={500} marginBottom="8px">
        Деньги будут списаны с привязанной карты карты:
      </Typography>

      <div className={s.mainCardInfo}>
        <BankCardIcon bankCardType={mainBankCard?.card_type} />

        <Typography variant="text4" fontWeight={500} marginLeft="8px">
          {`${mainBankCard?.last4 ? mainBankCard.last4 : 'Привязанная карта'}· основная`}
        </Typography>
      </div>
    </div>
  );
};

export const MainBankCardMemo = memo(MainBankCard);
