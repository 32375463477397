import React from 'react';

export const NotFoundSingleWorkouts = () => {
  return (
    <svg width="111" height="113" viewBox="0 0 111 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_bi_957_12254)">
        <path
          d="M51.0272 87.3374L88.5068 65.3386C89.5606 64.7201 89.8928 63.3276 89.2484 62.2299L85.2244 55.3741C84.8232 54.6906 84.3149 54.0978 83.7193 53.5841L76.6999 49.8232C75.4888 48.8074 74.6621 47.399 74.3657 45.8464L73.9684 38.3378C73.5636 36.2362 72.8119 34.2268 71.7178 32.3627L57.6671 8.42446C54.2023 2.52144 46.7889 0.412043 41.1023 3.74979L20.3444 15.9337C14.6778 19.2598 12.8732 26.7519 16.3502 32.6756L30.4008 56.6139C31.4828 58.4572 32.8829 60.1139 34.5205 61.492L40.8826 65.4994C42.0937 66.5152 42.9204 67.9236 43.2168 69.4762L43.0784 77.4385C43.2365 78.2089 43.5063 78.9418 43.9074 79.6253L47.9315 86.481C48.5758 87.5788 49.9535 87.9676 51.0073 87.3491L51.0272 87.3374Z"
          fill="url(#paint0_linear_957_12254)"
          fillOpacity="0.2"
        />
        <path
          d="M76.475 50.0914L76.5027 50.1146L76.5346 50.1317L83.5196 53.8742C84.076 54.359 84.5488 54.9145 84.9226 55.5513L88.9466 62.407C89.5 63.3498 89.2052 64.5228 88.3296 65.0368L50.8501 87.0355L50.8302 87.0472C49.9546 87.5611 48.7867 87.2467 48.2333 86.3039L44.2093 79.4481C43.8355 78.8113 43.581 78.1277 43.429 77.4055L43.5668 69.4823L43.5674 69.4461L43.5606 69.4106C43.2491 67.7788 42.3804 66.2988 41.1076 65.2313L41.0893 65.216L41.0692 65.2033L34.7275 61.2087C33.1282 59.8593 31.7604 58.2387 30.7026 56.4367L16.652 32.4985C13.2662 26.7301 15.0328 19.4573 20.5216 16.2356L41.2795 4.05163C46.7875 0.818708 53.9912 2.85318 57.3653 8.60163L71.4159 32.5399C72.4856 34.3623 73.2218 36.3261 73.6202 38.3805L74.0162 45.8649L74.0174 45.8887L74.0219 45.9121C74.3335 47.5438 75.2021 49.0238 76.475 50.0914Z"
          stroke="url(#paint1_linear_957_12254)"
          strokeOpacity="0.5"
          strokeWidth="0.7"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.6718 89.3977L78.6329 85.3118L78.6536 85.2997C79.902 84.5669 80.4235 83.0674 79.9713 81.7486L88.8101 76.5607C92.4482 74.4253 93.6566 69.7443 91.5387 66.136L89.3277 62.3691L48.0168 86.6167L50.2278 90.3836C52.3573 94.0117 57.0417 95.2073 60.6599 93.0836L67.9289 88.817C68.8599 89.8547 70.4233 90.1304 71.6718 89.3977Z"
        fill="url(#paint2_linear_957_12254)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.1466 100.17L76.4374 90.8878L70.7449 100.143C69.9974 101.243 69.5606 102.571 69.5606 104.002C69.5606 107.799 72.639 110.877 76.4363 110.877C80.2336 110.877 83.3119 107.799 83.3119 104.002C83.3119 102.583 82.8823 101.265 82.1461 100.17H82.1466Z"
        fill="url(#paint3_linear_957_12254)"
      />
      <defs>
        <filter
          id="filter0_bi_957_12254"
          x="-14.1659"
          y="-26.5805"
          width="132.488"
          height="142.969"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="14.3725" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_957_12254" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_957_12254" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.79084" />
          <feGaussianBlur stdDeviation="9.58167" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_957_12254" />
        </filter>
        <linearGradient
          id="paint0_linear_957_12254"
          x1="142.164"
          y1="103.37"
          x2="47.8581"
          y2="134.923"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.129474" stopColor="#424242" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_957_12254"
          x1="54.1817"
          y1="81.3519"
          x2="52.8573"
          y2="9.43415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.423397" stopColor="white" stopOpacity="0.576603" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_957_12254"
          x1="100.112"
          y1="61.3266"
          x2="53.1055"
          y2="91.023"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_957_12254"
          x1="76.4362"
          y1="90.8877"
          x2="76.4362"
          y2="110.877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
      </defs>
    </svg>
  );
};
