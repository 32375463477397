import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazySingleWorkoutsAll = lazy(() => import('./SingleWorkoutsAll'));

export const SingleWorkoutsAll = () => (
  <Suspense fallback={<Loading />}>
    <LazySingleWorkoutsAll />
  </Suspense>
);
