import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyWidgetSettings = lazy(() => import('./WidgetSettings'));

export const WidgetSettings = () => (
  <Suspense fallback={<Loading />}>
    <LazyWidgetSettings />
  </Suspense>
);
