import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useLevels = () => {
  const {
    levels: { data: levels, statuse },
  } = useAppSelector((state) => state.dictionariesStore);
  const { isLoading } = useAppSelector((state) => state.authStore);

  const isLevels = Boolean(levels?.length);

  const isLevelsLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isLevels) || isLoading;

  const isLevelsLoadingCompleted = isLevels || statuse === Statuses.succeeded || statuse === Statuses.failed;

  return {
    levels,
    isLevels,
    isLevelsLoading,
    isLevelsLoadingCompleted,
  };
};
