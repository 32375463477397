import { ILevel, IWorkout } from '../api/types';
import { WorkoutsProgressStatuses } from './constants/config';
import { CourseProgressStatus } from '.';
import { ICourseDetailse } from '../api/types';

export const getCountOfCompletedWorkouts = (workouts: IWorkout[] | undefined) => {
  if (!workouts) return 0;

  return workouts.filter(({ progress }) => progress?.status === WorkoutsProgressStatuses.completed).length;
};

export const getNumberOfWorkouts = (workouts: IWorkout[] | undefined) => {
  if (!workouts) return 0;

  return workouts.filter(({ type }) => type === 'workout').length;
};

export const getCourseStatuses = (course: ICourseDetailse | null | undefined) => {
  const isCourseStarted = course?.progress?.status === CourseProgressStatus.inProgress;

  const isCourseCompleted = course?.progress?.status === CourseProgressStatus.completed;

  const isAvailable = course?.is_public || course?.ordered;

  const isCourseNotStarted = !course?.progress || course?.progress?.status === CourseProgressStatus.notStarted;

  const status: 'notStarted' | 'inProgress' | 'completed' = isCourseCompleted
    ? 'completed'
    : isCourseStarted
    ? 'inProgress'
    : 'notStarted';

  return {
    isCourseNotStarted,
    isCourseCompleted,
    isCourseStarted,
    isAvailable,
    status,
  };
};

export const getCourseLevel = (levels: ILevel[]): ILevel | null => {
  if (!levels.length) return null;

  let maxLevel: ILevel | null = null;

  levels.forEach((level) => {
    if (!maxLevel) {
      maxLevel = level;
    }

    if (level.id > maxLevel.id) {
      maxLevel = level;
    }
  });

  return maxLevel;
};
