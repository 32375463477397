/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector, useGroups, useLevels, useRenderSchemes } from '.';
import { setCachedFiltersInfo } from '../redux/filters/slice';
import {
  FilterNames,
  FilterType,
  RenderSchemesNames,
  getFilterButtons,
  getFilteredCourse,
  getNumberOfFilters,
  isFilterNames,
} from '../utils';
import { IFilterButton } from '../utils/getFilterButtons';
import { getLevelsDictionaryThunk } from '../redux/dictionaries/thunks';
import { getGroupThunk } from '../redux/groups/thunk';
import { ICourseWithExtraOptions } from '../redux/courses/slice';

export interface IButtonsFilterOptions {
  onFilterButtonClickHandler: (filterItem: string) => () => void;
  clearFilterHandler: () => void;
  filterButtons: IFilterButton[];
  title: string;
  filterValues: string[];
  filterName: FilterNames;
}

export type TFilters = Record<FilterNames, string[]>;

export type TFilterButton = Record<string, IFilterButton[]>;

export enum SavedFilterType {
  search = 'search',
  button = 'button',
}

export interface ISavedFilter {
  name: string;
  type: 'search' | 'button';
  savedFilters: TFilters;
}

const FILTER_HISTORY_MAX_LENGTH = 4;

const DEFAULT_BUTTON_FILTERS = {
  direction: [],
  goals: [],
  levels: [],
  active_area: [],
  inventory: [],
  duration: [],
  groups: [],
  course_type: [],
  haveInventory: [],
};

const DEFAULT_FILTERS = {
  name: [],
  ...DEFAULT_BUTTON_FILTERS,
};

interface IConfiguredFilters {
  filterTitle: string;
  courseKey: FilterNames;
  groupType?: string;
  groupIds?: string[];
}

const useFilter = (
  configuredFilters: IConfiguredFilters[],
  courses: ICourseWithExtraOptions[],
  filtersCacheKey: string,
  isCoursesLoadingCompleted: boolean
) => {
  const { filtersCache } = useAppSelector((state) => state.filtersStore);
  const { levels, isLevelsLoadingCompleted } = useLevels();
  const { groupsByID, groupsByType, groups, isGroupsLoadingCompleted } = useGroups();
  const { renderScheme: durationFiltersSchema } = useRenderSchemes(RenderSchemesNames.duration);

  const [isFilterButtonsReady, setIsFilterButtonsReady] = useState(false);

  const [filters, setFilters] = useState<TFilters>(filtersCache?.[filtersCacheKey]?.filters ?? DEFAULT_FILTERS);

  const [filterButtons, setFilterButtons] = useState<TFilterButton>({});

  const [filtersHistory, setFiltersHistory] = useState<ISavedFilter[]>(
    filtersCache?.[filtersCacheKey]?.filtersHistory ?? []
  );

  const dispatch = useAppDispatch();

  const durationButtonsVariants = useMemo(() => {
    return durationFiltersSchema.reduce((acc, { name, from = 0, to = 999999 }) => {
      acc[name] = [from, to];

      return acc;
    }, {} as Record<string, [number, number]>);
  }, [durationFiltersSchema]);

  const getOnFilterButtonClickHandler = (filterName: FilterNames) => (filterItem: string) => () => {
    const newFilter = filters[filterName].includes(filterItem)
      ? filters[filterName].filter((fil) => fil !== filterItem)
      : [...filters[filterName], filterItem];

    setFilters((prev) => ({ ...prev, [filterName]: newFilter }));
  };

  useEffect(() => {
    //создает кнопки для фильтров

    if (!isLevelsLoadingCompleted || !isGroupsLoadingCompleted || !isCoursesLoadingCompleted) return;

    configuredFilters.forEach(({ courseKey, filterTitle, groupType, groupIds }) => {
      if (isFilterNames(courseKey)) {
        if (courseKey === FilterNames.levels) {
          const levelsFilterButtons = levels.map(({ title }) => ({ value: title, disabled: false, name: title }));

          setFilterButtons((prev) => ({ ...prev, [filterTitle]: levelsFilterButtons }));

          return;
        }

        if (courseKey === FilterNames.groups) {
          const result: IFilterButton[] = [];

          if (groupType) {
            groupsByType[groupType]?.forEach(({ id, name }) => {
              if (courses.find((course) => course.groups.includes(+id)))
                result.push({
                  value: String(id),
                  disabled: false,
                  name,
                });
            });

            setFilterButtons((prev) => ({ ...prev, [filterTitle]: result }));

            return;
          }

          if (groupIds) {
            groupIds.forEach((id) => {
              if (courses.find((course) => course.groups.includes(+id))) {
                result.push({
                  value: String(id),
                  disabled: false,
                  name: groupsByID[id]?.name,
                });
              }
            });

            setFilterButtons((prev) => ({ ...prev, [filterTitle]: result }));

            return;
          }

          return;
        }

        setFilterButtons((prev) => ({
          ...prev,
          [filterTitle]: getFilterButtons({
            courses,
            courseKey,
            durationFiltersSchema: durationFiltersSchema,
          }),
        }));
      }
    });

    setIsFilterButtonsReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, levels, groups]);

  const getClearFilterHandler = (filterName: FilterNames, filterTitle: string) => () => {
    if (filterName === FilterNames.groups) {
      setFilters((prev) => ({
        ...prev,
        groups: prev.groups.filter((val) => !filterButtons[filterTitle].find(({ value }) => val === value)),
      }));

      return;
    }

    setFilters((prev) => ({ ...prev, [filterName]: [] }));
  };

  const getButtonsFilterOptions = ({ filterTitle, courseKey: filterName }: IConfiguredFilters) => ({
    filterName,
    onFilterButtonClickHandler: getOnFilterButtonClickHandler(filterName),
    clearFilterHandler: getClearFilterHandler(filterName, filterTitle),
    filterButtons: filterButtons[filterTitle],
    title: filterTitle,
    filterValues: filters[filterName],
  });

  const onChangeNameFilterHandler = (value: string, onEraseNameFilter?: () => void) => {
    if (!value) {
      if (onEraseNameFilter) onEraseNameFilter();

      return setFilters((prev) => ({ ...prev, name: [] }));
    }

    setFilters((prev) => ({ ...prev, name: [value] }));
  };

  const isNameFilter = useMemo(() => {
    return Boolean(getNumberOfFilters(filters, FilterType.nameFilter));
  }, [filters]);

  const isButtonFilters = useMemo(() => {
    return Boolean(getNumberOfFilters(filters, FilterType.buttonFilters));
  }, [filters]);

  const numberOfFilters = useMemo(() => {
    return getNumberOfFilters(filters);
  }, [filters]);

  const clearNameFilter = () => {
    if (isNameFilter) setFilters((prev) => ({ ...prev, name: [] }));
  };

  const clearButtonFilters = () => {
    if (isButtonFilters) setFilters((prev) => ({ ...prev, ...DEFAULT_BUTTON_FILTERS }));
  };

  //вероятно сохраняет кнопочные фильтры в историю
  const saveButtonFilters = () => {
    const savedFilterName = Object.values(filters).flat().join(', ');

    if (!savedFilterName.length) return;

    const isHistoryIncludesFilter = Boolean(filtersHistory.find(({ name }) => name === savedFilterName));

    if (isHistoryIncludesFilter) return;

    const newFiltersHistory = [...filtersHistory];

    const savedFilter = {
      name: savedFilterName,
      type: SavedFilterType.button,
      savedFilters: filters,
    };

    if (filtersHistory.length >= FILTER_HISTORY_MAX_LENGTH) {
      newFiltersHistory.shift();

      newFiltersHistory.push(savedFilter);
    } else {
      newFiltersHistory.push(savedFilter);
    }

    setFiltersHistory(newFiltersHistory);
  };

  const saveNameFilter = () => {
    if (!filters.name.length) return; //если фильтр по имени пуст, ни чего не делаем

    const savedFilterName = filters.name[0]; //иначе забираем фильтрп по имени в переменную

    //если фильтр по имени содержится в истории, то ни чего не делаем
    const isHistoryIncludesFilter = Boolean(filtersHistory.find(({ name }) => name.includes(savedFilterName)));

    if (isHistoryIncludesFilter) return;

    const newFiltersHistory = [...filtersHistory]; //иначе копируем историю фильтров в переменную

    //создаем новый объект истории и называем его так же как фильтр по имени
    const savedFilter = {
      name: savedFilterName,
      type: SavedFilterType.search,
      savedFilters: filters,
    };

    const lastFilterIndex = filtersHistory.length - 1; //получаем индекс последнего элемента в истории

    //если история фильтров пуста, добавляем новый объект истории
    if (!filtersHistory.length) {
      newFiltersHistory.push(savedFilter);

      return setFiltersHistory(newFiltersHistory);
    }

    //если последний элемент истории не является фильтром по имени, добавляем новый объект
    if (filtersHistory[lastFilterIndex].type !== SavedFilterType.search) {
      if (filtersHistory.length >= FILTER_HISTORY_MAX_LENGTH) {
        newFiltersHistory.shift();

        newFiltersHistory.push(savedFilter);
      } else {
        newFiltersHistory.push(savedFilter);
      }

      return setFiltersHistory(newFiltersHistory);
    }

    if (
      filtersHistory[lastFilterIndex].type === SavedFilterType.search &&
      filtersHistory[lastFilterIndex].name === savedFilterName.slice(0, -1)
    ) {
      newFiltersHistory[lastFilterIndex] = savedFilter;

      return setFiltersHistory(newFiltersHistory);
    }

    if (filtersHistory.length >= FILTER_HISTORY_MAX_LENGTH) {
      newFiltersHistory.shift();

      newFiltersHistory.push(savedFilter);
    } else {
      newFiltersHistory.push(savedFilter);
    }

    setFiltersHistory(newFiltersHistory);
  };

  const setFilterFromHistory = (historyFilterName: string) => {
    const filter = filtersHistory.find(({ name }) => name === historyFilterName);

    if (filter) setFilters(filter.savedFilters);
  };

  const setFilter = (filterName: FilterNames, filterValues: string[]) => {
    setFilters((prev) => ({ ...prev, [filterName]: [...prev[filterName], ...filterValues] }));
  };

  const filteredCourses = useMemo(() => {
    if (!isCoursesLoadingCompleted || !configuredFilters.length || !courses.length) {
      return courses;
    }

    const hasActiveFilters = Object.values(filters).some((filterArray) => filterArray.length > 0);

    if (!hasActiveFilters) {
      return courses;
    }

    return courses.filter((course) =>
      configuredFilters.reduce((acc, { courseKey }) => {
        return (
          acc &&
          getFilteredCourse({
            course,
            courseKey,
            filtersValues: filters[courseKey],
            durationButtonsVariants,
          })
        );
      }, true)
    );
  }, [configuredFilters, courses, filters, isCoursesLoadingCompleted, durationButtonsVariants]);

  const removeFromFilterHistory = (historyFilterName: string) => {
    const newFilterHistory = filtersHistory.filter(({ name }) => name !== historyFilterName);

    setFiltersHistory(newFilterHistory);
  };

  const clearAllFilters = () => {
    setFilters(DEFAULT_FILTERS);
  };
  //если в быстром фильтре убрать все фильтры, нужно открывать фильтр

  useEffect(() => {
    dispatch(setCachedFiltersInfo({ [filtersCacheKey]: { filtersHistory, filters } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, filtersHistory]);

  useEffect(() => {
    if (configuredFilters.find(({ courseKey }) => courseKey === FilterNames.levels)) {
      dispatch(getLevelsDictionaryThunk());
    }

    if (configuredFilters.find(({ courseKey }) => courseKey === FilterNames.groups)) {
      dispatch(getGroupThunk());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO: если возникнет необходимость вернуться к сохранению фильтров, механизм сохранения фильтра по имени, ранее расолагался на странице
  // useEffect(() => {
  //   // в разработке, сохранение фильтра по имени, возможно требуется вынести в хук
  //   saveNameFilter();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters.name]);

  return {
    getButtonsFilterOptions,
    onChangeNameFilterHandler,
    numberOfFilters,
    clearNameFilter,
    isNameFilter,
    clearButtonFilters,
    clearAllFilters,
    setFilterFromHistory,
    saveButtonFilters,
    filtersHistory,
    saveNameFilter,
    removeFromFilterHistory,
    filters,
    filteredCourses,
    setFilter,
    isFilterButtonsReady,
  };
};

export default useFilter;
