import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyNotFound = lazy(() => import('./NotFound'));

export const NotFound = () => (
  <Suspense fallback={<Loading />}>
    <LazyNotFound />
  </Suspense>
);
