import React, { FC, ReactNode, memo, useCallback, useMemo } from 'react';
import s from './styles.module.scss';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, SwiperPicker } from '../..';
import { useAppDispatch, useBottomSheet, useSwiperPickerDate } from '../../../hooks';
import { Button, Typography } from 'front-package-ui-kit';
import { Environment, ErrorMessages, months, noticeCreator } from '../../../utils';
import { isFuture } from 'date-fns';
import { createServiceNotice } from '../../../redux/notifications/slice';

export const TEST_ID = 'SetDateBottomSheet';

export const openSetDateBottomSheet = 'openSetDateBottomSheet';

export interface SetDateBottomSheetProps {
  title?: ReactNode;
  onChange?: () => void;
  onApplyClick?: (date: Date) => void;
  defaultValue?: Date;
  disableFuture?: boolean;
  years: number[];
}

const SetDateBottomSheet: FC<SetDateBottomSheetProps> = ({
  onApplyClick,
  defaultValue,
  title,
  years,
  disableFuture,
}) => {
  const dispatch = useAppDispatch();

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openSetDateBottomSheet);

  const handleClose = useCallback(() => onCloseBottomSheet(), [onCloseBottomSheet]);

  const defaultSwiperPickerDate = useMemo(() => {
    return {
      day: defaultValue?.getDate() ?? 1,
      month: defaultValue?.getMonth() ? months[defaultValue.getMonth()] : months[0],
      year: defaultValue?.getFullYear() ?? years[0],
    };
  }, [defaultValue, years]);

  const { datePickerValue, days, setDateSwiperPickerHandler, setDatePickerValue } =
    useSwiperPickerDate(defaultSwiperPickerDate);

  const handleApplyClick = useCallback(() => {
    onCloseBottomSheet();

    if (!datePickerValue || !onApplyClick) return;

    const newValue = new Date(datePickerValue.year, months.indexOf(datePickerValue.month), datePickerValue.day);

    if (disableFuture) {
      if (isFuture(newValue)) {
        dispatch(
          createServiceNotice({
            notice: noticeCreator(ErrorMessages.futureDate, 'error'),
            otherInfo: {
              error: 'future date',
              pathname: 'handleSetDate',
              forEnvironment: Environment.production,
            },
          })
        );

        setDatePickerValue(defaultSwiperPickerDate);

        return;
      }
    }

    onApplyClick(newValue);
  }, [
    datePickerValue,
    defaultSwiperPickerDate,
    disableFuture,
    dispatch,
    onApplyClick,
    onCloseBottomSheet,
    setDatePickerValue,
  ]);

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={handleClose}
      header={<BottomSheetHeader title={title} onClose={handleClose} />}
      footer={
        <BottomButtonBlock direction="column" rowGap="8px">
          <Button type="button" size="L" color="black" onClick={handleApplyClick} variant="contained" fullWidth>
            <Typography variant="text6" fontWeight={700} color="white">
              Добавить
            </Typography>
          </Button>

          <Button type="button" size="L" color="black" onClick={handleClose} variant="text" fullWidth>
            <Typography variant="text6" fontWeight={700}>
              Назад
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <SwiperPicker
        onChange={setDateSwiperPickerHandler}
        leftPicker={{
          options: days,
          defaultValue: datePickerValue.day,
        }}
        middlePicker={{
          options: months,
          defaultValue: datePickerValue.month,
        }}
        rightPicker={{
          options: years,
          defaultValue: datePickerValue.year,
        }}
      />
    </BottomSheetUI>
  );
};

export const SetDateBottomSheetMemo = memo(SetDateBottomSheet);
