import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokens } from '../../api/types';
import { matcherHelper } from '../utils';
import { loginThunk } from './thunks';

interface IAuth {
  access_token: string;
  refresh_token: string;
  error: string;
  isLoading: boolean;
}

const initialState: IAuth = {
  access_token: '',
  refresh_token: '',
  error: '',
  isLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens(state, { payload: { access_token, refresh_token } }: PayloadAction<ITokens>) {
      state.access_token = access_token;
      state.refresh_token = refresh_token;
    },
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    clearAuthError(state) {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, { payload: { access_token, refresh_token } }: PayloadAction<ITokens>) => {
        state.error = '';
        state.isLoading = false;
        state.access_token = access_token;
        state.refresh_token = refresh_token;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, authSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, authSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const { setTokens, clearAuthError, setLoading } = authSlice.actions;

export default authSlice.reducer;
