import React, { FC, memo, useCallback } from 'react';
import { Button, Typography } from 'front-package-ui-kit';
import s from './styles.module.scss';
import { useBottomSheet, useUser } from '../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI } from '../..';

export const TEST_ID = 'ConfirmChangeAutoRenewalBottomSheet';

export const openConfirmChangeAutoRenewalBottomSheet = 'openConfirmChangeAutoRenewalBottomSheet';

export interface ConfirmChangeAutoRenewalBottomSheetProps {
  onApplyClick: () => void;
  onCancel?: () => void;
}

const ConfirmChangeAutoRenewalBottomSheet: FC<ConfirmChangeAutoRenewalBottomSheetProps> = ({
  onApplyClick,
  onCancel,
}) => {
  const { user } = useUser();

  const { autopay } = user ?? {};

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openConfirmChangeAutoRenewalBottomSheet);

  const handleClose = useCallback(() => {
    onCloseBottomSheet();

    onCancel?.();
  }, [onCancel, onCloseBottomSheet]);
  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      expandOnContentDrag
      onDismiss={handleClose}
      header={<BottomSheetHeader onClose={handleClose} />}
      footer={
        <BottomButtonBlock direction="column" rowGap="8px">
          <Button size="L" color="black" onClick={onApplyClick} variant="contained" fullWidth>
            <Typography variant="text6" fontWeight={700} color="white">
              {autopay ? 'Отключить' : 'Включить'}
            </Typography>
          </Button>

          <Button size="L" onClick={handleClose} variant="text" fullWidth>
            <Typography variant="text6" fontWeight={700} color="black">
              Назад
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <div className={s.confirmChangeAutoRenewalBottomSheet}>
        <Typography variant="h4" fontWeight={700} textAlign="center" marginBottom="8px">
          {autopay ? 'Отключить' : 'Включить'} автопродление?
        </Typography>

        {!autopay && (
          <Typography variant="text6" textAlign="center">
            После окончания текущего тарифа, подписка <br /> автоматически продлится и оплата спишется <br /> с
            привязанной карты.
          </Typography>
        )}

        {autopay && (
          <Typography variant="text6" textAlign="center">
            Вы сможете пользоваться тарифом <br /> PRO до окончания оплаченного периода.
          </Typography>
        )}
      </div>
    </BottomSheetUI>
  );
};

export const ConfirmChangeAutoRenewalBottomSheetMemo = memo(ConfirmChangeAutoRenewalBottomSheet);
