import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CurrentTab = Record<string, string>;

interface ICourseVideosPage {
  currentTab: CurrentTab;
}

const initialState: ICourseVideosPage = {
  currentTab: {},
};

export const courseVideosPageSlice = createSlice({
  name: 'courseVideosPage',
  initialState,
  reducers: {
    setCurrentTab(state, { payload }: PayloadAction<{ id: string; tab: string }>) {
      state.currentTab[payload.id] = payload.tab;
    },
  },
});

export const { setCurrentTab } = courseVideosPageSlice.actions;

export default courseVideosPageSlice.reducer;
