import { Button, Typography } from 'front-package-ui-kit';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI } from '../../../../components';
import { useAnalytics, useBottomSheet } from '../../../../hooks';
import { AnalyticsName } from '../../../../utils';
import { useStepmeterActions } from '../../hooks/useStepmeterActions';

export const openBottomSheetDeleteAllProgress = 'openBottomSheetDeleteAllProgress';

interface IProps {}

const BottomSheetDeleteAllProgress: FC<IProps> = () => {
  const { pathname } = useLocation();

  const { setAnalytics } = useAnalytics();

  const { resetAllProgress } = useStepmeterActions();

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openBottomSheetDeleteAllProgress);

  const handleCloseBottomSheet = () => onCloseBottomSheet();

  const handleDelete = () => {
    setAnalytics(AnalyticsName.clear_distance_all, {
      pathname,
    });

    resetAllProgress();
    onCloseBottomSheet();
  };

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={onCloseBottomSheet}
      expandOnContentDrag
      header={
        <BottomSheetHeader
          title={
            <Typography variant="h4" fontWeight={700} textAlign="center" color="black" marginBottom="8px">
              Данные о шагомере
            </Typography>
          }
          onClose={handleCloseBottomSheet}
        />
      }
      footer={
        <BottomButtonBlock rowGap="8px" flexDirection="column">
          <Button size="L" color="black" onClick={handleDelete} variant="contained" fullWidth>
            <Typography variant="text4" fontWeight={500} color="white">
              Удалить все данные
            </Typography>
          </Button>

          <Button size="L" onClick={handleCloseBottomSheet} variant="text" fullWidth>
            <Typography variant="text4" fontWeight={500} color="black">
              Назад
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <Typography variant="text6" fontWeight={400} textAlign="center" color="black" marginBottom="16px">
        Вы действительно хотите удалить <br />
        все данные?
      </Typography>
    </BottomSheetUI>
  );
};

export default BottomSheetDeleteAllProgress;
