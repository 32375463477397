import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPromoBanner } from '../../api/types';
import { matcherHelper } from '../utils';
import { getPromoBannersThunk } from './thunks';

interface IPromoBanners {
  banners: IPromoBanner[];
  error: string;
  isLoading: boolean;
}

const initialState: IPromoBanners = {
  banners: [],
  error: '',
  isLoading: false,
};

export const promoBannersSlice = createSlice({
  name: 'promoBanners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPromoBannersThunk.fulfilled, (state, { payload }: PayloadAction<IPromoBanner[]>) => {
        state.error = '';
        state.isLoading = false;
        state.banners = payload;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, promoBannersSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, promoBannersSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const {} = promoBannersSlice.actions;

export default promoBannersSlice.reducer;
