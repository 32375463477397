import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyStepmeterDistanceUpdate = lazy(() => import('./StepmeterDistanceUpdate'));

export const StepmeterDistanceUpdate = () => (
  <Suspense fallback={<Loading />}>
    <LazyStepmeterDistanceUpdate />
  </Suspense>
);
