import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyVideoPlayer = lazy(() => import('./VideoPlayer'));

export const VideoPlayer = () => (
  <Suspense fallback={<Loading />}>
    <LazyVideoPlayer />
  </Suspense>
);
