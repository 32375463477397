import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useParameters = () => {
  const {
    parametersStore: {
      parameters: { data: parameters, statuse: parametersStatuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ parametersStore: state.parametersStore, authStore: state.authStore }));

  const isParameters = Boolean(parameters?.length);

  const isParametersLoading =
    ((parametersStatuse === Statuses.idle || parametersStatuse === Statuses.loading) && !isParameters) || isLoading;

  return { parameters, isParameters, isParametersLoading };
};
