import React, { FC, memo, useLayoutEffect, useRef } from 'react';
import s from './styles.module.scss';
import { useAppSelector, useScrollSave } from '../../../hooks';
import { SaveScrollPathnames } from '../../../utils';
import cn from 'classnames';
import { useUpdateEffect } from 'usehooks-ts';

export const TEST_ID = 'SaveScroll';

export interface SaveScrollProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  pathname: [SaveScrollPathnames, string];
  resetScrollDeps?: any[];
  direction?: 'row' | 'column';
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

const SaveScroll: FC<SaveScrollProps> = ({
  children,
  pathname,
  className,
  resetScrollDeps = [],
  direction = 'column',
  onScroll,
  ...props
}) => {
  const key = `${pathname[0]}-${pathname[1]}`;

  const { scroll } = useAppSelector((state) => state.scrollSaveStore);

  const ref = useRef<HTMLDivElement | null>(null);

  const [onScrollSave] = useScrollSave(key);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    onScrollSave(e);
    onScroll?.(e);
  };

  useLayoutEffect(() => {
    if (!scroll[key] || !ref.current) return;

    ref.current.scrollTop = scroll[key].positionY;
    ref.current.scrollLeft = scroll[key].positionX;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useUpdateEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [...resetScrollDeps]);

  return (
    <div
      data-testid={TEST_ID}
      ref={ref}
      onScroll={handleScroll}
      className={cn(s.saveScroll, { [s.column]: direction === 'column' }, className)}
      {...props}
    >
      {children}
    </div>
  );
};

export const SaveScrollMemo = memo(SaveScroll);
