export const ProIcon = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0992 1.79528C11.211 1.34073 10.9847 0.872862 10.5408 0.641028C10.0969 0.409194 9.53882 0.4674 9.16404 0.784627L0.3726 8.22619C0.0210302 8.52377 -0.0946355 8.98686 0.0810292 9.39353C0.256694 9.8002 0.6879 10.0676 1.16801 10.0676H4.28908L2.78882 16.2062C2.67802 16.6595 2.90341 17.1258 3.34547 17.3578C3.78753 17.5899 4.3439 17.5338 4.71951 17.2194L13.6226 9.76802C13.9769 9.4715 14.095 9.00753 13.9202 8.5994C13.7453 8.19126 13.3133 7.92253 12.832 7.92253H9.59194L11.0992 1.79528Z"
        fill="url(#paint0_linear_2396_49873)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2396_49873"
          x1="5.29315"
          y1="3.50001"
          x2="4.58307"
          y2="17.8668"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.259084" stopColor="#7FFF00" />
          <stop offset="0.512606" stopColor="#FF0249" />
          <stop offset="0.854739" stopColor="#8000FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
