import { FC } from 'react';
import { ArrowDownSmall, InfoIcon } from '../../../assets/icons';
import { Box, Stack } from '@mui/material';
import { getValuesOfBar } from './helpers';
import cn from 'classnames';
import s from './styles.module.scss';
import { Typography } from 'front-package-ui-kit';
import PencilIcon from './icons/PencilIcon';
import { useUpdateEffect } from 'usehooks-ts';
import { useAnalytics } from '../../../hooks';
import { AnalyticsName } from '../../../utils';

export const TEST_ID = 'BodyMassIndex';

interface IProps {
  weight: number;
  height: number;
  onClickRightButton: () => void;
  onClickInfoButton: () => void;
}

const VALUES_MEASUREMENTS = [17, 19, 21, 23, 25, 27];

const BodyMassIndex: FC<IProps> = ({ weight, height, onClickRightButton, onClickInfoButton }) => {
  const { setAnalytics } = useAnalytics();

  const indexMassBody = +(weight / Math.pow(height / 100, 2)).toFixed(1);

  const integerIndexMassBody = Math.round(indexMassBody);

  const valuesOfBar = getValuesOfBar();

  const valueIndexMassBody = integerIndexMassBody < 17 ? 17 : integerIndexMassBody > 27 ? 27 : integerIndexMassBody;
  const classToolTip = cn(s.tooltip, {
    [s.tooltipRight]: indexMassBody > 27,
    [s.tooltipLeft]: indexMassBody < 17,
  });

  const classNumberIMB = cn(s.textNumberIMB, {
    [s.textNumberIMBLeft]: indexMassBody < 17,
    [s.textNumberIMBRight]: indexMassBody > 27,
  });

  const isDeficit = indexMassBody < 18.5;

  const isStandard = indexMassBody > 18.5 && indexMassBody < 25;

  const isExcess = indexMassBody > 25;

  useUpdateEffect(() => {
    setAnalytics(AnalyticsName.currentBmi, { indexMassBody });
  }, [weight, height]);

  return (
    <div className={s.bodyMassIndex} data-testid={TEST_ID}>
      <Stack className={s.bodyMassIndexContent} direction="row" justifyContent="space-between" marginBottom="50px">
        <Stack direction="row" columnGap="4px" alignItems="center">
          <Typography variant="text4" fontWeight={500} color="white">
            ИМТ
          </Typography>

          <button className={s.infoBtn} onClick={onClickInfoButton}>
            <InfoIcon size="12" />
          </button>
        </Stack>

        <Stack direction="row" columnGap="8px" alignItems="center">
          {height > 0 && weight > 0 && (
            <Typography variant="text6" fontWeight={500} color="white">
              {isDeficit && 'Дефицит'}
              {isStandard && 'В норме'}
              {isExcess && 'Избыток'}
            </Typography>
          )}

          <button className={s.pencilIconBtn} onClick={onClickRightButton}>
            <PencilIcon />
          </button>
        </Stack>
      </Stack>

      <Stack direction="row" className={s.skillBar}>
        {valuesOfBar.map((value, i) => (
          <Box className={s.skillBarValue} key={value}>
            {height > 0 && weight > 0 && valueIndexMassBody === value && (
              <>
                <Box className={classNumberIMB}>
                  <Typography variant="text2" color="white" fontWeight={600}>
                    {indexMassBody.toString().length === 5 ? Math.round(indexMassBody) : indexMassBody}
                  </Typography>
                </Box>

                <div className={classToolTip}>
                  <ArrowDownSmall />
                </div>
              </>
            )}

            {VALUES_MEASUREMENTS.includes(value) && (
              <Typography
                textAlign={i === 0 ? 'left' : i === valuesOfBar.length + 1 ? 'right' : 'center'}
                variant="text7"
                fontWeight={500}
                opacity={0.3}
                color="white"
                marginTop="4px"
              >
                {value}
              </Typography>
            )}
          </Box>
        ))}
      </Stack>
    </div>
  );
};

export default BodyMassIndex;
