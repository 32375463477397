import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyCourses = lazy(() => import('./Courses'));

export const Courses = () => (
  <Suspense fallback={<Loading />}>
    <LazyCourses />
  </Suspense>
);
