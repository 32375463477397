import React, { FC, useState, MouseEvent, useRef, memo } from 'react';
import DashBoardContainer, { IDashBoardBase } from '../DashBoardContainer';
import {
  PARAMETERS_TYPES,
  TParameterTypeChest,
  TParameterTypeHips,
  TParameterTypeWaist,
} from '../../../../../utils/constants/config';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import HeaderDashboard from '../HeaderDashboard';
import { KeyboardArrowDownIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import s from './styles.module.scss';
import DropDown from '../DropDown';
import ContentDashBoard from '../ContentDashBoard';
import { Stack } from '@mui/material';
import LineGraphic from '../../../LineGraphic';
import FooterDashBoard from '../FooterDashBoard';
import useDashBoardHelper from '../../hooks/useDashBoardHelper';
import { SingleParameterBadge } from '../SingleParameterBadge';
import { setCurrentSizeParameter } from './slice';
import { useEffectOnce } from 'usehooks-ts';

interface IProps extends IDashBoardBase {}

const DashBoardSizes: FC<IProps> = ({
  parameters,
  onButtonClick,
  onButtonPlusClick,
  onAreaClick,
  isBtnAdd,
  loading,
  size,
}) => {
  const { currentSizeParameter } = useAppSelector((state) => state.dashBoardSizesStore);

  const dispatch = useAppDispatch();

  const sizeParameters: (TParameterTypeHips | TParameterTypeWaist | TParameterTypeChest)[] = [
    PARAMETERS_TYPES.chest,
    PARAMETERS_TYPES.hips,
    PARAMETERS_TYPES.waist,
  ];

  const [openDropDown, setOpenDropDown] = useState(false);

  const sizeHeader = currentSizeParameter ? `Объём ${currentSizeParameter.declination.genitive}` : '';

  const handleButton = (e: MouseEvent<HTMLButtonElement>) => {
    if (onButtonClick) {
      e.stopPropagation();
      onButtonClick();
    }
  };

  const { isEmpty, isOneParameter, currentParamValue, valueProgress, uniqDates } = useDashBoardHelper(
    parameters,
    Number(currentSizeParameter?.id)
  );

  const handleSetParameter = (parameter: TParameterTypeHips | TParameterTypeWaist | TParameterTypeChest) => {
    dispatch(setCurrentSizeParameter(parameter));

    setOpenDropDown(() => false);
  };

  const handleToggleDropDown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenDropDown((prevOpen) => !prevOpen);
  };

  const wrapperRef = useRef(null);

  useEffectOnce(() => {
    if (!currentSizeParameter) {
      dispatch(setCurrentSizeParameter(PARAMETERS_TYPES.chest));
    }
  });

  return (
    <DashBoardContainer onAreaClick={onAreaClick} loading={loading} size={size}>
      <div ref={wrapperRef}>
        <HeaderDashboard
          isEmpty={isEmpty}
          onButtonPlusClick={onButtonPlusClick}
          title={
            <>
              <button className={s.buttonChangeParameter} onClick={handleToggleDropDown}>
                <Typography variant="text4" fontWeight={500} color="white">
                  {sizeHeader}
                </Typography>

                <KeyboardArrowDownIcon color={NamesOfColors.white50} size={16} />
              </button>

              <DropDown
                isOpenDropDown={openDropDown}
                handleSetParameter={handleSetParameter}
                onOpenDropDown={() => setOpenDropDown((prev) => !prev)}
                sizeParameters={sizeParameters}
                currentParameter={currentSizeParameter}
                ref={wrapperRef}
              />
            </>
          }
        />
      </div>

      <ContentDashBoard
        isEmpty={isEmpty}
        unit="см"
        rightTitle="объём"
        isOneParameter={isOneParameter}
        currentParamValue={currentParamValue}
        valueProgress={valueProgress}
      />

      {isOneParameter && <SingleParameterBadge value={currentParamValue} />}

      {!isEmpty && !isOneParameter && (
        <Stack className={s.graphicContainer}>
          <LineGraphic options={uniqDates} />
        </Stack>
      )}

      {(isEmpty || isBtnAdd) && handleButton && <FooterDashBoard onButtonClick={handleButton} textBtn="объём" />}
    </DashBoardContainer>
  );
};

export default memo(DashBoardSizes);
