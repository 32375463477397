import React, { FC, useMemo } from 'react';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, SwiperPicker } from '../..';
import {
  useAppDispatch,
  useBottomSheet,
  useSwiperPickerHelper,
  useLastParameters,
  useSwiperPickerDate,
  useLoadingBottomSheet,
  useAnalytics,
} from '../../../hooks';
import { getValuesDefaultOfWeight } from '../../../utils';
import { Action, AnalyticsName, PARAMETERS_TYPES, ParametersValuesDefault } from '../../../utils/constants/config';
import { setParametersThunk, updateParametersThunk } from '../../../redux/parameters/thunks';
import { format } from 'date-fns';
import { Button, Typography } from 'front-package-ui-kit';
import { Box } from '@mui/material';
import { IParameters } from '../../../api/types';
import { useLocation } from 'react-router-dom';

export const openSetWeightBottomSheet = 'openSetWeightBottomSheet';

const currentDate = new Date();

const defaultValueForSwiperPickerDate = {
  day: currentDate.getDate(),
  month: currentDate.toLocaleString('default', { month: 'long' }),
  year: currentDate.getFullYear(),
};

interface IProps {
  textButtonAccept?: string;
  title?: string;
  parameter?: IParameters;
}

const SetWeightBottomSheet: FC<IProps> = ({ textButtonAccept, title, parameter }) => {
  const { pathname } = useLocation();

  const { setAnalytics } = useAnalytics();

  const dispatch = useAppDispatch();

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openSetWeightBottomSheet);

  const { getAllDaysForInterval } = useSwiperPickerDate(defaultValueForSwiperPickerDate);

  const { weightPicker, handleSetWeight, sizesSwiperValuesKg, sizesSwiperValuesGramm } = useSwiperPickerHelper();

  const [lastSizeWeight] = useLastParameters([PARAMETERS_TYPES.weight.id]);

  const allDaysForInterval = useMemo(() => {
    return getAllDaysForInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultDate = parameter ? format(new Date(parameter.date), 'dd.MM.yy') : format(new Date(), 'dd.MM.yy');

  const defaultWeightKG = parameter
    ? getValuesDefaultOfWeight(parameter?.value, 'kg')
    : lastSizeWeight
    ? getValuesDefaultOfWeight(lastSizeWeight?.value, 'kg')
    : ParametersValuesDefault.weight;

  const defaultWeightGRAMM = parameter
    ? getValuesDefaultOfWeight(parameter?.value, 'gramm')
    : lastSizeWeight
    ? getValuesDefaultOfWeight(lastSizeWeight?.value, 'gramm')
    : 0;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const value = `${weightPicker.kg}.${weightPicker.gramm}`;

    const [day, month, year] =
      weightPicker.date && typeof weightPicker.date === 'string'
        ? weightPicker.date.split('.').map((elem) => Number(elem))
        : [];

    if (parameter) {
      const { date } = parameter;

      const formattedDate = weightPicker.date
        ? format(new Date(Number(`20${year}`), month - 1, day), 'yyyy-MM-dd')
        : format(new Date(date), 'yyyy-MM-dd');

      const newParameter = { ...parameter, value, date: formattedDate, update_at: new Date().toISOString() };

      setAnalytics(AnalyticsName.currentWeight, { pathname, action: Action.edit, value });

      dispatch(updateParametersThunk({ newParameter, oldParameter: parameter }));
    } else {
      setAnalytics(AnalyticsName.currentWeight, { pathname, action: Action.create, value });

      dispatch(
        setParametersThunk({
          value: value,
          parameter_id: PARAMETERS_TYPES.weight.id,
          date:
            weightPicker.date && typeof weightPicker.date === 'string'
              ? format(new Date(Number(`20${year}`), month - 1, day), 'yyyy-MM-dd')
              : format(new Date(), 'yyyy-MM-dd'),
        })
      );
    }

    onCloseBottomSheet();
  };

  const onCloseBottomSheetHandler = () => {
    onCloseBottomSheet();
  };

  const { isLoading, setLoadingHandler } = useLoadingBottomSheet();

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onSpring={setLoadingHandler}
      onDismiss={onCloseBottomSheetHandler}
      header={
        <BottomSheetHeader
          alignItems="center"
          title={
            title && (
              <Box width="100%">
                <Typography variant="text3" textAlign="center" fontWeight={700}>
                  {title}
                </Typography>
              </Box>
            )
          }
          onClose={onCloseBottomSheetHandler}
        />
      }
    >
      <form onSubmit={handleSubmit}>
        <SwiperPicker
          loading={isLoading}
          onChange={handleSetWeight}
          leftPicker={{
            options: allDaysForInterval,
            defaultValue: defaultDate,
          }}
          middlePicker={{
            options: sizesSwiperValuesKg,
            defaultValue: defaultWeightKG,
            unit: 'кг',
          }}
          rightPicker={{
            options: sizesSwiperValuesGramm,
            defaultValue: defaultWeightGRAMM,
            unit: 'гр',
          }}
        />

        <BottomButtonBlock direction="column" rowGap="8px">
          <Button disabled={isLoading} size="L" color="black" variant="contained" type="submit" fullWidth>
            <Typography variant="text6" color="white" fontWeight={700}>
              {textButtonAccept ?? 'Применить'}
            </Typography>
          </Button>

          <Button size="L" onClick={() => onCloseBottomSheet()} type="button" variant="text" fullWidth>
            <Typography variant="text6" fontWeight={700} color="black">
              Назад
            </Typography>
          </Button>
        </BottomButtonBlock>
      </form>
    </BottomSheetUI>
  );
};

export default SetWeightBottomSheet;
