import { useCallback } from 'react';
import { isInternalPath } from '../utils/isInternalPath';
import { useNavigate } from 'react-router-dom';

export const useOpenUrl = () => {
  const navigate = useNavigate();

  const handleOpenUrl = useCallback(
    (link: string) => {
      if (!link) return false;

      if (isInternalPath(link)) {
        navigate(link.replace('#', ''));
      }

      if (!isInternalPath(link)) {
        window.openUrl(link);
      }

      return true;
    },
    [navigate]
  );

  return { handleOpenUrl };
};
