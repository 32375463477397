import React from 'react';

const ArrowDownSmall = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.37756 1.84955C7.65811 1.38036 7.43982 1 6.89341 1H1.10638C0.558436 1 0.342801 1.38224 0.622224 1.84955L3.49191 6.64881C3.77246 7.118 4.22845 7.11612 4.50788 6.64881L7.37756 1.84955Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default ArrowDownSmall;
