import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { BlockCover, Typography } from 'front-package-ui-kit';
import { FC, memo, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IParameters } from '../../../../api/types';
import { DividerUI, StoryCard } from '../../../../components';
import { useAnalytics, useAppSelector, useBottomSheet } from '../../../../hooks';
import { TypesIntervalOfDates } from '../../../../models';
import { Action, AnalyticsName, getParametersByDateInterval, Paths } from '../../../../utils';
import { useStepmeterActions } from '../../hooks/useStepmeterActions';
import { getGoal, getProgress } from '../../model/selectors';
import { IStepmeterProgress } from '../../model/types';
import { formatToLocaleString } from '../../utils/formatToLocaleString';
import { openBottomSheetDeleteAllProgress } from '../BottomSheetDeleteAllProgress';
import { NoStepmeterData } from './NoStepmeterData';

import s from './styles.module.scss';

export const TEST_ID = 'StepmeterProgressHistory';

const { profile, stepmeter } = Paths;

export interface StepmeterProgressHistoryProps {
  interval?: TypesIntervalOfDates;
}

const StepmeterProgressHistory: FC<StepmeterProgressHistoryProps> = ({ interval }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { setAnalytics } = useAnalytics();

  const goal = useAppSelector(getGoal);
  const progressArr = useAppSelector(getProgress);

  const { removeProgressDay } = useStepmeterActions();

  const progressByDateInterval: IStepmeterProgress[] = useMemo(() => {
    const sortedArr = [...progressArr].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    if (sortedArr.length === 0) {
      return [];
    }

    if (!interval) {
      return sortedArr as unknown as IStepmeterProgress[];
    }

    return getParametersByDateInterval(
      sortedArr as unknown as IParameters[],
      interval
    ) as unknown as IStepmeterProgress[];
  }, [interval, progressArr]);

  const { onOpenBottomSheet } = useBottomSheet();

  const handleResetProgress = () => onOpenBottomSheet(openBottomSheetDeleteAllProgress);

  const handleDeleteProgressDay = (id: number) => () => {
    setAnalytics(AnalyticsName.current_distance, {
      action: Action.remove,
      pathname,
      id,
    });
    removeProgressDay(id);
  };

  const handleEditProgressDay = (id: number) => () => navigate(`${profile}${stepmeter}/distance/${id}/edit`);

  return (
    <div className={s.progressContainer} data-testid={TEST_ID}>
      <div className={s.historyHeader}>
        <Typography variant="text3" fontWeight={700}>
          История изменений
        </Typography>

        {Boolean(progressByDateInterval.length) && !interval && (
          <button onClick={handleResetProgress}>
            <Typography variant="text6" opacity={0.6} fontWeight={500}>
              Сбросить
            </Typography>
          </button>
        )}
      </div>

      <BlockCover padding="4px 16px" borderRadius="20px">
        {progressArr.length > 0 &&
          progressByDateInterval.map((item, i) => (
            <div key={item.id}>
              <StoryCard
                onHandleDelete={handleDeleteProgressDay(item.id)}
                onHandleEdit={handleEditProgressDay(item.id)}
                date={format(new Date(item.date), 'd MMM yyyy г.', { locale: ru })}
                measure={
                  <div className={s.mesure}>
                    <Typography variant="text5" fontWeight={500}>
                      {formatToLocaleString(item?.steps)}
                    </Typography>
                    <Typography variant="text5" opacity={0.5} fontWeight={500}>
                      {' / ' + formatToLocaleString(goal) + ' шагов'}
                    </Typography>
                  </div>
                }
              />

              {i + 1 < progressByDateInterval.length && <DividerUI color="grey" />}
            </div>
          ))}

        {progressArr.length === 0 && <NoStepmeterData />}
      </BlockCover>
    </div>
  );
};

export const StepmeterProgressHistoryMemo = memo(StepmeterProgressHistory);
