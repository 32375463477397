import { IProgressWorkout } from '../api/types';
import { WorkoutsProgressStatuses } from './constants/config';

export const getWorkoutProgressStatuse = (
  statuse: WorkoutsProgressStatuses,
  progress: IProgressWorkout | undefined
) => {
  if (statuse === WorkoutsProgressStatuses.notStarted && !progress?.status) return true;

  return statuse === progress?.status;
};
