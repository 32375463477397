import { createSlice, isPending, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { INotification } from '../../api/types';
import { clearServiceNotice, getNotificationsThunk } from './thunks';
import { IStoreBase } from '../../models';
import { Environment, Statuses } from '../../utils';

const environment = window.getEnvironment();

export type TNoticeType = 'warning' | 'success' | 'error' | 'info';

export interface IServiceNotice {
  id: string;
  text: string;
  type: TNoticeType;
  sleepTime: number;
}

export interface INotificationStorage extends Omit<INotification, 'params'> {
  params: Record<string, string>;
}

export interface IServiceNoticeWithOtherInfo {
  notice: IServiceNotice;
  otherInfo: { error?: unknown; pathname: string; anyInfo?: any; forEnvironment: Environment };
}

interface INotifications {
  serviceNotifications: IServiceNotice[];
  notifications: IStoreBase<INotificationStorage[]>;
}

const initialState: INotifications = {
  serviceNotifications: [],
  notifications: { data: [], error: '', statuse: Statuses.idle },
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    createServiceNotice(state, { payload }: PayloadAction<IServiceNoticeWithOtherInfo>) {
      const { notice, otherInfo } = payload;

      if (environment === otherInfo.forEnvironment || otherInfo.forEnvironment === Environment.production) {
        const isHaveNotice = state.serviceNotifications.find((note) => notice.text === note.text);

        if (!isHaveNotice) {
          state.serviceNotifications.push(notice);
        }
      }

      if (otherInfo) console.warn(otherInfo);
    },
    addNotification(state, { payload }: PayloadAction<INotificationStorage>) {
      if (!state.notifications.data) return;

      state.notifications.data = [...state.notifications.data, payload];
    },
    changeNotification(state, { payload }: PayloadAction<INotificationStorage>) {
      if (!state.notifications.data) return;

      const result = state.notifications.data.map((notification) => {
        if (notification.id === payload.id) {
          return payload;
        }

        return notification;
      });

      state.notifications.data = result;
    },
    readAllNotifications(state) {
      if (!state.notifications.data) return;

      const result = state.notifications.data.map((notification) => ({ ...notification, readed_at: 'readed' }));

      state.notifications.data = result;
    },
    removeNotification(state, { payload }: PayloadAction<number>) {
      if (!state.notifications.data?.length) return;

      const result = state.notifications.data.filter(({ id }) => id !== payload);

      state.notifications.data = result;
    },
    removeAllNotifications(state) {
      if (!state.notifications.data?.length) return;

      state.notifications.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearServiceNotice.fulfilled, (state, { payload: noticeId }: PayloadAction<string>) => {
        state.serviceNotifications = state.serviceNotifications.filter((notice) => notice.id !== noticeId);
      })
      .addCase(getNotificationsThunk.fulfilled, (state, { payload }: PayloadAction<INotificationStorage[]>) => {
        state.notifications.error = '';
        state.notifications.statuse = Statuses.succeeded;
        state.notifications.data = payload;
      })
      .addMatcher(isPending(getNotificationsThunk), (state) => {
        state.notifications.error = '';
        state.notifications.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getNotificationsThunk), (state, { payload }) => {
        state.notifications.statuse = Statuses.failed;
        state.notifications.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const {
  createServiceNotice,
  removeNotification,
  addNotification,
  changeNotification,
  readAllNotifications,
  removeAllNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
