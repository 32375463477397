import React from 'react';

const StarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 17.3281L6.59656 20.6144C6.24266 20.8297 5.78128 20.7173 5.56604 20.3634C5.46358 20.1949 5.43158 19.9929 5.47697 19.801L6.93275 13.6465L2.13749 9.52314C1.82342 9.25308 1.78774 8.77954 2.05781 8.46548C2.18636 8.31598 2.36862 8.22311 2.56513 8.20698L8.86825 7.68968L11.308 1.85491C11.4678 1.47276 11.9071 1.2925 12.2893 1.45229C12.4712 1.52836 12.6158 1.673 12.6919 1.85491L15.1317 7.68968L21.4348 8.20698C21.8476 8.24086 22.1548 8.60299 22.1209 9.01582C22.1048 9.21233 22.0119 9.39459 21.8624 9.52314L17.0672 13.6465L18.5229 19.801C18.6183 20.2041 18.3688 20.6082 17.9657 20.7035C17.7738 20.7489 17.5718 20.7169 17.4034 20.6144L12 17.3281Z"
        fill="#FAC12E"
      />
    </svg>
  );
};

export default StarIcon;
