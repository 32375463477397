import * as yup from 'yup';

const minPasswordLength = 6;
const maxPasswordLength = 20;

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Введите пароль')
    .min(minPasswordLength, `Минимальная длина пароля - ${minPasswordLength} символов`)
    .max(maxPasswordLength, `Максимальная длина пароля - ${maxPasswordLength} символов`),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Пароли не совпадают')
    .required('Введите подтверждение пароля'),
});

export const initialValues = {
  password: '',
  confirmPassword: '',
};

export enum InputNames {
  password = 'password',
  confirmPassword = 'confirmPassword',
}

export default validationSchema;
