import React, { FC } from 'react';
import { useAppSelector } from '../../../hooks';
import Notice from '../../molecules/Notice';
import s from './styles.module.scss';
import { Portal } from '../..';

const ServiceNotifications: FC = () => {
  const { serviceNotifications } = useAppSelector((state) => state.notificationsStore);

  return (
    <Portal element={document.getElementById('app') ?? document.body}>
      {!!serviceNotifications.length && (
        <div className={s.notificationsMain}>
          {serviceNotifications.map((notice) => (
            <Notice key={notice.id} {...notice} />
          ))}
        </div>
      )}
    </Portal>
  );
};

export default ServiceNotifications;
