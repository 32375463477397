import * as yup from 'yup';

const validationSchema = yup.object().shape({
  password: yup.string().required('Обязательное поле'),
});

export enum InputNames {
  password = 'password',
}

export default validationSchema;
