type TFormats = 'jpg' | 'jpeg' | 'png' | 'webm' | 'mp4' | 'ogg';

const isFormat = (format: string): format is TFormats => {
  return (
    format === 'jpg' ||
    format === 'webm' ||
    format === 'mp4' ||
    format === 'ogg' ||
    format === 'jpeg' ||
    format === 'png'
  );
};

interface FileType {
  name: string;
}

export const getCoverType = (files: FileType[]) => {
  const initialValue = { img: '', webm: '', mp4: '', ogg: '' };

  const { img, webm, mp4, ogg } = files.reduce((acc, { name: pathName }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
    const [_, videoFormat] = pathName.split('.');

    const videoFormatLowerCase = videoFormat.toLocaleLowerCase();

    if (isFormat(videoFormatLowerCase)) {
      if (videoFormatLowerCase === 'jpg' || videoFormatLowerCase === 'jpeg' || videoFormatLowerCase === 'png') {
        acc.img = pathName;
      } else {
        acc[videoFormatLowerCase] = pathName;
      }
    }

    return acc;
  }, initialValue);

  const isVideo = Boolean(webm) || Boolean(mp4) || Boolean(ogg);

  return { isVideo, img, webm, mp4, ogg };
};
