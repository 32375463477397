import { Box, Stack } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { IBottomNavigationIconProps } from '../../../assets/icons/types';
import BottomNavigationAction from '../../molecules/BottomNavigationAction';
import s from './styles.module.scss';
import cn from 'classnames';

export const TEST_ID = 'BottomNavigationUI';

interface IOption {
  path: string;
  Icon: React.FC<IBottomNavigationIconProps>;
  label: string;
}

interface IProps {
  children: ReactNode;
  options: IOption[];
  theme?: 'light' | 'dark';
}

const BottomNavigationUI: FC<IProps> = ({ children, options, theme = 'light' }) => {
  const { pathname } = useLocation();

  return (
    <Stack direction="column" className={s.bottomNavigationUI} data-testid={TEST_ID}>
      <Box className={s.body} flexGrow={1}>
        {children}
      </Box>

      <Stack
        justifyContent="space-around"
        alignItems="center"
        direction="row"
        className={cn(s.actionsContainer, { [s.actionsContainerDark]: theme === 'dark' })}
      >
        {options.map(({ label, path, Icon }) => (
          <BottomNavigationAction
            theme={theme}
            key={label}
            isAcvive={pathname.includes(path)}
            Icon={Icon}
            label={label}
            path={path}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default BottomNavigationUI;
