import { useContext } from 'react';
import { ModalContext } from '../contexts/ModalContext';

const useBottomSheet = () => {
  const context = useContext(ModalContext);

  if (!context) throw new Error('Modal context must be use inside ModalProvider');

  return context;
};

export default useBottomSheet;
