import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { matcherHelper } from '../utils';
import { IGrade } from '../../api/types';
import { getGradesListThunk } from './thunk';

interface IGrades {
  grades: IGrade[];
  error: string;
  isLoading: boolean;
}

const initialState: IGrades = {
  grades: [],
  error: '',
  isLoading: false,
};

export const gradeSlice = createSlice({
  name: 'grade',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGradesListThunk.fulfilled, (state, { payload }: PayloadAction<IGrade[]>) => {
        state.error = '';
        state.isLoading = false;
        state.grades = payload;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, gradeSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, gradeSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export default gradeSlice.reducer;
