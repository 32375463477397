import React, { FC, ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setTheme } from '../../../redux/theme/slice';
import { LocalStorage } from '../../../utils';

export type TTheme = 'light' | 'dark';

export interface IProps {
  theme: TTheme;
  children: ReactNode;
}

const applyTheme = (selectedTheme: TTheme) => {
  const root = document.querySelector('html')!;

  root.dataset.theme = selectedTheme;
};

const ThemeProvider: FC<IProps> = ({ children, theme: defaultTheme }) => {
  const themeStorage = localStorage.getItem(LocalStorage.theme) as TTheme | null;

  const {
    themeStore: { theme },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const initialize = () => {
    if (themeStorage) {
      applyTheme(themeStorage);

      return dispatch(setTheme(themeStorage));
    }

    applyTheme(defaultTheme);

    dispatch(setTheme(defaultTheme));
  };

  useEffect(() => {
    applyTheme(theme);

    localStorage.setItem(LocalStorage.theme, theme);
  }, [theme]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default ThemeProvider;
