import { Box } from '@mui/material';
import { Button, Typography } from 'front-package-ui-kit';
import { FC, memo } from 'react';

interface IProps {
  onGetAccess: () => void;
}

export const NoAccess: FC<IProps> = memo(({ onGetAccess }) => {
  return (
    <>
      <Box maxWidth="300px" mx="auto">
        <Typography variant="text7" color="white" opacity={0.5} fontWeight={500} textAlign="center">
          Разрешите использование функции для отслеживания целей, предоставив доступ к данным «Здоровье» от Apple
        </Typography>
      </Box>

      <Button variant="outlined" color="white20" size="S" fullWidth onClick={onGetAccess}>
        <Typography variant="text6" color="white" fontWeight={700}>
          Дать доступ
        </Typography>
      </Button>
    </>
  );
});

NoAccess.displayName = 'NoAccess';
