import { createSlice } from '@reduxjs/toolkit';
import { matcherHelper } from '../utils';
import ratingThunk from './thunks';

interface IRating {
  status: string;
  error: string;
  isLoading: boolean;
}

const initialState: IRating = {
  status: '',
  error: '',
  isLoading: false,
};

export const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ratingThunk.fulfilled, (state) => {
        state.error = '';
        state.isLoading = false;
        state.status = 'success';
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, ratingSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, ratingSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

// export const {} = ratingSlice.actions;

export default ratingSlice.reducer;
