import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TParameterTypeChest, TParameterTypeHips, TParameterTypeWaist } from '../../../../../utils';

interface IDashBoardSizes {
  currentSizeParameter: TParameterTypeHips | TParameterTypeWaist | TParameterTypeChest | null;
}

const initialState: IDashBoardSizes = {
  currentSizeParameter: null,
};

export const dashBoardSizesSlice = createSlice({
  name: 'dashBoardSizes',
  initialState,
  reducers: {
    setCurrentSizeParameter(
      state,
      { payload }: PayloadAction<TParameterTypeHips | TParameterTypeWaist | TParameterTypeChest>
    ) {
      state.currentSizeParameter = payload;
    },
  },
});

export const { setCurrentSizeParameter } = dashBoardSizesSlice.actions;

export default dashBoardSizesSlice.reducer;
