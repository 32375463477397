import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { Environment, noticeCreator } from '../../utils';
import { createServiceNotice } from '../notifications/slice';
import { getTextError } from '../utils';

export const getBannersThunk = createAsyncThunk('bannerOnMain/getBanners', async (_, { rejectWithValue, dispatch }) => {
  try {
    const res = (await api.getBanners()).data.data;

    return res;
  } catch (error) {
    const textError = getTextError(error);

    dispatch(
      createServiceNotice({
        notice: noticeCreator(textError, 'error'),
        otherInfo: { error, pathname: 'bannerOnMain/getBanners', forEnvironment: Environment.development },
      })
    );

    return rejectWithValue(textError);
  }
});
