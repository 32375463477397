import React, { useState, useRef } from 'react';

import { Typography } from 'front-package-ui-kit';

import s from './styles.module.scss';

const PIN_LENGTH = 4;

export const TEST_ID = 'PinInputUi';

export interface PinInputUIProps {
  onChange: (value: string) => void;
  onComplete?: (value: string) => void;
  initialValue?: string;
  error?: string;
  onFocus?: () => void;
}

const PinInputUI: React.FC<PinInputUIProps> = ({
  onChange,
  onComplete,
  initialValue = '',
  error,
  onFocus,
  ...props
}) => {
  const [values, setValues] = useState<string[]>(Array(PIN_LENGTH).fill(''));
  const inputsRef = useRef<HTMLInputElement[]>([]);

  // Handling input value change
  const handleChange = (index: number, value: string) => {
    const updatedValues = [...values];
    updatedValues[index] = value.slice(-1); // Take the last character of the entered value
    setValues(updatedValues);
    onChange(updatedValues.join(''));

    // If the last character is entered, call onComplete
    if (updatedValues.join('').length === PIN_LENGTH) {
      onComplete?.(updatedValues.join(''));
    }

    // Move focus to the next field if the input is not the last one
    if (value && index < PIN_LENGTH - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  // Keystroke handling to control Backspace and block letters
  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    // Blocks input of any alphabetic characters, including "e"
    if (e.key === 'e' || (isNaN(Number(e.key)) && e.key !== 'Backspace')) {
      e.preventDefault();
    }

    // Move focus back to previous input when pressing Backspace
    if (e.key === 'Backspace' && !values[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData('text').slice(0, PIN_LENGTH);
    const newValues = Array(PIN_LENGTH).fill('');

    for (let i = 0; i < Math.min(pastedData.length, PIN_LENGTH); i++) {
      newValues[i] = pastedData[i];
    }

    setValues(newValues);
    onChange(newValues.join(''));

    if (newValues.join('').length === PIN_LENGTH) {
      onComplete?.(newValues.join(''));
    }

    // Focus on the next field after pasting
    if (pastedData.length < PIN_LENGTH) {
      const nextIndex = pastedData.length;
      if (inputsRef.current[nextIndex]) {
        inputsRef.current[nextIndex].focus();
      }
    }
  };

  React.useEffect(() => {
    if (initialValue) {
      const initialValues = initialValue.split('').slice(0, PIN_LENGTH);
      setValues(initialValues);

      if (initialValues.length === PIN_LENGTH) {
        onComplete?.(initialValues.join(''));
      }
    }
  }, [initialValue, onComplete]);

  return (
    <div className={s.pinInputContainer} data-testid={TEST_ID} data-error={Boolean(error)} {...props}>
      <div className={s.pinInputs}>
        {values.map((value, index) => (
          <input
            key={index}
            type="number"
            inputMode="numeric"
            autoComplete={index === 0 ? 'one-time-code' : 'off'}
            maxLength={1}
            className={s.pinInput}
            value={value}
            onFocus={onFocus}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={index === 0 ? handlePaste : undefined}
            ref={(el) => (inputsRef.current[index] = el!)}
            aria-label={`Введите цифру ${index + 1} из 4 для подтверждения`}
            placeholder="-"
            autoFocus={index === 0}
          />
        ))}
      </div>
      {Boolean(error) && (
        <div className={s.pinError}>
          <Typography variant="text7" color="error">
            {error}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PinInputUI;
