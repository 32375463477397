import { useMemo } from 'react';
import { IWorkoutDetailse } from '../api/types';
import { Statuses, WorkoutsProgressStatuses } from '../utils';
import { useAppSelector } from '.';

const getCompletedWorkouts = (workouts: IWorkoutDetailse[]) => {
  return workouts.filter(
    (workout) =>
      workout.progress?.status === WorkoutsProgressStatuses.completed ||
      workout.progress?.status === WorkoutsProgressStatuses.restarted
  );
};

export const useStoryWorkouts = () => {
  const {
    workoutsStore: {
      storyWorkouts: { data: storyWorkouts, statuse: storyWorkoutsStatuse },
      fullStoryWorkouts: { data: fullStoryWorkouts, statuse: fullStoryWorkoutsStatuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ workoutsStore: state.workoutsStore, authStore: state.authStore }));

  const storyCompletedWorkouts = useMemo(() => {
    return getCompletedWorkouts(storyWorkouts ?? []);
  }, [storyWorkouts]);

  const fullStoryCompletedWorkouts = useMemo(() => {
    return getCompletedWorkouts(fullStoryWorkouts ?? []);
  }, [fullStoryWorkouts]);

  const isStoryWorkouts = Boolean(storyWorkouts?.length);

  const isFullStoryWorkouts = Boolean(fullStoryCompletedWorkouts?.length);

  const isStoryCompletedWorkouts = Boolean(storyCompletedWorkouts.length);

  const isFullStoryCompletedWorkouts = Boolean(fullStoryCompletedWorkouts.length);

  const isStoryWorkoutsLoading =
    ((storyWorkoutsStatuse === Statuses.idle || storyWorkoutsStatuse === Statuses.loading) && !isStoryWorkouts) ||
    isLoading;

  const isFullStoryWorkoutsLoading =
    ((fullStoryWorkoutsStatuse === Statuses.idle || fullStoryWorkoutsStatuse === Statuses.loading) &&
      !isFullStoryWorkouts) ||
    isLoading;

  return {
    storyWorkouts,
    storyCompletedWorkouts,
    isStoryWorkouts,
    isStoryWorkoutsLoading,
    isStoryCompletedWorkouts,
    fullStoryWorkouts,
    fullStoryCompletedWorkouts,
    isFullStoryWorkouts,
    isFullStoryWorkoutsLoading,
    isFullStoryCompletedWorkouts,
  };
};
