import React from 'react';
import { FC } from 'react';

const ErrorIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Status / Error">
        <path
          id="i"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12ZM10.75 16.2428C10.75 15.5202 11.2991 15 11.9928 15C12.7009 15 13.25 15.5202 13.25 16.2428C13.25 16.9653 12.7009 17.5 11.9928 17.5C11.2991 17.5 10.75 16.9653 10.75 16.2428ZM11.3223 6C11.0376 6 10.8103 6.23741 10.8228 6.52185L11.129 13.5219C11.1407 13.7892 11.3609 14 11.6285 14H12.3713C12.639 14 12.8591 13.7892 12.8708 13.5219L13.1771 6.52185C13.1895 6.23741 12.9623 6 12.6776 6H11.3223Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ErrorIcon;
