import React, { FC, useEffect } from 'react';
import s from './styles.module.scss';
import { useAppDispatch, useBottomSheet, useLastParameters, useSwiperPickerHelper } from '../../../../hooks';
import { PARAMETERS_TYPES, ParametersValuesDefault } from '../../../../utils/constants/config';
import { getValuesDefaultOfSizes } from '../../../../utils';
import { getParametersThunk, setParametersThunk } from '../../../../redux/parameters/thunks';
import { useFormik } from 'formik';
import validationSchema, { InputNames } from './config';
import { format } from 'date-fns';
import { Stack } from '@mui/material';
import { Button, Typography } from 'front-package-ui-kit';
import { ButtonNext, Input } from '..';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, SwiperPicker } from '../../../../components';

const openOnboardingSetHeightParametersBottomSheet = 'openOnboardingSetHeightParametersBottomSheet';

export const TEST_ID = 'SetHeight';

interface IProps {
  handleStep: () => void;
  stepNumber: number;
  numberOfSteps: number;
}

const SetHeight: FC<IProps> = ({ handleStep, stepNumber, numberOfSteps }) => {
  const { onOpenBottomSheet, onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const { setSizePicker, sizePicker, handleSetSize, sizesSwiperValuesCm, sizesSwiperValuesMm } =
    useSwiperPickerHelper();

  const [lastSizeHeight] = useLastParameters([PARAMETERS_TYPES.height.id]);

  const isOpenOnboardingSetParametersBottomSheet = getIsOpenBottomSheet(openOnboardingSetHeightParametersBottomSheet);

  const dispatch = useAppDispatch();

  const initialValues = {
    height: lastSizeHeight ? lastSizeHeight.value : '',
  };

  useEffect(() => {
    dispatch(getParametersThunk());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit, errors, getFieldProps, setFieldValue, setErrors, values } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: ({ height }) => {
      const dateRequest = format(new Date(), 'yyyy-MM-dd');

      if (height !== initialValues.height)
        dispatch(
          setParametersThunk({
            value: height,
            parameter_id: PARAMETERS_TYPES.height.id,
            date: dateRequest,
          })
        );

      handleStep();
    },
  });

  const onFocusHandler = () => {
    setErrors({});
  };

  const handleChangeSize = () => {
    const valueSize = `${sizePicker.cm}.${sizePicker.mm}`;

    setFieldValue(InputNames.height, valueSize);

    onCloseBottomSheet();
  };

  const handleOpenSwiper = () => {
    onFocusHandler();

    const lastValue = values.height;

    setSizePicker({
      cm: getValuesDefaultOfSizes(lastValue, 'cm') || ParametersValuesDefault.height,
      mm: getValuesDefaultOfSizes(lastValue, 'mm'),
    });

    onOpenBottomSheet(openOnboardingSetHeightParametersBottomSheet);
  };

  const isDisabled = Boolean(Object.values(errors).length) || !Boolean(values.height.length);

  return (
    <form className={s.setHeight} onSubmit={handleSubmit}>
      <Stack marginBottom="24px" padding="0 8px">
        <Typography variant="h3" color="white" fontWeight={700} marginBottom="8px">
          Какой у вас рост?
        </Typography>

        <Typography variant="text6" color="white" fontWeight={400} opacity={0.6}>
          Это второй параметр для расчета индекса массы тела
        </Typography>
      </Stack>

      <Input
        onClick={handleOpenSwiper}
        placeholder="Рост"
        margin="0 0 32px 0"
        value={getFieldProps(InputNames.height).value ? `${+getFieldProps(InputNames.height).value} см` : ''}
        error={errors.height}
        readOnly
        autoComplete="false"
      />

      <ButtonNext disabled={isDisabled} stepNumber={stepNumber} numberOfSteps={numberOfSteps} />

      <BottomSheetUI
        open={isOpenOnboardingSetParametersBottomSheet}
        touchLine
        height="auto"
        onDismiss={() => onCloseBottomSheet()}
        header={<BottomSheetHeader onClose={() => onCloseBottomSheet()} />}
        footer={
          <BottomButtonBlock>
            <Button size="L" color="black" onClick={handleChangeSize} variant="contained" fullWidth>
              <Typography variant="text6" color="white" fontWeight={700}>
                Применить
              </Typography>
            </Button>
          </BottomButtonBlock>
        }
      >
        <SwiperPicker
          onChange={handleSetSize}
          leftPicker={{
            options: sizesSwiperValuesCm,
            defaultValue: sizePicker.cm,
            unit: 'см',
          }}
          rightPicker={{
            options: sizesSwiperValuesMm,
            defaultValue: sizePicker.mm,
            unit: 'мм',
          }}
        />
      </BottomSheetUI>
    </form>
  );
};

export default SetHeight;
