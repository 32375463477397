import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITrainer } from '../../api/types';
import { matcherHelper } from '../utils';
import { getTrainersThunk, getTrainerInfoThunk } from './thunks';

export type TTrainers = Record<string, ITrainer>;

interface ITrainerStore {
  trainers: TTrainers;
  error: string;
  isLoading: boolean;
}

const initialState: ITrainerStore = {
  trainers: {},
  error: '',
  isLoading: false,
};

export const trainerSlice = createSlice({
  name: 'trainer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrainerInfoThunk.fulfilled, (state, { payload }: PayloadAction<ITrainer>) => {
        state.error = '';
        state.isLoading = false;
        state.trainers[payload.id] = payload;
      })
      .addCase(getTrainersThunk.fulfilled, (state, { payload }: PayloadAction<TTrainers>) => {
        state.error = '';
        state.isLoading = false;
        state.trainers = payload;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, trainerSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, trainerSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const {} = trainerSlice.actions;

export default trainerSlice.reducer;
