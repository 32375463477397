import React, { FC, useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import s from './styles.module.scss';
import { CssPlatformContainer } from '../../components';
import { Button, CircleSpinnerIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import { NoInternetConnectionIcon } from '../../assets/icons';
import { sleep } from '../../utils';

export const TEST_ID = 'NoInternetConnection';

const NoInternetConnection: FC = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading === false) return;

    (async () => {
      await sleep(2000);

      setLoading(false);
    })();
  }, [loading]);

  return (
    <Container disableGutters maxWidth="xs" className={s.noInternetConnection} data-testid={TEST_ID}>
      <CssPlatformContainer>
        <div className={s.noInternetConnectionContent}>
          <Box marginBottom="16px">
            <NoInternetConnectionIcon />
          </Box>

          <Typography variant="text6" fontWeight={500} marginBottom="16px">
            Проблемы с интернетом
          </Typography>

          <Box width="118px">
            <Button
              onClick={() => setLoading(true)}
              variant="contained"
              fullWidth
              size="M"
              color="black"
              loading={loading}
            >
              {!loading && (
                <Typography variant="text4" fontWeight={500} color="white">
                  Обновить
                </Typography>
              )}

              {loading && <CircleSpinnerIcon size={20} color={NamesOfColors.white100} />}
            </Button>
          </Box>
        </div>
      </CssPlatformContainer>
    </Container>
  );
};

export default NoInternetConnection;
