import React, { createContext, FC, ReactNode, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import NoInternetConnection from '../../pages/NoInternetConnection';

interface IInternetConnectionContext {}

const InternetConnectionContext = createContext<IInternetConnectionContext | null>(null);

interface IProps {
  children: ReactNode;
}

const InternetConnectionContextProvider: FC<IProps> = ({ children }) => {
  const [condition, setCondition] = useState(navigator.onLine);

  function updateOnlineStatus() {
    setCondition(navigator.onLine);
  }

  useEffectOnce(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  });

  return (
    <InternetConnectionContext.Provider value={{}}>
      {condition && children}

      {!condition && <NoInternetConnection />}
    </InternetConnectionContext.Provider>
  );
};

export { InternetConnectionContext, InternetConnectionContextProvider };
