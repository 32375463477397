import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { IUserActivity } from '../../api/types';
import { createtUserActivityThunk, editUserActivityThunk, getUserActivitiesThunk } from './thunks';

export interface StateSchema {
  userActivityStore?: IUserActivities;
}

export interface IUserActivities extends EntityState<IUserActivity> {
  isLoading?: boolean;
  error?: string;
}

const userActivitiesAdapter = createEntityAdapter<IUserActivity>({
  selectId: (activity) => activity.id,
});

export const getUserActivities = userActivitiesAdapter.getSelectors<StateSchema>(
  (state) => state.userActivityStore || userActivitiesAdapter.getInitialState()
);

const userActivitiesSlice = createSlice({
  name: 'userActivitiesSlice',
  initialState: userActivitiesAdapter.getInitialState<IUserActivities>({
    isLoading: false,
    error: undefined,
    ids: [],
    entities: {},
  }),
  reducers: {
    deleteUserActivity(state, action: PayloadAction<number>) {
      userActivitiesAdapter.removeOne(state, action.payload);
    },
    addUserActivity(state, action: PayloadAction<IUserActivity>) {
      userActivitiesAdapter.setOne(state, action.payload);
    },
    editUserActivity(state, action: PayloadAction<IUserActivity>) {
      userActivitiesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserActivitiesThunk.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(getUserActivitiesThunk.fulfilled, (state, action: PayloadAction<IUserActivity[]>) => {
        state.isLoading = false;
        userActivitiesAdapter.setAll(state, action.payload);
      })
      .addCase(getUserActivitiesThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(createtUserActivityThunk.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(createtUserActivityThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        userActivitiesAdapter.setOne(state, action.payload);
      })
      .addCase(createtUserActivityThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(editUserActivityThunk.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(editUserActivityThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        userActivitiesAdapter.updateOne(state, action.payload);
      })
      .addCase(editUserActivityThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = typeof action.payload === 'string' ? action.payload : '';
      });
  },
});

export const { addUserActivity, deleteUserActivity, editUserActivity } = userActivitiesSlice.actions;

export default userActivitiesSlice.reducer;
