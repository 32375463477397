import { PayloadAction, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { getProductsThunk, setProductPromocodeThunk } from './thunks';
import { IProduct } from '../../api/types';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';

export type TProductsWithPromocode = Record<number, IProduct | null>;

interface IOrder {
  products: IStoreBase<IProduct[] | null>;
  productsWithPromocode: IStoreBase<TProductsWithPromocode | null>;
}

const initialState: IOrder = {
  products: { data: null, error: '', statuse: Statuses.idle },
  productsWithPromocode: { data: null, error: '', statuse: Statuses.idle },
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clearProductsWithPromocode(state) {
      state.productsWithPromocode.data = null;
    },

    setProductsWithPromocodeStatusIdle(state) {
      state.productsWithPromocode.statuse = Statuses.idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsThunk.fulfilled, (state, { payload }: PayloadAction<IProduct[]>) => {
        state.products.error = '';
        state.products.statuse = Statuses.succeeded;
        state.products.data = payload;
      })
      .addCase(setProductPromocodeThunk.fulfilled, (state, { payload }: PayloadAction<TProductsWithPromocode>) => {
        state.productsWithPromocode.error = '';
        state.productsWithPromocode.statuse = Statuses.succeeded;
        state.productsWithPromocode.data = payload;
      })
      //getProductsThunk
      .addMatcher(isPending(getProductsThunk), (state) => {
        state.products.error = '';
        state.products.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getProductsThunk), (state, { payload }) => {
        state.products.statuse = Statuses.failed;
        state.products.error = typeof payload === 'string' ? payload : '';
      })
      //setProductPromocodeThunk
      .addMatcher(isPending(setProductPromocodeThunk), (state) => {
        state.productsWithPromocode.error = '';
        state.productsWithPromocode.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(setProductPromocodeThunk), (state, { payload }) => {
        state.productsWithPromocode.statuse = Statuses.failed;
        state.productsWithPromocode.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const { clearProductsWithPromocode, setProductsWithPromocodeStatusIdle } = productsSlice.actions;

export default productsSlice.reducer;
