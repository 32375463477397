import { IParameters } from '../api/types';
import { TypesIntervalOfDates } from '../models';

export const getParametersByDateInterval = (data: IParameters[], interval: TypesIntervalOfDates) => {
  const [startInterval, endInterval] = interval;

  return data.filter(
    (param) =>
      new Date(param.date).getTime() >= startInterval.getTime() &&
      new Date(param.date).getTime() <= endInterval.getTime()
  );
};
