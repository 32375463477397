import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthenticated } from '../../../hooks';
import { Paths } from '../../../utils';

interface IPrivateRouteProps {
  component: FC;
}

const { login } = Paths;

const PrivateRoute: FC<IPrivateRouteProps> = ({ component: Component }) => {
  const { isAuthenticated } = useAuthenticated();

  const { pathname } = useLocation();

  return isAuthenticated ? <Component /> : <Navigate to={login} state={{ from: pathname }} />;
};

export default PrivateRoute;
