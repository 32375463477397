import { FC } from 'react';
import { IBottomNavigationIconProps } from '../types';

interface IProps extends IBottomNavigationIconProps {}

const MainIconNav: FC<IProps> = ({ isAcvive, theme = 'light' }) => {
  return (
    <>
      {!isAcvive && (
        <>
          {theme === 'light' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.65722 20.7714V17.7047C9.6572 16.9246 10.2931 16.2908 11.081 16.2856H13.9671C14.7587 16.2856 15.4005 16.9209 15.4005 17.7047V17.7047V20.7809C15.4003 21.4432 15.9343 21.9845 16.603 22H18.5271C20.4451 22 22 20.4607 22 18.5618V18.5618V9.83784C21.9898 9.09083 21.6355 8.38935 21.038 7.93303L14.4577 2.6853C13.3049 1.77157 11.6662 1.77157 10.5134 2.6853L3.96203 7.94256C3.36226 8.39702 3.00739 9.09967 3 9.84736V18.5618C3 20.4607 4.55488 22 6.47291 22H8.39696C9.08235 22 9.63797 21.4499 9.63797 20.7714V20.7714"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.65722 20.7714V17.7047C9.6572 16.9246 10.2931 16.2908 11.081 16.2856H13.9671C14.7587 16.2856 15.4005 16.9209 15.4005 17.7047V17.7047V20.7809C15.4003 21.4432 15.9343 21.9845 16.603 22H18.5271C20.4451 22 22 20.4607 22 18.5618V18.5618V9.83784C21.9898 9.09083 21.6355 8.38935 21.038 7.93303L14.4577 2.6853C13.3049 1.77157 11.6662 1.77157 10.5134 2.6853L3.96203 7.94256C3.36226 8.39702 3.00739 9.09967 3 9.84736V18.5618C3 20.4607 4.55488 22 6.47291 22H8.39696C9.08235 22 9.63797 21.4499 9.63797 20.7714V20.7714"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </>
      )}

      {isAcvive && (
        <>
          {theme === 'light' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.65722 20.7714V17.7047C9.6572 16.9246 10.2931 16.2908 11.081 16.2856H13.9671C14.7587 16.2856 15.4005 16.9209 15.4005 17.7047V20.7809C15.4003 21.4432 15.9343 21.9845 16.603 22H18.5271C20.4451 22 22 20.4607 22 18.5618V9.83784C21.9898 9.09083 21.6355 8.38935 21.038 7.93303L14.4577 2.6853C13.3049 1.77157 11.6662 1.77157 10.5134 2.6853L3.96203 7.94256C3.36226 8.39702 3.00739 9.09967 3 9.84736V18.5618C3 20.4607 4.55488 22 6.47291 22H8.39696C9.08235 22 9.63797 21.4499 9.63797 20.7714"
                fill="#1D1D1D"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.65722 20.7714V17.7047C9.6572 16.9246 10.2931 16.2908 11.081 16.2856H13.9671C14.7587 16.2856 15.4005 16.9209 15.4005 17.7047V20.7809C15.4003 21.4432 15.9343 21.9845 16.603 22H18.5271C20.4451 22 22 20.4607 22 18.5618V9.83784C21.9898 9.09083 21.6355 8.38935 21.038 7.93303L14.4577 2.6853C13.3049 1.77157 11.6662 1.77157 10.5134 2.6853L3.96203 7.94256C3.36226 8.39702 3.00739 9.09967 3 9.84736V18.5618C3 20.4607 4.55488 22 6.47291 22H8.39696C9.08235 22 9.63797 21.4499 9.63797 20.7714"
                fill="white"
              />
            </svg>
          )}
        </>
      )}
    </>
  );
};

export default MainIconNav;
