import { parseISO } from 'date-fns';
import { IOrderPayment } from '../api/types';
import { TypesStatusesOrderPaid } from './constants/config';

export const getStatusPaid = (orderPaid: IOrderPayment | null) => {
  if (orderPaid && orderPaid.expired_at) {
    return parseISO(orderPaid.expired_at) > new Date();
  }
  return null;
};

export const getPaidOrderByMaxExpired = (orders: IOrderPayment[]) => {
  const paidOrder = orders.filter((order) => order.expired_at && order.status === TypesStatusesOrderPaid.paid);

  if (!paidOrder.length) return null;

  const [lastExpiredDate] = paidOrder.sort((current, prev) => {
    if (current.expired_at && prev.expired_at) {
      return parseISO(prev.expired_at).getTime() - parseISO(current.expired_at).getTime();
    }
    return 0;
  });

  return lastExpiredDate;
};

export const getLastOrderPaid = (orders: IOrderPayment[]) => {
  const paidOrder = orders.filter(
    (order) =>
      order.paid_at &&
      order.product_id &&
      (order.status === TypesStatusesOrderPaid.paid || order.status === TypesStatusesOrderPaid.canceled)
  );

  if (!paidOrder.length) return null;

  const [lastExpiredDate] = paidOrder.sort((current, prev) => {
    if (current.paid_at && prev.paid_at) {
      return parseISO(prev.paid_at).getTime() - parseISO(current.paid_at).getTime();
    }
    return 0;
  });

  return lastExpiredDate;
};
