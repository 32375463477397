import React, { FC } from 'react';
import s from './styles.module.scss';
import cn from 'classnames';
import { getIsValue } from '../../../utils';
import { DividerColor } from './config';
import { Box, BoxProps } from '@mui/material';

export const TEST_ID = 'divider';

export type TDividerColor = 'black' | 'white' | 'grey';

interface IProps extends BoxProps {
  color?: TDividerColor;
}

const DividerUI: FC<IProps> = ({ color, ...props }) => {
  const classes = cn(s.main, {
    [s.black]: getIsValue(color, DividerColor.black),
    [s.grey]: getIsValue(color, DividerColor.grey),
  });

  return <Box data-testid={TEST_ID} className={classes} {...props}></Box>;
};

export default DividerUI;
