import React, { FC } from 'react';
import s from './styles.module.scss';
import { NamesOfColors, Skeleton } from 'front-package-ui-kit';

export const TEST_ID = 'CourseCardSkeleton';

interface IProps {
  theme?: 'light' | 'dark';
}

const CourseCardSkeleton: FC<IProps> = ({ theme = 'light' }) => {
  return (
    <div className={s.courseCardSkeleton} data-testid={TEST_ID}>
      <div className={s.content}>
        <Skeleton width="100%" height="100%" color={theme === 'light' ? NamesOfColors.grey10 : NamesOfColors.white10} />
      </div>
    </div>
  );
};

export default CourseCardSkeleton;
