import React from 'react';

export const NoInternetConnectionIcon = () => {
  return (
    <svg width="119" height="118" viewBox="0 0 119 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="-0.5"
        y="0.5"
        width="117"
        height="117"
        rx="29.5"
        transform="matrix(-1 0 0 1 117.75 0)"
        fill="url(#paint0_linear_261_17184)"
        fillOpacity="0.2"
      />
      <rect
        x="-0.5"
        y="0.5"
        width="117"
        height="117"
        rx="29.5"
        transform="matrix(-1 0 0 1 117.75 0)"
        stroke="url(#paint1_linear_261_17184)"
      />
      <g clipPath="url(#clip0_261_17184)">
        <path
          d="M59.7499 86.4027C63.7583 86.4027 67.0078 83.1532 67.0078 79.1448C67.0078 75.1363 63.7583 71.8868 59.7499 71.8868C55.7414 71.8868 52.4919 75.1363 52.4919 79.1448C52.4919 83.1532 55.7414 86.4027 59.7499 86.4027Z"
          fill="#05F600"
        />
        <path
          d="M59.7501 59.79C53.5129 59.79 47.622 62.8239 43.992 67.9057C42.9563 69.3554 43.2921 71.3701 44.7417 72.4056C45.31 72.8116 45.965 73.007 46.6139 73.007C47.6204 73.007 48.612 72.5371 49.2413 71.6559C51.6632 68.2655 55.5916 66.2416 59.7499 66.2416C63.9085 66.2416 67.8367 68.2655 70.2583 71.6557C71.2936 73.1051 73.3082 73.4415 74.7581 72.4058C76.2078 71.3703 76.5437 69.3556 75.5082 67.9059C71.8785 62.8238 65.9875 59.79 59.7501 59.79Z"
          fill="#05F600"
        />
        <path
          d="M59.75 47.6936C50.8402 47.6936 42.312 51.4978 36.3516 58.1308C35.161 59.4559 35.2698 61.4954 36.5951 62.6863C37.2113 63.24 37.982 63.5127 38.7501 63.5127C39.6336 63.5126 40.5136 63.1519 41.1504 62.443C45.8891 57.1696 52.6684 54.1452 59.7502 54.1452C66.832 54.1452 73.6115 57.1696 78.3503 62.4428C79.5411 63.7679 81.5806 63.8767 82.9056 62.6861C84.2308 61.4953 84.3396 59.4558 83.1489 58.1308C77.1882 51.4977 68.6597 47.6936 59.75 47.6936Z"
          fill="#05F600"
        />
        <path
          d="M90.8263 48.6417C82.5687 40.2297 71.5323 35.597 59.75 35.597C47.9683 35.597 36.9318 40.2297 28.6739 48.642C27.4259 49.9135 27.4448 51.9556 28.7161 53.2036C29.344 53.82 30.16 54.1275 30.9756 54.1274C31.811 54.1274 32.6462 53.8046 33.2777 53.1615C40.3128 45.995 49.7141 42.0484 59.75 42.0484C69.7865 42.0484 79.1878 45.995 86.2222 53.1611C87.4704 54.4326 89.5127 54.4513 90.784 53.2035C92.0552 51.9554 92.0741 49.9131 90.8263 48.6417Z"
          fill="#05F600"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_261_17184"
          x1="118.594"
          y1="20.6253"
          x2="161.355"
          y2="92.0406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="1" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_261_17184"
          x1="8.32947"
          y1="9.28048"
          x2="106.966"
          y2="114.746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_261_17184">
          <rect width="64" height="64" fill="white" transform="translate(27.75 29)" />
        </clipPath>
      </defs>
    </svg>
  );
};
