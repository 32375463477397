import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { IChangeWorkoutProgress, IDateRange } from '../../api/types';
import { Environment, noticeCreator } from '../../utils';
import { createServiceNotice } from '../notifications/slice';
import { getTextError } from '../utils';

export const getWorkoutDetailsThunk = createAsyncThunk(
  'workouts/getWorkoutsDetails',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getWorkoutDetails(id)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'workouts/getWorkoutsDetails', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const changeWorkoutProgressThunk = createAsyncThunk(
  'workouts/changeWorkoutProgress',
  async (config: IChangeWorkoutProgress, { rejectWithValue, dispatch }) => {
    try {
      return (await api.changeWorkoutProgress(config)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'workouts/changeWorkoutProgress', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getFavoriteWorkoutsThunk = createAsyncThunk(
  'workouts/getFavoriteWorkouts',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getFavoritesWorkouts()).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'workouts/getFavoriteWorkouts', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getStoryWorkoutsThunk = createAsyncThunk(
  'workouts/getStoryWorkouts',
  async (config: IDateRange, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getStoryVideos(config)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'workouts/getStoryWorkouts', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getFullStoryWorkoutsThunk = createAsyncThunk(
  'workouts/getFullStoryWorkouts',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getFullStoryVideos()).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'workouts/getFullStoryWorkouts', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const deleteWorkoutFromHistoryThunk = createAsyncThunk(
  'workouts/deleteWorkoutFromHistory',
  async (
    { workoutProgressId, courseProgressId }: { workoutProgressId: number; courseProgressId: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = (await api.removeWorkoutFromHistory(workoutProgressId, courseProgressId)).data;
      dispatch(getFullStoryWorkoutsThunk());
      return result;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'workouts/deleteWorkoutFromHistory', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
