import React, { FC, createRef, memo, useRef } from 'react';
import s from './styles.module.scss';
import { BottomButtonBlock, NavBar, Portal } from '../..';
import Cropper, { ReactCropperElement, ReactCropperProps } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Box, Stack } from '@mui/material';
import { Button, CircleSpinnerIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import { ICrop } from '../../../api/types';
import './styles.scss';

export const TEST_ID = 'CropperImage';

export interface CropperImageProps extends ReactCropperProps {
  isOpen: boolean;
  onSave: (data: { preview: string; crop: ICrop }) => void;
  onCancel: () => void;
  loading: boolean;
}

const CropperImage: FC<CropperImageProps> = ({ isOpen, onSave, onCancel, loading, ...props }) => {
  const cropperRef = createRef<ReactCropperElement>();

  const data = useRef<ICrop>();

  const handleSave = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      cropperRef.current?.cropper.getCroppedCanvas().toDataURL();

      onSave({
        preview: cropperRef.current?.cropper.getCroppedCanvas().toDataURL() ?? '',
        crop: data.current ?? { x: 0, y: 0, height: 0, width: 0 },
      });
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Portal element={document.getElementById('app') ?? document.body}>
      <div className={s.cropperImage} data-testid={TEST_ID}>
        <Box className={s.head}>
          <NavBar goBackButtonProps={{ white: true, onClick: onCancel }} goBackButton />
        </Box>

        <Cropper
          ref={cropperRef}
          style={{ height: '100%', width: '100%' }}
          zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          crop={({ detail: { x, y, height, width } }) => {
            data.current = { x: +x.toFixed(0), y: +y.toFixed(0), height: +height.toFixed(0), width: +width.toFixed(0) };
          }}
          {...props}
        />

        <Stack position="absolute" bottom={0} width="100%">
          <BottomButtonBlock
            bgcolor="#1D1D1D80"
            borderTop="1px solid #FFFFFF1A"
            paddingBottom="32px"
            justifyContent="space-between"
          >
            <Button onClick={onCancel} variant="text" type="button" size="XS" color="white">
              <Typography variant="text6" fontWeight={700} color="white">
                Отмена
              </Typography>
            </Button>

            <Box width="74px">
              <Button
                onClick={handleSave}
                variant="contained"
                type="button"
                size="XS"
                color="white"
                loading={loading}
                fullWidth
              >
                {!loading && (
                  <Typography variant="text6" fontWeight={700}>
                    Готово
                  </Typography>
                )}

                {loading && <CircleSpinnerIcon size={20} color={NamesOfColors.grey100} />}
              </Button>
            </Box>
          </BottomButtonBlock>
        </Stack>
      </div>
    </Portal>
  );
};

export const CropperImageMemo = memo(CropperImage);
