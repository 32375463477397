import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getReferralLinkThunk, getReferralProductThunk, createReferralThunk, getIdsGotGiftsThunk } from './thunks';
import { IGotProduct, IReferralLink, IReferralProduct } from '../../api/types';

interface IReferral {
  referralProduct: IReferralProduct[];
  referralLink: IReferralLink[];
  error: string;
  isLoading: boolean;
  idsGotGifts: number[];
}

const initialState: IReferral = {
  referralProduct: [],
  referralLink: [],
  error: '',
  isLoading: false,
  idsGotGifts: [],
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReferralLinkThunk.fulfilled, (state, { payload }: PayloadAction<IReferralLink[]>) => {
        state.error = '';
        state.isLoading = false;
        state.referralLink = payload;
      })
      .addCase(getReferralProductThunk.fulfilled, (state, { payload }: PayloadAction<IReferralProduct[]>) => {
        state.error = '';
        state.isLoading = false;
        state.referralProduct = payload;
      })
      .addCase(createReferralThunk.fulfilled, (state, { payload }: PayloadAction<IReferralLink>) => {
        state.error = '';
        state.isLoading = false;
        state.referralLink = [...state.referralLink, payload];
      })
      .addCase(getIdsGotGiftsThunk.fulfilled, (state, { payload }: PayloadAction<IGotProduct>) => {
        state.error = '';
        state.isLoading = false;
        state.idsGotGifts = [...payload.referral_program_ids];
      });
  },
});

export default referralSlice.reducer;
