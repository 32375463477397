import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroups } from '../../api/types';
import { getGroupThunk } from './thunk';
import { matcherHelper } from '../utils';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';

interface IGroup extends IStoreBase<IGroups[]> {}

const initialState: IGroup = {
  data: [],
  error: '',
  statuse: Statuses.idle,
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroupThunk.fulfilled, (state, { payload }: PayloadAction<IGroups[]>) => {
        state.error = '';
        state.statuse = Statuses.succeeded;
        state.data = payload;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, groupsSlice.name),
        (state) => {
          state.error = '';
          state.statuse = Statuses.loading;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, groupsSlice.name),
        (state, { payload }) => {
          state.statuse = Statuses.failed;
          state.error = payload;
        }
      );
  },
});

export const {} = groupsSlice.actions;

export default groupsSlice.reducer;
