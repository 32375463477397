import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import {
  INotificationStorage,
  addNotification,
  changeNotification,
  createServiceNotice,
  readAllNotifications,
  removeAllNotifications,
  removeNotification,
} from './slice';
import { Environment, noticeCreator } from '../../utils';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getNotificationsThunk = createAsyncThunk(
  'notifications/getNotifications',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const notifications: INotificationStorage[] = (await api.getNotifications()).data.data.map((notification) => {
        const params = notification.params.reduce((acc, param) => {
          acc[param.name] = param.value;

          return acc;
        }, {} as Record<string, string>);

        return { ...notification, params };
      });

      return notifications;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'notifications/getNotifications', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const readNotificationThunk = createAsyncThunk(
  'notifications/readNotification',
  async (notification: INotificationStorage, { rejectWithValue, dispatch }) => {
    try {
      dispatch(changeNotification({ ...notification, readed_at: 'readed' }));

      const response = (await api.readNotification(notification.id)).data.data;

      if (response === 0) {
        dispatch(changeNotification({ ...notification, readed_at: null }));
      }
    } catch (error) {
      dispatch(changeNotification({ ...notification, readed_at: null }));

      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'notifications/readNotification', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const readAllNotificationsThunk = createAsyncThunk(
  'notifications/readNotification',
  async (notifications: INotificationStorage[], { rejectWithValue, dispatch }) => {
    dispatch(readAllNotifications());

    notifications.forEach(async (notification) => {
      try {
        const response = (await api.readNotification(notification.id)).data.data;

        if (response === 0) {
          dispatch(changeNotification({ ...notification, readed_at: null }));
        }
      } catch (error) {
        dispatch(changeNotification({ ...notification, readed_at: null }));

        const textError = getTextError(error);

        dispatch(
          createServiceNotice({
            notice: noticeCreator(textError, 'error'),
            otherInfo: { error, pathname: 'notifications/readNotification', forEnvironment: Environment.development },
          })
        );

        return rejectWithValue(textError);
      }
    });
  }
);

export const removeNotificationThunk = createAsyncThunk(
  'notifications/removeNotification',
  async (notification: INotificationStorage, { rejectWithValue, dispatch }) => {
    try {
      dispatch(removeNotification(notification.id));

      await api.removeNotification(notification.id);
    } catch (error) {
      dispatch(addNotification(notification));

      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'notifications/removeNotification', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const removeAllNotificationsThunk = createAsyncThunk(
  'notifications/removeNotification',
  async (notifications: INotificationStorage[], { rejectWithValue, dispatch }) => {
    dispatch(removeAllNotifications());

    notifications.forEach((notification) => {
      try {
        api.removeNotification(notification.id);
      } catch (error) {
        dispatch(addNotification(notification));

        const textError = getTextError(error);

        dispatch(
          createServiceNotice({
            notice: noticeCreator(textError, 'error'),
            otherInfo: {
              error,
              pathname: 'notifications/removeAllNotifications',
              forEnvironment: Environment.development,
            },
          })
        );

        return rejectWithValue(textError);
      }
    });
  }
);

export const clearServiceNotice = createAsyncThunk(
  'notifications/clearServiceNotice',
  async ({ id, sleepTime }: { id: string; sleepTime: number }, { rejectWithValue }) => {
    try {
      await sleep(sleepTime);

      return id;
    } catch (error) {
      const textError = getTextError(error);
      return rejectWithValue(textError);
    }
  }
);
