import React, { FC } from 'react';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import s from './styles.module.scss';

const ToolTipContent: FC<TooltipProps<ValueType, NameType>> = ({ payload }) => {
  const value = payload?.length ? payload[0].payload.value : '';

  return <div className={s.toolTipItem}>{value}</div>;
};

export default ToolTipContent;
