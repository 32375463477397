import { useContext } from 'react';
import { InitialContext } from '../contexts/InitialContext';

const usePlatform = () => {
  const context = useContext(InitialContext);

  if (!context) throw new Error('Initial context must be use inside InitialProvider');

  return { platform: context.currentPlatform };
};

export default usePlatform;
