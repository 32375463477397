import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  dateOfBirth: yup.date().nullable(),
});

export enum InputNames {
  dateOfBirth = 'dateOfBirth',
}

export default validationSchema;
