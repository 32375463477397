import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyPrivacyPolicy = lazy(() => import('./PrivacyPolicy'));

export const PrivacyPolicy = () => (
  <Suspense fallback={<Loading />}>
    <LazyPrivacyPolicy />
  </Suspense>
);
