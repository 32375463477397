import { FC } from 'react';
import { getIsValue } from '../../utils';

interface IProps {
  size: '10' | '12' | '16';
}

const InfoIcon: FC<IProps> = ({ size }) => {
  return (
    <>
      {getIsValue(size, '10') && (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
          <path
            d="M0 5C0 7.76142 2.23858 10 5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5ZM8.75 5C8.75 7.07107 7.07107 8.75 5 8.75C2.92893 8.75 1.25 7.07107 1.25 5C1.25 2.92893 2.92893 1.25 5 1.25C7.07107 1.25 8.75 2.92893 8.75 5ZM3.23625 3.81C3.23625 2.775 4.09125 2.145 5.17125 2.145C6.23625 2.145 7.03125 2.775 7.03125 3.705V3.735C7.03125 4.62 6.46125 4.98 5.92875 5.2125L5.79375 5.2725C5.61375 5.355 5.55375 5.4525 5.55375 5.67C5.55375 5.7777 5.46644 5.865 5.35875 5.865H4.83375C4.68463 5.865 4.56375 5.74412 4.56375 5.595C4.56375 5.085 4.74375 4.7625 5.21625 4.5525L5.35125 4.4925C5.80125 4.29 6.04125 4.125 6.04125 3.75C6.04125 3.345 5.69625 3.075 5.17125 3.075C4.63125 3.075 4.22625 3.345 4.22625 3.855C4.22625 3.95441 4.14566 4.035 4.04625 4.035H3.46125C3.33699 4.035 3.23625 3.93426 3.23625 3.81ZM5.05875 6.18C4.65375 6.18 4.34625 6.4725 4.34625 6.8925C4.34625 7.3125 4.65375 7.605 5.05875 7.605C5.46375 7.605 5.77125 7.3125 5.77125 6.8925C5.77125 6.4725 5.46375 6.18 5.05875 6.18Z"
            fill="#1D1D1D"
            fillOpacity="0.3"
          />
        </svg>
      )}

      {getIsValue(size, '12') && (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6ZM10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6ZM3.8835 4.572C3.8835 3.33 4.9095 2.574 6.2055 2.574C7.4835 2.574 8.4375 3.33 8.4375 4.446V4.482C8.4375 5.544 7.7535 5.976 7.1145 6.255L6.9525 6.327C6.7365 6.426 6.6645 6.543 6.6645 6.804C6.6645 6.93324 6.55973 7.038 6.4305 7.038H5.8005C5.62156 7.038 5.4765 6.89294 5.4765 6.714C5.4765 6.102 5.6925 5.715 6.2595 5.463L6.4215 5.391C6.9615 5.148 7.2495 4.95 7.2495 4.5C7.2495 4.014 6.8355 3.69 6.2055 3.69C5.5575 3.69 5.0715 4.014 5.0715 4.626C5.0715 4.74529 4.97479 4.842 4.8555 4.842H4.1535C4.00438 4.842 3.8835 4.72112 3.8835 4.572ZM6.0705 7.416C5.5845 7.416 5.2155 7.767 5.2155 8.271C5.2155 8.775 5.5845 9.126 6.0705 9.126C6.5565 9.126 6.9255 8.775 6.9255 8.271C6.9255 7.767 6.5565 7.416 6.0705 7.416Z"
            fill="white"
            fillOpacity="0.3"
          />
        </svg>
      )}

      {getIsValue(size, '16') && (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM7.4432 13.1728C7.9472 13.1728 8.5088 12.9424 9.2 12.0064L9.92901 11.0305C9.93031 11.0287 9.93158 11.027 9.93284 11.0252C10.0182 10.9049 9.9899 10.7382 9.8696 10.6528C9.74277 10.5628 9.56842 10.5832 9.46579 10.7L8.28559 12.0438C8.22991 12.1072 8.1395 12.1271 8.0624 12.0928C7.98746 12.0595 7.94728 11.9772 7.96713 11.8976L9.45307 5.941C9.46685 5.88578 9.46868 5.82826 9.45845 5.77228C9.41874 5.55496 9.21038 5.41098 8.99306 5.4507L6.31269 5.94051C6.20502 5.96019 6.11869 6.04079 6.09168 6.14686L6.02349 6.41465C6.01939 6.43078 6.01731 6.44736 6.01731 6.464C6.01731 6.57446 6.10685 6.664 6.21731 6.664H7.6016L6.4208 11.4016C6.3776 11.5888 6.3056 11.9056 6.3056 12.136C6.3056 12.6688 6.6224 13.1728 7.4432 13.1728ZM8.9072 4.744C9.4688 4.744 9.9008 4.4128 10.016 3.9088C10.0448 3.7936 10.0592 3.664 10.0592 3.5776C10.0592 3.1744 9.7424 2.8 9.152 2.8C8.5904 2.8 8.1584 3.1312 8.0432 3.6352C8.0144 3.7504 8 3.88 8 3.9664C8 4.3696 8.3168 4.744 8.9072 4.744Z"
            fill="#1D1D1D"
            fillOpacity="0.2"
          />
        </svg>
      )}
    </>
  );
};

export default InfoIcon;
