import { bindActionCreators } from '@reduxjs/toolkit';

import { StepmeterActions } from '../model/actions';
import { useAppDispatch } from '../../../hooks';

export const useStepmeterActions = () => {
  const dispatch = useAppDispatch();

  return bindActionCreators(StepmeterActions, dispatch);
};
