import React from 'react';

const PencilIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.86224 4.91182L4.28837 10.4849C4.16854 10.6048 4.07265 10.7463 4.00583 10.902L2.85377 13.0131L5.09671 11.9928C5.25215 11.9259 5.39345 11.8302 5.51307 11.7105L11.0863 6.13623C10.9589 5.91642 10.779 5.68848 10.5441 5.45356C10.3095 5.21894 10.0818 5.03923 9.86224 4.91182ZM10.8261 3.94805C11.0498 4.10594 11.2699 4.29377 11.4869 4.51075C11.7041 4.72798 11.8921 4.94836 12.0501 5.17224L13.0309 4.19128C13.1256 4.09657 13.1544 3.95437 13.104 3.83026C13.0329 3.65504 12.9056 3.47188 12.716 3.28226C12.5264 3.09265 12.3432 2.96533 12.168 2.89422C12.0439 2.84385 11.9017 2.87267 11.807 2.96737L10.8261 3.94805ZM2.78055 10.3762C2.91419 10.0648 3.10597 9.78169 3.34563 9.54207L10.8642 2.0245C11.3378 1.55098 12.0488 1.40692 12.6694 1.65874C13.02 1.80103 13.3493 2.02995 13.6588 2.33945C13.9683 2.64897 14.1972 2.97828 14.3395 3.3289C14.5913 3.94944 14.4473 4.66042 13.9738 5.134L6.45596 12.6532C6.21672 12.8925 5.93412 13.0841 5.62326 13.2177L3.32806 14.2043C2.7361 14.4588 2.04994 14.1851 1.79549 13.5932C1.66916 13.2993 1.66905 12.9664 1.79521 12.6724L2.78055 10.3762Z"
        fill="white"
      />
    </svg>
  );
};

export default PencilIcon;
