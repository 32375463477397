import { addDays, format, isToday } from 'date-fns';
import { HISTORY_DATE_FORMATE } from '../../../utils';

// type THistoryDateDictionary = Record<string, string>;

const getSelectedDay = (foundedGroup: string[][]) => {
  if (Boolean(foundedGroup[0]?.length)) return true;

  return false;
};

const getDayWithoutInterval = (foundedGroup: string[][]) => {
  if (foundedGroup[0]?.length === 1) return true;

  return false;
};

const getFirstDayByInterval = (day: Date, foundedGroup: string[][]) => {
  if (foundedGroup[0]?.length > 1 && foundedGroup[0][0] === format(day, HISTORY_DATE_FORMATE)) return true;
  return false;
};

const getLastDayByInterval = (day: Date, foundedGroup: string[][]) => {
  if (foundedGroup[0]?.length > 1 && foundedGroup[0][foundedGroup[0].length - 1] === format(day, HISTORY_DATE_FORMATE))
    return true;
  return false;
};

export const getStateday = (day: Date, intervals: string[][] | undefined) => {
  const foundedGroup = intervals?.filter((group) => group.includes(format(day, HISTORY_DATE_FORMATE)));

  return {
    isOneDay: foundedGroup ? getDayWithoutInterval(foundedGroup) : false,
    isSelected: foundedGroup ? getSelectedDay(foundedGroup) : false,
    isFirstDay: foundedGroup ? getFirstDayByInterval(day, foundedGroup) : false,
    isLastDay: foundedGroup ? getLastDayByInterval(day, foundedGroup) : false,
    isToday: isToday(day),
  };
};

// export const getIsDateInHistory = (date: Date, historyDateDictionary: THistoryDateDictionary): boolean => {
//   return format(date, FORMAT_SCHEME) in historyDateDictionary;
// };

// export const getHistoryDateDictionary = (storyWorkouts: IWorkoutDetailse[]) => {
//   return storyWorkouts.reduce<THistoryDateDictionary>((acc, { progress }) => {
//     if (!progress) return acc;

//     const date = format(parseISO(progress.date), FORMAT_SCHEME);

//     acc[date] = date;

//     return acc;
//   }, {});
// };

export const dataFormatter = (date: string) => {
  return `${date.charAt(0).toUpperCase()}${date.charAt(1).toUpperCase()}`;
};

export const getGroupOfIntervalsDate = (historyDates: string[]) => {
  const groupsIntervalsDate: string[][] = [];

  let group: string[] = [];

  while (historyDates.length) {
    const currentDate = historyDates.shift();

    if (currentDate) {
      if (
        new Date(historyDates[0]).getTime() !== new Date(addDays(new Date(currentDate), +1)).getTime() ||
        new Date(currentDate).getDay() === 0
      ) {
        group.push(currentDate);
        groupsIntervalsDate.push(group);
        group = [];
        continue;
      } else {
        group.push(currentDate);
        continue;
      }
    }
  }

  return groupsIntervalsDate;
};
