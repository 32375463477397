import { Box, Stack } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { CheckBoxIconOnboarding } from '../../../../assets/icons';
import { AnalyticsName, PARAMETERS_TYPES } from '../../../../utils/constants/config';
import s from './styles.module.scss';
import cn from 'classnames';
import { useAppDispatch, useLevels } from '../../../../hooks';
import { getIsValue, upperCaseFirst } from '../../../../utils';
import { setParametersThunk } from '../../../../redux/parameters/thunks';
import { format } from 'date-fns';
import { Typography } from 'front-package-ui-kit';
import { ButtonNext } from '..';
import { TypesConstants } from '../../../../models';

interface IProps {
  handleStep: () => void;
  userLevel: string | null;
  setUserLevel: (newValue: string) => void;
  stepNumber: number;
  numberOfSteps: number;
  listForCheckBox: TypesConstants;
}

const DefineLevel: FC<IProps> = ({
  handleStep,
  userLevel,
  setUserLevel,
  stepNumber,
  numberOfSteps,
  listForCheckBox,
}) => {
  const dispatch = useAppDispatch();

  const { levels } = useLevels();

  const onChangeHandler = (newValue: string) => () => {
    setUserLevel(newValue);
  };

  const levelOptions = useMemo(() => levels.map(({ title }) => title), [levels]);

  const handleSendTargetsValues = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (userLevel) {
      await dispatch(
        setParametersThunk({
          date: format(new Date(), 'yyyy-MM-dd'),
          parameter_id: PARAMETERS_TYPES.level.id,
          value: userLevel,
        })
      );

      handleStep();
    }
  };

  const isDisabled = !Boolean(userLevel);

  return (
    <Stack onSubmit={handleSendTargetsValues} component="form" className={s.defineLevelContent} flexGrow={1}>
      <Stack marginBottom="24px" padding="0 8px">
        <Typography variant="h3" color="white" fontWeight={700}>
          Определите свой уровень
        </Typography>
      </Stack>

      <div className={s.toggleButtonContainer}>
        {Boolean(levelOptions.length) &&
          levelOptions.map((level, i) => (
            <Stack
              component="button"
              key={level}
              className={cn(s.toggleButton, {
                [s.checked]: getIsValue(userLevel, level),
                [s.unchecked]: !getIsValue(userLevel, level),
              })}
              type="button"
              onClick={onChangeHandler(level)}
              direction="row"
              columnGap="16px"
            >
              <Stack textTransform="none" textAlign="left">
                <Typography variant="text4" color="white" fontWeight={500} marginBottom="4px">
                  {upperCaseFirst(level)}
                </Typography>

                <Box className={s.list}>{listForCheckBox[i]}</Box>
              </Stack>

              <Box className={s.containerToggler}>
                {getIsValue(userLevel, level) && <CheckBoxIconOnboarding type="checked" />}

                {!getIsValue(userLevel, level) && <CheckBoxIconOnboarding type="unchecked" />}
              </Box>
            </Stack>
          ))}
      </div>

      <ButtonNext
        disabled={isDisabled}
        stepNumber={stepNumber}
        numberOfSteps={numberOfSteps}
        analyticInfo={{ name: AnalyticsName.onboardingDefineLevelFinished }}
      />
    </Stack>
  );
};

export default DefineLevel;
