import React, { FC, memo } from 'react';
import s from './styles.module.scss';
import { Stack } from '@mui/material';
import { ImageCover } from '../..';
import { ImagePaths, ResizeType } from '../../../utils';
import defaultCover from '../../../assets/image/defaultCover.png';
import { Typography } from 'front-package-ui-kit';
import { getCountParticipants } from '../../../utils/getCountParticipants';

export const TEST_ID = 'CourseParticipants';

export interface CourseParticipantsProps {
  avatars: string[];
  clientsCount: number;
}

const CourseParticipants: FC<CourseParticipantsProps> = ({ avatars, clientsCount }) => {
  const newAvatars = [...avatars];

  if (avatars.length > 2) newAvatars.length = 2;
  return (
    <Stack className={s.courseParticipants} data-testid={TEST_ID} direction="row">
      {!!newAvatars.length &&
        newAvatars.map((avatar) => (
          <div key={avatar} className={s.participantIcon}>
            <ImageCover
              img={{
                baseApiService: 'BASE',
                imagePath: ImagePaths.clientAvatar,
                name: avatar,
                width: 96,
                height: 96,
                resize: ResizeType.resizeBothCenter,
              }}
              defaultImg={defaultCover}
            />
          </div>
        ))}

      {clientsCount > 0 && (
        <div className={s.badge}>
          <Typography variant="text7" fontWeight={600} color="white">
            {getCountParticipants(clientsCount)}+
          </Typography>
        </div>
      )}

      {clientsCount === 0 && (
        <Typography variant="h4" fontWeight={700} color="white">
          0
        </Typography>
      )}
    </Stack>
  );
};

export const CourseParticipantsMemo = memo(CourseParticipants);
