export const getValuesDefaultOfWeight = (value: string, measure: 'kg' | 'gramm') => {
  if (!value) return 0;

  const [intValue, floatValue]: string[] = value.toString().split('.');

  return measure === 'kg' ? Number(intValue) : Number(floatValue) ?? 0;
};

export const getValuesDefaultOfSizes = (value: string, measure: 'cm' | 'mm') => {
  if (!value) return 0;

  const [intValue, floatValue]: string[] = value.toString().split('.');

  return measure === 'cm' ? Number(intValue) : Number(floatValue) ?? 0;
};
