import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { setSession } from '../../api/jwt';
import { IAuthRequestConfig } from '../../api/types';
import { Environment, noticeCreator } from '../../utils';
import { createServiceNotice } from '../notifications/slice';
import { getTextError } from '../utils';

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (config: IAuthRequestConfig, { rejectWithValue, dispatch }) => {
    try {
      const { data: responseTokens } = await api.login(config);

      setSession(responseTokens);

      return responseTokens;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'auth/login', forEnvironment: Environment.production },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
