import React from 'react';
import { FC } from 'react';
import { TypesCardValues } from '../../../utils';
import VisaIcon from './VisaIcon';
import MasterCardIcon from './MasterCardIcon';
import MirIcon from './MirIcon';
import MaestroIcon from './MaestroIcon';
import DefaultCardIcon from './DefaultCardIcon';

export interface BankCardIconProps {
  bankCardType?: TypesCardValues;
}

export const BankCardIcon: FC<BankCardIconProps> = ({ bankCardType }) => {
  if (bankCardType === TypesCardValues.visa) {
    return <VisaIcon />;
  } else if (bankCardType === TypesCardValues.masterCard) {
    return <MasterCardIcon />;
  } else if (bankCardType === TypesCardValues.mir) {
    return <MirIcon />;
  } else if (bankCardType === TypesCardValues.maestro) {
    return <MaestroIcon />;
  } else if (bankCardType === TypesCardValues.robokassa) {
    return <DefaultCardIcon />;
  } else {
    return <DefaultCardIcon />;
  }
};
