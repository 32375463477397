import { IParameters } from '../api/types';

export const getParametersByID = (parameters: IParameters[], id: number) => {
  return parameters
    .filter(({ parameter_id }) => parameter_id === id)
    .sort(
      (prev, current) =>
        new Date(current.date).getTime() - new Date(prev.date).getTime() ||
        new Date(current.created_at).getTime() - new Date(prev.created_at).getTime()
    );
};
