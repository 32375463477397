import React from 'react';

const Logotip = () => {
  return (
    <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="120" height="120" rx="20" fill="#1D1D1D" />
      <path
        d="M86.6101 56.3081C84.5561 48.8458 80.0892 45.0168 80.0892 45.0168C83.8202 54.4018 77.4698 62.6048 77.4698 62.6048C77.5072 55.7467 71.3051 47.1658 71.3051 47.1658C69.0668 43.5864 67.6767 40.338 66.8617 37.4538C64.1092 27.717 67.9261 22.1431 67.9261 22.1431C36.2364 36.8069 32.7272 55.5963 33.4354 65.4559C34.3391 79.5556 46.1141 90.7145 60.5099 90.7145C74.8725 90.7145 86.6254 79.608 87.5789 65.5552L87.5844 65.4683C87.6219 64.8876 87.6426 64.3014 87.6426 63.7097C87.6426 61.1441 87.2837 58.6599 86.6101 56.3081Z"
        fill="#7FFF00"
      />
    </svg>
  );
};

export default Logotip;
