import React, { FC, memo, useCallback } from 'react';
import { Badge, Button, Typography } from 'front-package-ui-kit';
import { useAppSelector, useBottomSheet, useParseWebView } from '../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, DividerUI } from '../..';
import { Stack } from '@mui/material';
import { ProIcon } from './ProIcon';
import { ValuesOfConstants } from '../../../utils';
import Slider from './Slider';
import s from './styles.module.scss';

export const TEST_ID = 'KolsaProDetailsBottomSheet';
export const openKolsaProDetailsBottomSheet = 'openKolsaProDetailsBottomSheet';

export interface KolsaProDetailsBottomSheetProps {}

const KolsaProDetailsBottomSheet: FC<KolsaProDetailsBottomSheetProps> = () => {
  const { grades } = useAppSelector((state) => state.gradeStore);
  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();
  const isOpen = getIsOpenBottomSheet(openKolsaProDetailsBottomSheet);

  const handleClose = useCallback(() => onCloseBottomSheet(), [onCloseBottomSheet]);

  const [textProgramm, textWorkout] = useParseWebView([
    ValuesOfConstants.bottomSheetPaymentProgramm,
    ValuesOfConstants.bottomSheetPaymentWorkout,
  ]);

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={handleClose}
      expandOnContentDrag
      header={<BottomSheetHeader onClose={handleClose} />}
      footer={
        <BottomButtonBlock>
          <Button size="L" color="lime" onClick={handleClose} variant="contained" fullWidth>
            <Typography variant="text6" fontWeight={700}>
              Бросить вызов!
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <div className={s.kolsaProDetailsBottomSheet}>
        <Stack direction="row" justifyContent="center" alignItems="center" columnGap="8px" marginBottom="24px">
          <Typography variant="h4" fontWeight={800} textTransform="uppercase">
            Kolsa
          </Typography>

          <Badge size="M" color="grey100">
            <Typography color="white" variant="text6" textTransform="uppercase" fontWeight={700} marginRight="2px">
              pro
            </Typography>

            <ProIcon />
          </Badge>
        </Stack>

        <div className={s.containerInform}>
          <div className={s.text}>{textProgramm}</div>

          <DividerUI color="grey" />
        </div>

        <div className={s.containerInform}>
          <div className={s.text}>{textWorkout}</div>

          <DividerUI color="grey" />
        </div>
        <Slider grades={grades} />
      </div>
    </BottomSheetUI>
  );
};

export const KolsaProDetailsBottomSheetMemo = memo(KolsaProDetailsBottomSheet);
