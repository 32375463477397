import * as yup from 'yup';

const nameLength = 2;

const regex = /^(?! )(?!.* $)(?!(?:.* ){nameLength}).+$/;

export const validationSchema = yup.object().shape({
  sex: yup.number().nullable(),
  firstName: yup
    .string()
    .nullable()
    .min(nameLength, `Имя должно содержать не менее ${nameLength} символов`)
    .matches(regex, 'Введите имя без пробелов и символов'),
});

export enum InputNames {
  sex = 'sex',
  firstName = 'firstName',
}

export default validationSchema;
