import React from 'react';
import { FC } from 'react';
import { getIsValue } from '../../utils';

interface IProps {
  type: 1 | 2 | 3;
}

const WarningIcon: FC<IProps> = ({ type }) => {
  return (
    <>
      {getIsValue(type, 1) && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1174 7.9L18.4083 13.6C19.2238 15.0124 19.7643 15.9527 20.0998 16.6801C20.4396 17.417 20.4216 17.6392 20.4197 17.6568C20.3753 18.0796 20.1536 18.4636 19.8096 18.7135C19.7954 18.7239 19.6119 18.8507 18.8038 18.9248C18.0061 18.998 16.9216 19 15.2906 19H8.70885C7.07792 19 5.99336 18.998 5.19567 18.9248C4.3876 18.8507 4.20414 18.7239 4.18987 18.7135C3.84595 18.4636 3.6242 18.0796 3.57977 17.6568C3.57792 17.6392 3.55985 17.417 3.89969 16.6801C4.23516 15.9527 4.77569 15.0124 5.59116 13.6L8.88206 7.9C9.69753 6.48757 10.2415 5.54932 10.7038 4.89509C11.172 4.23234 11.3735 4.13686 11.3896 4.12968C11.778 3.95677 12.2215 3.95677 12.6099 4.12968C12.626 4.13686 12.8275 4.23234 13.2957 4.89509C13.758 5.54932 14.302 6.48757 15.1174 7.9ZM7.15001 6.9C8.74152 4.14342 9.53727 2.76514 10.5762 2.30259C11.4823 1.89914 12.5172 1.89914 13.4233 2.30259C14.4622 2.76514 15.258 4.14343 16.8495 6.9L20.1404 12.6C21.7319 15.3566 22.5277 16.7349 22.4088 17.8659C22.3051 18.8523 21.7877 19.7485 20.9852 20.3316C20.0652 21 18.4737 21 15.2906 21H8.70885C5.52584 21 3.93433 21 3.0143 20.3316C2.21181 19.7485 1.69441 18.8523 1.59072 17.8659C1.47185 16.7349 2.2676 15.3566 3.85911 12.6L7.15001 6.9ZM11.9926 14.5C11.299 14.5 10.7498 15.0202 10.7498 15.7428C10.7498 16.4653 11.299 17 11.9926 17C12.7007 17 13.2498 16.4653 13.2498 15.7428C13.2498 15.0202 12.7007 14.5 11.9926 14.5ZM10.8226 8.02185C10.8102 7.73741 11.0374 7.5 11.3221 7.5H12.6774C12.9621 7.5 13.1894 7.73741 13.1769 8.02185L12.8707 13.0219C12.859 13.2892 12.6388 13.5 12.3712 13.5H11.6284C11.3607 13.5 11.1405 13.2892 11.1289 13.0219L10.8226 8.02185Z"
            fill="#DB2C2C"
          />
        </svg>
      )}
      {getIsValue(type, 2) && (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" width="80" height="80" rx="40" fill="#1D1D1D" />
          <path
            d="M41.0116 47.2333C39.8214 47.2333 38.8214 46.3385 38.6897 45.1556L37 29.9782V26C37 23.7909 38.7909 22 41 22C43.2091 22 45 23.7909 45 26V29.9782L43.3338 45.1521C43.2037 46.3365 42.2031 47.2333 41.0116 47.2333Z"
            fill="#7FFF00"
          />
          <path
            d="M41.0585 56C42.8582 56 44.3171 54.5411 44.3171 52.7414C44.3171 50.9418 42.8582 49.4829 41.0585 49.4829C39.2589 49.4829 37.8 50.9418 37.8 52.7414C37.8 54.5411 39.2589 56 41.0585 56Z"
            fill="#7FFF00"
          />
        </svg>
      )}
      {getIsValue(type, 3) && (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="60" height="60" rx="30" fill="#1D1D1D" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.2104 39.7959C26.2104 37.6823 27.8752 36.1605 29.978 36.1605C32.1247 36.1605 33.7894 37.6823 33.7894 39.7959C33.7894 41.9095 32.1247 43.4736 29.978 43.4736C27.8752 43.4736 26.2104 41.9095 26.2104 39.7959ZM27.9453 15.6841C27.0822 15.6841 26.3933 16.3785 26.431 17.2106L27.3594 31.8367C27.3949 32.6188 28.0624 33.2353 28.8738 33.2353H31.1256C31.937 33.2353 32.6045 32.6188 32.6399 31.8367L33.5684 17.2106C33.6061 16.3785 32.9172 15.6841 32.054 15.6841H27.9453Z"
            fill="#ADFA2E"
          />
        </svg>
      )}
    </>
  );
};

export default WarningIcon;
