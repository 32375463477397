import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '.';
import { getCourseDetailsThunk } from '../redux/courses/thunks';
import { Statuses } from '../utils';

const useCourseDetails = (id: string | null | undefined) => {
  const {
    coursesStore: { coursesDetails },
    authStore: { isLoading: isAuthLoading },
  } = useAppSelector((state) => ({ coursesStore: state.coursesStore, authStore: state.authStore }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) dispatch(getCourseDetailsThunk(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const courseDetails = id ? coursesDetails.data[id] : null;

  const isCoursesDetailsLoading =
    ((coursesDetails.statuse === Statuses.idle || coursesDetails.statuse === Statuses.loading) && !courseDetails) ||
    isAuthLoading;

  return {
    courseDetails,
    coursesDetailsStatuse: coursesDetails.statuse,
    isCourseInCourseDetails: id === null || id === undefined ? false : id in coursesDetails.data,
    isCoursesDetailsLoading,
  };
};

export default useCourseDetails;
