import React from 'react';
import { FC } from 'react';

interface IProps {}

const PlayIcon: FC<IProps> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.88824 3.62114C5.32915 3.28232 4.62195 3.69442 4.62195 4.35902V11.641C4.62195 12.3056 5.32915 12.7177 5.88823 12.3789L12.2108 8.73788C12.759 8.40567 12.759 7.59433 12.2108 7.26212L5.88824 3.62114Z"
      fill="#1D1D1D"
      fillOpacity="0.3"
    />
  </svg>
);

export default PlayIcon;
