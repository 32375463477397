import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';

export const getSocialsThunk = createAsyncThunk('socials/getSocials', async (_, { rejectWithValue }) => {
  try {
    return (await api.getSocials()).data.data;
  } catch (error) {
    const textError = getTextError(error);
    return rejectWithValue(textError);
  }
});
