import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useSingleWorkouts = () => {
  const {
    coursesStore: {
      singleWorkouts: { data: singleWorkouts, statuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ coursesStore: state.coursesStore, authStore: state.authStore }));

  const isSingleWorkouts = Boolean(singleWorkouts?.length);

  const isSingleWorkoutsLoading =
    ((statuse === Statuses.idle || statuse === Statuses.loading) && !isSingleWorkouts) || isLoading;

  const isSingleWorkoutsLoadingCompleted =
    isSingleWorkouts || statuse === Statuses.succeeded || statuse === Statuses.failed;

  return {
    singleWorkouts,
    isSingleWorkouts,
    isSingleWorkoutsLoading,
    isSingleWorkoutsLoadingCompleted,
  };
};
