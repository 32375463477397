import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyOpenSupport = lazy(() => import('./OpenSupport'));

export const OpenSupport = () => (
  <Suspense fallback={<Loading />}>
    <LazyOpenSupport />
  </Suspense>
);
