import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import { createServiceNotice } from '../notifications/slice';
import { Environment, noticeCreator } from '../../utils';

export const getStatusOrderThunk = createAsyncThunk(
  'order/getStatusOrderThunk',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getOrderInform(id)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'order/getStatusOrderThunk', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
