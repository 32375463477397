import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useComplexes = () => {
  const {
    coursesStore: {
      complexes: { data: complexes, statuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ coursesStore: state.coursesStore, authStore: state.authStore }));

  const isComplexes = Boolean(complexes?.length);

  const isComplexesLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isComplexes) || isLoading;

  const isComplexesLoadingCompleted = isComplexes || statuse === Statuses.succeeded || statuse === Statuses.failed;

  return {
    complexes,
    isComplexes,
    isComplexesLoading,
    isComplexesLoadingCompleted,
  };
};
