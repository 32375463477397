import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { matcherHelper } from '../utils';

interface IRegistration {
  registrationPhone: string;
  registrationEmail: string;
  error: string;
  isLoading: boolean;
}

const initialState: IRegistration = {
  registrationPhone: '',
  registrationEmail: '',
  error: '',
  isLoading: false,
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setRegistrationPhone(state, { payload }: PayloadAction<string>) {
      state.registrationPhone = payload;
    },
    setRegistrationEmail(state, { payload }: PayloadAction<string>) {
      state.registrationEmail = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, registrationSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, registrationSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const { setRegistrationPhone, setRegistrationEmail } = registrationSlice.actions;

export default registrationSlice.reducer;
