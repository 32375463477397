import React, { FC } from 'react';
import { Box } from '@mui/material';
import s from './styles.module.scss';
import { Typography } from 'front-package-ui-kit';

interface IProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'className' | 'margin'
  > {
  margin?: string;
  error?: string;
}

const Input: FC<IProps> = ({ margin, error, ...props }) => {
  const isError = Boolean(error);

  return (
    <Box margin={margin}>
      <input className={s.inputComponent} type="text" {...props} />

      {isError && (
        <Typography color="error" variant="text7" marginTop="5px">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default Input;
