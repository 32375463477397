import { FC, memo, ReactNode } from 'react';

import { SwitchToggle, SwitchToggleProps, Typography } from 'front-package-ui-kit';

import s from './styles.module.scss';

export const TEST_ID = 'AutoRenewal';

export interface AutoRenewalProps extends SwitchToggleProps {
  info?: ReactNode;
}

const AutoRenewal: FC<AutoRenewalProps> = ({ info, ...props }) => {
  return (
    <>
      <div className={s.autoRenewal} data-testid={TEST_ID}>
        <Typography variant="text5" fontWeight={500}>
          Автопродление
        </Typography>

        <SwitchToggle {...props} />
      </div>

      {!!info && (
        <Typography opacity={0.5} variant="text6" fontWeight={500}>
          {info}
        </Typography>
      )}
    </>
  );
};

export const AutoRenewalMemo = memo(AutoRenewal);
