import React, { createContext, FC, ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthenticated, usePlatform, useUser } from '../../hooks';
import { format } from 'date-fns';
import api from '../../api';
import { Action, AnalyticsName } from '../../utils/constants/config';

export const TEST_ID = 'AnalyticsContext';

interface EventParametersBase {
  action?: Action;
}

export type EventParameters = Record<string, string | number | boolean> & EventParametersBase;
interface IAnalyticsContext {
  setAnalytics: (event_name: AnalyticsName, event_parameters?: EventParameters) => void;
}

const AnalyticsContext = createContext<IAnalyticsContext | null>(null);

export interface IAnalytics {
  user_type: string;
  user_id: string;
  ip: string; // fix
  browser_version: string; // fix
  device: string;
  screen_width: number;
  screen_height: number;
  event_name: AnalyticsName;
  event_parameters: string;
  event_date: string;
}

const screenWidth = window?.screen.width;
const screenHeight = window?.screen.height;

const initialAnalytics = {
  user_type: 'client',
  user_id: '',
  ip: '',
  browser_version: '',
  device: '',
  screen_width: screenWidth,
  screen_height: screenHeight,
  event_name: '',
  event_parameters: '',
  event_date: '',
};

const getCurrentDate = () => {
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
};

const getAnalyticsObject = (
  event_name: AnalyticsName,
  event_date: string,
  event_parameters: string,
  device: string
) => {
  return { ...initialAnalytics, event_name, event_date, event_parameters, device };
};

const postAnalytics = async (analytics: IAnalytics[]) => {
  if (!analytics.length) return;

  const metriks: TEventList = analytics.map(({ event_name: name, event_parameters: params }) => ({ name, params }));

  try {
    window?.sendToMetrika?.(metriks);
  } catch (error) {
    console.error(error);
  }

  try {
    await api.postAnalytics({ data: analytics });

    analytics.length = 0;
  } catch (error) {
    console.error(error);
  }
};

let postAnalyticsInterval: NodeJS.Timer | null = null;

interface IProps {
  children: ReactNode;
}

const AnalyticsProvider: FC<IProps> = ({ children }) => {
  const { user } = useUser();

  const { platform } = usePlatform();

  const { isAuthenticated } = useAuthenticated();

  const { current: analytics } = useRef<IAnalytics[]>([]);

  const { pathname } = useLocation();

  const setAnalytics = (event_name: AnalyticsName, event_parameters: EventParameters = {}) => {
    analytics.push(getAnalyticsObject(event_name, getCurrentDate(), JSON.stringify(event_parameters), platform));
  };

  useEffect(() => {
    if (postAnalyticsInterval) clearInterval(postAnalyticsInterval);

    if (!isAuthenticated) return;

    postAnalyticsInterval = setInterval(async () => {
      postAnalytics(analytics);
    }, 30000);

    return () => {
      if (postAnalyticsInterval) clearInterval(postAnalyticsInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user) return;

    initialAnalytics.user_id = user.id.toString();
  }, [user]);

  useEffect(() => {
    setAnalytics(AnalyticsName.location, { pathname });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <AnalyticsContext.Provider value={{ setAnalytics }}>{children}</AnalyticsContext.Provider>;
};

export { AnalyticsContext, AnalyticsProvider };
