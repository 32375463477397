export interface IVkAuthRes {
  token: string;
  uuid: string;
  user: { first_name: string; avatar: string };
}

export const getIsVkAuthRes = (res: any): res is IVkAuthRes => {
  return 'token' in res && 'uuid' in res && 'user' in res;
};

export enum SocialAuthState {
  vk = 'vkAuth',
  google = 'googleAuth',
}
