import React from 'react';

const MirIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18" fill="none">
      <rect x="1" y="1" width="28" height="16" rx="3" fill="white" />
      <path
        d="M5 6.0009H6.90529C7.0785 6.0009 7.59813 5.94317 7.82907 6.75147C8.00228 7.2711 8.23322 8.0794 8.57964 9.29186H8.69511C9.04153 8.02167 9.33021 7.15562 9.44568 6.75147C9.67663 5.94317 10.254 6.0009 10.4849 6.0009H12.2748V11.5436H10.4272V8.25261H10.3117L9.33021 11.5436H7.94454L6.96303 8.25261H6.78982V11.5436H5M13.0253 6.0009H14.8729V9.29186H15.0461L16.2585 6.57826C16.4895 6.05864 17.0091 6.0009 17.0091 6.0009H18.7412V11.5436H16.8936V8.25261H16.7782L15.5657 10.9662C15.3348 11.4858 14.7574 11.5436 14.7574 11.5436H13.0253M21.2239 9.86922V11.5436H19.4918V8.65676H25.1499C24.919 9.3496 24.1107 9.86922 23.1869 9.86922"
        fill="#0F754E"
      />
      <path
        d="M25.2653 8.3103C25.4962 7.27105 24.8034 6.00085 23.3023 6.00085H19.3762C19.4917 7.21331 20.5309 8.3103 21.6279 8.3103"
        fill="url(#paint0_linear_4390_3850)"
      />
      <rect
        x="0.75"
        y="0.75"
        width="28.5"
        height="16.5"
        rx="3.25"
        stroke="#1D1D1D"
        strokeOpacity="0.1"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4390_3850"
          x1="24.5725"
          y1="5.25028"
          x2="19.9536"
          y2="5.25028"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F5CD7" />
          <stop offset="1" stopColor="#02AEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MirIcon;
