import { differenceInYears, parseISO } from 'date-fns';
import { IUser } from '../api/types';
import { GendersTypesNumerical } from './constants/config';

const GENDERS = {
  [GendersTypesNumerical.male]: 'male',
  [GendersTypesNumerical.female]: 'female',
} as const;

export const setInitMetrika = (user: IUser) => {
  const dateofBirth = user.dob ? parseISO(user.dob) : null;

  const currentDate = new Date();

  const age = dateofBirth ? differenceInYears(currentDate, dateofBirth) : null;

  const gender = user.sex ? GENDERS[user.sex] : null;

  const countryName = user.country?.name ? user.country.name : null;

  const profileAvatar = user.avatar ? !!user.avatar : null;

  window?.initMetrika?.({
    countryName,
    profileAvatar,
    profileid: user.id,
    name: user.name,
    gender,
    age,
    push: user.push_notify,
  });
};
