type OptionsParameters = { date: number; value: number };

export const getMinMaxValuesParameters = (options: OptionsParameters[]) => {
  const sortedOptionsByValue = [...options].sort((prev, current) => prev.value - current.value);

  const maxValue = sortedOptionsByValue[sortedOptionsByValue.length - 1]?.value;

  const minValue = sortedOptionsByValue[0]?.value;

  return { minValue, maxValue };
};
