import React from 'react';

const VkIcon = () => {
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="56" height="56" rx="16" fill="white" />
      <path
        d="M14.5 19.8334H19.292C19.4494 27.6471 22.9822 30.9568 25.7805 31.6393V19.8333H30.2926V26.5722C33.0559 26.2822 35.9591 23.2113 36.9385 19.8333H41.4507C41.0815 21.5852 40.3458 23.2439 39.2895 24.7058C38.2333 26.1677 36.8792 27.4013 35.312 28.3295C37.0619 29.1769 38.6075 30.377 39.8466 31.8504C41.0857 33.3239 41.9901 35.0371 42.5 36.8768H37.5331C37.0754 35.2788 36.1441 33.8482 34.856 32.7646C33.5678 31.6811 31.9803 30.9928 30.2926 30.7862V36.8768H29.7505C20.184 36.8768 14.7274 30.4792 14.5 19.8334Z"
        fill="#3375F6"
      />
    </svg>
  );
};

export default VkIcon;
