import React, { FC } from 'react';
import s from './styles.module.scss';
import { withAnalytic } from '../../../utils';
import { Box } from '@mui/material';

interface IProps
  extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'className'> {
  size: number;
}

const IconButtonUI: FC<IProps> = ({ children, size, ...props }) => {
  return (
    <Box flexShrink={0} width={size} height={size}>
      <button className={s.iconButtonUI} {...props}>
        {children}
      </button>
    </Box>
  );
};

export default withAnalytic(IconButtonUI);
