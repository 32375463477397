import { COURSE_TYPE_NAMES, FilterNames } from '.';
import { IDurationFiltersSchema } from '../api/types';
import { ICourseWithExtraOptions } from '../redux/courses/slice';

export interface IFilterButton {
  name: string;
  disabled: boolean;
  value: string;
}

export const isDurationIncludesInInterval = (numOfDurationValue: number | null, min: number, max: number) => {
  if (numOfDurationValue === null) return false;

  return numOfDurationValue >= min && numOfDurationValue < max;
};

interface IProps {
  courses: ICourseWithExtraOptions[];
  courseKey: FilterNames;
  durationFiltersSchema: IDurationFiltersSchema[];
}

export const getFilterButtons = ({ courses, courseKey, durationFiltersSchema }: IProps) => {
  const result: IFilterButton[] = [];

  if (courseKey === FilterNames.duration) {
    durationFiltersSchema.forEach(({ name: filterButtonName }) => {
      if (result.findIndex((filt) => filt.value === filterButtonName) < 0)
        result.push({ value: filterButtonName, disabled: false, name: filterButtonName });
    });

    return result;
  }

  if (courseKey === FilterNames.course_type) {
    courses.forEach(({ course_type }) => {
      if (result.find((elem) => elem.value === String(course_type))) return;

      result.push({ value: String(course_type), disabled: false, name: COURSE_TYPE_NAMES[course_type] });
    });

    return result;
  }

  courses.forEach((course) => {
    const filterButtonName = course[courseKey];

    if (
      filterButtonName &&
      typeof filterButtonName === 'string' &&
      result.findIndex((filt) => filt.value === filterButtonName) < 0
    ) {
      result.push({ value: filterButtonName, disabled: false, name: filterButtonName });
    }
  });

  return result;
};
