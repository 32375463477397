import { IParameters } from '../../../api/types';
import { TypesOptionsGraphic } from '../../../models';
import { format } from 'date-fns';

export const getFilteredAndSortedByDate = (parameters: IParameters[], id: number) => {
  return parameters
    .filter(({ parameter_id }) => parameter_id === id)
    .sort(
      (prev, current) =>
        new Date(prev.date).getTime() - new Date(current.date).getTime() ||
        new Date(prev.created_at).getTime() - new Date(current.created_at).getTime()
    );
};

export const getCurrentParam = (parameters: IParameters[], id: number) => {
  const filteredValuesByDate = getFilteredAndSortedByDate(parameters, id);

  if (!filteredValuesByDate.length) return '';

  return `${Number(filteredValuesByDate[filteredValuesByDate.length - 1].value)}`;
};

export const getOptionsByParameterTypeSortedDate = (parameters: IParameters[], id: number) => {
  const filteredValuesByDate = getFilteredAndSortedByDate(parameters, id).map(({ value, date }) => {
    return {
      value: Number(value),
      date: new Date(date).getTime(),
    };
  });

  return filteredValuesByDate;
};

export const getValueProgress = (different: number) => {
  if (different > 0) {
    return `+${different.toFixed(1)}`;
  } else if (different < 0) {
    return different.toFixed(1);
  } else return 0;
};

export const getUniqDateByMaxTime = (parameters: TypesOptionsGraphic[]) => {
  if (1 >= parameters.length) return parameters;

  let currentValue = parameters[parameters.length - 1];

  const uniqDates = [currentValue];

  const minDate = format(new Date(parameters[0].date), 'yyyy-MM-dd');

  for (let i = parameters.length - 1; i >= 0; i--) {
    if (format(new Date(currentValue.date), 'yyyy-MM-dd') !== format(new Date(parameters[i].date), 'yyyy-MM-dd')) {
      if (format(new Date(parameters[i].date), 'yyyy-MM-dd') === minDate) {
        uniqDates.unshift(parameters[0]);
        break;
      }
      currentValue = parameters[i];

      uniqDates.unshift(currentValue);
    }
  }
  return uniqDates;
};
