import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import { TTrainers } from './slice';
import { createServiceNotice } from '../notifications/slice';
import { Environment, noticeCreator } from '../../utils';

export const getTrainerInfoThunk = createAsyncThunk(
  'trainer/getTrainerInfo',
  async (identifier: string, { rejectWithValue, dispatch }) => {
    try {
      const trainer = (await api.getTrainerInfo(identifier)).data.data;

      return trainer;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'trainer/getTrainerInfo', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getTrainersThunk = createAsyncThunk('trainer/getTrainers', async (_, { rejectWithValue, dispatch }) => {
  try {
    const arrOfTrainers = (await api.getTrainers()).data.data;

    const trainers = arrOfTrainers.reduce<TTrainers>((acc, trainer) => {
      acc[trainer.id] = trainer;

      return acc;
    }, {});

    return trainers;
  } catch (error) {
    const textError = getTextError(error);

    dispatch(
      createServiceNotice({
        notice: noticeCreator(textError, 'error'),
        otherInfo: { error, pathname: 'trainer/getTrainers', forEnvironment: Environment.development },
      })
    );

    return rejectWithValue(textError);
  }
});
