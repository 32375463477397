import { PayloadAction, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';

import { IGetAllStoriesResponse } from '../../../api/types';
import { IStoreBase } from '../../../models';
import { Statuses } from '../../../utils';
import { getAllStoriesThunk, getStoryByIdThunk, getStoryViewedThunk } from './thunks';
import { IStory } from './types';

export interface IStoriesState {
  stories: IStoreBase<IStory[]>;
  unwatchedStoriesIds: number[];
  storiesToSetViewed: number[];
  startedViewedStoryId: number | null;
  currentStoryId: number | null;
}

const initialState: IStoriesState = {
  stories: {
    data: [],
    error: '',
    statuse: Statuses.idle,
  },
  unwatchedStoriesIds: [],
  storiesToSetViewed: [],
  startedViewedStoryId: null,
  currentStoryId: null,
};

const orderStories = (stories: IStory[]) =>
  stories.sort((a: IStory, b: IStory) => String(b.created_at).localeCompare(String(a.created_at)));

const viewedStoriesOrder = (stories: IStory[]) =>
  stories.sort((a: IStory, b: IStory) => String(a.viewed).localeCompare(String(b.viewed)));

const sortStories = (stories: IStory[]) => {
  const viewedStories = [];
  const unViewedStories = [];

  for (const story of stories) {
    if (story.viewed === null) {
      unViewedStories.push(story);
    } else {
      viewedStories.push(story);
    }
  }

  return [...orderStories(unViewedStories), ...viewedStoriesOrder(viewedStories)];
};

export const storySlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setStoriesToSetViewed(state, { payload }: PayloadAction<number>) {
      state.storiesToSetViewed = [...state.storiesToSetViewed, payload];
    },
    setStartedViewedStoryId(state, { payload }: PayloadAction<number | null>) {
      state.startedViewedStoryId = payload;
    },
    setCurrentStoryId(state, { payload }: PayloadAction<number | null>) {
      state.currentStoryId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStoriesThunk.fulfilled, (state, { payload }: PayloadAction<IGetAllStoriesResponse>) => {
        state.stories.error = '';
        state.unwatchedStoriesIds = orderStories(payload.data)
          .filter((story: IStory) => story.viewed === null)
          .map((story: IStory) => story.id);
        state.stories.data = sortStories(payload.data);
        state.stories.statuse = Statuses.succeeded;
      })
      .addCase(getStoryByIdThunk.fulfilled, (state, { payload }: PayloadAction<IStory>) => {
        state.stories.error = '';
        const isNewStory = state.stories.data.every((story: IStory) => story.id !== payload.id);
        state.stories.data = isNewStory
          ? [...state.stories.data, payload]
          : state.stories.data.map((story: IStory) => (story.id === payload.id ? payload : story));
        state.stories.statuse = Statuses.succeeded;
      })
      .addCase(getStoryViewedThunk.fulfilled, (state, { meta }) => {
        state.stories.error = '';
        state.unwatchedStoriesIds = state.unwatchedStoriesIds.filter((id) => meta.arg.indexOf(id) === -1);
        state.storiesToSetViewed = state.storiesToSetViewed.filter((id) => meta.arg.indexOf(id) === -1);
        state.stories.data = state.stories.data.map((story: IStory, index) => {
          if (meta.arg.indexOf(story.id) > -1) {
            // Костыль для оптимистичного обновления во избежание повторного запроса
            return { ...story, viewed: new Date().toISOString() + index };
          }
          return story;
        });
        state.stories.data = sortStories(state.stories.data);
        state.stories.statuse = Statuses.succeeded;
      })
      // getAllStoriesThunk
      .addMatcher(isPending(getAllStoriesThunk), (state) => {
        state.stories.error = '';
        state.stories.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getAllStoriesThunk), (state, { payload }) => {
        state.stories.error = typeof payload === 'string' ? payload : '';
        state.stories.statuse = Statuses.failed;
      })
      // getStoryByIdThunk
      .addMatcher(isPending(getStoryByIdThunk), (state) => {
        state.stories.error = '';
        state.stories.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getStoryByIdThunk), (state, { payload }) => {
        state.stories.error = typeof payload === 'string' ? payload : '';
        state.stories.statuse = Statuses.failed;
      })
      // getStoryViewedThunk
      .addMatcher(isPending(getStoryViewedThunk), (state) => {
        state.stories.error = '';
        state.stories.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getStoryViewedThunk), (state, { payload }) => {
        state.stories.error = typeof payload === 'string' ? payload : '';
        state.stories.statuse = Statuses.failed;
      });
  },
});

export const { setStoriesToSetViewed, setStartedViewedStoryId, setCurrentStoryId } = storySlice.actions;
export default storySlice.reducer;
