import React, { FC } from 'react';
import { getIsValue } from '../../utils';

interface IProps {
  sex: 'male' | 'female';
  checked: boolean;
}

const SexIcon: FC<IProps> = ({ sex, checked }) => {
  return (
    <>
      {getIsValue(sex, 'female') && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.739 22.2827C7.5063 22.2827 4.86334 21.9378 3.81013 21.248C3.61623 21.121 3.50565 20.8995 3.52078 20.6682C3.70831 17.8017 6.44574 16.3684 11.7331 16.3684C17.0195 16.3684 19.7606 17.8012 19.9563 20.6668C19.9722 20.8988 19.8615 21.1213 19.6669 21.2486C18.613 21.938 15.9704 22.2827 11.739 22.2827Z"
            fill={checked ? '#7FFF00' : '#FFFFFF4D'}
          />
          <path
            d="M18.6366 13.1983C19.0138 13.0699 18.9432 12.3018 18.6002 12.099C17.5645 11.4866 16.8661 10.3266 16.8661 8.99565V7.51606C16.8661 4.61939 14.7877 2.02371 12.0136 1.87245C9.05647 1.7119 6.61028 4.16473 6.61028 7.21302V8.99559C6.61028 10.3265 5.91188 11.4865 4.87622 12.099C4.53323 12.3018 4.46268 13.0698 4.83988 13.1983C9.32144 14.7244 14.155 14.7245 18.6366 13.1983Z"
            fill={checked ? '#7FFF00' : '#FFFFFF4D'}
          />
        </svg>
      )}

      {getIsValue(sex, 'male') && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.0009 14.6958C8.6697 14.6958 6.29958 11.0314 6.29958 6.76119C6.29958 2.49103 8.32136 -0.00415039 12.0009 -0.00415039C15.6804 -0.00415039 17.6996 2.49103 17.6996 6.76119C17.6996 11.0314 15.332 14.6958 12.0009 14.6958ZM12.0065 23.9958C7.06837 23.9958 3.98491 23.5935 2.75617 22.7887L2.75612 22.7888C2.5299 22.6406 2.40094 22.3822 2.4186 22.1123C2.63738 18.768 5.83104 17.0958 11.9996 17.0958C18.1671 17.0958 21.365 18.7674 21.5934 22.1106L21.5935 22.1106C21.6119 22.3813 21.4828 22.6409 21.2557 22.7895C20.0262 23.5937 16.9431 23.9958 12.0065 23.9958Z"
            fill={checked ? '#7FFF00' : '#FFFFFF4D'}
          />
        </svg>
      )}
    </>
  );
};

export default SexIcon;
