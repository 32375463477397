import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserActivity } from '../../api/types';

interface ICreateActivity {
  currntActivity: IUserActivity | null;
}

const initialState: ICreateActivity = {
  currntActivity: null,
};

export const createActivitySlice = createSlice({
  name: 'createActivity',
  initialState,
  reducers: {
    setCurrntActivity: (state, action: PayloadAction<IUserActivity>) => {
      state.currntActivity = action.payload;
    },
    сlearCurrntActivity: (state) => {
      state.currntActivity = null;
    },
  },
  extraReducers: (builder) => {},
});

export const { setCurrntActivity, сlearCurrntActivity } = createActivitySlice.actions;

export default createActivitySlice.reducer;
