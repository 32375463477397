import { createSlice, isPending, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { IParameters } from '../../api/types';
import { getParametersThunk, setParametersThunk } from './thunks';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';
interface IParametersStore {
  parameters: IStoreBase<IParameters[] | null>;
}

const initialState: IParametersStore = {
  parameters: { data: null, error: '', statuse: Statuses.idle },
};

export const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    addParameter(state, { payload }: PayloadAction<IParameters>) {
      if (!state.parameters.data) return;

      state.parameters.data = [...state.parameters.data, payload];
    },
    removeParameter(state, { payload }: PayloadAction<number>) {
      if (!state.parameters.data) return;

      const result = state.parameters.data.filter((parameter) => parameter.id !== payload);

      state.parameters.data = result;
    },
    updateParameter(state, { payload }: PayloadAction<IParameters>) {
      if (!state.parameters.data) return;

      const result = state.parameters.data.map((param) => {
        if (param.id === payload.id) {
          return {
            ...payload,
          };
        }
        return param;
      });

      state.parameters.data = result;
    },
    clearParameters(state, { payload }: PayloadAction<number>) {
      if (!state.parameters.data) return;

      const result = state.parameters.data.filter((param) => param.parameter_id !== payload);

      state.parameters.data = result;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParametersThunk.fulfilled, (state, { payload }: PayloadAction<IParameters[]>) => {
        state.parameters.error = '';
        state.parameters.statuse = Statuses.succeeded;
        state.parameters.data = payload.map((elem) => {
          return {
            ...elem,
            date: elem.date.replace(/-/g, '/'),
          };
        });
      })
      .addCase(setParametersThunk.fulfilled, (state, { payload }: PayloadAction<IParameters>) => {
        // state.parameters.error = '';
        // state.parameters.statuse = Statuses.succeeded;
        state.parameters.data = state.parameters.data ? [...state.parameters.data, payload] : [payload];
      })
      //getParametersThunk
      .addMatcher(isPending(getParametersThunk), (state) => {
        state.parameters.error = '';
        state.parameters.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getParametersThunk), (state, { payload }) => {
        state.parameters.statuse = Statuses.failed;
        state.parameters.error = typeof payload === 'string' ? payload : '';
      });
    //setParametersThunk
    // .addMatcher(isPending(setParametersThunk), (state) => {
    //   state.parameters.error = '';
    //   state.parameters.statuse = Statuses.loading;
    // })
    // .addMatcher(isRejectedWithValue(setParametersThunk), (state, { payload }) => {
    //   state.parameters.statuse = Statuses.failed;
    //   state.parameters.error = typeof payload === 'string' ? payload : '';
    // });
  },
});

export const { removeParameter, addParameter, updateParameter, clearParameters } = parametersSlice.actions;

export default parametersSlice.reducer;
