import { Box, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBottomNavigationIconProps } from '../../../assets/icons/types';
import s from './styles.module.scss';
import { Paths } from '../../../utils';
import { useNotifications } from '../../../hooks';
import { BadgeCounter, Typography } from 'front-package-ui-kit';

interface IProps {
  path: string;
  Icon: React.FC<IBottomNavigationIconProps>;
  label: React.ReactNode;
  isAcvive: boolean;
  theme?: 'light' | 'dark';
}

const BottomNavigationAction: FC<IProps> = ({ Icon, path, isAcvive, label, theme = 'light' }) => {
  const { isHaveUnreadNotifications, titleCountOfUnreadNotifications } = useNotifications();

  const navigate = useNavigate();

  const isBadge = path === Paths.profile && isHaveUnreadNotifications;

  const onClickHandler = () => {
    if (isAcvive) return;

    navigate(path);
  };

  return (
    <Stack
      component="button"
      onClick={onClickHandler}
      direction="column"
      alignItems="center"
      width="100%"
      bgcolor="transparent"
    >
      <Stack direction="column" alignItems="center" justifyContent="center" className={s.iconContainer}>
        <Icon isAcvive={isAcvive} theme={theme} />

        {isBadge && (
          <Box position="absolute" minWidth="16px" height="16px" top="-2px" left="30px">
            <BadgeCounter color="lime">{titleCountOfUnreadNotifications}</BadgeCounter>
          </Box>
        )}
      </Stack>

      <Typography
        variant="text8"
        fontWeight={500}
        opacity={isAcvive ? 1 : 0.5}
        color={theme === 'light' ? 'black' : 'white'}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default BottomNavigationAction;
