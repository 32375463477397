import * as yup from 'yup';

export const emailValidationSchema = yup.object().shape({
  login: yup.string().email('Неверный e-mail. Попробуйте еще раз').required('Обязательное поле'),
});

export const phoneValidationSchema = yup.object().shape({
  login: yup.string().length(16, 'Неверный номер телефона. Попробуйте еще раз'),
});

export enum InputNames {
  login = 'login',
}

const s = /\S/;

export const MASK = ['+', '7', ' ', s, s, s, ' ', s, s, s, ' ', s, s, ' ', s, s, s];
