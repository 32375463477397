import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyUserAgreement = lazy(() => import('./UserAgreement'));

export const UserAgreement = () => (
  <Suspense fallback={<Loading />}>
    <LazyUserAgreement />
  </Suspense>
);
