import React from 'react';
import { FC } from 'react';

const ClearFiltersIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.79289 2.79289C2.40237 3.18342 2.40237 3.81658 2.79289 4.20711L6.58579 8L2.79289 11.7929C2.40237 12.1834 2.40237 12.8166 2.79289 13.2071C3.18342 13.5976 3.81658 13.5976 4.20711 13.2071L8 9.41421L11.7929 13.2071C12.1834 13.5976 12.8166 13.5976 13.2071 13.2071C13.5976 12.8166 13.5976 12.1834 13.2071 11.7929L9.41421 8L13.2071 4.20711C13.5976 3.81658 13.5976 3.18342 13.2071 2.79289C12.8166 2.40237 12.1834 2.40237 11.7929 2.79289L8 6.58579L4.20711 2.79289C3.81658 2.40237 3.18342 2.40237 2.79289 2.79289Z"
      fill="#1D1D1D"
    />
  </svg>
);

export default ClearFiltersIcon;
