import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileTabButtons } from './config';
import { ALL_TIME_VALUE, IntervalValue } from './components/SetHistoryIntervalBottomSheet';

interface IProfile {
  activityDay: number;
  currentTab: ProfileTabButtons;
  currentHistoryIntervalYear: string;
  currentHistoryIntervalMonth: number | null;
  isRestructuringContent: boolean;
  currentHistoryTitle: string;
  currentHistory: IntervalValue;
}

const initialState: IProfile = {
  activityDay: new Date().getTime(),
  currentTab: ProfileTabButtons.progress,
  currentHistoryIntervalYear: ALL_TIME_VALUE,
  currentHistoryIntervalMonth: null,
  isRestructuringContent: false,
  currentHistoryTitle: '',
  currentHistory: { workouts: [], activities: [] },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setActivityDay(state, { payload }: PayloadAction<number>) {
      state.activityDay = payload;
    },
    setCurrentTab(state, { payload }: PayloadAction<ProfileTabButtons>) {
      state.currentTab = payload;
    },
    setCurrentHistoryIntervalYear(state, { payload }: PayloadAction<string>) {
      state.currentHistoryIntervalYear = payload;
    },
    setCurrentHistoryIntervalMonth(state, { payload }: PayloadAction<number | null>) {
      state.currentHistoryIntervalMonth = payload;
    },
    setIsRestructuringContent(state, { payload }: PayloadAction<boolean>) {
      state.isRestructuringContent = payload;
    },
    setCurrentHistoryTitle(state, { payload }: PayloadAction<string>) {
      state.currentHistoryTitle = payload;
    },
    setCurrentHistory(state, { payload }: PayloadAction<IntervalValue>) {
      state.currentHistory = payload;
    },
  },
});

export const {
  setActivityDay,
  setCurrentTab,
  setCurrentHistoryIntervalYear,
  setCurrentHistoryIntervalMonth,
  setIsRestructuringContent,
  setCurrentHistoryTitle,
  setCurrentHistory,
} = profileSlice.actions;

export default profileSlice.reducer;
