import React, { FC } from 'react';
import { Stack, StackProps } from '@mui/material';
import { RatingIcon } from '../../../assets/icons';
import s from './style.module.scss';

export type TRatings = 1 | 2 | 3 | 4 | 5;
interface IProps extends StackProps {
  currentRating: 0 | TRatings;
  setCurrentRating: (rating: TRatings) => void;
  size: 'small' | 'medium';
}

const ratings = [1, 2, 3, 4, 5] as const;

const RatingBar: FC<IProps> = ({ currentRating, setCurrentRating, size, ...props }) => {
  const onRatingClickHandler = (rating: TRatings) => () => {
    setCurrentRating(rating);
  };

  return (
    <Stack display="flex" direction="row" width="100%" justifyContent="center" {...props}>
      {ratings.map((rating) => (
        <button className={s.buttonContainer} key={rating} onClick={onRatingClickHandler(rating)}>
          <RatingIcon isAcvive={currentRating >= rating} size={size} />
        </button>
      ))}
    </Stack>
  );
};

export default RatingBar;
