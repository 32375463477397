import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { ArrowRightIcon, CircularProgressbar } from 'front-package-ui-kit';
import { ButtonWithAnalytic } from '../../../../components';
import { IAnalyticInfo } from '../../../../utils';

interface IProps {
  disabled: boolean;
  stepNumber: number;
  numberOfSteps: number;
  analyticInfo?: IAnalyticInfo;
}

const ButtonNext: FC<IProps> = ({ numberOfSteps, stepNumber, disabled, analyticInfo }) => {
  const isCompleted = numberOfSteps === stepNumber;

  return (
    <Stack alignItems="end">
      {!isCompleted && (
        <CircularProgressbar
          indicatorWidth={1}
          number={numberOfSteps}
          numberOfCompleted={stepNumber}
          size={68}
          trackWidth={1}
        >
          <ButtonWithAnalytic
            borderType="circle"
            buttonType="iconButton"
            color="white"
            size="L"
            variant="contained"
            type="submit"
            theme="dark"
            disabled={disabled}
            analyticInfo={analyticInfo}
          >
            <ArrowRightIcon size={20} />
          </ButtonWithAnalytic>
        </CircularProgressbar>
      )}

      {isCompleted && (
        <ButtonWithAnalytic
          borderType="circle"
          buttonType="iconButton"
          color="lime"
          size="L"
          variant="contained"
          type="submit"
          theme="dark"
          disabled={disabled}
          analyticInfo={analyticInfo}
        >
          <ArrowRightIcon size={20} />
        </ButtonWithAnalytic>
      )}
    </Stack>
  );
};

export default ButtonNext;
