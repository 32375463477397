import { Stack } from '@mui/material';
import React, { MouseEvent, FC, ReactNode } from 'react';
import s from './styles.module.scss';
import { AddIcon, NamesOfColors } from 'front-package-ui-kit';
interface IProps {
  onButtonPlusClick?: () => void;
  isEmpty: boolean;
  title: ReactNode;
}

const HeaderDashboard: FC<IProps> = ({ onButtonPlusClick, isEmpty, title }) => {
  const handleButtonPlus = (e: MouseEvent<HTMLButtonElement>) => {
    if (onButtonPlusClick) {
      e.stopPropagation();
      onButtonPlusClick();
    }
  };

  return (
    <Stack direction="row" marginBottom="8px" justifyContent="space-between">
      <Stack columnGap="6px" direction="row" alignItems="center">
        {title}
      </Stack>

      {onButtonPlusClick && !isEmpty && (
        <button className={s.buttonAddParameter} onClick={handleButtonPlus}>
          <AddIcon color={NamesOfColors.white100} size={16} />
        </button>
      )}
    </Stack>
  );
};

export default HeaderDashboard;
