import React from 'react';

const VisaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18" fill="none">
      <rect x="1" y="1" width="28" height="16" rx="3" fill="white" />
      <path
        d="M15.3435 7.55751C15.3321 8.67194 16.1453 9.2938 16.7579 9.66357C17.3873 10.0429 17.5987 10.2862 17.5962 10.6255C17.5915 11.1447 17.0941 11.3738 16.6288 11.3827C15.8168 11.3983 15.3447 11.1112 14.9694 10.894L14.6769 12.5894C15.0534 12.8043 15.7507 12.9918 16.4738 13C18.171 13 19.2814 11.9622 19.2874 10.3532C19.2941 8.31108 17.0071 8.19805 17.0227 7.28529C17.0281 7.00852 17.2413 6.71317 17.7085 6.63807C17.9398 6.60014 18.5781 6.5711 19.3019 6.98394L19.5859 5.34364C19.1967 5.16809 18.6965 5 18.0737 5C16.4762 5 15.3526 6.05192 15.3435 7.55751ZM22.3155 5.14129C22.0055 5.14129 21.7444 5.36522 21.6278 5.70886L19.2034 12.8795H20.8994L21.2369 11.7241H23.3094L23.5052 12.8795H25L23.6955 5.14129H22.3155ZM22.5527 7.23167L23.0422 10.1375H21.7017L22.5527 7.23167ZM13.2872 5.14138L11.9503 12.8794H13.5665L14.9027 5.14119H13.2872M10.8963 5.14119L9.21414 10.4082L8.53367 5.92989C8.45383 5.42996 8.13852 5.14129 7.78836 5.14129H5.03852L5 5.36599C5.56453 5.51773 6.20594 5.76247 6.59453 6.02434C6.83234 6.1843 6.90016 6.32414 6.97828 6.70426L8.26711 12.8795H9.975L12.5934 5.14129L10.8963 5.14119Z"
        fill="#222357"
      />
      <rect
        x="0.75"
        y="0.75"
        width="28.5"
        height="16.5"
        rx="3.25"
        stroke="#1D1D1D"
        strokeOpacity="0.1"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default VisaIcon;
