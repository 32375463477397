import { useOpenUrl, useParseWebView, useUser } from '.';
import { ValuesOfConstants } from '../utils';

const useOnSupportClickHandler = () => {
  const { user } = useUser();

  const { handleOpenUrl } = useOpenUrl();

  const [telegramSupport] = useParseWebView([ValuesOfConstants.telegramSupport]);

  const telegramSupportUrl = typeof telegramSupport === 'string' ? telegramSupport : '';

  const onSupportClickHandler = () => {
    const openSupportResponse = window.openSupport({
      name: user?.name ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
    });

    if (openSupportResponse === true) return true;

    return handleOpenUrl(telegramSupportUrl);
  };

  return { onSupportClickHandler };
};

export default useOnSupportClickHandler;
