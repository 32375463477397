import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyAboutApp = lazy(() => import('./AboutApp'));

export const AboutApp = () => (
  <Suspense fallback={<Loading />}>
    <LazyAboutApp />
  </Suspense>
);
