import { PayloadAction, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { IStoreBase } from '../../models';
import { IBanner } from '../../api/types';
import { Statuses } from '../../utils';
import { getBannersThunk } from './thunks';

interface IBannerState {
  banners: IStoreBase<IBanner[]>;
}

const initialState: IBannerState = {
  banners: {
    data: [],
    error: '',
    statuse: Statuses.idle,
  },
};

export const bannerOnMainSlice = createSlice({
  name: 'bannerOnMain',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBannersThunk.fulfilled, (state, { payload }: PayloadAction<IBanner[]>) => {
        state.banners.error = '';
        state.banners.data = payload.length > 0 ? payload.sort((a, b) => a.sort - b.sort) : payload;
        state.banners.statuse = Statuses.succeeded;
      })
      .addMatcher(isPending(getBannersThunk), (state) => {
        state.banners.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getBannersThunk), (state) => {
        state.banners.statuse = Statuses.failed;
      });
  },
});

export default bannerOnMainSlice.reducer;
