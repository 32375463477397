import React from 'react';
import { FC } from 'react';
import s from './styles.module.scss';
import IconButtonUI from '../../atoms/IconButtonUI';
import CloseIcon from './CloseIcon';
import { NamesOfColors } from 'front-package-ui-kit';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value: string;
  handleChange: (value: string) => void;
  handleClear: () => void;
  theme?: 'dark' | 'light';
}

const SearchInput: FC<Props> = ({ handleChange, handleClear, theme = 'light', value, ...props }) => {
  const onChangeHandle = ({ target: { value: val } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange(val);
  };

  const clearInput = () => {
    handleClear();
  };

  return (
    <div className={s.container} data-theme={theme}>
      <input
        placeholder="Поиск"
        value={value}
        onChange={onChangeHandle}
        className={s.inputComponent}
        type="text"
        {...props}
      />

      {Boolean(value.length) && (
        <IconButtonUI size={24} onClick={clearInput}>
          <CloseIcon fill={theme === 'dark' ? 'white' : '#1D1D1D'} opacity={theme === 'dark' ? '0.5' : '0.3'} />
        </IconButtonUI>
      )}
    </div>
  );
};

export default SearchInput;
