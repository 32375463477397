import React, { FC, useEffect, FocusEvent } from 'react';
import { CssPlatformContainer, NavBar } from '../../../../components';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import s from './styles.module.scss';
import { InputNames, emailValidationSchema } from './config';
import cn from 'classnames';
import { usePlatform } from '../../../../hooks';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { FormInput, Typography } from 'front-package-ui-kit';
import ConfiguredCircleButtonLime from '../ConfiguredCircleButtonLime';
interface IProps {
  step: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onBackClick: () => void;
  email: string;
  loading: boolean;
}

const EnterMailRecovery: FC<IProps> = ({ step, onChange, onBackClick, onSubmit, loading, email }) => {
  const isKeyboardOpen = useDetectKeyboardOpen();

  const { platform } = usePlatform();

  // const isNeedRestructuringContent = platform === 'android' && isKeyboardOpen;

  const isNeedRestructuringContent = false;

  const initialValues = {
    login: email ?? '',
  };

  const { getFieldProps, errors, values, handleSubmit, setFieldValue, setErrors } = useFormik({
    initialValues,
    validateOnChange: false,
    validationSchema: emailValidationSchema,
    onSubmit: () => {
      onSubmit();
    },
  });

  const onFocusHandler = () => {
    setErrors({});
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement, Element>) => {
    getFieldProps(InputNames.login).onBlur(e);
  };

  useEffect(() => {
    onChange(values.login);
  }, [onChange, values.login]);

  const isEmailError = Boolean(Object.entries(errors).length);

  const onChangeHandler = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(InputNames.login, value);
  };

  const disabled = isEmailError || !Boolean(values.login.length);

  return (
    <div className={s.enterMailRecovery}>
      <CssPlatformContainer>
        <NavBar
          marginBottom="23px"
          justifyContent={isNeedRestructuringContent ? 'space-between' : 'flex-end'}
          onBackClick={onBackClick}
          goBackButton
          goBackButtonProps={{ white: true }}
        >
          {isNeedRestructuringContent && (
            <Typography color="white" variant="text4">
              Укажите почту для восстановления
            </Typography>
          )}

          <Typography variant="text4" color="white">
            {step}
          </Typography>
        </NavBar>

        <div className={s.content}>
          {!isNeedRestructuringContent && (
            <Typography marginBottom="40px" variant="h2" color="white" fontWeight={700}>
              Укажите почту <br /> для восстановления
            </Typography>
          )}

          <form onSubmit={handleSubmit}>
            <FormInput
              theme="dark"
              marginBottom="40px"
              type="text"
              inputMode="email"
              label="Введите e-mail"
              error={errors.login}
              {...getFieldProps(InputNames.login)}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
              autoFocus
              autoCapitalize="off"
            />

            <Stack
              className={cn(s.btn, { [s.btnRestructuring]: isNeedRestructuringContent })}
              direction="row"
              justifyContent="flex-end"
            >
              <ConfiguredCircleButtonLime loading={loading} disabled={disabled} />
            </Stack>
          </form>
        </div>
      </CssPlatformContainer>
    </div>
  );
};

export default EnterMailRecovery;
