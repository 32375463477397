import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IServerConectionStore {
  serverConection: boolean;
}

export const initialState: IServerConectionStore = {
  serverConection: true,
};

export const serverConectionSlice = createSlice({
  name: 'serverConection',
  initialState,
  reducers: {
    setServerConection(state, { payload }: PayloadAction<boolean>) {
      state.serverConection = payload;
    },
  },
});

export const { setServerConection } = serverConectionSlice.actions;

export default serverConectionSlice.reducer;
