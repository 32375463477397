import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { setAgreement } from '../user/slice';
import { getTextError } from '../utils';
import { createServiceNotice } from '../notifications/slice';
import { Environment, noticeCreator } from '../../utils';

export const postUserAgreementsThunk = createAsyncThunk(
  'userAgreements/postUserAgreements',
  async (agreements: string[], { rejectWithValue, dispatch }) => {
    try {
      api.setClientAgreement({ document: agreements.join(' ,'), version: '1.0' });

      dispatch(setAgreement(true));

      return [];
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'userAgreements/postUserAgreements', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
