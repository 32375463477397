import { useMemo } from 'react';
import { useUser } from '.';
import { getLastOrderPaid, getPaidOrderByMaxExpired, getStatusPaid } from '../utils';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

export const useGetOrderPaid = () => {
  const { user } = useUser();

  const orderPaid = user ? getPaidOrderByMaxExpired(user.orders) : null;

  const lastOrderPaid = user ? getLastOrderPaid(user.orders) : null;

  const isPaidOrders = !!lastOrderPaid;

  const isPaid = useMemo(() => {
    return getStatusPaid(orderPaid);
  }, [orderPaid]);

  const expiredDays = differenceInCalendarDays(parseISO(orderPaid?.expired_at ?? ''), new Date());

  const expiredDate = orderPaid?.expired_at
    ? format(parseISO(orderPaid.expired_at), 'd MMMM yyyy', { locale: ru })
    : '';

  return { orderPaid, isPaid, expiredDays, lastOrderPaid, isPaidOrders, expiredDate };
};
