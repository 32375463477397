import { useContext } from 'react';
import { AnalyticsContext } from '../contexts/AnalyticsContext';

const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) throw new Error('Analytics context must be use inside AnalyticsProvider');

  return context;
};

export default useAnalytics;
