import { Box } from '@mui/material';
import React, { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Typography, Button } from 'front-package-ui-kit';
import s from './styles.module.scss';
import { upperCaseFirst } from '../../../utils';
import { OPTIONS_INTERVAL } from './helpers/OptionsInterval';

export const TEST_ID = 'RangePickerBar';

interface IProps {
  onChange: (value: string) => void;
}

const RangePickerBar: FC<IProps> = ({ onChange }) => {
  const [currentValue, setCurrentValue] = useState<string>(OPTIONS_INTERVAL[0].value);

  const handleChangeValue = (value: string) => {
    setCurrentValue(value);
    onChange(value);
  };

  return (
    <Box className={s.rangePicker} data-testid={TEST_ID}>
      <Swiper
        className={s.swiper}
        slidesPerView="auto"
        spaceBetween={8}
        watchOverflow={true}
        freeMode
        resistanceRatio={0}
      >
        {OPTIONS_INTERVAL.map(({ option, value }, index) => (
          <SwiperSlide key={`${value}-${index}`}>
            <Button
              variant={value === currentValue ? 'contained' : 'text'}
              borderType="circle"
              onClick={() => handleChangeValue(value)}
              color="white"
              size="S"
            >
              <Typography
                whiteSpace="nowrap"
                color="black"
                opacity={value === currentValue ? 1 : 0.6}
                fontWeight={500}
                variant="text6"
              >
                {upperCaseFirst(option)}
              </Typography>
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default RangePickerBar;
