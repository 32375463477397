import { useMemo } from 'react';
import { IGroups } from '../api/types';
import { IGroupOptions } from '../utils/groupHelpers';
import { useAppSelector } from './redux';
import { Statuses } from '../utils';

const defaultAcc: IGroupOptions = {
  new: [],
  popular: [],
  level: [],
  duration: [],
  target: [],
  marathon: [],
};

const getGroupsByType = (groups: IGroups[]) => {
  return groups.reduce<IGroupOptions>(
    (acc, elem) => {
      if (acc[elem.type]) {
        acc[elem.type] = [...acc[elem.type], elem];
      } else {
        acc[elem.type] = [elem];
      }

      return acc;
    },
    { ...defaultAcc }
  );
};

const getGroupsByID = (groups: IGroups[]) => {
  return groups.reduce((acc, elem) => {
    acc[elem.id] = elem;

    return acc;
  }, {} as { [key: string]: IGroups });
};

const useGroups = () => {
  const { data: groups, statuse } = useAppSelector((state) => state.groupsStore);
  const { isLoading } = useAppSelector((state) => state.authStore);

  const isGroups = Boolean(groups?.length);

  const isGroupsLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isGroups) || isLoading;

  const isGroupsLoadingCompleted = isGroups || statuse === Statuses.succeeded || statuse === Statuses.failed;

  const collections = useMemo(() => {
    return groups.filter(({ type }) => type === 'popular' || type === 'new' || type === 'collection');
  }, [groups]);

  const groupsByType = useMemo(() => {
    return getGroupsByType(groups);
  }, [groups]);

  const groupsByID = useMemo(() => {
    return getGroupsByID(groups);
  }, [groups]);

  return { groupsByType, groupsByID, collections, groups, isGroups, isGroupsLoading, isGroupsLoadingCompleted };
};

export default useGroups;
