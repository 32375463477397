import React, { FC, memo, useMemo } from 'react';
import { TypesOptionsGraphic } from '../../../models';
import { CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area, ResponsiveContainer } from 'recharts';
import { getMinMaxValuesParameters } from '../../../utils';
import ToolTipContent from './ToolTipContent/ToolTipContent';
import s from './styles.module.scss';

export const TEST_ID = 'LineGraphic';

interface IProps {
  options: TypesOptionsGraphic[];
}

const LineGraphic: FC<IProps> = ({ options }) => {
  const { minValue, maxValue } = useMemo(() => {
    return getMinMaxValuesParameters(options);
  }, [options]);

  const differentParams = maxValue - minValue;

  return (
    <ResponsiveContainer
      className={s.responsiveContainer}
      height={options.length > 1 ? 80 : 60}
      width={options.length > 1 ? '100%' : '0%'}
    >
      <AreaChart
        margin={{
          left: options.length > 1 ? -55 : -60,
          right: 5,
          top: options.length > 1 ? 8 : 15,
        }}
        data={options}
      >
        <defs>
          <linearGradient id="areaGraph" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0.323479" stopColor="#FF01E6" />
            <stop offset="1" stopColor="#2C2C2C" />
          </linearGradient>
        </defs>

        <defs>
          <linearGradient
            id="lineGraph"
            x1="3.82504"
            y1="8.67075"
            x2="187.062"
            y2="8.72403"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0682136" stopColor="#FF008A" />
            <stop offset="1" stopColor="#DB00FF" />
          </linearGradient>
        </defs>

        <Area
          type="monotone"
          dataKey="value"
          connectNulls
          isAnimationActive={false}
          stroke="url(#lineGraph)"
          strokeWidth={2}
          strokeLinejoin="round"
          fill="url(#areaGraph)"
        />

        <Tooltip content={<ToolTipContent />} cursor={false} wrapperStyle={{ outline: 'none' }} />

        <CartesianGrid strokeDasharray="3 0" vertical={false} horizontal={false} strokeOpacity={0.3} />

        <XAxis dataKey="date" tick={false} axisLine={false} tickLine={false} />

        <YAxis
          dataKey="value"
          tick={false}
          domain={options.length ? [minValue - differentParams / 3, 'auto'] : [85, 100]}
          axisLine={false}
          tickLine={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default memo(LineGraphic);
