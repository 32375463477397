import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  height: yup.number().typeError('Введите число'),
});

export enum InputNames {
  height = 'height',
}

export default validationSchema;
