import { FilterNames } from '.';
import { ICourseWithExtraOptions } from '../redux/courses/slice';
import { isDurationIncludesInInterval } from './getFilterButtons';

interface IProps {
  course: ICourseWithExtraOptions;
  courseKey: FilterNames;
  filtersValues: string[];
  durationButtonsVariants: Record<string, [number, number]>;
}

export const getFilteredCourse = ({ course, courseKey, filtersValues, durationButtonsVariants }: IProps) => {
  if (!filtersValues.length) return true;

  if (courseKey === FilterNames.duration) {
    const courseKeyValue = course[courseKey];

    if (courseKeyValue === null) return false;

    const isCourseIncludesFiltersValues = filtersValues
      .map((filter) => {
        const duration = course[courseKey];

        const [min, max] = durationButtonsVariants[filter];

        return isDurationIncludesInInterval(duration, min, max);
      })
      .reduce((acc, item) => {
        acc = acc || item;

        return acc;
      }, false);

    return isCourseIncludesFiltersValues;
  }

  if (courseKey === FilterNames.levels) {
    return filtersValues.some((elem) => course[courseKey].find(({ title }) => title === elem));
  }

  if (courseKey === FilterNames.groups) {
    return filtersValues.some((elem) => course[courseKey].includes(Number(elem)));
  }

  if (courseKey === FilterNames.course_type) {
    return filtersValues.some((elem) => course[courseKey] === Number(elem));
  }

  const isCourseIncludesFiltersValues = filtersValues
    .map((filter) => Boolean(course[courseKey]?.toLowerCase().includes(filter.toLowerCase())))
    .reduce((acc, item) => {
      acc = acc || item;

      return acc;
    }, false);

  return isCourseIncludesFiltersValues;
};
