import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { IParameters, ISetSize } from '../../api/types';
import { getTextError } from '../utils';
import { removeParameter, addParameter, updateParameter, clearParameters } from './slice';
import { createServiceNotice } from '../notifications/slice';
import { Environment, noticeCreator } from '../../utils';

type TOldNewParameters = { newParameter: IParameters; oldParameter: IParameters };

export const getParametersThunk = createAsyncThunk(
  'parameters/getParameters',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getParameters()).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'parameters/getParameters', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const setParametersThunk = createAsyncThunk(
  'parameters/setParameters',
  async (config: ISetSize, { rejectWithValue, dispatch }) => {
    try {
      return (await api.setParameters(config)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'parameters/setParameters', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const updateParametersThunk = createAsyncThunk(
  'parameters/updateParameters',
  async ({ newParameter, oldParameter }: TOldNewParameters, { rejectWithValue, dispatch }) => {
    const { id, parameter_id: parameterId, value, date } = newParameter;
    try {
      dispatch(updateParameter(newParameter));

      await api.updateParameters({ id, parameter_id: parameterId, value, date });

      return;
    } catch (error) {
      dispatch(updateParameter(oldParameter));

      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'parameters/updateParameters', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const clearParametersThunk = createAsyncThunk(
  'parameters/updateParameters',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await api.clearParameters({ parameters: [id] });

      dispatch(clearParameters(id));

      return;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'parameters/updateParameters', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const removeParametersThunk = createAsyncThunk(
  'parameters/removeParameters',

  async (parameter: IParameters, { rejectWithValue, dispatch }) => {
    try {
      dispatch(removeParameter(parameter.id));

      await api.removeParameters(parameter.id);
    } catch (error) {
      dispatch(addParameter(parameter));

      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'parameters/removeParameters', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
