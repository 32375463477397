import { createSlice, isPending, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { ICourse, ICourseDetailse, IProgressCourse } from '../../api/types';
import {
  getCourseDetailsThunk,
  getCoursesThunk,
  getFavoritesCoursesThunk,
  getRecommendedThunk,
  getSingleWorkoutsThunk,
  getComplexesThunk,
} from './thunks';
import { CourseProgressStatus, Statuses } from '../../utils';
import { IStoreBase } from '../../models';

type TCoursesDetails = Record<string, ICourseDetailse>;

interface IFavoritesCourses extends IStoreBase<ICourse[]> {
  cache: Record<number, boolean>;
}

export interface ICourseWithExtraOptions extends ICourse {
  haveInventory: string;
}

interface ICourses {
  courses: IStoreBase<ICourseWithExtraOptions[]>;
  singleWorkouts: IStoreBase<ICourseWithExtraOptions[]>;
  complexes: IStoreBase<ICourseWithExtraOptions[]>;
  recommended: IStoreBase<ICourse[]>;
  favoritesCourses: IFavoritesCourses;
  coursesDetails: IStoreBase<TCoursesDetails>;
  myCourses: ICourseWithExtraOptions[];
  completedCourses: ICourseWithExtraOptions[];
}

const initialState: ICourses = {
  courses: { data: [], error: '', statuse: Statuses.idle },
  singleWorkouts: { data: [], error: '', statuse: Statuses.idle },
  complexes: { data: [], error: '', statuse: Statuses.idle },
  recommended: { data: [], error: '', statuse: Statuses.idle },
  favoritesCourses: { data: [], error: '', statuse: Statuses.idle, cache: {} },
  coursesDetails: { data: {}, error: '', statuse: Statuses.idle },
  myCourses: [],
  completedCourses: [],
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    removeFavoriteCourse(state, { payload: { id } }: PayloadAction<{ id: number }>) {
      state.favoritesCourses.cache[id] = false;

      state.favoritesCourses.data = state.favoritesCourses.data.filter((course) => course.id !== id);
    },
    addFavoriteCourse(state, { payload: { id } }: PayloadAction<{ id: number }>) {
      const courseForfavorites = state.courses.data.find((course) => course.id === id);

      if (courseForfavorites) state.favoritesCourses.data = [courseForfavorites, ...state.favoritesCourses.data];

      state.favoritesCourses.cache[id] = true;
    },
    restoreFavoritesCourses(state, { payload }: PayloadAction<IFavoritesCourses>) {
      state.favoritesCourses = payload;
    },
    changeCourseProgress(
      state,
      { payload: { id, progress } }: PayloadAction<{ id: number; progress: IProgressCourse }>
    ) {
      if (!state.coursesDetails.data) return;

      state.courses.data = state.courses.data.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            progress: progress,
          };
        }
        return course;
      });

      state.coursesDetails.data[id].progress = progress;
    },
    setMyCourses(state) {
      state.myCourses = state.courses.data.filter(
        (course) => course.progress?.status === CourseProgressStatus.inProgress
      );
    },
    setCompletedCourses(state) {
      state.completedCourses = state.courses.data.filter(
        (course) => course.progress?.status === CourseProgressStatus.completed
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoursesThunk.fulfilled, (state, { payload }: PayloadAction<ICourseWithExtraOptions[]>) => {
        state.courses.error = '';
        state.courses.statuse = Statuses.succeeded;
        state.courses.data = payload;

        state.myCourses = payload.filter((course) => course.progress?.status === CourseProgressStatus.inProgress);
        state.completedCourses = payload.filter((course) => course.progress?.status === CourseProgressStatus.completed);
      })
      .addCase(getComplexesThunk.fulfilled, (state, { payload }: PayloadAction<ICourseWithExtraOptions[]>) => {
        state.complexes.error = '';
        state.complexes.statuse = Statuses.succeeded;
        state.complexes.data = payload;
      })
      .addCase(getSingleWorkoutsThunk.fulfilled, (state, { payload }: PayloadAction<ICourseWithExtraOptions[]>) => {
        state.singleWorkouts.error = '';
        state.singleWorkouts.statuse = Statuses.succeeded;
        state.singleWorkouts.data = payload;
      })
      .addCase(getRecommendedThunk.fulfilled, (state, { payload }: PayloadAction<ICourse[]>) => {
        state.recommended.error = '';
        state.recommended.statuse = Statuses.succeeded;
        state.recommended.data = payload;
      })
      .addCase(getFavoritesCoursesThunk.fulfilled, (state, { payload }: PayloadAction<ICourse[]>) => {
        state.favoritesCourses.error = '';
        state.favoritesCourses.statuse = Statuses.succeeded;
        state.favoritesCourses.data = payload;
      })
      .addCase(getCourseDetailsThunk.fulfilled, (state, { payload }: PayloadAction<ICourseDetailse>) => {
        state.coursesDetails.error = '';
        state.coursesDetails.statuse = Statuses.succeeded;
        state.coursesDetails.data[payload.id] = payload;
      })
      //getCoursesThunk
      .addMatcher(isPending(getCoursesThunk), (state) => {
        state.courses.error = '';
        state.courses.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getCoursesThunk), (state, { payload }) => {
        state.courses.statuse = Statuses.failed;
        state.courses.error = typeof payload === 'string' ? payload : '';
      })
      //getCourseDetailsThunk
      .addMatcher(isPending(getCourseDetailsThunk), (state) => {
        state.coursesDetails.error = '';
        state.coursesDetails.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getCourseDetailsThunk), (state, { payload }) => {
        state.coursesDetails.statuse = Statuses.failed;
        state.coursesDetails.error = typeof payload === 'string' ? payload : '';
      })
      //getFavoritesCoursesThunk
      .addMatcher(isPending(getFavoritesCoursesThunk), (state) => {
        state.favoritesCourses.error = '';
        state.favoritesCourses.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getCourseDetailsThunk), (state, { payload }) => {
        state.favoritesCourses.statuse = Statuses.failed;
        state.favoritesCourses.error = typeof payload === 'string' ? payload : '';
      })
      //getComplexesThunk
      .addMatcher(isPending(getComplexesThunk), (state) => {
        state.complexes.error = '';
        state.complexes.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getComplexesThunk), (state, { payload }) => {
        state.complexes.statuse = Statuses.failed;
        state.complexes.error = typeof payload === 'string' ? payload : '';
      })
      //getSingleWorkoutsThunk
      .addMatcher(isPending(getSingleWorkoutsThunk), (state) => {
        state.singleWorkouts.error = '';
        state.singleWorkouts.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getSingleWorkoutsThunk), (state, { payload }) => {
        state.singleWorkouts.statuse = Statuses.failed;
        state.singleWorkouts.error = typeof payload === 'string' ? payload : '';
      })
      //getRecommendedThunk
      .addMatcher(isPending(getRecommendedThunk), (state) => {
        state.recommended.error = '';
        state.recommended.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getRecommendedThunk), (state, { payload }) => {
        state.recommended.statuse = Statuses.failed;
        state.recommended.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const {
  changeCourseProgress,
  restoreFavoritesCourses,
  removeFavoriteCourse,
  addFavoriteCourse,
  setMyCourses,
  setCompletedCourses,
} = coursesSlice.actions;

export default coursesSlice.reducer;
