import React, { FC, useState } from 'react';
import { Stack, StackProps } from '@mui/material';
import { SexIcon } from '../../../assets/icons';
import { AnalyticsName, GendersTypesNumerical } from '../../../utils/constants/config';
import cn from 'classnames';
import s from './styles.module.scss';
import { MUIButtonWithAnalytic } from '../..';

export const TEST_ID = 'ToggleSex';

interface IProps extends StackProps {
  handleChange: (value: number) => void;
  currentSex: number | null;
}

const ToggleSex: FC<IProps> = ({ handleChange, currentSex, ...props }) => {
  const [currentValue, setCurrentValue] = useState<number | null>(currentSex);

  const styleOverride = {
    '&.MuiButton-contained': {
      backgroundColor: '#FFFFFF33',
    },
  };

  const isMale = currentValue === GendersTypesNumerical.male;

  const isFemale = currentValue === GendersTypesNumerical.female;

  const handleChangeToggle = (value: number) => {
    setCurrentValue(value);
    handleChange(value);
  };

  return (
    <Stack direction="row" className={s.toggleSex} data-testid={TEST_ID} {...props}>
      <MUIButtonWithAnalytic
        type="button"
        sx={styleOverride}
        className={cn(s.buttonToggle, s.leftRounding)}
        variant={isFemale ? 'contained' : 'outlined'}
        onClick={() => handleChangeToggle(GendersTypesNumerical.female)}
        fullWidth
        disableRipple
        analyticInfo={{ name: AnalyticsName.onboardingAboutSelfChooseFemale }}
      >
        <SexIcon sex="female" checked={isFemale} />
      </MUIButtonWithAnalytic>

      <MUIButtonWithAnalytic
        type="button"
        sx={styleOverride}
        className={cn(s.buttonToggle, s.rightRounding)}
        variant={isMale ? 'contained' : 'outlined'}
        onClick={() => handleChangeToggle(GendersTypesNumerical.male)}
        fullWidth
        disableRipple
        analyticInfo={{ name: AnalyticsName.onboardingAboutSelfChooseSetMale }}
      >
        <SexIcon sex="male" checked={isMale} />
      </MUIButtonWithAnalytic>
    </Stack>
  );
};

export default ToggleSex;
