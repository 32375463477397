import React from 'react';

const GoogleIcon = () => {
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="56" height="56" rx="16" fill="white" />
      <path
        d="M42.2277 25.2581H41.1V25.2H28.5V30.8H36.4121C35.2578 34.0599 32.1561 36.4 28.5 36.4C23.8611 36.4 20.1 32.6389 20.1 28C20.1 23.3611 23.8611 19.6 28.5 19.6C30.6413 19.6 32.5894 20.4078 34.0727 21.7273L38.0326 17.7674C35.5322 15.4371 32.1876 14 28.5 14C20.7685 14 14.5 20.2685 14.5 28C14.5 35.7315 20.7685 42 28.5 42C36.2315 42 42.5 35.7315 42.5 28C42.5 27.0613 42.4034 26.145 42.2277 25.2581Z"
        fill="#FFC107"
      />
      <path
        d="M16.1142 21.4837L20.7139 24.857C21.9585 21.7756 24.9727 19.6 28.5 19.6C30.6413 19.6 32.5894 20.4078 34.0727 21.7273L38.0326 17.7674C35.5322 15.4371 32.1876 14 28.5 14C23.1226 14 18.4592 17.0359 16.1142 21.4837Z"
        fill="#FF3D00"
      />
      <path
        d="M28.5 42C32.1162 42 35.402 40.6161 37.8863 38.3656L33.5533 34.699C32.1005 35.8039 30.3252 36.4014 28.5 36.4C24.8586 36.4 21.7667 34.0781 20.6019 30.8378L16.0365 34.3553C18.3535 38.8892 23.0589 42 28.5 42Z"
        fill="#4CAF50"
      />
      <path
        d="M42.2277 25.2581H41.1V25.2H28.5V30.8H36.4121C35.8599 32.3515 34.8653 33.7072 33.5512 34.6997L33.5533 34.6983L37.8863 38.3649C37.5797 38.6435 42.5 35 42.5 28C42.5 27.0613 42.4034 26.145 42.2277 25.2581Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default GoogleIcon;
