import { useContext } from 'react';
import { InitialContext } from '../contexts/InitialContext';

const useDeviceInfo = () => {
  const context = useContext(InitialContext);

  if (!context) throw new Error('Initial context must be use inside InitialProvider');

  return { deviceInfo: context.currentDeviceInfo };
};

export default useDeviceInfo;
