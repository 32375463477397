import { KeyboardArrowDownIcon, KeyboardArrowUpIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import { FC, memo, useEffect } from 'react';

import { CurrentOption, IStepmeterProgress } from '../../model/types';
import { formatTime } from '../../utils/formatStepmeterTime';
import { formatToLocaleString } from '../../utils/formatToLocaleString';
import { getMedianMetrics } from '../../utils/getMedianMetrics';

import s from './styles.module.scss';

export const TEST_ID = 'StepmeterMetrics';

export interface StepmeterMetricsProps {
  fullInfo: boolean;
  currentMetric: CurrentOption;
  onChangeMetric: () => void;
  time?: number;
  distance?: number;
  calories?: number;
  currentWeek: (IStepmeterProgress | null)[] | null;
}

const metricNames: Record<CurrentOption, string> = {
  [CurrentOption.TIME]: 'Время',
  [CurrentOption.DISTANCE]: 'Расстояние',
  [CurrentOption.CALORIES]: 'Калории',
  [CurrentOption.STEPS]: 'Шаги',
};

const StepmeterMetrics: FC<StepmeterMetricsProps> = ({
  fullInfo,
  onChangeMetric,
  currentMetric,
  time = 0,
  distance = 0,
  calories = 0,
  currentWeek,
}) => {
  useEffect(() => {
    if (!fullInfo && currentMetric === CurrentOption.STEPS) {
      onChangeMetric();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMetric, fullInfo]);

  const [medianTime, medianDistance, medianCalories, medianSteps] = getMedianMetrics(currentWeek);

  const [hours, minutes] = formatTime(fullInfo ? medianTime : time);

  const handleChangeMetric = (e: any) => {
    e.stopPropagation();
    onChangeMetric();
  };

  return (
    <div className={s.stepmeterMetrics} onClick={handleChangeMetric} data-testid={TEST_ID}>
      <Typography variant="text7" color="white" opacity={0.5} fontWeight={500}>
        {fullInfo ? 'В среднем' : metricNames[currentMetric]}
      </Typography>

      <div className={s.metric}>
        {currentMetric === CurrentOption.TIME && (
          <div className={s.metricWrapper}>
            {hours !== null && (
              <>
                <Typography variant="h3" color="white" fontWeight={600}>
                  {hours}
                </Typography>
                <Typography variant="h5" color="white" opacity={0.5} fontWeight={500}>
                  ч
                </Typography>
              </>
            )}
            {minutes !== null && (
              <>
                &nbsp;
                <Typography variant="h3" color="white" fontWeight={600}>
                  {minutes}
                </Typography>
                <Typography variant="h5" color="white" opacity={0.5} fontWeight={500}>
                  м
                </Typography>
              </>
            )}
          </div>
        )}

        {currentMetric === CurrentOption.CALORIES && (
          <div className={s.metricWrapper}>
            <Typography variant="h3" color="white" fontWeight={600}>
              {formatToLocaleString(fullInfo ? medianCalories : calories)}
            </Typography>
            &nbsp;
            <Typography variant="h5" color="white" opacity={0.5} fontWeight={500}>
              ккал
            </Typography>
          </div>
        )}

        {currentMetric === CurrentOption.DISTANCE && (
          <div className={s.metricWrapper}>
            <Typography variant="h3" color="white" fontWeight={600}>
              {fullInfo ? medianDistance : distance}
            </Typography>
            &nbsp;
            <Typography variant="h5" color="white" opacity={0.5} fontWeight={500}>
              км
            </Typography>
          </div>
        )}

        {fullInfo && currentMetric === CurrentOption.STEPS && (
          <div className={s.metricWrapper}>
            <Typography variant="h3" color="white" fontWeight={600}>
              {formatToLocaleString(medianSteps)}
            </Typography>
            &nbsp;
            <Typography variant="h5" color="white" opacity={0.5} fontWeight={500}>
              шагов
            </Typography>
          </div>
        )}

        <div className={s.arrows}>
          <KeyboardArrowUpIcon color={NamesOfColors.white30} size={8} />
          <KeyboardArrowDownIcon color={NamesOfColors.white30} size={8} />
        </div>
      </div>
    </div>
  );
};

export const StepmeterMetricsMemo = memo(StepmeterMetrics);
