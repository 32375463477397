import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyLicenseAgreement = lazy(() => import('./LicenseAgreement'));

export const LicenseAgreement = () => (
  <Suspense fallback={<Loading />}>
    <LazyLicenseAgreement />
  </Suspense>
);
