import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '../utils';
import useAuthenticated from './useAuthenticated';

const { main } = Paths;

const useAuthRedirect = () => {
  const { isAuthenticated } = useAuthenticated();

  const { state } = useLocation();

  const navigate = useNavigate();

  const redirectFrom = state?.from ? state.from : null;

  useEffect(() => {
    if (isAuthenticated && redirectFrom) {
      navigate(redirectFrom);
    } else if (isAuthenticated) {
      navigate(main);
    }
  }, [isAuthenticated, navigate, redirectFrom]);
};

export default useAuthRedirect;
