import React from 'react';
import { FC } from 'react';
import { IBottomNavigationIconProps } from '../types';

interface IProps extends IBottomNavigationIconProps {}

const CoursesIcon: FC<IProps> = ({ isAcvive, theme = 'light' }) => {
  return (
    <>
      {!isAcvive && (
        <>
          {theme === 'light' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3.5" y="2" width="18" height="19" rx="5" stroke="#818181" strokeWidth="2" />
              <rect x="7.5" y="8.36401" width="10" height="2" rx="1" fill="#818181" />
              <rect x="7.5" y="12.364" width="10" height="2" rx="1" fill="#818181" />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3.5" y="2" width="18" height="19" rx="5" stroke="#818181" strokeWidth="2" />
              <rect x="7.5" y="8.36401" width="10" height="2" rx="1" fill="#818181" />
              <rect x="7.5" y="12.364" width="10" height="2" rx="1" fill="#818181" />
            </svg>
          )}
        </>
      )}

      {isAcvive && (
        <>
          {theme === 'light' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 7C2.5 3.68629 5.18629 1 8.5 1H16.5C19.8137 1 22.5 3.68629 22.5 7V16C22.5 19.3137 19.8137 22 16.5 22H8.5C5.18629 22 2.5 19.3137 2.5 16V7ZM7.5 9.36401C7.5 8.81173 7.94772 8.36401 8.5 8.36401H16.5C17.0523 8.36401 17.5 8.81173 17.5 9.36401C17.5 9.9163 17.0523 10.364 16.5 10.364H8.5C7.94772 10.364 7.5 9.9163 7.5 9.36401ZM8.5 12.364C7.94772 12.364 7.5 12.8117 7.5 13.364C7.5 13.9163 7.94772 14.364 8.5 14.364H16.5C17.0523 14.364 17.5 13.9163 17.5 13.364C17.5 12.8117 17.0523 12.364 16.5 12.364H8.5Z"
                fill="#1D1D1D"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 7C2.5 3.68629 5.18629 1 8.5 1H16.5C19.8137 1 22.5 3.68629 22.5 7V16C22.5 19.3137 19.8137 22 16.5 22H8.5C5.18629 22 2.5 19.3137 2.5 16V7ZM7.5 9.36401C7.5 8.81173 7.94772 8.36401 8.5 8.36401H16.5C17.0523 8.36401 17.5 8.81173 17.5 9.36401C17.5 9.9163 17.0523 10.364 16.5 10.364H8.5C7.94772 10.364 7.5 9.9163 7.5 9.36401ZM8.5 12.364C7.94772 12.364 7.5 12.8117 7.5 13.364C7.5 13.9163 7.94772 14.364 8.5 14.364H16.5C17.0523 14.364 17.5 13.9163 17.5 13.364C17.5 12.8117 17.0523 12.364 16.5 12.364H8.5Z"
                fill="white"
              />
            </svg>
          )}
        </>
      )}
    </>
  );
};

export default CoursesIcon;
