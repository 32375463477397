import { LevelsID } from './constants/config';

export const isLevels = (id: number | null): id is LevelsID => {
  if (id === null) return false;

  return id === LevelsID.beginner || id === LevelsID.middle || id === LevelsID.high;
};

export const getLevelValues = (levelID: LevelsID | null | undefined): [boolean, boolean, boolean] => {
  switch (levelID) {
    case LevelsID.beginner:
      return [true, false, false];
      break;
    case LevelsID.middle:
      return [true, true, false];
      break;
    case LevelsID.high:
      return [true, true, true];
      break;
    default:
      return [false, false, false];
  }
};

export const getIntensityValues = (intensityID: number | null | undefined): [boolean, boolean, boolean] => {
  switch (intensityID) {
    case LevelsID.beginner:
      return [true, false, false];
      break;
    case LevelsID.middle:
      return [true, true, false];
      break;
    case LevelsID.high:
      return [true, true, true];
      break;
    default:
      return [false, false, false];
  }
};
