import { useEffect, useState } from 'react';
import { useAnalytics, useAppSelector } from '.';
import { AnalyticsName, Statuses, TypesStatusesOrderPaid } from '../utils';
import { format, parseISO } from 'date-fns';

export const useOrder = () => {
  const {
    orderStore: {
      order: { data: order, statuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ orderStore: state.orderStore, authStore: state.authStore }));

  const { setAnalytics } = useAnalytics();

  const [analyticsSent, setAnalyticsSent] = useState(false);

  const isOrder = Boolean(order);

  const isOrderLoading = statuse === Statuses.idle || statuse === Statuses.loading || isLoading;

  useEffect(() => {
    if (order && order.status === TypesStatusesOrderPaid.paid && !analyticsSent) {
      setAnalyticsSent(true);

      const { product_id: productId, price, paid_at: paidAt } = order;

      const date = format(parseISO(paidAt ?? ''), 'dd-MM-yyyy HH:mm:ss');

      setAnalytics(AnalyticsName.subscriptionType, { productId, price, date });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return {
    order,
    isOrder,
    isOrderLoading,
  };
};
