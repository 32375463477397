import { setTokens } from '../redux/auth/slice';
import { createServiceNotice } from '../redux/notifications/slice';
import { removeStore, store } from '../redux/store';
import { Environment, ErrorMessages, noticeCreator } from '../utils';
import ApiClient from './ApiClient';
import { ITokens } from './types';
import jwtDecode from 'jwt-decode';

export enum RemoveTokens {
  message = 'remove_tokens',
}

export const setSession = async (tokens: ITokens | RemoveTokens) => {
  if (tokens && tokens !== RemoveTokens.message) {
    const { exp: tokenTime } = jwtDecode<{ exp: number }>(tokens.access_token);

    try {
      await window?.setTokens?.({ ...tokens, tokenTime });

      store.dispatch(setTokens(tokens));
    } catch (error) {
      console.error(error, ErrorMessages.setTokens, { tokens });

      store.dispatch(
        createServiceNotice({
          notice: noticeCreator(ErrorMessages.setTokens, 'error'),
          otherInfo: {
            error: ErrorMessages.setTokens,
            pathname: 'setSession set tokens',
            forEnvironment: Environment.development,
          },
        })
      );
    }

    ApiClient.defaults.headers.common.Authorization = tokens.access_token;
  } else if (tokens === RemoveTokens.message) {
    try {
      await window?.removeTokens?.();

      store.dispatch(removeStore());
    } catch (error) {
      console.error(error, ErrorMessages.removeTokens, { tokens });

      store.dispatch(
        createServiceNotice({
          notice: noticeCreator(ErrorMessages.removeTokens, 'error'),
          otherInfo: {
            error: ErrorMessages.removeTokens,
            pathname: 'setSession remove tokens',
            forEnvironment: Environment.development,
          },
        })
      );
    }

    delete ApiClient.defaults.headers.common.Authorization;
  } else {
    alert(`функция setSession получила: ${tokens} в качестве аргумента`);
  }
};
