import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { CssPlatformContainer, NavBar } from '../../../../components';
import validationSchema, { InputNames } from './config';
import s from './styles.module.scss';
import cn from 'classnames';
import { usePlatform } from '../../../../hooks';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { FormInput, Typography } from 'front-package-ui-kit';
import ConfiguredCircleButtonLime from '../ConfiguredCircleButtonLime';

interface IProps {
  step: string;
  password: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onBackClick: () => void;
  onClick: () => void;
  loading: boolean;
}

const EnterPassword: FC<IProps> = ({ step, onChange, onBackClick, onSubmit, onClick, loading, password }) => {
  const isKeyboardOpen = useDetectKeyboardOpen();

  const { platform } = usePlatform();

  // const isNeedRestructuringContent = platform === 'android' && isKeyboardOpen;

  const isNeedRestructuringContent = false;

  const initialValues = {
    password: password ?? '',
  };

  const { handleSubmit, errors, values, getFieldProps, setFieldValue, setErrors } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      onSubmit();
    },
  });

  const isError = Boolean(Object.entries(errors).length);

  const onChangeHandler = ({ target: { value: val } }: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(InputNames.password, val);
  };

  const onFocusHandler = () => {
    setErrors({});
  };

  useEffect(() => {
    onChange(values.password);
  }, [onChange, values.password]);

  const disabled = isError || !Boolean(values.password.length);

  return (
    <div className={s.enterPassword}>
      <CssPlatformContainer>
        <NavBar
          marginBottom="23px"
          justifyContent={isNeedRestructuringContent ? 'space-between' : 'flex-end'}
          onBackClick={onBackClick}
          goBackButton
          goBackButtonProps={{ white: true }}
        >
          {isNeedRestructuringContent && (
            <Typography color="white" variant="text4">
              Введите пароль
            </Typography>
          )}

          <Typography variant="text4" color="white">
            {step}
          </Typography>
        </NavBar>

        <div className={s.content}>
          {!isNeedRestructuringContent && (
            <Typography marginBottom="40px" fontWeight={700} variant="h2" color="white">
              Введите пароль
            </Typography>
          )}

          <form onSubmit={handleSubmit}>
            <FormInput
              theme="dark"
              type="password"
              label="Пароль"
              {...getFieldProps(InputNames.password)}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              error={errors.password}
              autoFocus
              marginBottom="24px"
            />

            <Typography onClick={onClick} variant="text6" color="lime" marginBottom="40px" textDecoration="underline">
              Забыли пароль?
            </Typography>

            <div className={cn(s.btn, { [s.btnRestructuring]: isNeedRestructuringContent })}>
              <ConfiguredCircleButtonLime loading={loading} disabled={disabled} />
            </div>
          </form>
        </div>
      </CssPlatformContainer>
    </div>
  );
};

export default EnterPassword;
