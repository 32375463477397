import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyEdit = lazy(() => import('./Edit'));

export const Edit = () => (
  <Suspense fallback={<Loading />}>
    <LazyEdit />
  </Suspense>
);
