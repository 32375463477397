import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { matcherHelper } from '../utils';
import { getCountriesThunk } from './thunks';
import { ICountry } from '../../api/types';

interface ICountries {
  countries: ICountry[];
  error: string;
  isLoading: boolean;
}

const initialState: ICountries = {
  countries: [],
  error: '',
  isLoading: false,
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesThunk.fulfilled, (state, { payload }: PayloadAction<ICountry[]>) => {
        state.error = '';
        state.isLoading = false;
        state.countries = payload;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, countriesSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, countriesSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export default countriesSlice.reducer;
