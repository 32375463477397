import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyPaymentHistory = lazy(() => import('./PaymentHistory'));

export const PaymentHistory = () => (
  <Suspense fallback={<Loading />}>
    <LazyPaymentHistory />
  </Suspense>
);
