import React, { FC, memo, useCallback } from 'react';
import { Button, CheckCircleIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import s from './styles.module.scss';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI } from '../..';
import { useBottomSheet } from '../../../hooks';
import { Box } from '@mui/material';
import { SORT_NAMES, TypesSort, upperCaseFirst } from '../../../utils';

export const TEST_ID = 'SortBottomSheet';

export const openSortBottomSheet = 'openSortBottomSheet';

export interface SortBottomSheetProps {
  onSetCurrentSort: (value: TypesSort) => void;
  currentSort: TypesSort;
  defaultValue?: TypesSort;
  theme?: 'dark' | 'light';
  values: TypesSort[];
}

const SortBottomSheet: FC<SortBottomSheetProps> = ({
  onSetCurrentSort,
  currentSort,
  defaultValue = TypesSort.novelty,
  theme = 'light',
  values,
}) => {
  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openSortBottomSheet);

  const handleClose = useCallback(() => onCloseBottomSheet(), [onCloseBottomSheet]);

  const handleChange = useCallback(
    (value: TypesSort) => () => {
      if (value && value !== currentSort) onSetCurrentSort(value);
    },
    [currentSort, onSetCurrentSort]
  );

  const handleAccept = useCallback(() => {
    onCloseBottomSheet();
  }, [onCloseBottomSheet]);

  const handleReset = useCallback(() => {
    onSetCurrentSort(defaultValue);

    onCloseBottomSheet();
  }, [defaultValue, onCloseBottomSheet, onSetCurrentSort]);

  return (
    <BottomSheetUI
      data-testid={TEST_ID}
      height="auto"
      touchLine
      onDismiss={handleClose}
      open={isOpen}
      theme={theme}
      expandOnContentDrag
      header={
        <BottomSheetHeader
          alignItems="center"
          theme={theme}
          title={
            <Box width="100%">
              <Typography variant="h4" fontWeight={700} textAlign="center" color={theme === 'dark' ? 'white' : 'black'}>
                Сортировка
              </Typography>
            </Box>
          }
          color="white"
          onClose={handleClose}
          closeIconColor={theme === 'dark' ? NamesOfColors.white100 : NamesOfColors.grey50}
        />
      }
      footer={
        <BottomButtonBlock maxWidth="444px" direction="column" gap="8px">
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleAccept()}
            size="L"
            color={theme === 'dark' ? 'white' : 'black'}
          >
            <Typography variant="text6" color={theme === 'dark' ? 'black' : 'white'} fontWeight={700}>
              Применить
            </Typography>
          </Button>

          <Button variant="text" fullWidth onClick={() => handleReset()} size="L">
            <Typography variant="text6" color={theme === 'dark' ? 'white' : 'black'} fontWeight={700}>
              Сбросить
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <div className={s.sortBottomSheet} data-theme={theme}>
        {values.map((sortValue) => (
          <div key={sortValue} className={s.toggleButton} onClick={handleChange(sortValue)}>
            <Typography variant="text5" fontWeight={500}>
              {upperCaseFirst(SORT_NAMES[sortValue])}
            </Typography>

            {sortValue === currentSort && <CheckCircleIcon size={20} />}
          </div>
        ))}
      </div>
    </BottomSheetUI>
  );
};

export const SortBottomSheetMemo = memo(SortBottomSheet);
