import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '.';
import { getTrainerInfoThunk } from '../redux/trainer/thunks';

const useTrainerInfo = (id: number | null | undefined) => {
  const {
    trainerStore: { trainers },
  } = useAppSelector((state) => ({ trainerStore: state.trainerStore }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id && !trainers[id]) {
      dispatch(getTrainerInfoThunk(String(id)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const trainer = id ? trainers[id] : null;

  return { trainer };
};

export default useTrainerInfo;
