import React from 'react';

const FeedBackSuccessIcon = () => {
  return (
    <svg width="100" height="81" viewBox="0 0 100 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5849 32.9783C30.0812 31.9728 31.515 31.9728 32.0112 32.9783L38.7712 46.6756C38.9683 47.0749 39.3492 47.3516 39.7898 47.4156L54.9056 49.6121C56.0152 49.7733 56.4583 51.1369 55.6554 51.9196L44.7174 62.5814C44.3986 62.8922 44.2531 63.34 44.3284 63.7789L46.9105 78.8336C47.1 79.9388 45.9401 80.7815 44.9476 80.2597L31.4276 73.1518C31.0335 72.9446 30.5627 72.9446 30.1685 73.1518L16.6485 80.2597C15.6561 80.7815 14.4961 79.9388 14.6857 78.8336L17.2677 63.7789C17.343 63.34 17.1975 62.8922 16.8787 62.5814L5.94075 51.9196C5.13783 51.1369 5.58089 49.7733 6.6905 49.6121L21.8063 47.4156C22.247 47.3516 22.6279 47.0749 22.8249 46.6756L29.5849 32.9783Z"
        fill="url(#paint0_linear_261_17238)"
      />
      <g filter="url(#filter0_b_261_17238)">
        <path
          d="M52.4831 5.99943C52.9793 4.99396 54.4131 4.99395 54.9093 5.99943L64.908 26.259C65.1051 26.6583 65.486 26.935 65.9266 26.999L88.2843 30.2478C89.394 30.4091 89.837 31.7727 89.0341 32.5553L72.8559 48.3252C72.537 48.636 72.3915 49.0838 72.4668 49.5226L76.286 71.79C76.4755 72.8952 75.3156 73.7379 74.3231 73.2161L54.3257 62.7029C53.9316 62.4957 53.4608 62.4957 53.0667 62.7029L33.0693 73.2161C32.0768 73.7379 30.9169 72.8952 31.1064 71.79L34.9256 49.5226C35.0008 49.0838 34.8553 48.636 34.5365 48.3252L18.3583 32.5553C17.5554 31.7727 17.9984 30.4091 19.108 30.2478L41.4658 26.999C41.9064 26.935 42.2873 26.6583 42.4844 26.259L52.4831 5.99943Z"
          fill="url(#paint1_linear_261_17238)"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_261_17238"
          x="1.94824"
          y="-10.7546"
          width="103.496"
          height="100.129"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_261_17238" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_261_17238" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_261_17238"
          x1="30.7981"
          y1="30.5203"
          x2="30.7981"
          y2="86.9211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_261_17238"
          x1="93.3113"
          y1="17.2521"
          x2="121.737"
          y2="64.7255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="1" stopColor="#C1C1C1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FeedBackSuccessIcon;
