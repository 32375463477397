import React from 'react';
import { FC } from 'react';

interface IProps {
  isAcvive: boolean;
  size: 'small' | 'medium';
}

const RatingIcon: FC<IProps> = ({ isAcvive, size }) => (
  <>
    {size === 'small' && (
      <>
        {!isAcvive && (
          <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M24.9269 15.9437C23.981 12.4613 21.9239 10.6744 21.9239 10.6744L21.9245 10.6757C23.6427 15.0554 20.7175 18.8821 20.7175 18.8821C20.7348 15.6817 17.8785 11.6773 17.8785 11.6773C16.8477 10.0069 16.2076 8.49098 15.8323 7.14501C14.5647 2.60117 16.3224 0 16.3224 0C1.72849 6.84312 0.112407 15.6115 0.438561 20.2127C0.85471 26.7925 6.27741 32 12.9071 32C19.5214 32 24.9339 26.817 25.373 20.259C25.3737 20.2455 25.375 20.232 25.3756 20.2185C25.3928 19.9475 25.4024 19.6739 25.4024 19.3977C25.4024 18.2005 25.2371 17.0412 24.9269 15.9437Z"
              fill="#1D1D1D"
              fillOpacity="0.1"
            />
          </svg>
        )}

        {isAcvive && (
          <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.1221 15.9437C24.1761 12.4613 22.119 10.6744 22.119 10.6744L22.1197 10.6757C23.8379 15.0554 20.9127 18.8821 20.9127 18.8821C20.9299 15.6817 18.0737 11.6773 18.0737 11.6773C17.0429 10.0069 16.4027 8.49098 16.0274 7.14501C14.7598 2.60117 16.5176 0 16.5176 0C1.92365 6.84312 0.307567 15.6115 0.633721 20.2127C1.04987 26.7925 6.47257 32 13.1022 32C19.7166 32 25.1291 26.817 25.5682 20.259C25.5688 20.2455 25.5701 20.232 25.5708 20.2185C25.588 19.9475 25.5976 19.6739 25.5976 19.3977C25.5976 18.2005 25.4323 17.0412 25.1221 15.9437Z"
              fill="#7FFF00"
            />
          </svg>
        )}
      </>
    )}

    {size === 'medium' && (
      <>
        {!isAcvive && (
          <svg width="41" height="50" viewBox="0 0 41 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.7392 24.912C38.2257 19.4708 34.9343 16.6787 34.9343 16.6787L34.9353 16.6807C37.6845 23.524 33.0042 29.5034 33.0042 29.5034C33.0318 24.5026 28.4618 18.2457 28.4618 18.2457C26.8125 15.6357 25.7882 13.2672 25.1878 11.1641C23.1596 4.06433 25.9721 0 25.9721 0C2.62174 10.6924 0.0360037 24.393 0.55785 31.5823C1.22369 41.8633 9.90002 50 20.5075 50C31.0904 50 39.7504 41.9015 40.453 31.6547C40.454 31.6336 40.4561 31.6125 40.4571 31.5913C40.4847 31.1679 40.5 30.7405 40.5 30.309C40.5 28.4382 40.2355 26.6268 39.7392 24.912Z"
              fill="#1D1D1D"
              fillOpacity="0.1"
            />
          </svg>
        )}

        {isAcvive && (
          <svg width="41" height="50" viewBox="0 0 41 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.7392 24.912C38.2257 19.4708 34.9343 16.6787 34.9343 16.6787L34.9353 16.6807C37.6845 23.524 33.0042 29.5034 33.0042 29.5034C33.0318 24.5026 28.4618 18.2457 28.4618 18.2457C26.8125 15.6357 25.7882 13.2672 25.1878 11.1641C23.1596 4.06433 25.9721 0 25.9721 0C2.62174 10.6924 0.0360037 24.393 0.55785 31.5823C1.22369 41.8633 9.90002 50 20.5075 50C31.0904 50 39.7504 41.9015 40.453 31.6547C40.454 31.6336 40.4561 31.6125 40.4571 31.5913C40.4847 31.1679 40.5 30.7405 40.5 30.309C40.5 28.4382 40.2355 26.6268 39.7392 24.912Z"
              fill="#7FFF00"
            />
          </svg>
        )}
      </>
    )}
  </>
);

export default RatingIcon;
