import React, { FC } from 'react';
import s from './styles.module.scss';
import { useAppDispatch, useBottomSheet, useSwiperPickerDate, useUser } from '../../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, SwiperPicker } from '../../../../components';
import { ButtonNext, Input } from '..';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import validationSchema, { InputNames } from './config';
import { IUserInfoForUpdate } from '../../../../api/types';
import api from '../../../../api';
import { updateUserInfo } from '../../../../redux/user/slice';
import { getTextError } from '../../../../redux/utils';
import { createServiceNotice } from '../../../../redux/notifications/slice';
import { Environment, months, noticeCreator, years } from '../../../../utils';
import { Button, Typography } from 'front-package-ui-kit';

const openOnboardingAboutSelfBottomSheet = 'openOnboardingAboutSelfBottomSheet';

const getDefaultDateOfBirth = (defaultDateOfBirth: string | undefined) => {
  if (!defaultDateOfBirth) return null;

  const [year, month, day] = defaultDateOfBirth.split('-');

  return new Date(+year, +month - 1, +day);
};

export const TEST_ID = 'SetDateOfBirth';

interface IProps {
  handleStep: () => void;
  stepNumber: number;
  numberOfSteps: number;
}

const SetDateOfBirth: FC<IProps> = ({ handleStep, stepNumber, numberOfSteps }) => {
  const { user } = useUser();

  const dispatch = useAppDispatch();

  const defaultDateOfBirth = user?.dob ? getDefaultDateOfBirth(user?.dob) : null;

  const initialValues = {
    firstName: user?.name ?? '',
    dateOfBirth: defaultDateOfBirth,
  };

  const { handleSubmit, errors, getFieldProps, setFieldValue, values, setErrors } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async ({ firstName, dateOfBirth }) => {
      const dob = dateOfBirth ? format(dateOfBirth, 'yyyy-MM-dd') : null;

      const userInfo: IUserInfoForUpdate = { avatar: user?.avatar ?? '', name: firstName };

      if (dob !== null) userInfo.dob = dob;

      try {
        const userResponse = (await api.updateUserInfo(userInfo)).data.data;

        dispatch(updateUserInfo(userResponse));

        handleStep();
      } catch (error) {
        const textError = getTextError(error);

        dispatch(
          createServiceNotice({
            notice: noticeCreator(textError, 'error'),
            otherInfo: { error, pathname: 'SetDateOfBirth updateUserInfo', forEnvironment: Environment.development },
          })
        );
      }
    },
  });

  const onFocusHandler = () => {
    setErrors({});
  };

  const { onOpenBottomSheet, onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpenOnboardingAboutSelfBottomSheet = getIsOpenBottomSheet(openOnboardingAboutSelfBottomSheet);

  const { datePickerValue, days, setDateSwiperPickerHandler } = useSwiperPickerDate({
    day: defaultDateOfBirth?.getDate() ?? 1,
    month: defaultDateOfBirth?.getMonth() ? months[defaultDateOfBirth.getMonth()] : months[0],
    year: defaultDateOfBirth?.getFullYear() ?? 1987,
  });

  const handleChangeBirthDay = () => {
    const month = months.findIndex((elem) => elem === datePickerValue.month);

    setFieldValue(InputNames.dateOfBirth, new Date(datePickerValue.year, month, datePickerValue.day));

    onCloseBottomSheet();
  };

  const isDisabled = Boolean(Object.values(errors).length) || !Boolean(values.dateOfBirth);

  return (
    <form className={s.setDateOfBirth} onSubmit={handleSubmit}>
      <Stack marginBottom="24px" padding="0 8px">
        <Typography variant="h3" color="white" fontWeight={700} marginBottom="8px">
          Когда ваш день рождения?
        </Typography>

        <Typography variant="text6" color="white" fontWeight={400} opacity={0.6}>
          Важно для настройки профиля
        </Typography>
      </Stack>

      <Input
        onClick={() => onOpenBottomSheet(openOnboardingAboutSelfBottomSheet)}
        placeholder="Дата рождения"
        margin="0 0 32px 0"
        onFocus={onFocusHandler}
        value={
          getFieldProps(InputNames.dateOfBirth).value
            ? format(getFieldProps(InputNames.dateOfBirth).value, 'dd.MM.yyyy')
            : ''
        }
        error={errors.dateOfBirth}
        readOnly
        autoComplete="false"
      />

      <ButtonNext disabled={isDisabled} stepNumber={stepNumber} numberOfSteps={numberOfSteps} />

      <BottomSheetUI
        open={isOpenOnboardingAboutSelfBottomSheet}
        touchLine
        height="auto"
        onDismiss={() => onCloseBottomSheet()}
        header={<BottomSheetHeader onClose={() => onCloseBottomSheet()} />}
        footer={
          <BottomButtonBlock>
            <Button size="L" color="black" onClick={handleChangeBirthDay} variant="contained" fullWidth>
              <Typography variant="text6" fontWeight={700} color="white">
                Применить
              </Typography>
            </Button>
          </BottomButtonBlock>
        }
      >
        <SwiperPicker
          onChange={setDateSwiperPickerHandler}
          leftPicker={{
            options: days,
            defaultValue: datePickerValue.day,
          }}
          middlePicker={{
            options: months,
            defaultValue: datePickerValue.month,
          }}
          rightPicker={{
            options: years,
            defaultValue: datePickerValue.year,
          }}
        />
      </BottomSheetUI>
    </form>
  );
};

export default SetDateOfBirth;
