import { Box, Divider, Stack } from '@mui/material';
import { Button, NamesOfColors, Typography } from 'front-package-ui-kit';
import parse from 'html-react-parser';
import { FC, useCallback, useRef, useState } from 'react';

import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, CourseCard, ImageCover } from '../..';
import { ICourse, ITrainer } from '../../../api/types';
import { useBottomSheet } from '../../../hooks';
import { ImagePaths, ResizeType } from '../../../utils';
import SliderCards from '../../molecules/SliderCards';

import s from './styles.module.scss';

import defaultCover from '../../../assets/image/defaultCover.png';

export const openTrainerDetailsModal = 'openTrainerDetailsModal';

interface IProps {
  trainer: ITrainer | null;
  coursesList?: ICourse[];
}

const TrainerDetailsModal: FC<IProps> = ({ trainer, coursesList }) => {
  const [isRestructuringContent, setIsRestructuringContent] = useState(false);

  const trackedDiv = useRef<HTMLDivElement | null>(null);

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openTrainerDetailsModal);

  const [isMoreDescription, setIsMoreDescription] = useState(false);

  const trainerText = trainer?.text_html ? parse(trainer.text_html) : '';

  const trainerTextMainDescription = Array.isArray(trainerText)
    ? trainerText.filter((elem) => elem.props?.className === 'mainDescription')
    : [];

  const trainerTextMoreDescription = Array.isArray(trainerText)
    ? trainerText.filter((elem) => elem.props?.className === 'moreDescription')
    : [];

  const handleOpenMoreDescription = () => {
    setIsMoreDescription((prev) => !prev);
  };

  const handleCloseBottomSheet = useCallback(() => {
    onCloseBottomSheet();
  }, [onCloseBottomSheet]);

  const courses = coursesList?.filter((elem) => elem.trainer_id === trainer?.id);

  const hadleScroll = useCallback(() => {
    if (!trackedDiv.current) return;

    const { y: bodyContentPositionY = 0 } = trackedDiv.current.getBoundingClientRect();

    if (isRestructuringContent !== bodyContentPositionY < 45) setIsRestructuringContent(bodyContentPositionY < 45);
  }, [isRestructuringContent]);

  return (
    <BottomSheetUI
      height="full"
      expandOnContentDrag
      touchLine
      onDismiss={handleCloseBottomSheet}
      open={isOpen}
      transparentHeader
      onScroll={hadleScroll}
      header={
        <BottomSheetHeader
          onClose={handleCloseBottomSheet}
          closeIconColor={isRestructuringContent ? NamesOfColors.grey30 : NamesOfColors.white100}
          transparent={!isRestructuringContent}
        />
      }
      footer={
        <BottomButtonBlock>
          <Button size="L" color="black" variant="contained" onClick={handleCloseBottomSheet} fullWidth>
            <Typography variant="text6" fontWeight={700} color="white">
              Понятно
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <Stack alignItems="center" justifyContent="center" className={s.mainContainer}>
        <Box className={s.photoContainer}>
          <ImageCover
            img={{
              baseApiService: 'BASE',
              imagePath: ImagePaths.trainerCover,
              name: trainer?.cover,
              width: 1500,
              height: 900,
              resize: ResizeType.resizeBothCenter,
            }}
            defaultImg={defaultCover}
          />
        </Box>

        <div className={s.content} ref={trackedDiv}>
          <Typography marginBottom="8px" variant="h4" fontWeight={700}>
            {trainer?.name ?? ''}
          </Typography>

          <div className={s.mainDescription}>{trainerTextMainDescription}</div>

          {Boolean(trainerTextMoreDescription?.length) && (
            <>
              <Stack justifyContent="space-between" alignItems="center" direction="row">
                <button onClick={handleOpenMoreDescription}>
                  <Typography variant="text4" fontWeight={700}>
                    {isMoreDescription ? 'cкрыть детали' : 'показать больше'}
                  </Typography>
                </button>
              </Stack>

              {isMoreDescription && <div className={s.moreDescription}>{trainerTextMoreDescription}</div>}
            </>
          )}
          <Box my="20px">
            <Divider className={s.divider} />
          </Box>
        </div>

        <Box width="100%">
          <SliderCards
            renderCard={(course) => <CourseCard fullWidth course={course} size="M" />}
            options={courses ?? []}
            saveScrollKey={String(trainer?.id)}
          />
        </Box>
      </Stack>
    </BottomSheetUI>
  );
};

export default TrainerDetailsModal;
