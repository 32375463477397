import React from 'react';
import { FC } from 'react';
import { getIsValue } from '../../utils';

interface IProps {
  size: '16' | '24';
}

const ProfileIcon: FC<IProps> = ({ size }) => {
  return (
    <>
      {getIsValue(size, '24') && (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
          <path
            d="M15.0002 16.25C11.7859 16.25 9.37518 12.8223 9.37518 8.82812C9.37518 4.83392 11.4498 2.5 15.0002 2.5C18.5506 2.5 20.6252 4.83392 20.6252 8.82812C20.6252 12.8223 18.2145 16.25 15.0002 16.25ZM15.0002 27.5C9.53314 27.5 6.26199 26.768 5.18674 25.3041C5.06135 25.1334 4.99752 24.9252 5.00556 24.7136C5.15654 20.7379 8.48808 18.75 15.0002 18.75C21.5124 18.75 24.8439 20.7379 24.9948 24.7137C25.0028 24.9254 24.9389 25.1336 24.8135 25.3043C23.738 26.7681 20.4669 27.5 15.0002 27.5Z"
            fill="#1D1D1D"
            fillOpacity="0.3"
          />
        </svg>
      )}

      {getIsValue(size, '16') && (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M8.00058 9.79731C5.7798 9.79731 4.19972 7.35432 4.19972 4.50754C4.19972 1.66076 5.54758 -0.00268555 8.00058 -0.00268555C10.4536 -0.00268555 11.7997 1.66076 11.7997 4.50754C11.7997 7.35432 10.2214 9.79731 8.00058 9.79731ZM8.00436 15.9973C4.71224 15.9973 2.65661 15.7291 1.83745 15.1925L1.83741 15.1926C1.6866 15.0938 1.60063 14.9215 1.6124 14.7416C1.75826 12.5121 3.88736 11.3973 7.99972 11.3973C12.1114 11.3973 14.2433 12.5117 14.3956 14.7405L14.3956 14.7405C14.408 14.921 14.3219 15.094 14.1705 15.1931C13.3508 15.7292 11.2954 15.9973 8.00436 15.9973Z"
            fill="#1D1D1D"
            fillOpacity="0.3"
          />
        </svg>
      )}
    </>
  );
};

export default ProfileIcon;
