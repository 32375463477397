import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import { createServiceNotice } from '../notifications/slice';
import { Environment, ErrorMessages, noticeCreator, promocodeErrorsByIndex } from '../../utils';
import { RootState } from '../store';
import { TProductsWithPromocode } from './slice';
import { AxiosError } from 'axios';
import { setCurrentProductForPayment } from '../payment/slice';

export const getProductsThunk = createAsyncThunk(
  'products/getProductsThunk',
  async (params: { [key: string]: string | undefined }, { rejectWithValue, dispatch, getState }) => {
    try {
      const products = (await api.getProducts(params)).data.data;

      const state = getState() as RootState;

      if (!state.paymentStore.currentProductForPayment) {
        const productMaxPrice = products.reduce((acc, product) => {
          if (acc === null) {
            acc = product;
          } else {
            if (product.price > acc.price) {
              acc = product;
            }
          }

          return acc;
        });

        dispatch(setCurrentProductForPayment(productMaxPrice));
      }

      return products;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'products/getProductsThunk', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const errorConverter = (errorMessage: number) => {
  switch (errorMessage) {
    case 3:
    case 2:
      return ErrorMessages.promocodeLimitExceeded;
    case 4:
      return ErrorMessages.promocodeExpired;
    case 1:
    case 5:
    case 6:
      return ErrorMessages.promocodeNotFitAnotherConditions;
    default:
      return ErrorMessages.defaultTextError;
  }
};

export const setProductPromocodeThunk = createAsyncThunk(
  'products/setProductPromocodeThunk',
  async ({ promocode }: { promocode: string }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;

    if (!state.productsStore.products.data) return [];

    const currentPlatform = await window?.getPlatform();

    const productsWithApplyPromo: TProductsWithPromocode = {};

    let error: number | null = null;

    try {
      const requests = state.productsStore.products.data.map(async ({ id }) => {
        try {
          const product = (await api.getProduct(id, { promocode, platform: currentPlatform })).data.data;

          productsWithApplyPromo[id] = product;

          return { [id]: product };
        } catch (e) {
          if (e instanceof AxiosError) {
            const errorText: string = typeof e.response?.data.message === 'string' ? e.response?.data.message : '';
            if (!error) error = promocodeErrorsByIndex[errorText];

            if (error && error > promocodeErrorsByIndex[errorText]) error = promocodeErrorsByIndex[errorText];
          }

          return { [id]: null };
        }
      });

      await Promise.all(requests);

      if (Object.keys(productsWithApplyPromo).length) {
        return productsWithApplyPromo;
      } else {
        throw new Error();
      }
    } catch (err) {
      return rejectWithValue(errorConverter(error ?? 0));
    }
  }
);
