import { LinearProgress } from '@mui/material';
import classNames from 'classnames';
import { NamesOfColors, Typography, VerticalMenuIcon } from 'front-package-ui-kit';
import { FC, memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector, useBottomSheet } from '../../../../hooks';
import { Paths, Statuses } from '../../../../utils';
import { useStepmeterActions } from '../../hooks/useStepmeterActions';
import { getCurrentOption, getGoal, getIsAccessGranted, getProgress, getStatuse } from '../../model/selectors';
import { CurrentOption, IStepmeterProgress } from '../../model/types';
import { formatDateRangeToLocalString } from '../../utils/formatDateToWeek';
import { splitByWeeks } from '../../utils/splitProgressByWeeks';
import { StepmeterChartContainer } from '../StepmeterChartContainer';
import { StepmeterDialog } from '../StepmeterDialog';
import { StepmeterMetrics } from '../StepmeterMetrics';
import { StepmeterSetGoalBottomsheet } from '../StepmeterSetGoalBottomsheet';
import { openStepmeterSetGoalBottomsheet } from '../StepmeterSetGoalBottomsheet/StepmeterSetGoalBottomsheet';
import { NoAccess } from './NoAccess';

import s from './styles.module.scss';
import { addDays, format } from 'date-fns';

export const TEST_ID = 'Stepmeter';

const { profile, stepmeter, widgetSettings } = Paths;

export interface StepmeterProps {
  fullInfo: boolean;
}

const Stepmeter: FC<StepmeterProps> = ({ fullInfo }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentWeek, setCurrentWeek] = useState<(IStepmeterProgress | null)[] | null>(null);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const hasAccess = useAppSelector(getIsAccessGranted);
  const goal = useAppSelector(getGoal);
  const progress = useAppSelector(getProgress);
  const currentOption = useAppSelector(getCurrentOption);
  const status = useAppSelector(getStatuse);

  const todayProgress = progress.length > 0 ? progress[0] : null;

  const { setStepmeterGoal, setStepmeterCurrentOption } = useStepmeterActions();

  const isLoading = (status === Statuses.idle || status === Statuses.loading) && progress.length === 0;

  const splittedProgressByWeeks = splitByWeeks(progress).reverse();

  const handleSetCurrentWeek = useCallback(
    (value: number) => {
      setCurrentWeek(splittedProgressByWeeks[value]);
    },
    [splittedProgressByWeeks]
  );

  useEffect(() => {
    const dateFrom = format(new Date(), 'yyyy-MM-dd');
    const dateTo = format(addDays(new Date(), 1), 'yyyy-MM-dd');

    window
      .getHealthData?.(dateFrom, dateTo)
      .then((res) => {
        console.log('Resolved in react: ', res);
      })
      .catch((err) => {
        console.log('error: ' + err);
      });
  }, []);

  const { onOpenBottomSheet } = useBottomSheet();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOpenBottomSheet = useCallback(() => onOpenBottomSheet(openStepmeterSetGoalBottomsheet), []);

  const onOpenDialog = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  const onCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleChangeGoal = useCallback((value: number) => {
    setStepmeterGoal(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMetric = useCallback(() => {
    if (currentOption === CurrentOption.TIME) {
      setStepmeterCurrentOption(CurrentOption.CALORIES);
    } else if (currentOption === CurrentOption.CALORIES) {
      setStepmeterCurrentOption(CurrentOption.DISTANCE);
    } else if (currentOption === CurrentOption.DISTANCE) {
      setStepmeterCurrentOption(fullInfo ? CurrentOption.STEPS : CurrentOption.TIME);
    } else {
      setStepmeterCurrentOption(CurrentOption.TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOption]);

  const goToStepmeterPage = () => {
    if (hasAccess && '/' + pathname.split('/').pop() === profile) {
      navigate(`${profile}${stepmeter}`);
    }
  };

  const goToAddStepmeterDay = useCallback(
    () => navigate(`${profile}${stepmeter}/distance/${todayProgress?.id ?? 0}/add`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [todayProgress?.id]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGoToSettings = useCallback(() => navigate(`${profile}${widgetSettings}`), []);

  return (
    <>
      <section
        className={s.stepmeter}
        style={{ height: fullInfo || !hasAccess ? '186px' : '144px' }}
        data-testid={TEST_ID}
        onClick={goToStepmeterPage}
      >
        <div className={s.header}>
          <Typography variant="text4" color="white" fontWeight={500}>
            Шагомер
          </Typography>

          <button className={classNames(s.menuBtn, { [s._hidden]: !hasAccess })} onClick={onOpenDialog}>
            <VerticalMenuIcon color={NamesOfColors.white100} size={16} />
          </button>
        </div>

        {!isLoading && !hasAccess && <NoAccess onGetAccess={handleGoToSettings} />}

        {!isLoading && hasAccess && (
          <div className={s.content}>
            <StepmeterChartContainer
              fullInfo={fullInfo}
              goal={goal}
              todayProgress={todayProgress}
              splittedProgress={splittedProgressByWeeks}
              handleSetCurrentWeek={handleSetCurrentWeek}
            />

            <div className={s.metrics}>
              <StepmeterMetrics
                fullInfo={fullInfo}
                currentMetric={currentOption}
                onChangeMetric={handleChangeMetric}
                time={todayProgress?.time ?? 0}
                distance={todayProgress?.distance ?? 0}
                calories={todayProgress?.calories ?? 0}
                currentWeek={currentWeek}
              />

              {fullInfo && (
                <div className={s.dateWrapper}>
                  <Typography variant="text7" color="white" opacity={0.5} fontWeight={500}>
                    {currentWeek !== null &&
                      formatDateRangeToLocalString(
                        currentWeek?.filter((day) => day !== null)[0]?.date ?? String(new Date())
                      )}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}

        {!isLoading && !fullInfo && hasAccess && (
          <div
            className={classNames(s.progressWrapper, {
              [s.progressWrapperDone]: (todayProgress?.steps ?? 0) >= goal,
            })}
          >
            <LinearProgress value={((todayProgress?.steps ?? 0) / goal) * 100} variant="determinate" />
          </div>
        )}
      </section>

      <StepmeterDialog
        isOpen={isDialogOpen}
        onClose={onCloseDialog}
        onOpenBottomSheet={handleOpenBottomSheet}
        goToAddStepmeterDay={goToAddStepmeterDay}
      />

      <StepmeterSetGoalBottomsheet title="Цель" goal={goal} handleChangeGoal={handleChangeGoal} />
    </>
  );
};

export const StepmeterMemo = memo(Stepmeter);
