import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import eruda from 'eruda';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(<App />);

reportWebVitals();

const environment = window.getEnvironment();

if (environment === 'development') {
  let el = document.createElement('div');
  document.body.appendChild(el);

  eruda.init({});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
