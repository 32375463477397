import { createSlice, isPending, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { IIntensity, ILevel } from '../../api/types';
import { matcherHelper } from '../utils';
import { getIntensityDictionaryThunk, getLevelsDictionaryThunk } from './thunks';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';
import { getCoursesThunk } from '../courses/thunks';

interface IDictionaries {
  intensity: IIntensity[];
  levels: IStoreBase<ILevel[]>;
  error: string;
  isLoading: boolean;
}

const initialState: IDictionaries = {
  intensity: [],
  levels: { data: [], error: '', statuse: Statuses.idle },
  error: '',
  isLoading: false,
};

export const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIntensityDictionaryThunk.fulfilled, (state, { payload }: PayloadAction<IIntensity[]>) => {
        state.error = '';
        state.isLoading = false;
        state.intensity = payload;
      })
      .addCase(getLevelsDictionaryThunk.fulfilled, (state, { payload }: PayloadAction<ILevel[]>) => {
        state.levels.error = '';
        state.levels.statuse = Statuses.succeeded;
        state.levels.data = payload;
      })
      //getLevelsThunk
      .addMatcher(isPending(getCoursesThunk), (state) => {
        state.levels.error = '';
        state.levels.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getCoursesThunk), (state, { payload }) => {
        state.levels.statuse = Statuses.failed;
        state.levels.error = typeof payload === 'string' ? payload : '';
      })
      //=====
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, dictionariesSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, dictionariesSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const {} = dictionariesSlice.actions;

export default dictionariesSlice.reducer;
