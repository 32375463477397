import { IParameters } from '../../../../api/types';
import {
  getCurrentParam,
  getOptionsByParameterTypeSortedDate,
  getUniqDateByMaxTime,
  getValueProgress,
} from '../helpers';

const useDashBoardHelper = (values: IParameters[] | null, paramId: number) => {
  const currentParamValue = values ? getCurrentParam(values, paramId) : '';

  const valuesParametersByTypesSortedDate = values ? getOptionsByParameterTypeSortedDate(values, paramId) : [];

  const uniqDates = getUniqDateByMaxTime(valuesParametersByTypesSortedDate);

  const startParamOption = valuesParametersByTypesSortedDate[0]?.value;

  const lastParamOption = valuesParametersByTypesSortedDate[valuesParametersByTypesSortedDate.length - 1]?.value;

  const differentOptions = lastParamOption - startParamOption;

  const isEmpty = valuesParametersByTypesSortedDate.length === 0;

  const isOneParameter = valuesParametersByTypesSortedDate.length === 1;

  const valueProgress = getValueProgress(differentOptions);

  return { currentParamValue, uniqDates, isEmpty, isOneParameter, valueProgress };
};

export default useDashBoardHelper;
