import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISingleWorkoutsPage {
  test: number;
}

const initialState: ISingleWorkoutsPage = { test: 0 };

export const singleWorkoutsPageSlice = createSlice({
  name: 'singleWorkoutsPage',
  initialState,
  reducers: {
    setTest(state, { payload }: PayloadAction<number>) {
      state.test = payload;
    },
  },
});

export const { setTest } = singleWorkoutsPageSlice.actions;

export default singleWorkoutsPageSlice.reducer;
