import React from 'react';

const MaestroIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18" fill="none">
      <rect x="1" y="1" width="28" height="16" rx="3" fill="white" />
      <path d="M17.4336 13.5403H12.5664V4.6991H17.4336V13.5403Z" fill="#6C6BBD" />
      <path
        d="M12.8754 9.11989C12.8754 7.32637 13.7061 5.7288 14.9999 4.69928C14.0538 3.94644 12.8597 3.49707 11.5621 3.49707C8.49019 3.49707 6 6.01443 6 9.11989C6 12.2253 8.49019 14.7426 11.5621 14.7426C12.8598 14.7426 14.0538 14.2933 14.9999 13.5404C13.7061 12.5109 12.8754 10.9133 12.8754 9.11989Z"
        fill="#D32011"
      />
      <path
        d="M24 9.11989C24 12.2253 21.5098 14.7426 18.4378 14.7426C17.1402 14.7426 15.9462 14.2933 14.9998 13.5404C16.2939 12.5109 17.1246 10.9133 17.1246 9.11989C17.1246 7.32637 16.2939 5.7288 14.9998 4.69928C15.9462 3.94644 17.1403 3.49707 18.4378 3.49707C21.5098 3.49707 24 6.01443 24 9.11989Z"
        fill="#0099DF"
      />
      <rect
        x="0.75"
        y="0.75"
        width="28.5"
        height="16.5"
        rx="3.25"
        stroke="#1D1D1D"
        strokeOpacity="0.1"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default MaestroIcon;
