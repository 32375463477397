import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyFeedback = lazy(() => import('./Feedback'));

export const Feedback = () => (
  <Suspense fallback={<Loading />}>
    <LazyFeedback />
  </Suspense>
);
