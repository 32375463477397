import { FC } from 'react';

interface IProps {
  theme?: 'light' | 'dark';
}
const BadgeIcon: FC<IProps> = ({ theme = 'light' }) => {
  return (
    <>
      {theme === 'dark' && (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0992 1.29528C11.211 0.840727 10.9847 0.372862 10.5408 0.141028C10.0969 -0.0908059 9.53882 -0.0325997 9.16404 0.284627L0.3726 7.72619C0.0210302 8.02377 -0.0946355 8.48686 0.0810292 8.89353C0.256694 9.3002 0.6879 9.56761 1.16801 9.56761H4.28908L2.78882 15.7062C2.67802 16.1595 2.90341 16.6258 3.34547 16.8578C3.78753 17.0899 4.3439 17.0338 4.71951 16.7194L13.6226 9.26802C13.9769 8.9715 14.095 8.50753 13.9202 8.0994C13.7453 7.69126 13.3133 7.42253 12.832 7.42253H9.59194L11.0992 1.29528Z"
            fill="url(#paint0_linear_1883_23568)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1883_23568"
              x1="5.29315"
              y1="3.00001"
              x2="4.58307"
              y2="17.3668"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.241937" stopColor="#7FFF00" />
              <stop offset="0.503562" stopColor="#FF0249" />
              <stop offset="0.719687" stopColor="#8000FF" />
            </linearGradient>
          </defs>
        </svg>
      )}

      {theme === 'light' && (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0992 1.29528C11.211 0.840727 10.9847 0.372862 10.5408 0.141028C10.0969 -0.0908059 9.53882 -0.0325997 9.16404 0.284627L0.3726 7.72619C0.0210302 8.02377 -0.0946355 8.48686 0.0810292 8.89353C0.256694 9.3002 0.6879 9.56761 1.16801 9.56761H4.28908L2.78882 15.7062C2.67802 16.1595 2.90341 16.6258 3.34547 16.8578C3.78753 17.0899 4.3439 17.0338 4.71951 16.7194L13.6226 9.26802C13.9769 8.9715 14.095 8.50753 13.9202 8.0994C13.7453 7.69126 13.3133 7.42253 12.832 7.42253H9.59194L11.0992 1.29528Z"
            fill="#FF0A56"
          />
        </svg>
      )}
    </>
  );
};

export default BadgeIcon;
