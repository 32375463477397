import { useContext } from 'react';
import { InitialContext } from '../contexts/InitialContext';

const useUpdate = () => {
  const context = useContext(InitialContext);

  if (!context) throw new Error('Initial context must be use inside InitialProvider');

  return { isRequiredUpdate: context.isRequiredUpdate, updateLink: context.updateLink };
};

export default useUpdate;
