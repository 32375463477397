import { DetailedHTMLProps, VideoHTMLAttributes, forwardRef } from 'react';

import s from './styles.module.scss';

export const TEST_ID = 'video-cover';

type TVideoProps = DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;

interface IProps extends Omit<TVideoProps, 'className'> {
  webm: string;
  mp4: string;
  ogg: string;
  poster?: string;
  objectFit?: 'contain' | 'cover' | 'unset';
  muted?: boolean;
  loop?: boolean;
  autoplay?: boolean;
}

const VideoCover = forwardRef<HTMLVideoElement, IProps>(
  ({ webm, mp4, ogg, poster, objectFit = 'cover', muted = true, loop = true, autoplay = true, ...props }, ref) => {
    return (
      <div className={s.main}>
        <video
          style={{ objectFit }}
          data-testid={TEST_ID}
          className={s.video}
          playsInline
          muted={muted}
          autoPlay={autoplay}
          loop={loop}
          preload="auto"
          poster={poster}
          ref={ref}
          {...props}
        >
          <source src={mp4} type="video/mp4" />
          <source src={ogg} type="video/ogg" />
          <source src={webm} type="video/webm" />
        </video>
      </div>
    );
  }
);

VideoCover.displayName = 'VideoCover';

export default VideoCover;
