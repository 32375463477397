import { Statuses } from '../utils';
import { useAppSelector } from './redux';

export const useUser = () => {
  const {
    userStore: {
      user: { data: user, statuse },
      updateAutopay: { statuse: updateAutopayStatuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ userStore: state.userStore, authStore: state.authStore }));

  const isUser = Boolean(user);

  const isUserLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isUser) || isLoading;

  const isUpdateAutopayLoading = updateAutopayStatuse === Statuses.loading;

  return { user, isUser, isUserLoading, isUpdateAutopayLoading };
};
