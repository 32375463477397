import { Box, Stack } from '@mui/material';
import { Typography } from 'front-package-ui-kit';
import React, { FC } from 'react';
import { BottomButtonBlock, ButtonWithAnalytic, CssPlatformContainer } from '../../../../components';
import s from './styles.module.scss';
import SuccessIcon from './SuccessIcon';
import { useEffectOnce } from 'usehooks-ts';
import { useAnalytics } from '../../../../hooks';
import { AnalyticsName } from '../../../../utils';

interface IProps {
  onClick: () => void;
}

const RegistrationCompleted: FC<IProps> = ({ onClick }) => {
  const { setAnalytics } = useAnalytics();

  useEffectOnce(() => {
    setAnalytics(AnalyticsName.registrationCompleted);
  });

  return (
    <div className={s.registrationCompleted}>
      <CssPlatformContainer>
        <Stack className={s.contentContainer}>
          <Stack alignItems="center" flexGrow={1} className={s.content}>
            <Box marginBottom="32px">
              <SuccessIcon />
            </Box>

            <Typography fontWeight={700} marginBottom="18px" color="white" variant="h2">
              Поздравляем!
            </Typography>

            <Typography textAlign="center" color="white" variant="text6">
              Регистрация прошла успешно. <br /> Давайте познакомимся
            </Typography>
          </Stack>

          <BottomButtonBlock justifySelf="flex-end">
            <ButtonWithAnalytic
              size="L"
              onClick={onClick}
              fullWidth
              variant="contained"
              color="lime"
              analyticInfo={{ name: AnalyticsName.registrationCompletedNext }}
            >
              <Typography fontWeight={700} variant="text6">
                Продолжить
              </Typography>
            </ButtonWithAnalytic>
          </BottomButtonBlock>
        </Stack>
      </CssPlatformContainer>
    </div>
  );
};

export default RegistrationCompleted;
