import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProduct, MethodPaymentType } from '../../api/types';

const currentProductForPaymentstringify = localStorage.getItem('currentProductForPayment');

const defaultCurrentProductForPayment = currentProductForPaymentstringify
  ? JSON.parse(currentProductForPaymentstringify)
  : null;

interface IPaymentStore {
  newCardMethodPayment: MethodPaymentType;
  promocode: string;
  trial: boolean;
  currentProductForPayment: IProduct | null;
}

export const initialState: IPaymentStore = {
  newCardMethodPayment: MethodPaymentType.yookassa,
  promocode: '',
  trial: false,
  currentProductForPayment: defaultCurrentProductForPayment,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setNewCardMethodPayment(state, { payload }: PayloadAction<MethodPaymentType>) {
      state.newCardMethodPayment = payload;
    },
    setPromocode(state, { payload }: PayloadAction<string>) {
      state.promocode = payload;
    },
    changeTrial(state, { payload }: PayloadAction<boolean>) {
      state.trial = payload;
    },
    setCurrentProductForPayment(state, { payload }: PayloadAction<IProduct | null>) {
      state.currentProductForPayment = payload;
    },
  },
});

export const { setNewCardMethodPayment, setPromocode, changeTrial, setCurrentProductForPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
