import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { PickersDayProps } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { ComponentType, FC, useMemo } from 'react';
import { IUserActivity, IWorkoutDetailse } from '../../../api/types';
import { useAppDispatch } from '../../../hooks';
import { openActivityBottomSheet } from '../../../pages/Profile/components/ActivityBottomSheet';
import { setActivityDay } from '../../../pages/Profile/slice';
import { dataFormatter, getGroupOfIntervalsDate, getStateday } from './helpers';
import './style.scss';

export const TEST_ID = 'DatePickerUi';

export interface IStateday {
  isSelected: boolean;
  isOneDay: boolean;
  isLastDay: boolean;
  isFirstDay: boolean;
  isToday: boolean;
}

interface CustomPickerDayProps extends PickersDayProps<Date> {
  stateday: IStateday;
}

interface DayComponentProps extends PickersDayProps<Date> {
  selectedDay?: Date | null;
  intervals?: string[][];
}

interface DatePickerProps {
  historyDates: string[];
  onClick: (bottomSheetName: string, state?: any) => void;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ stateday: { isSelected, isOneDay, isFirstDay, isLastDay, isToday } }) => ({
  borderRadius: 0,
  height: '28px',
  backgroundColor: '#aaa',
  color: '#1D1D1D',
  fontWeight: 500,
  fontSize: '14px',
  fontFamily: 'inherit',
  lineHeight: 'normal',
  overflow: 'hidden',
  '&:focus': {
    backgroundColor: 'transparent',
  },
  flexGrow: 1,
  ...(isToday && {
    position: 'relative',
    zIndex: 1,
    color: '#FFFFFF',
    '&:before': {
      content: '""',
      borderRadius: '20px',
      position: 'absolute',
      width: '24px',
      height: '24px',
      background: 'linear-gradient(180deg, #6F6F6F 0%, #5B5B5B 100%);',
      zIndex: -1,
    },
  }),
  ...(isSelected && {
    position: 'relative',
    zIndex: 1,
    color: '#1D1D1D',
    '&:after': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'rgba(29, 29, 29, 0.15)',
      width: '100%',
      height: '100%',
      zIndex: -2,
    },
    '&:before': {
      content: '""',
      borderRadius: '20px',
      position: 'absolute',
      width: '24px',
      height: '24px',
      background: 'linear-gradient(180deg, #A8FF00 0%, #00FF57 100%);',
      zIndex: -1,
    },
  }),
  ...(isSelected &&
    isToday && {
      position: 'relative',
      zIndex: 1,
      color: '#1D1D1D',
      '&:after': {
        content: '""',
        position: 'absolute',
        backgroundColor: 'rgba(29, 29, 29, 0.15)',
        width: '100%',
        height: '100%',
        zIndex: -2,
      },
      '&:before': {
        content: '""',
        borderRadius: '20px',
        position: 'absolute',
        width: '24px',
        height: '24px',
        background: 'linear-gradient(180deg, #A8FF00 0%, #00FF57 100%);',
        zIndex: -1,
      },
    }),
  ...(isOneDay && {
    backgroundColor: 'transparent',
    '&:after': {
      content: '""',
    },
  }),
  ...(isFirstDay &&
    isSelected && {
      backgroundColor: 'transparent',
      '&:after': {
        content: '""',
        position: 'absolute',
        backgroundColor: 'rgba(29, 29, 29, 0.15)',
        left: '20%',
        width: '100%',
        height: '100%',
        borderTopLeftRadius: '24px',
        borderBottomLeftRadius: '24px',
        zIndex: -2,
      },
    }),
  ...(isLastDay &&
    isSelected && {
      backgroundColor: 'transparent',
      '&:after': {
        content: '""',
        position: 'absolute',
        backgroundColor: 'rgba(29, 29, 29, 0.15)',
        right: '20%',
        width: '100%',
        height: '100%',
        zIndex: -2,
        borderTopRightRadius: '24px',
        borderBottomRightRadius: '24px',
      },
    }),
})) as ComponentType<CustomPickerDayProps>;

const Day: FC<DayComponentProps> = ({ day, selectedDay, intervals, onPointerEnter, onPointerLeave, ...other }) => {
  const stateday = useMemo(() => {
    return getStateday(day, intervals);
  }, [day, intervals]);

  return (
    <CustomPickersDay
      day={day}
      disableRipple
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      stateday={stateday}
      {...other}
    />
  );
};

const DatePickerUi: FC<DatePickerProps> = ({ onClick, historyDates }) => {
  const dispatch = useAppDispatch();

  const intervals = useMemo(() => {
    return getGroupOfIntervalsDate(historyDates);
  }, [historyDates]);

  // const historyDateDictionary = useMemo(() => {
  //   return getHistoryDateDictionary(storyWorkouts);
  // }, [storyWorkouts]);

  const handleOpenStoryBottomSheet = (day: Date | null) => {
    // if (day && getIsDateInHistory(day, historyDateDictionary)) {
    //   dispatch(setActivityDay(day));

    //   onClick(openActivityBottomSheet);
    // }

    if (day) {
      dispatch(setActivityDay(day.getTime()));

      onClick(openActivityBottomSheet);
    }
  };

  return (
    <>
      <StaticDatePicker
        data-testid={TEST_ID}
        displayStaticWrapperAs="desktop"
        value={null}
        onChange={(value) => handleOpenStoryBottomSheet(value)}
        slots={{
          day: Day,
          switchViewIcon: () => <Box />,
          switchViewButton: () => <Box />,
        }}
        slotProps={{
          day: () => ({
            intervals: intervals,
            onPointerEnter: () => {},
            onPointerLeave: () => {},
          }),
        }}
        dayOfWeekFormatter={dataFormatter}
        disableHighlightToday
        disableFuture
        reduceAnimations
      />
    </>
  );
};

export default DatePickerUi;
