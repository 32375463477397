import React, { FC } from 'react';
import s from './styles.module.scss';
import { Skeleton } from 'front-package-ui-kit';
import { Box, Stack } from '@mui/material';

export const TEST_ID = 'SkeletonWorkoutCard';

interface IProps {
  size: 'L' | 'M' | 'S';
}

const SkeletonWorkoutCard: FC<IProps> = ({ size }) => {
  return (
    <div className={s.skeletonWorkoutCard} data-testid={TEST_ID}>
      {size === 'L' && (
        <Stack rowGap="8px">
          <Skeleton width="200px" height="200px" borderRadius="14px" />

          <Box padding="0 8px">
            <Stack rowGap="4px" marginBottom="6px">
              <Skeleton width="100%" height="14px" borderRadius="4px" />

              <Skeleton width="60%" height="14px" borderRadius="4px" />
            </Stack>

            <Stack direction="row" columnGap="4px">
              <Skeleton width="36%" height="14px" borderRadius="4px" />

              <Skeleton width="36%" height="14px" borderRadius="4px" />
            </Stack>
          </Box>
        </Stack>
      )}

      {size === 'S' && (
        <Stack direction="row" alignItems="center">
          <Box marginRight="12px">
            <Skeleton width="104px" height="104px" borderRadius="14px" />
          </Box>

          <Stack direction="row" flexGrow={1}>
            <Box flexGrow={1} marginRight="20px">
              <Stack marginBottom="4px" rowGap="8px">
                <Skeleton width="20%" height="12px" borderRadius="4px" />

                <Skeleton width="100%" height="16px" borderRadius="4px" />

                <Skeleton width="65%" height="12px" borderRadius="4px" />
              </Stack>

              <Skeleton width="20%" height="12px" borderRadius="4px" />
            </Box>

            <Skeleton width="16px" height="16px" borderRadius="4px" />
          </Stack>
        </Stack>
      )}
    </div>
  );
};

export default SkeletonWorkoutCard;
