import React, { FC, memo, useCallback, useMemo } from 'react';
import { Button, Typography } from 'front-package-ui-kit';
import s from './styles.module.scss';
import { useAppDispatch, useAppSelector, useBankCards, useBottomSheet, useNeedTrial, usePayment } from '../../../hooks';
import {
  AutoRenewal,
  BottomButtonBlock,
  BottomSheetHeader,
  BottomSheetUI,
  DividerUI,
  Fallback,
  MainBankCard,
  SetMethodPaymentType,
} from '../..';
import {
  declinationTextByProductUnit,
  Environment,
  getDeclinationText,
  InfoMessages,
  noticeCreator,
  upperCaseFirst,
  VariantsOfEveryText,
} from '../../../utils';
import { createServiceNotice } from '../../../redux/notifications/slice';

export const TEST_ID = 'ConfirmPaymentBottomSheet';

export const openConfirmPaymentBottomSheet = 'openConfirmPaymentBottomSheet';

export interface ConfirmPaymentBottomSheetProps {
  autoRenewalChecked: boolean;
  onAutoRenewalChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel?: () => void;
}

const ConfirmPaymentBottomSheet: FC<ConfirmPaymentBottomSheetProps> = ({
  autoRenewalChecked,
  onAutoRenewalChange,
  onCancel,
}) => {
  const { isLinkedBankCards } = useBankCards();

  const { isNeedUseTrial } = useNeedTrial();

  const { trial, currentProductForPayment, promocode } = useAppSelector((state) => state.paymentStore);

  const {
    productsWithPromocode: { data: productsWithPromocode },
  } = useAppSelector((state) => state.productsStore);

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const dispatch = useAppDispatch();

  const isOpen = getIsOpenBottomSheet(openConfirmPaymentBottomSheet);

  const handleClose = useCallback(() => {
    onCloseBottomSheet();

    onCancel?.();
  }, [onCancel, onCloseBottomSheet]);

  const handleAutoRenewalChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onAutoRenewalChange(e);
      if (e.target.checked || !trial) return;

      dispatch(
        createServiceNotice({
          notice: noticeCreator(InfoMessages.disableAutoRenewal, 'info'),
          otherInfo: { pathname: TEST_ID, forEnvironment: Environment.production },
        })
      );
    },
    [dispatch, onAutoRenewalChange, trial]
  );

  const currentProductForPaymentDuration = useMemo(() => {
    return currentProductForPayment?.duration ?? 0;
  }, [currentProductForPayment]);

  const currentProductForPaymentDurationType = useMemo(() => {
    return currentProductForPayment?.duration_type ?? '';
  }, [currentProductForPayment]);

  const currentProductForPaymentId = currentProductForPayment?.id ?? 0;

  const declinationText = declinationTextByProductUnit[currentProductForPaymentDurationType];

  const currentProductForPaymentPricePromotion = currentProductForPayment?.price_promotion;

  const currentProductForPaymentPrice = currentProductForPayment?.price;

  const price = useMemo(() => {
    return Number(
      productsWithPromocode && productsWithPromocode[currentProductForPaymentId]
        ? productsWithPromocode[currentProductForPaymentId]?.price_promotion
        : currentProductForPaymentPricePromotion !== 0
        ? currentProductForPaymentPricePromotion
        : currentProductForPaymentPrice
    ).toLocaleString('ru');
  }, [
    productsWithPromocode,
    currentProductForPaymentId,
    currentProductForPaymentPricePromotion,
    currentProductForPaymentPrice,
  ]);

  const isTrial = isNeedUseTrial && trial;

  const isPromocode = promocode && productsWithPromocode && productsWithPromocode[currentProductForPaymentId];

  const isPromocodeWithTrial = isTrial && isPromocode;

  const promocodeWithTrialInfoText = isPromocodeWithTrial
    ? `через 7 дней ${productsWithPromocode?.[currentProductForPaymentId]?.price_promotion.toLocaleString('ru')} ₽ за ${
        productsWithPromocode?.[currentProductForPaymentId]?.duration
      } ${getDeclinationText(productsWithPromocode?.[currentProductForPaymentId]?.duration ?? 0, declinationText)}, `
    : isTrial && currentProductForPaymentPricePromotion !== 0
    ? `через 7 дней ${currentProductForPaymentPricePromotion?.toLocaleString('ru')} ₽ за ${
        currentProductForPayment?.duration
      } ${getDeclinationText(currentProductForPayment?.duration ?? 0, declinationText)}, `
    : '';

  const everyText = !isPromocodeWithTrial
    ? `${getDeclinationText(currentProductForPaymentDuration, VariantsOfEveryText)} ${
        currentProductForPaymentDuration > 1 ? currentProductForPaymentDuration : ''
      } ${getDeclinationText(currentProductForPaymentDuration, declinationText)}`
    : '';

  const { handlePay, loading } = usePayment();

  const handleClickPay = () => {
    onCloseBottomSheet();

    handlePay(autoRenewalChecked);
  };

  return (
    <BottomSheetUI
      open={isOpen}
      expandOnContentDrag
      touchLine
      height="auto"
      onDismiss={handleClose}
      header={
        <BottomSheetHeader
          justifyContent="flex-start"
          title={
            <Typography variant="h4" fontWeight={700} color="black" marginLeft="-24px">
              Подтверждение оплаты
            </Typography>
          }
          onClose={handleClose}
        />
      }
      footer={
        <BottomButtonBlock rowGap="8px" direction="column">
          <Button loading={loading} size="L" color="black" onClick={handleClickPay} variant="contained" fullWidth>
            <Typography variant="text6" fontWeight={700} color="white">
              Оплатить
            </Typography>
          </Button>

          <Button size="L" onClick={handleClose} variant="text" fullWidth>
            <Typography variant="text6" fontWeight={700} color="black">
              Отменить
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <div className={s.confirmPaymentBottomSheet}>
        {!trial && autoRenewalChecked && (
          <Typography variant="h3" fontWeight={500}>
            {`${price} ₽ за ${currentProductForPaymentDuration} ${getDeclinationText(
              currentProductForPaymentDuration,
              declinationText
            )}`}
          </Typography>
        )}

        {!autoRenewalChecked && (
          <Typography variant="h3" fontWeight={500}>
            {`${price} ₽ за ${currentProductForPaymentDuration} ${getDeclinationText(
              currentProductForPaymentDuration,
              declinationText
            )}`}
          </Typography>
        )}

        {trial && autoRenewalChecked && (
          <Typography variant="h3" fontWeight={500}>
            1₽ за 7 дней
          </Typography>
        )}

        {autoRenewalChecked && (
          <Typography opacity={0.5} variant="text6" fontWeight={500} marginTop="4px">
            {upperCaseFirst(`${promocodeWithTrialInfoText}далее `)}

            <span className={s.noWrapText}>{`${(
              currentProductForPayment?.price_promotion || currentProductForPayment?.price
            )?.toLocaleString('ru')} ₽ `}</span>

            {everyText}
          </Typography>
        )}

        <DividerUI color="grey" marginTop="16px" marginBottom="16px" />

        {!isLinkedBankCards && <SetMethodPaymentType />}

        {isLinkedBankCards && <MainBankCard />}

        <DividerUI color="grey" marginTop="16px" marginBottom="16px" />

        <AutoRenewal
          defaultChecked={autoRenewalChecked}
          onChange={handleAutoRenewalChange}
          info="Эта настройка автоматически продлит подписку"
        />

        <Fallback active={loading} />
      </div>
    </BottomSheetUI>
  );
};

export const ConfirmPaymentBottomSheetMemo = memo(ConfirmPaymentBottomSheet);
