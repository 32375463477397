import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoursesTabButtonsValues } from './config';

type THideSearch = Record<CoursesTabButtonsValues, boolean>;

interface ICourses {
  currentTab: CoursesTabButtonsValues;
  searchValue: string;
  isOpenSearch: boolean;
  hideFilters: boolean;
  hideSearch: THideSearch;
}

const initialState: ICourses = {
  currentTab: CoursesTabButtonsValues.all,
  searchValue: '',
  isOpenSearch: false,
  hideFilters: false,
  hideSearch: {
    [CoursesTabButtonsValues.all]: false,
    [CoursesTabButtonsValues.completed]: false,
    [CoursesTabButtonsValues.inProgress]: false,
    [CoursesTabButtonsValues.favorite]: false,
  },
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCurrentTab(state, { payload }: PayloadAction<CoursesTabButtonsValues>) {
      state.currentTab = payload;
    },
    setSearchValue(state, { payload }: PayloadAction<string>) {
      state.searchValue = payload;
    },
    setIsOpenSearch(state, { payload }: PayloadAction<boolean>) {
      state.isOpenSearch = payload;
    },
    setHideFilters(state, { payload }: PayloadAction<boolean>) {
      state.hideFilters = payload;
    },
    setHideSearch(state, { payload: { key, value } }: PayloadAction<{ key: CoursesTabButtonsValues; value: boolean }>) {
      state.hideSearch[key] = value;
    },
  },
});

export const { setCurrentTab, setSearchValue, setIsOpenSearch, setHideFilters, setHideSearch } = coursesSlice.actions;

export default coursesSlice.reducer;
