import React, { FC, useMemo } from 'react';
import { IParameters } from '../../../api/types';
import {
  useAnalytics,
  useAppDispatch,
  useBottomSheet,
  useLastParameters,
  useLoadingBottomSheet,
  useSwiperPickerDate,
  useSwiperPickerHelper,
} from '../../../hooks';
import {
  Action,
  AnalyticsNameByParametersID,
  PARAMETERS_TYPES,
  ParametersValuesDefault,
  TParameterTypeChest,
  TParameterTypeHips,
  TParameterTypeWaist,
} from '../../../utils/constants/config';
import { getValuesDefaultOfSizes } from '../../../utils';
import { format } from 'date-fns';
import { setParametersThunk, updateParametersThunk } from '../../../redux/parameters/thunks';
import BottomSheetUI from '../BottomSheetUI';
import BottomButtonBlock from '../../molecules/BottomButtonBlock';
import { Button, Typography } from 'front-package-ui-kit';
import BottomSheetHeader from '../../molecules/BottomSheetHeader';
import { Box, Stack } from '@mui/material';
import SwiperPicker from '../../molecules/SwiperPicker';
import Player, { openMeasureSizePlayer } from './Player';
import { useLocation, useSearchParams } from 'react-router-dom';
import PlayIcon from './PlayIcon';

export const TEST_ID = 'SetSizeBottomSheet';

export const openSetSizeBottomSheet = 'openSetSizeBottomSheet';

const checkTypeParameter = (parameter: any): parameter is IParameters => {
  if (!parameter || typeof parameter !== 'object') return false;

  return 'parameter_id' in parameter && 'value' in parameter;
};

interface IProps {
  textButtonAccept?: string;
  parameter?: IParameters;
  parameterType: TParameterTypeHips | TParameterTypeWaist | TParameterTypeChest | null;
}

const SetSizeBottomSheet: FC<IProps> = ({ textButtonAccept, parameter, parameterType }) => {
  const { pathname } = useLocation();

  const { setAnalytics } = useAnalytics();

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openSetSizeBottomSheet);

  const { getAllDaysForInterval } = useSwiperPickerDate();

  const { handleSetSize, sizePicker, sizesSwiperValuesCm, sizesSwiperValuesMm } = useSwiperPickerHelper();

  const [lastSizeChest, lastSizeHips, lastSizeWaist] = useLastParameters([
    PARAMETERS_TYPES.chest.id,
    PARAMETERS_TYPES.hips.id,
    PARAMETERS_TYPES.waist.id,
  ]);

  const lastSizes = {
    [PARAMETERS_TYPES.chest.id]: { value: lastSizeChest, default: ParametersValuesDefault.chest },
    [PARAMETERS_TYPES.hips.id]: { value: lastSizeHips, default: ParametersValuesDefault.hips },
    [PARAMETERS_TYPES.waist.id]: { value: lastSizeWaist, default: ParametersValuesDefault.waist },
  } as const;

  const allDaysForInterval = useMemo(() => {
    return getAllDaysForInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultDate = parameter ? format(new Date(parameter.date), 'dd.MM.yy') : format(new Date(), 'dd.MM.yy');

  const defaultSizeCM = parameter
    ? getValuesDefaultOfSizes(parameter?.value, 'cm')
    : parameterType && lastSizes[parameterType.id]?.value
    ? getValuesDefaultOfSizes(lastSizes[parameterType.id]?.value?.value ?? '', 'cm')
    : parameterType && lastSizes[parameterType.id]?.default
    ? lastSizes[parameterType.id]?.default
    : 0;

  const defaultSizeMM = parameter
    ? getValuesDefaultOfSizes(parameter?.value, 'mm')
    : parameterType && lastSizes[parameterType.id]?.value
    ? getValuesDefaultOfSizes(lastSizes[parameterType.id]?.value?.value ?? '', 'mm')
    : 0;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const value = `${sizePicker.cm}.${sizePicker.mm}`;

    const [day, month, year] =
      sizePicker.date && typeof sizePicker.date === 'string'
        ? sizePicker.date.split('.').map((elem) => Number(elem))
        : [];

    if (parameter && checkTypeParameter(parameter)) {
      const { date } = parameter;

      const formattedDate = sizePicker.date
        ? format(new Date(Number(`20${year}`), month - 1, day), 'yyyy-MM-dd')
        : format(new Date(date), 'yyyy-MM-dd');

      const newParameter = { ...parameter, value, date: formattedDate, update_at: new Date().toISOString() };

      //Analytics
      if (parameterType) {
        setAnalytics(AnalyticsNameByParametersID[parameterType.id], { pathname, action: Action.edit, value });
      }

      dispatch(updateParametersThunk({ newParameter, oldParameter: parameter }));
    } else {
      if (parameterType) {
        //Analytics
        setAnalytics(AnalyticsNameByParametersID[parameterType.id], { pathname, action: Action.create, value });

        dispatch(
          setParametersThunk({
            value: value,
            parameter_id: parameterType ? parameterType.id : 0,
            date:
              sizePicker.date && typeof sizePicker.date === 'string'
                ? format(new Date(Number(`20${year}`), month - 1, day), 'yyyy-MM-dd')
                : format(new Date(), 'yyyy-MM-dd'),
          })
        );
      }
    }

    onCloseBottomSheet();
  };

  const onCloseBottomSheetHandler = () => {
    onCloseBottomSheet();
  };

  const { isLoading, setLoadingHandler } = useLoadingBottomSheet();

  return (
    <>
      <BottomSheetUI
        open={isOpen}
        touchLine
        height="auto"
        onSpring={setLoadingHandler}
        onDismiss={onCloseBottomSheetHandler}
        header={
          <BottomSheetHeader
            title={
              <Box width="100%">
                <Typography marginBottom="8px" variant="text3" textAlign="center" fontWeight={700}>
                  {parameterType && `Добавить объём ${parameterType?.declination?.genitive}`}
                </Typography>

                <Stack
                  margin="0 auto"
                  component="button"
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  columnGap="6px"
                >
                  <PlayIcon />

                  <Typography
                    onClick={() => {
                      setSearchParams({ openMeasureSizePlayer });
                    }}
                    variant="text6"
                    textAlign="center"
                    fontWeight={500}
                    opacity={0.5}
                  >
                    Как сделать замер?
                  </Typography>
                </Stack>
              </Box>
            }
            onClose={onCloseBottomSheetHandler}
          />
        }
      >
        <form onSubmit={handleSubmit}>
          <SwiperPicker
            loading={isLoading}
            onChange={handleSetSize}
            leftPicker={{
              options: allDaysForInterval,
              defaultValue: defaultDate,
            }}
            middlePicker={{
              options: sizesSwiperValuesCm,
              defaultValue: defaultSizeCM,
              unit: 'см',
            }}
            rightPicker={{
              options: sizesSwiperValuesMm,
              defaultValue: defaultSizeMM,
              unit: 'мм',
            }}
          />

          <BottomButtonBlock direction="column" rowGap="8px">
            <Button disabled={isLoading} size="L" color="black" variant="contained" type="submit" fullWidth>
              <Typography variant="text6" color="white" fontWeight={700}>
                {textButtonAccept ?? 'Применить'}
              </Typography>
            </Button>

            <Button size="L" onClick={() => onCloseBottomSheet()} type="button" variant="text" fullWidth>
              <Typography variant="text6" fontWeight={700} color="black">
                Назад
              </Typography>
            </Button>
          </BottomButtonBlock>
        </form>
      </BottomSheetUI>

      {searchParams.get(openMeasureSizePlayer) === openMeasureSizePlayer && <Player />}
    </>
  );
};

export default SetSizeBottomSheet;
