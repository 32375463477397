import {
  setStepmeterGoal,
  setStepmeterCurrentOption,
  removeProgressDay,
  resetAllProgress,
  editProgressDay,
  toggleAccess,
} from './slice';

export const StepmeterActions = {
  setStepmeterGoal,
  setStepmeterCurrentOption,
  removeProgressDay,
  resetAllProgress,
  editProgressDay,
  toggleAccess,
};
