// Slider.tsx
import React, { FC, useRef, useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import { Typography } from 'front-package-ui-kit';
import { StarIcon } from '../../../assets/icons';
import { ImageCover } from '../..';
import { ImagePaths, ResizeType } from '../../../utils';
import defaultCover from '../../../assets/image/defaultCover.png';
import parse from 'html-react-parser';
import s from './styles.module.scss';
import { IGrade } from '../../../api/types';

interface SliderProps {
  grades: IGrade[];
}

const Slider: FC<SliderProps> = ({ grades }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const startX = useRef(0);
  const diffX = useRef(0);

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % grades.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + grades.length) % grades.length);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    diffX.current = e.touches[0].clientX - startX.current;
  };

  const handleTouchEnd = () => {
    if (Math.abs(diffX.current) > 50) {
      if (diffX.current > 0) {
        handlePrevSlide();
      } else {
        handleNextSlide();
      }
    }
    diffX.current = 0;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNextSlide();
    }, 3000);

    return () => clearInterval(intervalId);
  }, [handleNextSlide]);

  return (
    <div className={s.slider} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
      <div
        className={s.slidesContainer}
        style={{ transform: `translateX(-${currentSlide * 100}%)`, transition: 'transform 0.5s ease' }}
      >
        {grades.map((grade) => (
          <div key={`${grade.user_name}-${grade.id}`} className={s.slide}>
            <Stack columnGap="14px">
              <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom="14px">
                <Stack direction="row">
                  {new Array(grade.rating).fill(null).map((_, index) => (
                    <StarIcon key={`${grade.user_name}-${index}`} />
                  ))}
                </Stack>

                <Stack direction="row" columnGap="8px" alignItems="center">
                  <Typography variant="text7" fontWeight={600}>
                    {grade.user_name}
                  </Typography>

                  <Box className={s.photoContainer}>
                    <ImageCover
                      img={{
                        baseApiService: 'APP',
                        imagePath: ImagePaths.gradePhoto,
                        name: grade.user_photo,
                        width: 36,
                        height: 36,
                        resize: ResizeType.resizeBothCenter,
                      }}
                      defaultImg={defaultCover}
                    />
                  </Box>
                </Stack>
              </Stack>

              <Typography variant="text6">{parse(grade.text, { trim: true })}</Typography>
            </Stack>
          </div>
        ))}
      </div>

      <div className={s.dots}>
        {grades.map((_, index) => (
          <span
            key={index}
            className={`${s.dot} ${currentSlide === index ? s.active : ''}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
