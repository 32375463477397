import React, { FC, memo, useCallback } from 'react';
import { Button, Typography } from 'front-package-ui-kit';
import { useBottomSheet } from '../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI } from '../..';
import s from './styles.module.scss';
import { IBankCardWithDataInform } from '../../../api/types';

export const TEST_ID = 'DeletBankCardBottomSheet';

export const openDeletBankCardBottomSheet = 'openDeletBankCardBottomSheet';

export interface DeletBankCardBottomSheetProps {
  bankCard: IBankCardWithDataInform | null;
  onBackClick: () => void;
  onApplyClick: () => void;
}

const DeletBankCardBottomSheet: FC<DeletBankCardBottomSheetProps> = ({ bankCard, onBackClick, onApplyClick }) => {
  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openDeletBankCardBottomSheet);

  const handleClose = useCallback(() => onCloseBottomSheet(), [onCloseBottomSheet]);

  const handleBack = useCallback(() => {
    handleClose();

    onBackClick();
  }, [handleClose, onBackClick]);

  const handleApply = () => {
    handleClose();

    onApplyClick();
  };

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      expandOnContentDrag
      onDismiss={handleClose}
      header={
        <BottomSheetHeader
          title={
            <Typography variant="h4" fontWeight={700} marginLeft="-24px">
              Удалить карту?
            </Typography>
          }
          onClose={handleClose}
        />
      }
      footer={
        <BottomButtonBlock direction="column" rowGap="8px">
          <Button variant="contained" color="black" size="L" onClick={handleApply} fullWidth>
            <Typography variant="text6" fontWeight={700} color="white">
              Удалить
            </Typography>
          </Button>

          <Button variant="text" size="L" onClick={handleBack} fullWidth>
            <Typography variant="text6" fontWeight={700} color="black">
              Назад
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <div className={s.deletBankCardBottomSheet}>
        <Typography textAlign="center" variant="text6">
          {`Вы действительно хотите удалить привязанную карту ${bankCard?.last4 ? bankCard?.last4 : ''}?`}
        </Typography>
      </div>
    </BottomSheetUI>
  );
};

export const DeletBankCardBottomSheetMemo = memo(DeletBankCardBottomSheet);
