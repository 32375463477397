import { ButtonGroup, Dialog, DialogContent, Divider } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Typography } from 'front-package-ui-kit';
import { FC, forwardRef, memo, ReactElement, Ref } from 'react';

import s from './styles.module.scss';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TEST_ID = 'StepmeterDialog';

export interface StepmeterDialogProps {
  isOpen: boolean;
  onOpenBottomSheet: () => void;
  onClose: () => void;
  goToAddStepmeterDay: () => void;
}

const StepmeterDialog: FC<StepmeterDialogProps> = ({ isOpen, onClose, onOpenBottomSheet, goToAddStepmeterDay }) => {
  const handleOpenBottomSheet = () => {
    onClose();
    onOpenBottomSheet();
  };

  return (
    <Dialog
      data-testid={TEST_ID}
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ zIndex: 1500 }}
      PaperProps={{
        sx: {
          position: 'absolute',
          bottom: '4px',
          left: 0,
          right: 0,
          margin: '0 auto',
          width: 'calc(100% - 24px)',
          maxWidth: '420px',
          height: '230px',
          background: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent className={s.stepmeterDialogContent}>
        <ButtonGroup orientation="vertical" className={s.stepmeterButtonGroup}>
          <Button variant="contained" color="white" size="M">
            <Typography variant="text7" color="black" opacity={0.5}>
              Шагомер
            </Typography>
          </Button>

          <Divider />

          <Button variant="contained" color="white" size="L" onClick={goToAddStepmeterDay}>
            <Typography variant="text6" color="black">
              Добавить дистанцию
            </Typography>
          </Button>

          <Divider />

          <Button variant="contained" color="white" size="L" onClick={handleOpenBottomSheet}>
            <Typography variant="text6" color="black">
              Изменить цель
            </Typography>
          </Button>
        </ButtonGroup>

        <Button variant="contained" color="white" size="L" fullWidth onClick={onClose}>
          <Typography variant="text6" color="black" fontWeight={700}>
            Отменить
          </Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export const StepmeterDialogMemo = memo(StepmeterDialog);
