import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyFeedbackMethod = lazy(() => import('./FeedbackMethod'));

export const FeedbackMethod = () => (
  <Suspense fallback={<Loading />}>
    <LazyFeedbackMethod />
  </Suspense>
);
