import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyProfile = lazy(() => import('./Profile'));

export const Profile = () => (
  <Suspense fallback={<Loading />}>
    <LazyProfile />
  </Suspense>
);
