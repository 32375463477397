import { ReactNode } from 'react';
import s from './styles.module.scss';
import { Box } from '@mui/material';
import { SaveScroll } from '../..';
import { SaveScrollPathnames } from '../../../utils';

export const TEST_ID = 'SliderCards';

const skeletons = [1, 2];

export type TRenderCard<T> = (params: T) => ReactNode;

export type TSkeleton = () => ReactNode;

interface IProps<T> {
  options: T[];
  renderCard: TRenderCard<T>;
  skeleton?: TSkeleton;
  loading?: boolean;
  renderLimit?: number;
  saveScrollKey: string;
}

function SliderCards<T>({ options, renderCard, skeleton, loading, renderLimit, saveScrollKey }: IProps<T>) {
  const renderOptions = [...options];

  if (renderLimit && options.length > renderLimit) renderOptions.length = renderLimit;

  return (
    <div className={s.sliderCards} data-testid={TEST_ID}>
      <SaveScroll
        className={s.scrollPadding}
        direction="row"
        pathname={[SaveScrollPathnames.sliderCards, saveScrollKey]}
      >
        {!loading &&
          options.map((course, index) => (
            <Box width="57%" flexShrink={0} key={index}>
              {Boolean(renderCard) && renderCard(course)}
            </Box>
          ))}

        {loading && skeletons.map((skel) => <Box key={skel}>{Boolean(skeleton) && skeleton?.()}</Box>)}
      </SaveScroll>
    </div>
  );
}

export default SliderCards;
