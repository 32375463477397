import { Paths } from '../utils';
import { IPromoBanner } from './types';
import bannerPro from '../assets/image/bannerPro.png';

const { paySubscribe } = Paths;

export const bannerListMock: IPromoBanner[] = [{ cover: bannerPro, link: `#${paySubscribe}` }];

// export const durationFiltersSchemaMock: IDurationFiltersSchema[] = [
//   { name: 'до 5 минут', to: 5 },
//   { name: '5-15 минут', from: 5, to: 15 },
//   // { name: 'менее 15 минут', to: 15 },
//   { name: '15-25 минут', from: 15, to: 25 },
//   { name: '+30 минут', from: 30 },
// ];

//Варианты рендеринга
// export const renderSchemaMock: TRenderSchemaElem[] = [
//   { variant: RenderSchemaVariants.collections, id: 1 },
//   {
//     variant: RenderSchemaVariants.group,
//     ids: [{ id: 33 }, { id: 34 }, { id: 35 }, { id: 36 }, { id: 37 }, { id: 38 }],
//     //type не используется, переведено на систему идентификации по id
//     type: 'target',
//     component: 'iconCard',
//   },
//   // { variant: RenderSchemaVariants.banner, autoplay: { delay: 3000, disableOnInteraction: false } },
//   { variant: RenderSchemaVariants.group, type: VirtualGroups.level, title: 'Уровень' },
//   {
//     variant: RenderSchemaVariants.group,
//     ids: [{ id: 39 }, { id: 40 }, { id: 41 }, { id: 42 }],
//     type: 'muscles',
//     title: 'Группа мышц',
//     component: 'smallCard',
//   },
//   // { variant: RenderSchemaVariants.collections, id: 2 },
//   {
//     title: 'Время',
//     variant: RenderSchemaVariants.group,
//     type: VirtualGroups.duration,
//   },
// ];
